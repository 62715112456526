import React, { type FC } from 'react'
import { CgClose, CgCloseO, CgSpinner } from 'react-icons/cg'
import { FaSpinner } from 'react-icons/fa'

interface Props {
  size?: 'sm' | 'md' | 'lg'
  onClick: (a: any) => void
  disabled?: boolean
  disabledClassName?: string
  className?: string
  enableHoverScale?: boolean
  enableHoverShadow?: boolean
  loading?: boolean
  type?: 'button' | 'submit'
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const Button: FC<Props> = ({
  size = 'md',
  onClick = () => null,
  disabled = false,
  disabledClassName = '',
  className = '',
  children,
  enableHoverScale = false,
  enableHoverShadow = true,
  loading = false,
  ...otherProps
}) => {
  return (
    <button
      {...otherProps}
      className={`button px-4 flex flex-row justify-center items-center gap-2 transition-all duration-300 ${size === 'sm' ? 'py-2 text-sm' : 'py-3 text-base'} font-medium select-none rounded-md text-white bg-blue-600 overflow-hidden 
        hover:opacity-70
        ${enableHoverShadow ? 'hover:shadow' : ''} ${enableHoverScale ? 'hover:scale-105' : ''} 
        opacity-100 transition-all duration-200 ${className} ${disabled ? `disabled ${disabledClassName}` : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <CgSpinner className={`animate-spin text-xl ${!loading ? 'opacity-0 invisible' : 'opacity-100 visible'}`} />
      )}
      {children}
    </button>
  )
}


export const ButtonClose = ({ onClick, className = '' }: { onClick: () => void; className?: string }) => {
  return (
    <button
      className={`button absolute border-2 border-gray-200 right-5 top-5 h-8 w-8 rounded-full flex flex-row justify-center items-center gap-2 transition-all duration-300 text-sm font-medium text-white bg-neutral-700 overflow-hidden 
        hover:opacity-80
        hover:shadow hover:scale-105
        opacity-100 ${className}`}
      onClick={onClick}
    >
      <CgClose className='text-lg' />
    </button>
  )
}