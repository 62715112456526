import React, { ChangeEvent, FC } from 'react'
import { DebounceInput } from 'react-debounce-input';

export interface TextInputProps {
  value: string | number
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  name?: string
  type?: 'text' | 'number' | 'textarea'

  size?: 'sm' | 'md'
  inputClassName?: string
  className?: string
}

export const TextInput: FC<TextInputProps> = ({
  value,
  onChange,
  inputClassName = '',
  className = '',
  disabled = false,
  placeholder = '',
  iconLeft = null,
  iconRight = null,
  name = '',
  type = 'text',
  size = 'md',
}) => {

  const onChangeFunction = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  return (
    <div className={`bg-gray-100 rounded-lg flex flex-row gap-2 items-center ${iconLeft ? 'pl-2' : ''} ${className}`}>
      {iconLeft ? iconLeft : null}
      <input
        type={type}
        {...name ? { name } : {}}
        className={`${inputClassName} ${size === 'sm' ? 'px-[8px] py-[6px] text-xs border' : 'px-[8px] py-[9px] text-sm border-2'}
        w-full
        block transition-all duration-300 rounded-xl text-gray-900 bg-gray-50  border-divider-200 hover:border-divider-600 focus:outline-none focus:border-ui-blue-600 peer`}
        onChange={onChangeFunction}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      {iconRight}
    </div>
  )
}

export const TextInputDebounce: FC<TextInputProps & { debounceTimeout?: number }> = ({
  value,
  onChange,
  inputClassName = '',
  className = '',
  disabled = false,
  placeholder = '',
  iconLeft = null,
  iconRight = null,
  name = '',
  type = 'text',
  size = 'md',
  debounceTimeout = 0,
}) => {

  const onChangeFunction = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  return (
    <div className={`bg-gray-100 rounded-lg flex flex-row gap-2 items-center ${iconLeft ? 'pl-2' : ''} ${className}`}>
      {iconLeft ? iconLeft : null}
      <DebounceInput
        type={type}
        debounceTimeout={debounceTimeout || 0}
        {...name ? { name } : {}}
        className={`${inputClassName} ${size === 'sm' ? 'px-[8px] py-[6px] text-xs border' : 'px-[8px] py-[9px] text-sm border-2'}
        w-full
        block transition-all duration-300 rounded-xl text-gray-900 bg-gray-50  border-divider-200 hover:border-divider-600 focus:outline-none focus:border-ui-blue-600 peer`}
        onChange={onChangeFunction}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      {iconRight}
    </div>
  )
}

export default TextInput