import { BiDotsVertical, BiDotsVerticalRounded, BiFolder, BiSearch, Button, FiCommand, MdOutlineChatBubble, Tooltip } from "@acme/ui"
import { useAtomValue, useSetAtom } from "jotai"
import { chatSessionsAddNewAtom } from "../store/chat"
import { isSearchAtom } from "../store/util"
import { useRouter } from "next/router"
import { useUserSubscription } from "../hooks"
import { chatDefaultSettingsAtom } from "../store/setting"

export const ChatAction = () => {
  const { isAllowAddChatSession, isLoggedIn } = useUserSubscription()
  const router = useRouter()
  const setIsSearch = useSetAtom(isSearchAtom)
  const onNewChat = useSetAtom(chatSessionsAddNewAtom)
  const chatDefaultSettings = useAtomValue(chatDefaultSettingsAtom)

  const handleNewChat = async () => {
    const newId = await onNewChat({ isAllowAddChatSession, ...chatDefaultSettings?.defaultModel ? { defaultModel: chatDefaultSettings.defaultModel } : {} })
    if (newId) {
      router.push({ query: { chatId: newId } })
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-3 pb-[7px] -ml-2 -mt-2 -mr-2 p-2">
        <h1 className="text-xl font-bold text-gray-100 ml-1">Chats</h1>
        <Button
          className="w-[100px] h-8 !bg-blue-600"
          onClick={handleNewChat}
        >
          <MdOutlineChatBubble />
          New
        </Button>
      </div>
      <div className="flex flex-row gap-1 mb-1">
        <Button
          onClick={() => setIsSearch(true)}
          className="flex-1 flex flex-row !justify-start !pl-4 items-start !p-0 h-10 !bg-[#373636] !rounded-md"
        >
          <BiSearch />
          <p className="text-sm font-thin flex flex-row items-center">Search or <span className="flex flex-row gap-[3px] items-center ml-1"><FiCommand className="text-xs" /> K</span>...</p>
        </Button>

        <Tooltip
          position="bottom"
          message="Actions"
          popupClassName="-ml-5"
        >
          <Button
            onClick={() => setIsSearch(true)}
            className="w-[20px] !px-0 !py-0 !rounded-md bg-transparent"
          >
            <BiDotsVerticalRounded className="text-xl" />
          </Button>
        </Tooltip>
      </div>
    </>
  )
}
