import { useEffect, useState } from 'react';
import { SpeechLanguageCode } from './useSpeechText';

type TextToSpeechOptions = {
    text: string;
    lang?: SpeechLanguageCode;
    voiceURI?: string;
    volume?: number;
    rate?: number;
    pitch?: number;
}

export const useTextToSpeechVoices = () => {
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [voicesOptions, setVoicesOptions] = useState<{ label: string, value: string }[]>([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const onVoicesChanged = () => {
            const allVoices = speechSynthesis.getVoices();
            /* const availableVoices = allVoices.filter(voice => textToSpeechVoices.some(availableVoice => availableVoice.name === voice.name));
            const availableVoiceNames = availableVoices.map(voice => voice.name); */
            setVoicesOptions(allVoices.map(a => ({ label: `${a.name} - ${a.lang}`, value: a.voiceURI })));
            setVoices(allVoices);
            setIsReady(true);
        };

        speechSynthesis.addEventListener('voiceschanged', onVoicesChanged);

        return () => {
            speechSynthesis.removeEventListener('voiceschanged', onVoicesChanged);
        };
    }, []);

    return { voices, voicesOptions, isReady };
};

export const useTextToSpeech = () => {
    const [isSpeaking, setIsSpeaking] = useState(false);

    const speak = (options: TextToSpeechOptions) => {
        const utterance = new SpeechSynthesisUtterance(options.text);

        const allVoices = speechSynthesis.getVoices();
        const matchingVoice = allVoices.find((voice) => voice.voiceURI === options.voiceURI);
        // If a matching voice is found, set it as the utterance voice
        if (matchingVoice) {
            utterance.voice = matchingVoice;
        }

        utterance.lang = options.lang || 'en-US';
        utterance.volume = options.volume || 1;
        utterance.rate = options.rate || 1;
        utterance.pitch = options.pitch || 1;

        utterance.onstart = () => {
            setIsSpeaking(true);
        };

        utterance.onend = () => {
            setIsSpeaking(false);
        };

        speechSynthesis.speak(utterance);
    };

    const stop = () => {
        speechSynthesis.cancel();
        setIsSpeaking(false);
    };

    return { isSpeaking, speak, stop };
};


/* export const textToSpeechVoices = [
    { "name": "Albert", "language": "en-US" },
    { "name": "Alice", "language": "it-IT" },
    { "name": "Alva", "language": "sv-SE" },
    { "name": "Amira", "language": "ms-MY" },
    { "name": "Amélie", "language": "fr-CA" },
    { "name": "Anna", "language": "de-DE" },
    { "name": "Bad News", "language": "en-US" },
    { "name": "Bahh", "language": "en-US" },
    { "name": "Bells", "language": "en-US" },
    { "name": "Boing", "language": "en-US" },
    { "name": "Bubbles", "language": "en-US" },
    { "name": "Carmit", "language": "he-IL" },
    { "name": "Cellos", "language": "en-US" },
    { "name": "Damayanti", "language": "id-ID" },
    { "name": "Daniel", "language": "en-GB" },
    { "name": "Daria", "language": "bg-BG" },
    { "name": "Eddy (English (UK))", "language": "en-GB" },
    { "name": "Eddy (English (US))", "language": "en-US" },
    { "name": "Eddy (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Eddy (French (Canada))", "language": "fr-CA" },
    { "name": "Eddy (French (France))", "language": "fr-FR" },
    { "name": "Eddy (German (Germany))", "language": "de-DE" },
    { "name": "Eddy (Italian (Italy))", "language": "it-IT" },
    { "name": "Eddy (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Eddy (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Eddy (Spanish (Spain))", "language": "es-ES" },
    { "name": "Ellen", "language": "nl-BE" },
    { "name": "Flo (English (UK))", "language": "en-GB" },
    { "name": "Flo (English (US))", "language": "en-US" },
    { "name": "Flo (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Flo (French (Canada))", "language": "fr-CA" },
    { "name": "Flo (French (France))", "language": "fr-FR" },
    { "name": "Flo (German (Germany))", "language": "de-DE" },
    { "name": "Flo (Italian (Italy))", "language": "it-IT" },
    { "name": "Flo (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Flo (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Flo (Spanish (Spain))", "language": "es-ES" },
    { "name": "Fred", "language": "en-US" },
    { "name": "Good News", "language": "en-US" },
    { "name": "Grandma (English (UK))", "language": "en-GB" },
    { "name": "Grandma (English (US))", "language": "en-US" },
    { "name": "Grandma (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Grandma (French (Canada))", "language": "fr-CA" },
    { "name": "Grandma (French (France))", "language": "fr-FR" },
    { "name": "Grandma (German (Germany))", "language": "de-DE" },
    { "name": "Grandma (Italian (Italy))", "language": "it-IT" },
    { "name": "Grandma (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Grandma (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Grandma (Spanish (Spain))", "language": "es-ES" },
    { "name": "Grandpa (English (UK))", "language": "en-GB" },
    { "name": "Grandpa (English (US))", "language": "en-US" },
    { "name": "Grandpa (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Grandpa (French (Canada))", "language": "fr-CA" },
    { "name": "Grandpa (French (France))", "language": "fr-FR" },
    { "name": "Grandpa (German (Germany))", "language": "de-DE" },
    { "name": "Grandpa (Italian (Italy))", "language": "it-IT" },
    { "name": "Grandpa (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Grandpa (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Grandpa (Spanish (Spain))", "language": "es-ES" },
    { "name": "Ioana", "language": "ro-RO" },
    { "name": "Jacques", "language": "fr-FR" },
    { "name": "Jester", "language": "en-US" },
    { "name": "Joana", "language": "pt-PT" },
    { "name": "Junior", "language": "en-US" },
    { "name": "Kanya", "language": "th-TH" },
    { "name": "Karen", "language": "en-AU" },
    { "name": "Kathy", "language": "en-US" },
    { "name": "Kyoko", "language": "ja-JP" },
    { "name": "Lana", "language": "hr-HR" },
    { "name": "Laura", "language": "sk-SK" },
    { "name": "Lekha", "language": "hi-IN" },
    { "name": "Lesya", "language": "uk-UA" },
    { "name": "Linh", "language": "vi-VN" },
    { "name": "Luciana", "language": "pt-BR" },
    { "name": "Majed", "language": "ar-001" },
    { "name": "Meijia", "language": "zh-TW" },
    { "name": "Melina", "language": "el-GR" },
    { "name": "Milena", "language": "ru-RU" },
    { "name": "Moira", "language": "en-IE" },
    { "name": "Montse", "language": "ca-ES" },
    { "name": "Mónica", "language": "es-ES" },
    { "name": "Nora", "language": "nb-NO" },
    { "name": "Organ", "language": "en-US" },
    { "name": "Paulina", "language": "es-MX" },
    { "name": "Ralph", "language": "en-US" },
    { "name": "Reed (English (UK))", "language": "en-GB" },
    { "name": "Reed (English (US))", "language": "en-US" },
    { "name": "Reed (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Reed (French (Canada))", "language": "fr-CA" },
    { "name": "Reed (French (France))", "language": "fr-FR" },
    { "name": "Reed (German (Germany))", "language": "de-DE" },
    { "name": "Reed (Italian (Italy))", "language": "it-IT" },
    { "name": "Reed (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Reed (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Reed (Spanish (Spain))", "language": "es-ES" },
    { "name": "Rishi", "language": "en-IN" },
    { "name": "Rocko (English (UK))", "language": "en-GB" },
    { "name": "Rocko (English (US))", "language": "en-US" },
    { "name": "Rocko (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Rocko (French (Canada))", "language": "fr-CA" },
    { "name": "Rocko (French (France))", "language": "fr-FR" },
    { "name": "Rocko (German (Germany))", "language": "de-DE" },
    { "name": "Rocko (Italian (Italy))", "language": "it-IT" },
    { "name": "Rocko (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Rocko (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Rocko (Spanish (Spain))", "language": "es-ES" },
    { "name": "Samantha", "language": "en-US" },
    { "name": "Sandy (English (UK))", "language": "en-GB" },
    { "name": "Sandy (English (US))", "language": "en-US" },
    { "name": "Sandy (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Sandy (French (Canada))", "language": "fr-CA" },
    { "name": "Sandy (French (France))", "language": "fr-FR" },
    { "name": "Sandy (German (Germany))", "language": "de-DE" },
    { "name": "Sandy (Italian (Italy))", "language": "it-IT" },
    { "name": "Sandy (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Sandy (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Sandy (Spanish (Spain))", "language": "es-ES" },
    { "name": "Sara", "language": "da-DK" },
    { "name": "Satu", "language": "fi-FI" },
    { "name": "Shelley (English (UK))", "language": "en-GB" },
    { "name": "Shelley (English (US))", "language": "en-US" },
    { "name": "Shelley (Finnish (Finland))", "language": "fi-FI" },
    { "name": "Shelley (French (Canada))", "language": "fr-CA" },
    { "name": "Shelley (French (France))", "language": "fr-FR" },
    { "name": "Shelley (German (Germany))", "language": "de-DE" },
    { "name": "Shelley (Italian (Italy))", "language": "it-IT" },
    { "name": "Shelley (Portuguese (Brazil))", "language": "pt-BR" },
    { "name": "Shelley (Spanish (Mexico))", "language": "es-MX" },
    { "name": "Shelley (Spanish (Spain))", "language": "es-ES" },
    { "name": "Sinji", "language": "zh-HK" },
    { "name": "Superstar", "language": "en-US" },
    { "name": "Tessa", "language": "en-ZA" },
    { "name": "Thomas", "language": "fr-FR" },
    { "name": "Tingting", "language": "zh-CN" },
    { "name": "Trinoids", "language": "en-US" },
    { "name": "Tünde", "language": "hu-HU" },
    { "name": "Whisper", "language": "en-US" },
    { "name": "Wobble", "language": "en-US" },
    { "name": "Xander", "language": "nl-NL" },
    { "name": "Yelda", "language": "tr-TR" },
    { "name": "Yuna", "language": "ko-KR" },
    { "name": "Zarvox", "language": "en-US" },
    { "name": "Zosia", "language": "pl-PL" },
    { "name": "Zuzana", "language": "cs-CZ" }
] as const

export type TextToSpeechVoice = typeof textToSpeechVoices[number]['name'];

export const textToSpeechVoicesOptions = textToSpeechVoices.map(voice => ({ label: `${voice.name} - ${voice.language}`, value: voice.name }));
 */
