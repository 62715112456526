import { type ReactNode, useMemo, useEffect, type RefObject, useRef } from 'react'
import { HeaderMain } from '../header/HeaderMain';
import { ChatCompose } from '../chat';
import { useRouter } from 'next/router';
import { modelPopupAtom } from '../store/instruction';
import { useAtom, useSetAtom } from 'jotai';
import { InstructionsPopup } from '../popup/InstructionPopup';
import { PromptPopup } from '../popup/PromptPopup';
import { MainLeftMenu } from '../header/LeftMenu';
import { PopupSearch } from '../popup/SearchPopup';
import { isSearchAtom } from '../store/util';
import { UpgradeModal } from '../popup/UpgradePopup';
import { ApiKeyModal } from '../popup/ApiKeyPopup';
import { BiChevronDown } from '@acme/ui';
import { useScrollToBottom } from '../hooks/useScrollToBottom';
import { FileAttachPopup } from '../popup/FileAttachPopup';

type LayoutMainProps = {
  title: ReactNode;
  titleMenu: ReactNode;
  children: ReactNode;
  leftMenu: ReactNode;
  leftMenuAction: ReactNode;
};

export function LayoutMain(props: LayoutMainProps) {
  const { title, titleMenu, children, leftMenu, leftMenuAction } = props;

  const scrollRef = useRef<HTMLElement>(null);
  const router = useRouter();
  const setSearchPopup = useSetAtom(isSearchAtom)

  const activePath = useMemo<'note' | 'chat' | ''>(() => {
    if (router.pathname === '/' || router.pathname === '/chat') {
      return 'chat';
    } else if (router.pathname === '/note') {
      return 'note';
    } else {
      return '';
    }
  }, [router.pathname]);

  const [isNotBottom, onScrollToBottom] = useScrollToBottom(scrollRef, 300, activePath === 'chat', 100)

  const isHideMenuContent = useMemo(() => {
    // if /extension/*/**  or /settings/*/** then hide menu content
    if (router.pathname.startsWith('/extension') || router.pathname.startsWith('/settings')) {
      return true;
    }
    return false;
  }, [router.pathname]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/' || e.key === 'k') {
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          setSearchPopup(true);
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setSearchPopup]);

  // Useeffect if on mobile and path is chat (/) and has chatId in query then user click back it should prevent go back but open the chat list sidebar
  useEffect(() => {
    if (activePath === 'chat' && router.query.chatId) {
      const handlePopState = (e: PopStateEvent) => {
        e.preventDefault();
        router.push({ pathname: '/chat', query: {} });
      };
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [activePath, router]);

  return (
    <>
      <PopupSearch />
      <UpgradeModal />
      <ApiKeyModal />
      <div className="flex h-screendvh max-h-screen overflow-hidden">
        {/* Left Navbar */}
        <MainLeftMenu
          action={leftMenuAction}
        >
          {leftMenu}
        </MainLeftMenu>

        {/* Main Content */}
        <div className={`flex flex-col flex-grow relative ${!isHideMenuContent ? 'lg:max-w-[calc(100%-19rem)]' : 'max-w-[calc(100%-3.5rem)]'}  max-w-[100vw]`}>
          {/* Fixed Header */}
          <HeaderMain title={title} className="!absolute !w-[100%]">
            {titleMenu}
          </HeaderMain>

          {/* Content Container */}
          <main className="w-full flex-grow max-h-screen overflow-y-auto relative pt-12 p-2 bg-[#0b141a]">

            {/* <div className="h-[calc(100vh-55px)] w-[calc(100%-15px)] absolute bg-[#353541] rounded-lg"
              style={{
                //backgroundImage: "url(https://camo.githubusercontent.com/854a93c27d64274c4f8f5a0b6ec36ee1d053cfcd934eac6c63bed9eaef9764bd/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67)",
                // backgroundRepeat: 'repeat',
                // backgroundSize: '412.5px 749.25px',
                // opacity: 0.04,
                zIndex: 0
              }}
            /> */}

            <div className="h-full overflow-y-auto bg-white dark:bg-[#353541] rounded-lg" ref={scrollRef as RefObject<HTMLDivElement>}>
              {/* Middle Content */}
              {/* Add your content here */}
              {children}
              {activePath === 'chat' && <ChatCompose />}
              <Popup />
              {/* {activePath === 'note' && <NoteCompose />} */}
            </div>

            {(isNotBottom && activePath === 'chat') && (
              <div className="absolute right-2 sm:right-10 bottom-[6.5rem] z-[999999]">
                <button
                  className="bg-neutral-700 dark:bg-neutral-300 rounded-xl p-1 shadow-md cursor-pointer"
                  onClick={onScrollToBottom}
                >
                  <BiChevronDown className="text-white dark:text-neutral-800 w-5 h-5 text-xl sm:w-6 sm:h-6 sm:text-2xl" />
                </button>
              </div>
            )}

            {/* Footer */}
            {/* <div className="absolute w-full left-0 bottom-5 flex items-center justify-center">
              <div className="flex flex-row gap-2 items-center justify-between p-4 rounded shadow-sm bg-white w-full lg:w-[35vw]">
              </div>
            </div> */}
          </main>
        </div>
      </div>
    </>
  );
}

const Popup = () => {
  const [modelPopup, setModelPopup] = useAtom(modelPopupAtom)
  return (
    <>
      {(modelPopup.open && modelPopup.type === 'instruction') && <InstructionsPopup />}
      {(modelPopup.open && modelPopup.type === 'prompt') && <PromptPopup />}
      {(modelPopup.open && modelPopup.type === 'attach-file') && <FileAttachPopup />}
    </>
  )
}

