import { chatModels, type GPTChatModel, type SavedInstruction, type SavedPrompt } from "@acme/util";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const modelPopupAtom = atom<{ open: boolean, type: 'prompt' | 'instruction' | 'attach-file' | '' }>({ open: false, type: '' })
export const popupSettingsInstructionTriggerAtom = atom<{ type?: 'note' | 'chat' | null, instruction?: string }>({})

export const savedInstructionsAtom = atomWithStorage<SavedInstruction[]>("savedInstructions", [])
export const savedPromptsAtom = atomWithStorage<SavedPrompt[]>("savedPrompts", [])

export const newInstructionPopupAtom = atom(false)
export const newInstructionAtom = atom<{ name: string, defaultModel: GPTChatModel, description: string, instructions: string, isSync?: boolean, isUpdateId?: string, isUpdateSync?: boolean }>({
    name: "", defaultModel: chatModels["gpt-3.5-turbo"].id, description: "", instructions: "", isSync: false, isUpdateId: "", isUpdateSync: false
})
export const newPromptAtom = atom<{ name: string, prompt: string, isSync?: boolean, isUpdateId?: string, isUpdateSync?: boolean }>({
    name: "", prompt: ""
})


