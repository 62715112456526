import React, { type FC, type ReactNode } from 'react'
import { useDelayUnmount } from '../Hooks/useDelayUnmount'

interface IConditionalRender {
  show: boolean
  elseChildren: ReactNode
  containerClassName?: string
  children?: ReactNode
}

const mountedStyle = { animation: "inAnimation 250ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 300ms ease-out",
  animationFillMode: "forwards"
};

// !TODO Fix the animation
export const ConditionalRenderWithElse: FC<IConditionalRender> = (props) => {
  const { show = false, children, elseChildren } = props
  if (!show) {
    return <>{elseChildren}</>
  } else {
    return <>{children}</>
  }
}

export const ConditionalRenderWithElseAnimated: FC<IConditionalRender> = (props) => {
  const { show = false, children, elseChildren, containerClassName = '' } = props
  const showDiv = useDelayUnmount(show, 300);

  if (!showDiv) {
    return <div className={containerClassName} style={!show ? mountedStyle : unmountedStyle}>{elseChildren}</div>
  } else {
    return <div className={containerClassName} style={show ? mountedStyle : unmountedStyle}>{children}</div>
  }
}

export default ConditionalRenderWithElse
