/* eslint-disable @typescript-eslint/ban-ts-comment */
import { type ReactNode, useCallback, useEffect, useState, memo, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { Button } from "../Button";
import { toast } from "../Toast";

import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx';
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript';
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import jsx from 'react-syntax-highlighter/dist/cjs/languages/prism/jsx';
import css from 'react-syntax-highlighter/dist/cjs/languages/prism/css';
import go from 'react-syntax-highlighter/dist/cjs/languages/prism/go';
import java from 'react-syntax-highlighter/dist/cjs/languages/prism/java';
import php from 'react-syntax-highlighter/dist/cjs/languages/prism/php';
import ruby from 'react-syntax-highlighter/dist/cjs/languages/prism/ruby';
import rust from 'react-syntax-highlighter/dist/cjs/languages/prism/rust';
import swift from 'react-syntax-highlighter/dist/cjs/languages/prism/swift';
import yaml from 'react-syntax-highlighter/dist/cjs/languages/prism/yaml';
import c from 'react-syntax-highlighter/dist/cjs/languages/prism/c';
import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss';
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash';
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { BsSearch } from "react-icons/bs";
import { Tooltip } from "../Wrapper";
import React from "react";

SyntaxHighlighter.registerLanguage('tsx', tsx)
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('css', css);
SyntaxHighlighter.registerLanguage('go', go);
SyntaxHighlighter.registerLanguage('java', java);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('ruby', ruby);
SyntaxHighlighter.registerLanguage('rust', rust);
SyntaxHighlighter.registerLanguage('swift', swift);
SyntaxHighlighter.registerLanguage('yaml', yaml);
SyntaxHighlighter.registerLanguage('c', c);
SyntaxHighlighter.registerLanguage('scss', scss);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('markdown', markdown);
SyntaxHighlighter.registerLanguage('json', json);

const javascriptList = ["javascript", "js", "jsx", "ts", "tsx"];

export function renderImagePreview(imageUrl: string) {
    const maxLength = 30; // Change this value to adjust the maximum length of the trimmed link
    const trimmedUrl = imageUrl.length > maxLength ? `${imageUrl.substring(0, maxLength)}...` : imageUrl;

    return (
        <div className="mt-4">
            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
                {trimmedUrl}
            </a>
            <img src={imageUrl} alt="Preview" className="w-full mt-2 rounded-lg" />
        </div>
    );
}


const LinkPreview = ({ link }: { link: string }) => {
    const hoverRef = useRef(null);
    const isHovering = true // useHover(hoverRef); // Replace with your custom useHover hook or a hover detection method

    return (
        <span className="inline-flex flex-row gap-1 items-center mr-1" ref={hoverRef}>
            <a href={link} target="_blank" rel="noopener noreferrer">
                {link}
            </a>
            {isHovering && (
                <div className="absolute p-2 bg-white border border-gray-300 rounded-lg shadow-md">
                    <iframe src={link} title="Link Preview" width="300" height="200" />
                </div>
            )}
        </span>
    );
};

export const TextMarkDown = memo(({ children, className }: { children: string, className?: string }) => {

    const [imageUrls, setImageUrls] = useState<string[]>([]);

    const onCopy = useCallback(async (text: string) => {
        await navigator.clipboard.writeText(text);
        toast.success("Copied to clipboard");
    }, []);


    const onOpenCodepen = useCallback((code: string) => {
        const form = document.createElement('form');
        form.action = 'https://codepen.io/pen/define';
        form.method = 'POST';
        form.target = '_blank';

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'data';

        const data = {
            title: 'My Codepen Snippet',
            js: code,
            editors: '001', // Binary flags: 1 for HTML, 10 for CSS, 100 for JS (e.g. 110 for HTML+CSS)
        };

        input.value = JSON.stringify(data);
        form.appendChild(input);
        document.body.appendChild(form);

        form.submit();

        document.body.removeChild(form);
    }, []);

    const onCopyColor = useCallback(async (text: string) => {
        await navigator.clipboard.writeText(text);
        toast.success("Copied to clipboard");
    }, []);


    const processNode = useCallback((node: ReactNode): ReactNode[] => {
        if (typeof node === 'string') {
            const processed: ReactNode[] = [];

            const words = node.split(' ');

            words.forEach((word, index) => {
                if (isColor(word)) {
                    processed.push(renderColorShape(word, onCopyColor));
                } else if (isDomain(word)) {
                    processed.push(renderDomainPreview(word));
                } /* else if (isImageLink(word)) {
                    console.log({ word })
                    setImageUrls((prev) => [...prev, word]);
                } else if (isLink(word)) {
                    processed.push(<LinkPreview key={`link-${index}`} link={word} />);
                } */ else {
                    processed.push(word);
                }

                if (index < words.length - 1) {
                    processed.push(' ');
                }
            });

            return processed;
        }

        if (Array.isArray(node)) {
            return node.flatMap(processNode);
        }

        return [node];
    }, [onCopyColor]);

    return (
        <>
            <ReactMarkdown
                className={className}
                remarkPlugins={[remarkGfm]}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')

                        /* EXAMPLE: https://amirardalan.com/blog/syntax-highlight-code-in-markdown */

                        if (!inline && match) {
                            const isJavascript = javascriptList.includes(match[1] || '');
                            return (
                                <TextMarkdownCode
                                    match={match}
                                    onOpenCodepen={onOpenCodepen}
                                    onCopy={onCopy}
                                    isJavascript={isJavascript}
                                    allProps={props}
                                    inline={inline}
                                >
                                    {children}
                                </TextMarkdownCode>
                            )
                        } else if (!inline) {
                            return (
                                <TextMarkdownCode
                                    match={null}
                                    onOpenCodepen={onOpenCodepen}
                                    onCopy={onCopy}
                                    isJavascript={false}
                                    allProps={props}
                                    inline={inline}
                                >
                                    {children}
                                </TextMarkdownCode>
                            )
                        }
                        return (
                            <TextMarkdownCode
                                match={null}
                                onOpenCodepen={onOpenCodepen}
                                onCopy={onCopy}
                                isJavascript={false}
                                allProps={props}
                                inline={inline}
                            >
                                {children}
                            </TextMarkdownCode>
                        )

                    },
                    p({ node, children, ...props }) {

                        const processedChildren = processNode(children);

                        return (
                            <TextMarkdownText
                                type="p"
                                allProps={props}
                            >
                                {processedChildren}
                            </TextMarkdownText>
                        )
                    },
                    text({ children }) {
                        const processedChildren = processNode(children);
                        return (
                            <TextMarkdownText
                                type="text"
                            >
                                {processedChildren}
                            </TextMarkdownText>
                        )
                    },
                    li: ({ node, children, ...props }) => {
                        const processedChildren = processNode(children);

                        return (
                            <TextMarkdownText
                                type="li"
                                allProps={props}
                            >
                                {processedChildren}
                            </TextMarkdownText>
                        )
                    },
                    img: ({ node, children, ...props }) => {
                        return (
                            <TextMarkdownText
                                type="img"
                                allProps={props}
                            >
                                {children}
                            </TextMarkdownText>
                        )
                    },
                }}
            >
                {children}
            </ReactMarkdown>
            {!!imageUrls?.length && (
                imageUrls.map((url, index) => (
                    <React.Fragment key={`image-${index}`}>
                        {renderImagePreview(url)}
                    </React.Fragment>
                ))
            )}
        </>
    )
})
TextMarkDown.displayName = "TextMarkDown";


const TextMarkdownCode = memo(({ match, onOpenCodepen, onCopy, children, isJavascript, inline, className, allProps }: { match?: RegExpExecArray | null, onOpenCodepen: (code: string) => void, onCopy: (text: string) => void, children: ReactNode, isJavascript: boolean, inline?: boolean, className?: string, allProps: any }) => {
    if (inline) {
        return (
            <code className={`${className} bg-gray-200/20 dark:text-white py-[2px] text-yellow-800 rounded px-2`} {...allProps}>
                {children}
            </code>
        )
    }
    return (
        <div className="my-4 max-w-full w-full overflow-auto non-inline-match">
            {!match ? (
                <SyntaxHighlighter
                    // @ts-ignore
                    style={oneDark}
                    showLineNumbers={true}
                    PreTag="div"
                    customStyle={{
                        border: '1px solid #4a4a4a',
                        ...allProps?.customStyle
                    }}
                    {...allProps}
                >
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            ) : (
                <SyntaxHighlighter
                    // @ts-ignore
                    style={oneDark}
                    language={match[1]}
                    showLineNumbers={true}
                    PreTag="div"
                    customStyle={{
                        border: '1px solid #4a4a4a',
                        ...allProps?.customStyle
                    }}
                    {...allProps}
                >
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            )}

            <div className="flex flex-row items-center gap-2">
                <Button
                    className="!py-1 text-xs mt-1 bg-gray-500"
                    onClick={() => onCopy(String(children).replace(/\n$/, ''))}
                >
                    Copy Code
                </Button>
                {isJavascript && (
                    <Button
                        className="!py-1 text-xs mt-1 ml-2 bg-green-500"
                        onClick={() => onOpenCodepen(String(children).replace(/\n$/, ""))}
                    >
                        Open in Codepen
                    </Button>
                )}
            </div>
        </div>
    )
})
TextMarkdownCode.displayName = "TextMarkdownCode";

const TextMarkdownText = memo(({ children, className, type, allProps }: { children: ReactNode | ReactNode[], className?: string, type: 'li' | 'p' | 'text' | 'img', allProps?: any }) => {
    if (type === 'li') {
        return (
            <li className={className} {...allProps}>
                {children}
            </li>
        )
    }
    if (type === 'p') {
        return (
            <p
                className={className}
                style={{
                    ...allProps?.style,
                    whiteSpace: 'pre-line'
                }}
                {...allProps}
            >
                {children}
            </p>
        )
    }
    if (type === 'img') {
        return (
            <div className="bg-white dark:bg-neutral-600 p-2 w-[250px] rounded-md shadow-md">
                <img {...allProps} className="max-w-full w-full rounded-md" />
            </div>
        )
    }
    return (
        <>{children}</>
    )
})
TextMarkdownText.displayName = "TextMarkdownText";


// Check if the text is a valid color (Hex, RGB, or RGBA)
export function isColor(text: string): boolean {
    const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    const rgbColorRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
    const rgbaColorRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0(\.\d+)?|1(\.0+)?)\)$/;
    return hexColorRegex.test(text) || rgbColorRegex.test(text) || rgbaColorRegex.test(text);
}

// Check if the text is a valid domain
export function isDomain(text: string): boolean {
    const domainRegex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
    return domainRegex.test(text);
}

// Check if the text is a valid image link
export function isImageLink(text: string): boolean {
    const imageLinkRegex = /\.(jpe?g|png|gif|bmp|webp|tiff?)(\?.*)?$/i;
    const urlRegex = /^https?:\/\/[^\s$.?#].[^\s]*$/;
    return urlRegex.test(text) && imageLinkRegex.test(text);
}

export function isLink(text: string): boolean {
    const linkRegex = /^https?:\/\/[^\s$.?#].[^\s]*$/;
    return linkRegex.test(text);
}

// Render image preview given the image URL
/* export function renderImagePreview(imageUrl: string) {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => setImageLoaded(true);
    }, [imageUrl]);

    return (
        <span className="image-preview-container">
            {imageLoaded ? (
                <img src={imageUrl} alt="Preview" className="image-preview" />
            ) : (
                <span>Loading image...</span>
            )}
        </span>
    );
} */

export function renderColorShape(color: string, onCopyColor: (text: string) => void) {
    return (
        <span className="inline-flex flex-row gap-1 items-center mr-1" onClick={() => onCopyColor(color)}>
            <Tooltip
                message="Copy color"
                className="flex flex-row gap-1 items-center cursor-pointer !z-0"
                popupClassName="!top-[-30px] w-[80px]"
                position="top"
            >
                <span className="color-square w-3 h-3 rounded cursor-pointer" style={{ backgroundColor: color }} />
                <span className="color-text">{color}</span>
            </Tooltip>
        </span>
    );
}

// Render domain preview with a link to check domain availability
export function renderDomainPreview(domain: string) {
    const checkDomainAvailabilityUrl = `https://www.godaddy.com/domainsearch/find?domainToCheck=${encodeURIComponent(
        domain
    )}`;

    const previewDomain = () => {
        // Function to preview the domain (e.g., show a modal with an iframe)
    };

    return (
        <span className="inline-flex flex-row gap-1 items-center mr-1">
            <span className="domain-text" onMouseOver={previewDomain}>
                {domain}
            </span>
            <Tooltip
                message="Check Domain"
                className="block cursor-pointer !z-0"
                popupClassName="!top-[-35px] w-[100px]"
                position="top"
            >
                <a
                    href={checkDomainAvailabilityUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-300 rounded-lg p-1"
                >
                    <BsSearch className="text-black text-[10px]" />
                </a>
            </Tooltip>
        </span>
    );
}

type ILinkPreview = { title: string; url: string };

const getLinkPreview = async (link: string): Promise<ILinkPreview> => {
    const response = await fetch(`https://api.linkpreview.net/?key=5f8b1b0e4b9c4e0c8c8b4f7b8f8b1b0e4b9c4e0c8c8b4f7b8f8b1b0e4b9c4e0c8c8b4f7b8f&q=${link}`);
    const data = await response.json();
    return data;
};

/*  const useLinkPreview = (link: string) => {
    const [linkPreview, setLinkPreview] = useState<LinkPreview | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchLinkPreview = async () => {
            setIsLoading(true);
            const linkPreview = await getLinkPreview(link);
            setLinkPreview(linkPreview);
            setIsLoading(false);
        };

        fetchLinkPreview();
    }, [link]);

    return { linkPreview, isLoading };
};

const LinkPreview = ({ link }: { link: string }) => {
    const [linkPreview, setLinkPreview] = useState<ILinkPreview | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const fetchLinkPreview = async () => {
        const data = await getLinkPreview(link);
        setLinkPreview(data);
    };

    const handleMouseOver = (event: React.MouseEvent<HTMLSpanElement>) => {
        console.log('overr')
        const rect = event.currentTarget.getBoundingClientRect();
        setPosition({ x: rect.x, y: rect.y });
        fetchLinkPreview();
        setShowPreview(true);
    };

    const handleMouseOut = () => {
        setShowPreview(false);
    };

    return (
        <>
            <span
                className="domain-text bg-red-500"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {link}
            </span>
            {showPreview && linkPreview && position && (
                <div
                    style={{
                        position: "absolute",
                        left: position.x,
                        top: position.y,
                        zIndex: 1000,
                    }}
                >
                    <a href={linkPreview.url} target="_blank" rel="noopener noreferrer">
                        {linkPreview.title}
                    </a>
                </div>
            )}
        </>
    );
};

export function renderLinkPreview(link: string) {
    return (
        <span className="inline-flex flex-row gap-1 items-center mr-1">
            <LinkPreview link={link} />
        </span>
    );
} */