import React from 'react'
import { motion } from 'framer-motion'
import Link from 'next/link'

export const headerVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
};

export const HeaderSite = ({ containerClassName }: { containerClassName?: string }) => {
    return (
        <header className={`container mx-auto px-4 py-6 flex items-center justify-between ${containerClassName}`}>
            <Link href="/">
                <motion.h1
                    className="text-white font-bold text-2xl"
                    variants={headerVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ ease: 'easeInOut', duration: 0.5 }}
                >
                    DapperGPT
                </motion.h1>
            </Link>
            <motion.a
                href="/chat"
                className="bg-white px-6 py-2 rounded font-semibold text-blue-800 hover:bg-blue-200"
                variants={headerVariants}
                initial="hidden"
                animate="visible"
                transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.3 }}
            >
                Go to App
            </motion.a>
        </header>
    )
}

