import { type ChangeEvent, type CSSProperties } from "react"
import { TextLabel } from "../Text/Body"

interface Props {
  label?: string
  value: string
  type?: 'text' | 'password' | 'number'
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  name?: string
  id: string
  size?: 'sm' | 'md'
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  className?: string
  inputClassName?: string
  labelClassName?: string
  disabled?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  helperText?: string
  errorText?: string
  style?: CSSProperties
  inputStyle?: CSSProperties

  skipDarkClass?: boolean
}

export function TextInputLabel({
  label,
  value,
  type = 'text',
  disabled = false,
  onChange,
  placeholder,
  name,
  id,
  className,
  inputClassName,
  leftIcon,
  rightIcon,
  helperText,
  errorText,
  size = 'md',
  labelClassName = '',
  style,
  inputStyle,
}: Props) {

  const onChangeFunction = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  return (
    <div style={style} className={`relative ${className}`}>
      {leftIcon ? (
        <div
          className={`absolute flex items-center justify-center w-[24px] h-[24px] max-h-[24px] left-[8px]
          ${helperText || errorText ? 'top-[calc(50%-7px)]' : 'top-[50%]'} translate-y-[-50%]`}
        >
          {leftIcon}
        </div>
      ) : null}
      {rightIcon ? (
        <div
          className={`absolute flex items-center justify-center w-[24px] h-[24px] max-h-[24px] right-[8px]
          ${helperText || errorText ? 'top-[calc(50%-7px)]' : 'top-[50%]'} translate-y-[-50%]
          `}
        >
          {rightIcon}
        </div>
      ) : null}
      <input
        type="text"
        id={id}
        name={name || id}
        disabled={disabled}
        onChange={onChangeFunction}
        value={value}
        style={inputStyle}
        className={`
        ${!!leftIcon ? 'pl-[35px]' : ''}
        ${!!rightIcon ? 'pr-[35px]' : ''}
        ${inputClassName}  
        ${size === 'sm' ? 'px-[8px] py-[7px] pt-[12px] text-xs border' : 'px-[8px] py-[9px] pt-[13px] text-sm border'}
        block transition-all duration-300 rounded-xl text-gray-900  border-divider-200 hover:border-divider-600 focus:outline-none focus:border-ui-blue-600 peer`}
        placeholder=" "
      />
      {label && (
        <label
          htmlFor={id}
          className={`${!!leftIcon ? '!left-9' : ''}
        ${size === 'sm'
              ? 'top-[13px] text-xs peer-placeholder-shown:-translate-y-[3px] scale-75 peer-focus:scale-75 peer-focus:-translate-y-3 -translate-y-3'
              : 'top-4 text-sm peer-placeholder-shown:-translate-y-[3px] scale-75 peer-focus:scale-75 peer-focus:-translate-y-4 -translate-y-4'}
        absolute  text-gray-500 duration-300 transform origin-[0] left-2.5 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 ${labelClassName}`}>
          {label || placeholder}
        </label>
      )}
      {errorText && (<p className={`text-[10px] ${errorText ? 'text-text-negative-600' : 'text-primary-600'}`}>
        {helperText || errorText}
      </p>)}
    </div>
  );
}


export const TextInputLabelForm = ({ label, value, type = 'text', disabled = false, onChange, onKeyDown, placeholder, name, id, className, inputClassName, size, skipDarkClass = false }: Props) => {
  return (
    <div className={`w-full ${className}`}>
      <TextLabel size={size} skipDarkClass={skipDarkClass}>{label}</TextLabel>
      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        {...onKeyDown ? { onKeyDown } : {}}
        className={`border-2 outline-none rounded-xl py-[8px] pt-[9px] px-4 border-gray-200 focus:border-gray-400 placeholder-gray-500 ${!skipDarkClass ? 'dark:bg-neutral-700 dark:border-neutral-400 dark:placeholder-neutral-300' : ''} w-full ${size === 'sm' ? 'text-sm' : ''} ${inputClassName}`}
      />
    </div>
  );
}

export const SelectLabelForm = ({ label, value, options, onChange, placeholder, name, id, className, inputClassName, labelClassName, size = 'md', skipDarkClass = false }: Props & { options: { label: string; value: string; disabled?: boolean }[], size?: 'sm' | 'md' }) => {
  return (
    <div className={`w-full ${className}`}>
      <TextLabel size={size} className={labelClassName} skipDarkClass>{label}</TextLabel>
      <select
        name={name}
        id={id}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value, e as any)}
        className={`border-2 placeholder-gray-500 outline-none rounded-xl py-[7px] pt-[9px] px-4 border-gray-200 focus:border-gray-400 ${!skipDarkClass ? 'dark:bg-neutral-700 dark:border-neutral-400 dark:placeholder-neutral-300' : ''}  w-full ${inputClassName}`}
      >
        {options.map((option) => (
          <option disabled={option?.disabled} key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
}
