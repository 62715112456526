import { PopupWrapper } from "@acme/ui";
import { ChatBubble, ChatConversation, ChatList } from "../chat";
import { ChatAction } from "../chat/ChatAction";
import { ChatSettings } from "../chat/ChatSettings";
import { ChatTitle, ChatTitleMenu } from "../chat/ChatTitle";
import { LayoutMain } from "./LayoutMain";

export function LayoutChat() {
  return (
    <>
      <LayoutMain
        leftMenu={<ChatList />}
        title={<ChatTitle />}
        titleMenu={<ChatTitleMenu />}
        leftMenuAction={<ChatAction />}
      >
        <div className="flex flex-col gap-2 pb-32">
          <ChatConversation />
          {/* Middle Content */}
          <ChatSettings />
        </div>
      </LayoutMain>
    </>
  );
}



