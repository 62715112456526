import { toast } from "@acme/ui";
import { atom, PrimitiveAtom, SetStateAction, WritableAtom } from "jotai"
import { atomWithStorage as atomStorage } from "jotai/utils"
import { apiKeyModalAtom } from "./user";
import { type SearchResult } from "../popup/SearchPopup";

/* export function atomWithLocalStorage<T>(
    key: string,
    initialValue: T
) {
    const getInitialValue = () => {
        const item = localStorage.getItem(key)
        if (item !== null) {
            return JSON.parse(item) as T
        }
        return initialValue
    }
    const baseAtom = atom(getInitialValue())

    const derivedAtom = atom(
        (get) => get(baseAtom),
        (get, set, update: T) => {
            const nextValue =
                typeof update === 'function' ? update(get(baseAtom)) : update
            set(baseAtom, nextValue)
            localStorage.setItem(key, JSON.stringify(nextValue))
        }
    )
    return derivedAtom
} */

export const composeContextAtom = atom('');
export const composeContextImgsAtom = atom<{ url: string, type?: 'image' }[]>([]); // For image URLs

export const themeModeAtom = atom<'dark' | 'light' | 'system'>('dark');
export const searchHistoryAtom = atom<SearchResult>([]);
export const isLoadedAtom = atomStorage<{ status: boolean, lastLoad?: null | Date }>('isLoadedAccount', { status: false, lastLoad: null });
export const isSearchAtom = atom(false);
export const isLoadAPIKeyAtom = atom(false);
export const isLoadAPIKeyTriggerAtom = atom(false)
export const appStorageAtom = atomStorage<{ number: number, version: string, lastCheck: Date | null }>('appStorage', { number: 3, version: '0.0.3', lastCheck: null })
export const appVersionCurrent = { number: 5, version: '0.0.5' }

export const sendErrorHandlerAtom = atom(
    null,
    (get, set, update: { error: any, message?: string }) => {
        const { error } = update
        console.log({ error })
        const message = error?.response?.data?.error?.message
            || error?.response?.data?.message
            || error?.response?.data?.error
            || error?.message
            || 'There are some error'
        if (message?.includes('Incorrect API key provided') || message?.includes('API key is invalid')) {
            set(apiKeyModalAtom, true)
            toast.error('Your API key is incorrect')
        } else if (message?.includes('Please add your API') || message?.includes('API key is required')) {
            set(apiKeyModalAtom, true)
            toast.error('Please add your API key')
        } else {
            toast.error(message)
        }
    }
)

export const atomWithLocalStorage = atomStorage
export const atomWithStorage = atomStorage
