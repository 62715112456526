import React, { FC, type ReactNode } from 'react'

export function Tooltip({ message, children, position = 'bottom', className = '', popupClassName = '', onClick }: { message: string, children: ReactNode, position?: 'top' | 'bottom', className?: string, popupClassName?: string, onClick?: () => void }) {
  return (
    <div className={`group/tooltip relative flex z-10 ${className}`}>
      {children}
      {position === 'bottom' ? (
        <span className={`absolute top-10 transition-all rounded bg-gray-800 border border-gray-700 p-2 text-xs text-white scale-0 group-hover/tooltip:scale-100 ${popupClassName}`}>{message}</span>
      ) : (
        <span className={`absolute top-[-50px] scale-0 transition-all rounded bg-gray-800 border border-gray-700 p-2 text-xs text-white group-hover/tooltip:scale-100 ${popupClassName}`}>{message}</span>
      )}
    </div>
  )
}