import { Button } from "../Button"

interface Props {
  children: React.ReactNode
  title: string
  isShowSave?: boolean
  isSaving?: boolean
  isShowTitle?: boolean
  onSave?: () => void
  titleClassName?: string
}


export const SectionSettingsWrapper = ({ children, title, isShowSave = false, isShowTitle = true, isSaving, onSave, titleClassName = '' }: Props) => {
  return (
    <div className='border rounded-lg p-5 px-0 pb-8 bg-white dark:bg-[#0B141A] dark:border-neutral-700 dark:text-white'>
      {isShowTitle && (
        <>
          <div className="flex flex-row justify-between items-center px-5">
            <h2 className={`text-2xl font-bold pb-4 ${titleClassName}`}>{title}</h2>
            {isShowSave && (
              <Button
                className="w-[100px] h-10"
                onClick={() => onSave ? onSave() : null}
                loading={isSaving}
                disabled={isSaving}
              >
                Save
              </Button>
            )}
          </div>
          <div className="border-b border-slate-200 dark:border-neutral-600 mb-4" />
        </>
      )
      }
      <div className='px-5'>
        {children}
      </div>
    </div>
  )
}