import React, { FC } from 'react'

interface IConditionalRender {
  show: boolean
  children?: React.ReactNode
}

export const ConditionalRender: FC<IConditionalRender> = (props) => {
  const { show = false, children } = props

  if (!show) {
    return null
  } else {
    return <>{children}</>
  }
}

export default ConditionalRender
