import React, { useEffect, useMemo, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button, ButtonClose, IoMdWarning, SwitchWithLabel, Tooltip } from "@acme/ui";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { apiKeyModalAtom, teamActiveAtom, userAtom } from "../store/user";
import { apiKeyAnthropicAtom, apiKeyAtom, apiKeyExistAtom } from "../store/setting";
import { generateNanoId } from "@acme/util";
import { api } from "@acme/client";
import { isLoadAPIKeyAtom } from "../store/util";

const faqItems = [
  {
    question: "Why do you need to fill in this API Key?",
    answer: "The API key is required to authenticate and authorize access to the GPT service. By providing a valid API key, you will be able to use the GPT-powered features of this application.",
  },
  {
    question: "Compare to ChatGPT what are the advantages (low downtime)?",
    answer: (
      <>
        <ul className="gap-1 flex flex-col">
          <li>
            <p className="font-semibold text-sm">Low Downtime</p>
            <p className="text-sm">Using your own API key reduces the downtime of the service, compared to ChatGPT where the service is dependent on the availability if you're not using a paid plan.</p>
          </li>
          <li>
            <p className="font-semibold text-sm">More Control</p>
            <p className="text-sm">By using your own API key, you have more control over the GPT settings, allowing you to fine-tune and customize the AI responses based on your preferences.</p>
          </li>
          <li>
            <p className="font-semibold text-sm">Cheaper than Paid ChatGPT</p>
            <p className="text-sm">Using your own API key is cheaper than using a paid ChatGPT plan. More details on <a href="https://openai.com/pricing" target="_blank">ChatGPT Pricing</a></p>
          </li>
        </ul>
      </>
    )
  },
  {
    question: "Is it cheaper than ChatGPT?",
    answer: "Yes, it is much cheaper than ChatGPT. 1k tokens (approx 750 words) using the default ChatGPT model is just $0.002",
  },
];

export const ApiKeyModal: React.FC = () => {
  const [apiKey, setApiKey] = useAtom(apiKeyAtom);
  const [apiKeyAnthropic, setApiKeyAnthropic] = useAtom(apiKeyAnthropicAtom);
  const [apiKeyModal, setApiKeyModal] = useAtom(apiKeyModalAtom);

  const onClose = () => {
    setApiKeyModal(false);
  };

  if (!apiKeyModal) return null;
  return (
    <ApiKeyInputModal
      type={apiKeyModal === 'anthropic' ? 'anthropic' : 'openai'}
      isOpen={true}
      onClose={onClose}
      onSave={(apiKey) => {
        if (apiKeyModal === 'anthropic') {
          setApiKeyAnthropic({ key: apiKey, id: generateNanoId(16), created: new Date() });
        } else {
          setApiKey({ key: apiKey, id: generateNanoId(16), created: new Date() });
        }
        onClose();
      }}
    />
  );
};

type UpgradeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (apiKey: string) => void;
  type?: 'openai' | 'anthropic';
};


const ApiKeyInputModal = ({ isOpen, onClose, onSave, type = 'openai' }: UpgradeModalProps) => {
  const [isAddApiKeyCloud, setIsAddApiKeyCloud] = useState(false);

  const apiKeyOpenAIState = useAtomValue(apiKeyAtom);
  const apiKeyAnthropicState = useAtomValue(apiKeyAnthropicAtom)

  const user = useAtomValue(userAtom)
  const teamActive = useAtomValue(teamActiveAtom)
  const [apiKey, setApiKey] = useState("")
  const [isEditMode, setIsEditMode] = useState(false)
  const [faqIndex, setFaqIndex] = useState(-1)
  const [apiKeyExist, setApiKeyExist] = useAtom(apiKeyExistAtom)
  const setIsLoadAPIKey = useSetAtom(isLoadAPIKeyAtom)

  const apiKeyState = useMemo(() => {
    if (type === 'anthropic') {
      return apiKeyAnthropicState
    } else {
      return apiKeyOpenAIState
    }
  }, [apiKeyOpenAIState, apiKeyAnthropicState, type])

  const { data: dataCheck, isLoading: isLoadingCheck } = api.user.teamHasAPIKey.useQuery({ teamId: teamActive?.id || '' }, { enabled: !!user?.id && !!teamActive?.id });
  const { mutateAsync: createAPIKey, isLoading: isLoadingAdd } = api.user.updateTeamAPIKey.useMutation();
  const { mutateAsync: removeApiKey, isLoading: isLoadingRemove } = api.user.removeApiKey.useMutation({
    onSuccess: () => {
      setApiKeyExist(false)
    },
    onError: () => {
      setApiKeyExist(false)
    }
  });

  const handleSave = async () => {
    if ((dataCheck || (user?.id)) && isAddApiKeyCloud) {
      await createAPIKey({ teamId: teamActive?.id || '', apiKey, type })
      setApiKeyExist(true)
    }
    if (apiKeyExist && !isAddApiKeyCloud) {
      await removeApiKey({ teamId: teamActive?.id || '', type })
    }
    onSave(apiKey);
    setIsEditMode(false);
  };

  const handleEditMode = () => {
    setIsEditMode(true);
    setIsAddApiKeyCloud(apiKeyExist)
  };

  const handleApiKeyChange = (e: any) => {
    setApiKey(e.target.value);
  };

  const isHasCloudAPI = useMemo(() => !!user?.id && !!teamActive?.id && dataCheck, [user?.id, teamActive?.id, dataCheck])
  const isLoadingAPIKey = useMemo(() => isLoadingCheck && user?.id && teamActive?.id, [isLoadingCheck, user?.id, teamActive?.id])

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const modal = {
    hidden: { y: "-50vh", opacity: 0 },
    visible: { y: "0", opacity: 1, transition: { delay: 0.5 } },
  };

  const apiKeySlicedHidden = apiKey.slice(0, 3) + apiKey.slice(3, apiKey.length - 3).replace(/./g, '*') + apiKey.slice(apiKey.length - 3)

  useEffect(() => {
    if (type === 'anthropic') {
      if (apiKeyAnthropicState) {
        setApiKey(apiKeyAnthropicState.key);
      }
    } else {
      if (apiKeyOpenAIState) {
        setApiKey(apiKeyOpenAIState.key);
      }
    }
  }, [apiKeyOpenAIState, type, apiKeyAnthropicState]);

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-[100001] flex items-center justify-center bg-gray-900 bg-opacity-50"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={backdrop}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        >
          <motion.div
            className="bg-white p-8 pt-10 rounded-lg shadow-xl w-full max-w-lg m-auto relative z-50 h-auto"
            variants={modal}
            onClick={(e) => e.stopPropagation()}
          >
            <ButtonClose onClick={onClose} />
            <h2 className="text-2xl font-bold mb-4 text-center">
              Enter Your {type === 'anthropic' ? 'Anthropic' : 'OpenAI'} API Key
            </h2>
            <div className={`${!isEditMode ? 'bg-gray-200 rounded' : ''}`}>
              {isEditMode ? (
                <input
                  type="text"
                  value={apiKey}
                  onChange={handleApiKeyChange}
                  className="border rounded w-full p-3 mb-4 text-gray-700 bg-transparent"
                  placeholder="Enter your API key"
                />
              ) : (
                <div className={`border rounded w-full p-3 mb-4 text-gray-700 max-w-full overflow-scroll`}>
                  {apiKey ? (
                    apiKeySlicedHidden
                  ) : (
                    <span className="text-gray-500">API key not entered</span>
                  )}
                </div>
              )}
            </div>
            <div className="mb-6">
              {isEditMode ? (
                <>
                  {user?.id && (
                    <div className="mb-4">
                      <Tooltip message="API Key should be added if you have synced data, public chat / note or extension">
                        <SwitchWithLabel
                          value={isAddApiKeyCloud}
                          onChange={() => setIsAddApiKeyCloud(prev => !prev)}
                          size='small'
                          textClassName='text-xs'
                          label='Save your key to cloud (only if you save chat / note in the cloud or using our chrome extension)'
                        />
                      </Tooltip>
                    </div>
                  )}
                  {(apiKeyExist && !isAddApiKeyCloud) && (
                    <div className="p-1 px-2 flex flex-row gap-2 items-center bg-red-500 rounded-lg mt-2 mb-3">
                      <IoMdWarning className="text-white" />
                      <div className="text-xs text-white">
                        <p>Are you sure you want to remove your api key on the cloud?</p>
                      </div>
                    </div>
                  )}
                  <Button
                    loading={!!isLoadingAPIKey || isLoadingAdd || isLoadingRemove}
                    className="bg-blue-600 text-white font-bold px-4 py-3 rounded-lg shadow-md hover:bg-blue-700 w-full mb-4"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <div className="flex flex-row items-center gap-2">
                  <Button
                    className="bg-gray-400 text-black font-bold px-4 py-3 rounded-lg shadow-md hover:bg-gray-400 w-full mb-4"
                    onClick={handleEditMode}
                  >
                    Edit API Key
                  </Button>
                  {!!isHasCloudAPI && (
                    <Tooltip
                      message="Check if your API stored in the cloud and save it"
                      className="w-full"
                    >
                      <Button
                        className="bg-gray-700 text-black font-bold px-4 py-3 rounded-lg shadow-md hover:bg-gray-400 w-full mb-4"
                        onClick={() => setIsLoadAPIKey(true)}
                      >
                        Check on Cloud
                      </Button>
                    </Tooltip>
                  )}
                </div>
              )}
              <a href={type === 'anthropic' ? "https://console.anthropic.com/settings/keys" : "https://platform.openai.com/account/api-keys"} target="_blank">
                <p className="text-sm text-center">
                  Get my API Key from {` `}
                  {type === 'anthropic'
                    ? (
                      <span className="text-blue-700 hover:underline">https://console.anthropic.com/settings/keys</span>
                    )
                    : (
                      <span className="text-blue-700 hover:underline">https://platform.openai.com/account/api-keys</span>
                    )
                  }
                </p>
              </a>
            </div>
            <h3 className="text-xl font-bold mb-4 text-center">FAQ</h3>
            {faqItems.map((item, index) => (
              <div key={index} className="mb-2 bg-gray-100 p-2 rounded-lg">
                <button
                  className="flex items-center w-full text-left text-blue-600 bg-gray-100 rounded-lg p-1 px-2 font-medium"
                  onClick={() => setFaqIndex(index)}
                >
                  {/* <BiQuestionMark className="mr-2" /> */}
                  {item.question}
                </button>
                <AnimatePresence>
                  {faqIndex === index && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="mt-2 text-gray-700 ml-2 text-sm"
                    >
                      {item.answer}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};