import { generateNanoId } from "../function"
import { type ChatSession, type Note } from "../interface"

export const noteDefaultInstruction = {
    system: `You are a note and document writer assistant that can help me with my tasks, documents and notes. You are in a text editor, so you can only answer as text completion and write based on the text in markdown.

Remember these:
- When asked to CONTINUE or to WRITE, directly add the requested content without any introduction or explanation or including the current note content.
- {{CURSOR}} is the current cursor position which you need to fill
- Do not include {{CURSOR}} on your response
- You can use markdown to format your response
- If there is any content after {{CURSOR}} stop your response before there`,
    continue: `Help me CONTINUE from the cursor position but dont change and include the content after.
Title: {{note.name}}.
Current Note Content State:
-----
{{note.markdown}}
-----

Continue here with context {{context}}:
`,
    write: `Help me WRITE from the cursor position but dont change and include the content after.
Title: {{note.name}}.
Current Note Content State:
-----
{{note.markdown}}
-----

WRITE here with context {{context}}:
`,
    select: `Help me update the note content with the selected content {{note.markdown.selection}} with context {{context}}:
`,
}

const today = new Date()

export const noteListDefault: Note[] = [
    {
        "id": generateNanoId(14),
        "content": [
            {
                "id": "60ec55e3-6f4d-41c7-83a1-18799a60f73c",
                "type": "heading",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left",
                    "level": "2"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Daily TODO",
                        "styles": {
                            "bold": true
                        }
                    }
                ],
                "children": []
            },
            {
                "id": "a8e15faf-cd58-41bc-9720-628db886ecb0",
                "type": "heading",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left",
                    "level": "3"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Monday",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "77c4059a-a731-4ae4-800b-3be9b10c574c",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Research potential marketing strategies",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "8354cd52-fa48-49e6-90c4-d6133276b1fa",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Develop a promotional plan",
                        "styles": {}
                    }
                ],
                "children": [
                    {
                        "id": "ca77b5f4-2638-4413-b4f1-fc663bc70e4c",
                        "type": "bulletListItem",
                        "props": {
                            "textColor": "default",
                            "backgroundColor": "default",
                            "textAlignment": "left"
                        },
                        "content": [
                            {
                                "type": "text",
                                "text": "Video",
                                "styles": {}
                            }
                        ],
                        "children": []
                    },
                    {
                        "id": "9d5589be-e813-4e0f-a178-b7d34dc45d0b",
                        "type": "bulletListItem",
                        "props": {
                            "textColor": "default",
                            "backgroundColor": "default",
                            "textAlignment": "left"
                        },
                        "content": [
                            {
                                "type": "text",
                                "text": "Social Media",
                                "styles": {}
                            }
                        ],
                        "children": []
                    }
                ]
            },
            {
                "id": "518d6b24-51b7-432e-b4eb-4aaa26ff6037",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Create marketing materials",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "fd3bb095-7cf9-496b-b4b2-6c6b9fa77f2b",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Implement the marketing campaign",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "61f176ac-1047-4605-88ad-424f75ea3614",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Monitor the effectiveness of the marketing efforts",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "919f3ae9-3c9d-4178-a15b-a763d8f40453",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            },
            {
                "id": "cf9e7cb9-1d3a-43af-b3be-cbd0ae757407",
                "type": "heading",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left",
                    "level": "3"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Tuesday",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "3db719fd-83c1-48b1-8845-098eee0d3e4d",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Reach out to potential clients and schedule meetings",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "810d9bd7-bc7b-497f-8d40-8677c7f9a9e5",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Review and analyze market research data",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "3bf68065-09eb-49a8-861b-6c55804b3e93",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Brainstorm new ideas for service offerings",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "b506bf49-0e66-408f-9a05-a4ae9c6d4cba",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Attend industry conferences and events",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "f5b7dace-2d4b-4745-8ac4-23b656483685",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Follow up with clients and leads to maintain relationships",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "50499d45-9ab5-4101-8967-9d7238ebe720",
                "type": "bulletListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Prepare reports and presentations for management and stakeholders",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "2475d8f2-3636-47e5-95f0-3670bbc39480",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            },
            {
                "id": "2bd6d268-d307-49e7-b006-f856ba3e0588",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            }
        ],
        "name": "Marketing Todo",
        "markdown": "## **Daily TODO**\n\n### Monday\n\n*   Research potential marketing strategies\n\n*   Develop a promotional plan\n\n    *   Video\n    *   Social Media\n\n*   Create marketing materials\n\n*   Implement the marketing campaign\n\n*   Monitor the effectiveness of the marketing efforts\n\n### Tuesday\n\n*   Reach out to potential clients and schedule meetings\n*   Review and analyze market research data\n*   Brainstorm new ideas for service offerings\n*   Attend industry conferences and events\n*   Follow up with clients and leads to maintain relationships\n*   Prepare reports and presentations for management and stakeholders\n",
        "created": today,
        "teamId": "",
        "userId": "clgrs3izh001ypk64i9d4k509",
        "defaultInstruction": "You are a note and document writer assistant that can help me with my tasks, documents and notes. You are in a text editor, so you can only answer as text completion and write based on the text in markdown.\n\nRemember these:\n- When asked to CONTINUE or to WRITE, directly add the requested content without any introduction or explanation or including the current note content.\n- {{CURSOR}} is the current cursor position which you need to fill\n- Do not include {{CURSOR}} on your response\n- You can use markdown to format your response\n- If there is any content after {{CURSOR}} stop your response before there",
        "defaultContinueInstruction": "Help me CONTINUE from the cursor position but dont change and include the content after.\nTitle: {{note.name}}.\nCurrent Note Content State:\n-----\n{{note.markdown}}\n-----\n\nContinue here with context {{context}}:\n",
        "defaultWriteInstruction": "Help me WRITE from the cursor position but dont change and include the content after.\nTitle: {{note.name}}.\nCurrent Note Content State:\n-----\n{{note.markdown}}\n-----\n\nWRITE here with context {{context}}:\n",
        "defaultSelectInstruction": "Help me update the note content with the selected content {{note.markdown.selection}} with context {{context}}:\n",
        "isInit": true,
        "order": 98,
        "orderUpdated": today
    },
    {
        "id": generateNanoId(14),
        "name": "Blog Post Idea",
        "created": today,
        "updated": today,
        "order": 99,
        "orderUpdated": today,
        "defaultInstruction": "You are a note and document writer assistant that can help me with my tasks, documents and notes. You are in a text editor, so you can only answer as text completion and write based on the text in markdown.\n\nRemember these:\n- When asked to CONTINUE or to WRITE, directly add the requested content without any introduction or explanation or including the current note content.\n- {{CURSOR}} is the current cursor position which you need to fill\n- Do not include {{CURSOR}} on your response\n- You can use markdown to format your response\n- If there is any content after {{CURSOR}} stop your response before there",
        "defaultContinueInstruction": "Help me CONTINUE from the cursor position but dont change and include the content after.\nTitle: {{note.name}}.\nCurrent Note Content State:\n-----\n{{note.markdown}}\n-----\n\nContinue here with context {{context}}:\n",
        "defaultWriteInstruction": "Help me WRITE from the cursor position but dont change and include the content after.\nTitle: {{note.name}}.\nCurrent Note Content State:\n-----\n{{note.markdown}}\n-----\n\nWRITE here with context {{context}}:\n",
        "defaultSelectInstruction": "Help me update the note content with the selected content {{note.markdown.selection}} with context {{context}}:\n",
        "defaultModel": "gpt-3.5-turbo",
        "content": [
            {
                "id": "980964ea-30bb-43b5-a52b-d4ba58c7db68",
                "type": "heading",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left",
                    "level": "2"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "List Unique Marketing Blog Post Idea",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "8e1fd568-9dc8-4d58-92e9-9e5049070e4a",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "The benefits of niche marketing",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "01d82fe8-f5f8-4522-af11-e6f41bf73ba0",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "How to create a successful viral marketing campaign",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "e5376c4d-0d2b-4e50-af32-5f42a4cdb419",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Top social media trends for businesses in 2023",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "35c6a3e5-c473-449f-aab1-f5934072abfc",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "The importance of storytelling in brand marketing",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "cddaddc4-3074-4904-a9b6-c9aedc8c771b",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Developing a content marketing strategy for small businesses",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "7e03b1ff-4595-4fb2-aec0-c4942ebdb7f0",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "The ethics of marketing and advertising",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "73a00acc-a5d9-4b4f-9c61-d60979052e21",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Using data analytics to improve marketing campaigns",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "edb763eb-9251-4273-b0ce-cc7e621f1e50",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Tips for creating effective email marketing campaigns",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "2587ad38-7ed2-4da2-b486-38ee9c84ac34",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "The impact of AI on digital marketing",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "ca79f805-b40e-467b-8912-67c928223b1f",
                "type": "numberedListItem",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Influencer marketing: strategies and best practices",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "b94e1756-1d18-4501-b339-0f6684c03b83",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            },
            {
                "id": "ca2366e9-87d4-4f59-9bad-fb47040f2969",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            },
            {
                "id": "7f5cf94f-1b2c-45e6-8f16-e1de2137ae33",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            }
        ],
        "markdown": "## List Unique Marketing Blog Post Idea\n\n1.  The benefits of niche marketing\n2.  How to create a successful viral marketing campaign\n3.  Top social media trends for businesses in 2023\n4.  The importance of storytelling in brand marketing\n5.  Developing a content marketing strategy for small businesses\n6.  The ethics of marketing and advertising\n7.  Using data analytics to improve marketing campaigns\n8.  Tips for creating effective email marketing campaigns\n9.  The impact of AI on digital marketing\n10. Influencer marketing: strategies and best practices\n"
    },
    {
        "id": generateNanoId(14),
        "content": [
            {
                "id": "cb62a38b-1346-4f1c-a121-b6eb464d410c",
                "type": "heading",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left",
                    "level": "2"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "How you can use ChatGPT for your Work?",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "6cfa9d5a-a22e-4d18-9ffd-c13d1954489c",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            },
            {
                "id": "24fafe95-85bf-4c56-8bdc-538c02edd394",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "For many of us, work life can get quite demanding and stressful at times. Finding ways to improve efficiency and reducing stress levels can be quite helpful in boosting productivity. One of the ways to do so is by using ",
                        "styles": {}
                    },
                    {
                        "type": "text",
                        "text": "ChatGPT",
                        "styles": {
                            "bold": true
                        }
                    },
                    {
                        "type": "text",
                        "text": ". This AI-powered assistant can be a great tool to help streamline your work and reduce the workload. ",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "4699b4f6-402e-44fc-b7ae-4d1eaaa8e222",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "In this blog, you will learn how you can use ChatGPT to enhance your work performance.",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "9c2cfc41-a48c-4576-87d5-6b43ce64858c",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "First things first, start by installing and configuring ChatGPT for your work-related tasks. Once you have done that, you can start using it for various tasks like scheduling meetings, setting reminders, and even generating text. Using ChatGPT can significantly reduce the amount of time and effort required for these tasks.",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "32a8c681-b49f-45c1-b2f2-6af26b5b5292",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Another way you can use ChatGPT is by using it to generate reports and summaries. ChatGPT can analyze large data sets and extract valuable insights. This can be especially helpful for professionals like analysts, researchers, and data scientists.",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "e19ee4c1-5393-4b24-aaad-9273bcdf255a",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "text": "In summary, ChatGPT has a lot to offer to professionals who are looking to optimize their work processes. By using this AI-powered assistant, you can save time and effort while also enhancing your work performance.",
                        "styles": {}
                    }
                ],
                "children": []
            },
            {
                "id": "21275656-9281-42a5-af90-3330cc7b140c",
                "type": "paragraph",
                "props": {
                    "textColor": "default",
                    "backgroundColor": "default",
                    "textAlignment": "left"
                },
                "content": [],
                "children": []
            }
        ],
        "name": "Article",
        "markdown": "## How you can use ChatGPT for your Work?\n\nFor many of us, work life can get quite demanding and stressful at times. Finding ways to improve efficiency and reducing stress levels can be quite helpful in boosting productivity. One of the ways to do so is by using **ChatGPT**. This AI-powered assistant can be a great tool to help streamline your work and reduce the workload.\n\nIn this blog, you will learn how you can use ChatGPT to enhance your work performance.\n\nFirst things first, start by installing and configuring ChatGPT for your work-related tasks. Once you have done that, you can start using it for various tasks like scheduling meetings, setting reminders, and even generating text. Using ChatGPT can significantly reduce the amount of time and effort required for these tasks.\n\nAnother way you can use ChatGPT is by using it to generate reports and summaries. ChatGPT can analyze large data sets and extract valuable insights. This can be especially helpful for professionals like analysts, researchers, and data scientists.\n\nIn summary, ChatGPT has a lot to offer to professionals who are looking to optimize their work processes. By using this AI-powered assistant, you can save time and effort while also enhancing your work performance.\n",
        "created": today,
        "teamId": "",
        "defaultInstruction": "You are a writer assistant that can help me write unique, good and SEO friendly article.\nYou are in a text editor, so you can only answer as text completion and write based on the text in markdown.\nFill the content in {{CURSOR}} and do not include the previous content or after the {{CURSOR}} and do not include introduction.\nOnly respond with the content.",
        "defaultContinueInstruction": "Help me CONTINUE from the cursor position but dont change and include the content after.\nTitle: {{note.name}}.\nCurrent Note Content State:\n-----\n{{note.markdown}}\n-----\n\nContinue here with context {{context}}:\n",
        "defaultWriteInstruction": "Help me WRITE from the cursor position but dont change and include the content after.\nTitle: {{note.name}}.\nCurrent Note Content State:\n-----\n{{note.markdown}}\n-----\n\nWRITE here with context {{context}}:\n",
        "defaultSelectInstruction": "Help me update the note content with the selected content {{note.markdown.selection}} with context {{context}}:\n",
        "isInit": true,
        "order": 100,
        "orderUpdated": today
    },
]


const csId1 = generateNanoId(14)
const csId2 = generateNanoId(14)
const csId3 = generateNanoId(14)
const csId4 = generateNanoId(14)

export const chatSessionListDefault: ChatSession[] = [
    {
        "id": csId4,
        "localId": csId4,
        "name": "Dog Video Ideas",
        "defaultModel": "gpt-3.5-turbo",
        "messages": [
            {
                "id": "usrjxuOcz9Xwmnqc",
                "chatSessionId": csId4,
                "content": {
                    "contentType": "text",
                    "content": "Suggest me 2 youtube video ideas about dog"
                },
                "userId": "clgw32asz0005pkut7ekikp53",
                "created": new Date("2023-04-26T05:51:19.756Z"),
                "senderRole": "user"
            },
            {
                "id": "37W9lBaMPLIvaN",
                "chatSessionId": csId4,
                "content": {
                    "contentType": "text",
                    "content": "Sure, here are two video ideas on dogs:\n\n1. \"10 Surprising Benefits of Owning a Dog\" - showcase research-based benefits of having a furry companion in your life, such as reducing stress, lowering blood pressure, providing companionship and more.\n\n2. \"Training Your Dog with Positive Reinforcement\" - demonstrate how to train dogs using positive reinforcement techniques such as treats, praise, and rewards. This is a great video idea for those who are looking to start training their dogs at home."
                },
                "lastSync": new Date("2023-04-26T05:51:19.759Z"),
                "userId": "",
                "loading": false,
                "parentId": "usrjxuOcz9Xwmnqc",
                "senderRole": "assistant",
                "created": new Date("2023-04-26T05:51:19.759Z"),
                "model": "gpt-3.5-turbo",
                "generating": false,
                "updated": new Date("2023-04-26T05:51:29.595Z")
            },
            {
                "id": "usr5bmbDTe8WsM4D",
                "chatSessionId": csId4,
                "content": {
                    "contentType": "text",
                    "content": "Generate picture to each titles for youtube thumbnail"
                },
                "userId": "clgw32asz0005pkut7ekikp53",
                "created": new Date("2023-04-26T09:30:38.334Z"),
                "senderRole": "user",
                "parentId": "37W9lBaMPLIvaN"
            },
            {
                "id": "2llB0oGsTZa70X",
                "chatSessionId": csId4,
                "content": {
                    "content": "Here are two image for your two video ideas about dogs. The first one is a cute dog sitting on a bed with a caption \"10 Surprising Benefits of Owning a Dog\", and the other one is a happy dog with his owner with a caption \"Training Your Dog with Positive Reinforcement\".\n\nImages:\n\nCute dog sitting on a bed with a caption \"10 Surprising Benefits of Owning a Dog\"\n![Cute dog sitting on a bed with a caption \"10 Surprising Benefits of Owning a Dog\"](/images/img-dalle-sample1.png)\n\nA happy dog with his owner with a caption \"Training Your Dog with Positive Reinforcement\"\n![A happy dog with his owner with a caption \"Training Your Dog with Positive Reinforcement\"](/images/img-dalle-sample2.png)\n\n**This DALL-E generate feature is in preview and currently only for pro/lifetime users**",
                    "contentType": "text"
                },
                "userId": "",
                "senderRole": "assistant",
                "created": new Date("2023-04-26T09:30:55.331Z"),
                "model": "gpt-3.5-turbo",
                "parentId": "usr5bmbDTe8WsM4D",
                "updated": new Date("2023-04-26T09:49:44.199Z")
            },
            {
                "id": "usrbuXvDGe3OKuyb",
                "chatSessionId": csId4,
                "content": {
                    "content": "Generate picture with this prompt (just one image): `A siberian husky vs conor mcgregor in mars, anime style`",
                    "contentType": "text"
                },
                "userId": "clgw32asz0005pkut7ekikp53",
                "created": new Date("2023-04-26T09:41:08.708Z"),
                "senderRole": "user",
                "parentId": "sEksB27XLKaVXe",
                "updated": new Date("2023-04-26T09:44:53.341Z")
            },
            {
                "id": "Q4PSNJ0hubnIu8",
                "chatSessionId": csId4,
                "content": {
                    "content": "Here's your requested prompt: `A siberian husky vs conor mcgregor in mars, anime style`. Unfortunately\n\nImages:\n\nA siberian husky vs conor mcgregor in mars, anime style\n![A siberian husky vs conor mcgregor in mars, anime style](/images/img-dalle-sample3.png)\n",
                    "contentType": "text"
                },
                "userId": "",
                "senderRole": "assistant",
                "created": new Date("2023-04-26T09:46:53.152Z"),
                "model": "gpt-3.5-turbo",
                "parentId": "usrbuXvDGe3OKuyb",
                "updated": new Date("2023-04-26T09:49:20.362Z")
            }
        ],
        "created": new Date("2023-04-26T03:40:09.369Z"),
        "updated": new Date("2023-04-26T03:40:09.369Z"),
        "isStreaming": true,
        "isSync": false,
        "isInit": false,
        "order": 97,
        "orderUpdated": new Date("2023-04-26T03:40:09.369Z"),
        "defaultInstruction": "You are a professional writer with knowledge in many industry and can implement best and modern seo",
        "isImageGen": false
    },
    {
        "id": csId1,
        "localId": csId1,
        "name": "Interior Design Tips",
        "defaultModel": "gpt-3.5-turbo",
        "messages": [
            {
                "id": "usrVkQaMExSwg0ft",
                "chatSessionId": csId1,
                "content": {
                    "contentType": "text",
                    "content": "Give me some color in hex"
                },
                "userId": "clgrs3izh001ypk64i9d4k509",
                "created": new Date("2023-04-22T12:37:22.446Z"),
                "senderRole": "user"
            },
            {
                "id": "lQmVnrFubgyBRL",
                "chatSessionId": csId1,
                "content": {
                    "contentType": "text",
                    "content": "Sure! Here are some color hex codes that can be used in home décor:\n\n1. #F5B041 (a warm, golden yellow)\n2. #90AFC5 (a soft, cool blue)\n3. #F0E68C (a pale, creamy yellow)\n4. #F08080 (a bold, fiery red)\n5. #A4C639 (a bright, citrusy green)\n6. #FF69B4 (a playful, bubblegum pink)\n7. #7B1FA2 (a deep, royal purple)\n8. #4B0082 (a rich, dark blue)\n9. #9932CC (a vibrant, electric purple)\n10. #008080 (a calming, deep teal)\n\nOf course, it's important to consider the overall style and aesthetic of the space before choosing colors. These are just some starting points to get the creative juices flowing!"
                },
                "lastSync": new Date("2023-04-22T12:37:22.449Z"),
                "userId": "",
                "loading": false,
                "parentId": "usrVkQaMExSwg0ft",
                "senderRole": "assistant",
                "created": new Date("2023-04-22T12:37:22.449Z"),
                "model": "gpt-3.5-turbo",
                "generating": false,
                "updated": new Date("2023-04-22T12:37:35.737Z")
            }
        ],
        "created": new Date("2023-04-22T12:37:10.903Z"),
        "updated": new Date("2023-04-22T12:37:10.903Z"),
        "teamId": "xYvzCs6smN63PRK8",
        "isStreaming": true,
        "isSync": false,
        "isInit": false,
        "order": 98,
        "orderUpdated": new Date("2023-04-22T12:37:10.903Z"),
        "defaultInstruction": "You are an interior designer, offering suggestions on home décor, furniture arrangement, and color schemes to improve users' living spaces."
    },
    {
        "id": csId2,
        "localId": csId2,
        "name": "Code with Data",
        "defaultModel": "gpt-3.5-turbo",
        "messages": [
            {
                "id": "usrfmSco060EQHMm",
                "chatSessionId": csId2,
                "content": {
                    "contentType": "text",
                    "content": "How to query list of users?"
                },
                "userId": "clgrs3izh001ypk64i9d4k509",
                "created": new Date("2023-04-22T12:39:07.363Z"),
                "senderRole": "user"
            },
            {
                "id": "8y6J5YJlPKmgCc",
                "chatSessionId": csId2,
                "content": {
                    "contentType": "text",
                    "content": "To query a list of users, first you need to identify the source of the user data. \n\nIf you are querying user data from a database, you can use SQL queries to retrieve the data. For example, you can use the SELECT statement to select all rows from the users table.\n\n```\nSELECT * FROM users;\n```\n\nIf you are querying user data from an API, you can use the appropriate API endpoint to retrieve the data. For example, if you are using the GitHub API, you can use the following endpoint to retrieve a list of users:\n```\nhttps://api.github.com/users\n```\n\nIf you are querying user data from a file, you can use a programming language like Python to read the file and extract the relevant user data. For example, with a CSV file, you can use the CSV module in Python to open the file and extract the user data."
                },
                "lastSync": new Date("2023-04-22T12:39:07.364Z"),
                "userId": "",
                "loading": false,
                "parentId": "usrfmSco060EQHMm",
                "senderRole": "assistant",
                "created": new Date("2023-04-22T12:39:07.364Z"),
                "model": "gpt-3.5-turbo",
                "generating": false,
                "updated": new Date("2023-04-22T12:39:19.815Z")
            },
            {
                "id": "usrg4dHGwQc0ScV1",
                "chatSessionId": csId2,
                "content": {
                    "contentType": "text",
                    "content": "Share a sample javascript code"
                },
                "userId": "clgrs3izh001ypk64i9d4k509",
                "created": new Date("2023-04-22T12:39:25.688Z"),
                "senderRole": "user",
                "parentId": "8y6J5YJlPKmgCc"
            },
            {
                "id": "FKujjxdf2liFQQ",
                "chatSessionId": csId2,
                "content": {
                    "contentType": "text",
                    "content": "Sure! Here's some example JavaScript code for displaying the current date and time using the built-in `Date` object:\n\n```javascript\n// Create a new Date object\nlet currentDate = new Date();\n\n// Get the current date and time\nlet date = currentDate.getDate();\nlet month = currentDate.getMonth() + 1;\nlet year = currentDate.getFullYear();\nlet hours = currentDate.getHours();\nlet minutes = currentDate.getMinutes();\nlet seconds = currentDate.getSeconds();\n\n// Pad the time values if they are less than 10\nif (hours < 10) {\n  hours = \"0\" + hours;\n}\nif (minutes < 10) {\n  minutes = \"0\" + minutes;\n}\nif (seconds < 10) {\n  seconds = \"0\" + seconds;\n}\n\n// Output the current date and time\nconsole.log(\"Current date and time: \" + date + \"/\" + month + \"/\" + year + \" \" + hours + \":\" + minutes + \":\" + seconds);\n```\n\nThis code creates a new `Date` object using the `new` keyword. Then it uses various methods of the `Date` object, such as `getDate()` and `getMonth()`, to retrieve the current date and time. Finally, it formats the date and time as a string and outputs it to the console using `console.log()`."
                },
                "lastSync": new Date("2023-04-22T12:39:25.688Z"),
                "userId": "",
                "loading": false,
                "parentId": "usrg4dHGwQc0ScV1",
                "senderRole": "assistant",
                "created": new Date("2023-04-22T12:39:25.688Z"),
                "model": "gpt-3.5-turbo",
                "generating": false,
                "updated": new Date("2023-04-22T12:39:42.964Z")
            }
        ],
        "created": new Date("2023-04-22T12:38:51.110Z"),
        "updated": new Date("2023-04-22T12:38:51.110Z"),
        "teamId": "xYvzCs6smN63PRK8",
        "isStreaming": true,
        "isSync": false,
        "isInit": false,
        "order": 99,
        "orderUpdated": new Date("2023-04-22T12:38:51.110Z"),
        "defaultInstruction": "You are a data science advisor, helping users navigate the world of data analysis, machine learning, and artificial intelligence by offering advice on tools, libraries, and algorithms."
    },
    {
        "id": csId3,
        "localId": csId3,
        "name": "Domain Checking",
        "defaultModel": "gpt-3.5-turbo",
        "messages": [
            {
                "id": "usrqh8adn1TaaPkn",
                "chatSessionId": csId3,
                "content": {
                    "contentType": "text",
                    "content": "Give me some unique domain names"
                },
                "userId": "clgrs3izh001ypk64i9d4k509",
                "created": new Date("2023-04-22T12:38:36.882Z"),
                "senderRole": "user"
            },
            {
                "id": "IazbLtpfw3FtWN",
                "chatSessionId": csId3,
                "content": {
                    "contentType": "text",
                    "content": "Sure, here are some suggestions for unique domain names:\n1. HypeHive.com\n2. BrainwaveHQ.com\n3. LaunchLeap.com\n4. FutureFortress.co\n5. BoldBrands.co\n6. PivotPointHQ.com\n7. VelocityVentures.co\n8. InnovationIsland.co\n9. NicheNexus.net\n10. IdeaIncubatorHQ.com"
                },
                "lastSync": new Date("2023-04-22T12:38:36.883Z"),
                "userId": "",
                "loading": false,
                "parentId": "usrqh8adn1TaaPkn",
                "senderRole": "assistant",
                "created": new Date("2023-04-22T12:38:36.883Z"),
                "model": "gpt-3.5-turbo",
                "generating": false,
                "updated": new Date("2023-04-22T12:38:43.763Z")
            }
        ],
        "created": new Date("2023-04-22T12:38:20.523Z"),
        "updated": new Date("2023-04-22T12:38:20.523Z"),
        "teamId": "xYvzCs6smN63PRK8",
        "isStreaming": true,
        "isSync": false,
        "isInit": false,
        "order": 100,
        "orderUpdated": new Date("2023-04-22T12:38:20.523Z"),
        "defaultInstruction": "You are a startup mentor, offering guidance and advice to aspiring entrepreneurs and founders on topics such as ideation, market research, business planning, and funding strategies."
    }
]

