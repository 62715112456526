import { type ChatMessage } from '@acme/util';
import { useMemo } from 'react';

export function useLatestMessagesAlternatives(messages: ChatMessage[]): ChatMessage[] {
  const data = useMemo<ChatMessage[]>(() => {
    const latestAlternatives = new Map();

    messages.forEach((message) => {
      const currentLatest = latestAlternatives.get(message.parentId);
      if (!currentLatest || message.created > currentLatest.created) {
        latestAlternatives.set(message.parentId, message);
      }
    });

    const sortedMessages = Array.from(latestAlternatives.values());
    sortedMessages.sort((a, b) => a.created - b.created);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return sortedMessages;
  }, [messages]);

  return data
}