import { useEffect, useState } from "react"
import { BiCheck, BiX, BiEditAlt, BiTrashAlt } from "react-icons/bi"
import { CgSpinner } from "react-icons/cg"
import { Button } from "../Button"
import { ConditionalRenderWithElse } from "../Wrapper"

type ListBubbleProps = {
  data: {
    title: string
    id: string
    desc?: string
    lastMessage?: string
  }
  isActive: boolean
  isSync?: boolean
  isLoading?: boolean
  isFavorite?: boolean
  onEdit: (id: string, name: string) => void
  onDelete: (id: string) => void
  onSelect: (id: string) => void

  className?: string
}

export const ListBubble = ({ data, isSync, isFavorite, className, onDelete, onSelect, onEdit, isLoading = false, isActive = false }: ListBubbleProps) => {
  const [nameTemp, setNameTemp] = useState(data.title)
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [timeLeft, setTimeLeft] = useState(3);

  const onDeleteConfirm = () => {
    setIsDeleteConfirm(true)
  }

  const bgClass = isActive ? 'bg-neutral-700' : 'bg-transparent'

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isDeleteConfirm) {
      setTimeLeft(3);
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 1) {
            setIsDeleteConfirm(false);
            clearInterval(timer);
            return 3;
          } else {
            return prevTimeLeft - 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isDeleteConfirm]);

  useEffect(() => {
    if (isEditing) {
      setNameTemp(data.title)
      if (document) {
        setTimeout(() => {
          const input = document.getElementById(`list-bubble-edit-${data.id}`)
          console.log({ input })
          if (input) {
            input.focus()
          }
        }, 100);
      }
    }
  }, [data.id, data.title, isEditing])

  return (
    <Button
      className={`w-full border-b border-neutral-600 border-opacity-30 bg-transparent !duration-100 pb-[9px] pt-[6px] group !pr-2 rounded ${isActive ? '!bg-neutral-700' : 'hover:bg-neutral-800'} ${bgClass} ${isActive ? 'hover:!opacity-100' : 'hover:opacity-80'} transition-all duration-150 ${className}`}
      onClick={() => onSelect(data.id)}
    >
      <div className="flex flex-row items-center justify-start gap-2 w-full relative">
        {isLoading && (
          <div className="w-[100%] h-[30px] gap-2 absolute bg-neutral-800 px-2 z-50 rounded-full flex flex-row items-center border-opacity-50">
            <CgSpinner className="animate-spin text-xl" />
            <p>Loading...</p>
          </div>
        )}
        <ConditionalRenderWithElse
          show={!isDeleteConfirm}
          containerClassName="flex flex-row items-center justify-start gap-2 w-full relative"
          elseChildren={
            <>
              {isDeleteConfirm && (
                <div className={`w-full h-full z-10 flex flex-row items-center gap-2 ${bgClass}`}>
                  <p className="text-sm text-red-300 flex-1 text-left">({timeLeft}) Confirm Delete</p>
                  <div className="flex flex-row gap-2 mt-1">
                    <p
                      onClick={(e) => {
                        e.stopPropagation()
                        setIsDeleteConfirm(false)
                      }}
                      className="text-sm p-1 rounded bg-gray-500 px-2 hover:opacity-70"
                    >
                      No
                    </p>
                    <p
                      onClick={(e) => {
                        e.stopPropagation()
                        onDelete(data.id)
                      }}
                      className="text-sm p-1 rounded bg-red-500 px-2 hover:opacity-70"
                    >
                      Yes
                    </p>
                  </div>
                </div>
              )}
            </>
          }
        >
          {isSync && (
            <div className="absolute left-[-13px] top-[-8px] text-2xl z-50 text-green-500">•</div>
          )}
          {/* {isFavorite && (
            <div className="absolute left-[-12px] top-[-2px]">
              ⭐
            </div>
          )} */}
          <div className="flex flex-row items-center justify-between relative space-x-4 max-w-full w-full">
            <div className="flex flex-col gap-[1px] flex-1 flex-grow items-start relative min-w-0">
              <ConditionalRenderWithElse
                show={!isEditing}
                elseChildren={
                  <input
                    value={nameTemp}
                    className={`bg-transparent border-b-[1px] border-b-white border rounded px-1 outline-none text-sm list-bubble-edit-${data.id}`}
                    id={`list-bubble-edit-${data.id}`}
                    onChange={(e) => setNameTemp(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation()
                        onEdit(data.id, nameTemp)
                        setIsEditing(false)
                      }
                    }}
                  />
                }
              >
                <p className="text-sm font-semibold text-left">{data.title}</p>
              </ConditionalRenderWithElse>
              {data.desc && (
                <p className="text-[11px] pt-[0px] text-gray-400 h-[19px] font-light text-left truncate flex-grow max-w-full min-w-full">{data.desc || '`'}</p>
              )}
            </div>
            <div className={`flex flex-row flex-shrink-0 gap-2 items-center ${isEditing ? '!gap-1' : ''} ${(!isActive && !isEditing) ? 'invisible opacity-0' : ''} group-hover:visible group-hover:opacity-100`}>
              {isEditing ? (
                <>
                  <BiCheck
                    className="text-xl mt-[-2px] text-gray-400 hover:text-white"
                    onClick={(e) => {
                      e.stopPropagation()
                      onEdit(data.id, nameTemp)
                      setIsEditing(false)
                    }}
                  />
                  <BiX
                    className="text-xl text-gray-400 hover:text-red-500 hover:font-bold"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsEditing(false)
                    }}
                  />
                </>
              ) : (
                <>
                  <BiEditAlt
                    className="text-lg text-gray-400 hover:text-white"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsEditing(true)
                    }}
                  />
                  <BiTrashAlt
                    className="text-lg text-gray-400 hover:text-red-500 hover:font-bold"
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteConfirm()
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </ConditionalRenderWithElse>
      </div>
    </Button >
  )
}
