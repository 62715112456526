import {
    FaAppleAlt, FaBook, FaBriefcase, FaShieldAlt, FaDatabase, FaBuilding,
    FaCalendarPlus, FaLeaf, FaShoppingBag, FaTools, FaLandmark, FaRocket, FaUserTie, FaChartLine,
    FaCalendarAlt, FaCloud, FaCode, FaCogs, FaCommentDollar, FaFilm, FaGamepad, FaHandshake, FaHeart, FaLaptopCode, FaLightbulb, FaMobileAlt, FaPaintRoller, FaRoute, FaRunning, FaUserAstronaut, FaUsers, FaWeightHanging
} from 'react-icons/fa';

export const chatModels = {
    // GPT-3.5 Turbo
    'gpt-3.5-turbo': {
        id: 'gpt-3.5-turbo',
        dbId: 'gpt35turbo',
    },
    'gpt-3.5-turbo-16k': {
        id: 'gpt-3.5-turbo-16k',
        dbId: 'gpt35turbo16k',
    },
    'gpt-3.5-turbo-1106': {
        id: 'gpt-3.5-turbo-1106',
        dbId: 'gpt35turbo1106',
    },
    'gpt-3.5-turbo-instruct': {
        id: 'gpt-3.5-turbo-instruct',
        dbId: 'gpt35turboinstruct',
    },

    // LEGACY
    'gpt-3.5-turbo-0301': {
        id: 'gpt-3.5-turbo-0301',
        dbId: 'gpt35turbo0301',
    },

    // GPT-4 Turbo
    'gpt-4-1106-preview': {
        id: 'gpt-4-1106-preview',
        dbId: 'gpt41106preview',
    },
    // GPT-4 Turbo with vision
    'gpt-4-vision-preview': {
        id: 'gpt-4-vision-preview',
        dbId: 'gpt4visionpreview',
    },

    // GPT-4
    'gpt-4': {
        id: 'gpt-4',
        dbId: 'gpt4',
    },
    'gpt-4-32k': {
        id: 'gpt-4-32k',
        dbId: 'gpt432k',
    },
    'gpt-4o': {
        id: 'gpt-4o',
        dbId: 'gpt4o'
    },
    'gpt-4-turbo': {
        id: 'gpt-4-turbo',
        dbId: 'gpt4turbo'
    },
    'o1-preview': {
        id: 'o1-preview',
        dbId: 'o1preview'
    },

    'o1-mini': {
        id: 'o1-mini',
        dbId: 'o1mini'
    },




    // GPT-4 0613
    'gpt-4-0613': {
        id: 'gpt-4-0613',
        dbId: 'gpt40613',
    },
    'gpt-4-32k-0613': {
        id: 'gpt-4-32k-0613',
        dbId: 'gpt432k0613',
    },


    // ANTHROPIC
    'claude-3-opus-20240229': {
        id: 'claude-3-opus-20240229',
        dbId: 'claude3opus20240229',
        type: 'anthropic'
    },
    'claude-3-sonnet-20240229': {
        id: 'claude-3-sonnet-20240229',
        dbId: 'claude3sonnet20240229',
    },
    'claude-3-haiku-20240307': {
        id: 'claude-3-haiku-20240307',
        dbId: 'claude3haiku20240307'
    },
    'claude-3-5-sonnet-20240620': {
        id: 'claude-3-5-sonnet-20240620',
        dbId: 'claude35sonnet20240620'
    },


    // LEGACY
    'gpt-4-0314': {
        id: 'gpt-4-0314',
        dbId: 'gpt40314',
    },
    'gpt-4-32k-0314': {
        id: 'gpt-4-32k-0314',
        dbId: 'gpt432k0314',
    },
} as const

export const chatModelsList = [
    chatModels['gpt-3.5-turbo'].id,
    chatModels['gpt-3.5-turbo-16k'].id,
    chatModels['gpt-3.5-turbo-1106'].id,
    chatModels['gpt-3.5-turbo-instruct'].id,
    chatModels['gpt-3.5-turbo-0301'].id,

    chatModels['gpt-4-1106-preview'].id,
    chatModels['gpt-4-vision-preview'].id,

    chatModels['gpt-4'].id,
    chatModels['gpt-4-32k'].id,
    chatModels['gpt-4o'].id,
    chatModels['gpt-4-turbo'].id,

    chatModels['gpt-4-0613'].id,
    chatModels['gpt-4-32k-0613'].id,

    chatModels['claude-3-haiku-20240307'].id,
    chatModels['claude-3-sonnet-20240229'].id,
    chatModels['claude-3-opus-20240229'].id,

    // legacy
    chatModels['gpt-4-0314'].id,
    chatModels['gpt-4-32k-0314'].id,
] as const

export const chatModelsListDb = [
    chatModels['gpt-3.5-turbo'].dbId,
    chatModels['gpt-3.5-turbo-16k'].dbId,
    chatModels['gpt-3.5-turbo-1106'].dbId,
    chatModels['gpt-3.5-turbo-instruct'].dbId,
    chatModels['gpt-3.5-turbo-0301'].dbId,

    chatModels['gpt-4-1106-preview'].dbId,
    chatModels['gpt-4-vision-preview'].dbId,

    chatModels['gpt-4'].dbId,
    chatModels['gpt-4-32k'].dbId,
    chatModels['gpt-4o'].dbId,
    chatModels['gpt-4-turbo'].dbId,

    chatModels['o1-preview'].dbId,
    chatModels['o1-mini'].dbId,

    chatModels['gpt-4-0613'].dbId,
    chatModels['gpt-4-32k-0613'].dbId,

    chatModels['claude-3-haiku-20240307'].dbId,
    chatModels['claude-3-sonnet-20240229'].dbId,
    chatModels['claude-3-opus-20240229'].dbId,
    chatModels['claude-3-5-sonnet-20240620'].dbId,

    // legacy
    chatModels['gpt-4-0314'].dbId,
    chatModels['gpt-4-32k-0314'].dbId,
] as const

export type ChatModel = typeof chatModels[keyof typeof chatModels]['id'];
export type ChatModelDb = typeof chatModels[keyof typeof chatModels]['dbId'];

export const openAiChatModels: { label: string, value: ChatModel, desc: string, type: 'gpt-3.5' | 'gpt-4' | 'custom' | 'anthropic' }[] = [
    {
        label: "GPT-4o",
        value: chatModels['gpt-4o'].id,
        desc: "GPT-4o, The latest GPT-4 model",
        type: 'gpt-4'
    },
    {
        label: "GPT-4-turbo",
        value: chatModels['gpt-4-turbo'].id,
        desc: "GPT-4, GPT-4 Turbo with Vision",
        type: 'gpt-4'
    },
    {
        label: "o1-preview",
        value: chatModels['o1-preview'].id,
        desc: "o1, reasoning model designed to solve hard problems",
        type: 'gpt-4'
    },
    {
        label: "o1-mini",
        value: chatModels['o1-mini'].id,
        desc: "o1-mini, faster and cheaper reasoning mode",
        type: 'gpt-4'
    },
    {
        label: "GPT-4-turbo",
        value: chatModels['gpt-4-turbo'].id,
        desc: "GPT-4, GPT-4 Turbo with Vision",
        type: 'gpt-4'
    },
    {
        label: "GPT-3.5-Turbo",
        value: chatModels['gpt-3.5-turbo'].id,
        desc: "Default ChatGPT model",
        type: 'gpt-3.5'
    }, {
        label: "GPT-3.5-Turbo 16k tokens",
        value: chatModels['gpt-3.5-turbo-16k'].id,
        desc: "GPT 3.5 and can handle up to 16k tokens",
        type: 'gpt-3.5'
    }, {
        label: "GPT-3.5-Turbo Instruct",
        value: chatModels['gpt-3.5-turbo-1106'].id,
        desc: "The latest GPT-3.5 Turbo model",
        type: 'gpt-3.5'
    }, {
        label: "GPT-3.5-Turbo Instruct",
        value: chatModels['gpt-3.5-turbo-instruct'].id,
        desc: "Similar capabilities as text-davinci-003 but compatible with legacy Completions",
        type: 'gpt-3.5'
    },
    {
        label: "GPT-4",
        value: chatModels['gpt-4'].id,
        desc: "Default ChatGPT 4 model",
        type: 'gpt-4'
    },
    {
        label: "GPT-4-32k",
        value: chatModels['gpt-4-32k'].id,
        desc: "GPT-4 and can handle up to 32k tokens",
        type: 'gpt-4'
    },
    {
        label: "GPT-4-1106-preview",
        value: chatModels['gpt-4-1106-preview'].id,
        desc: "GPT-4 Turbo, The latest GPT-4 model",
        type: 'gpt-4'
    },

    {
        label: "Opus",
        value: chatModels['claude-3-opus-20240229'].id,
        desc: "Anthropic Opus, fastest anthropic model",
        type: 'anthropic'
    },
    {
        label: "Sonnet",
        value: chatModels['claude-3-sonnet-20240229'].id,
        desc: "Anthropic Sonnet, balanced anthropic model",
        type: 'anthropic'
    },
    {
        label: "Haiku",
        value: chatModels['claude-3-haiku-20240307'].id,
        desc: "Anthropic Haiku, most powerful anthropic model",
        type: 'anthropic'
    },
    /* {
        label: "GPT-4-vision-preview",
        value: chatModels['gpt-4-vision-preview'].id,
        desc: "GPT-4 Turbo with vision, GPT-4 turbo with ability to understand images",
        type: 'gpt-4'
    } */
]

export const isModelAllowedVision = (model?: ChatModel): boolean => {
    return model === chatModels['gpt-4-vision-preview'].id
        || model === chatModels['gpt-4-turbo'].id
        || model === chatModels['gpt-4o'].id
}

export const isModelAnthropic = (model: ChatModel): boolean => {
    return model.startsWith("claude-")
}

export const chatInstructionsDefault = [
    {
        name: "Fitness Coach",
        instruction: "You are a fitness coach, offering workout routines, exercise tips, and motivation to help users achieve their fitness goals.",
        short_description: "A fitness coach that offers workout routines and exercise tips",
        color: "#EC7063",
        icon: <FaRunning />
    },
    {
        name: "Personal Finance Advisor",
        instruction: "You are a personal finance advisor, offering budgeting tips, savings strategies, and investment advice to help users manage their money and reach their financial goals.",
        short_description: "A personal finance advisor that helps users manage their money and achieve financial goals",
        color: "#58D68D",
        icon: <FaCommentDollar />
    },
    {
        name: "Growth Hacker",
        instruction: "You are a growth hacker, sharing innovative marketing strategies, user acquisition techniques, and analytics insights to help startups and founders achieve rapid growth and user engagement.",
        short_description: "A growth hacker that shares strategies for rapid growth and user engagement",
        color: "#16A085",
        icon: <FaChartLine />
    },
    {
        name: "Web Development Expert",
        instruction: "You are a web development expert, providing guidance on front-end and back-end technologies, web frameworks, and best practices for building responsive and user-friendly websites.",
        short_description: "A web development expert that offers guidance on building responsive and user-friendly websites",
        color: "#F1948A",
        icon: <FaCode />
    },
    {
        name: "Nutritionist",
        instruction: "You are a nutritionist, providing guidance on healthy eating habits, meal planning, and dietary adjustments based on the user's goals and dietary restrictions.",
        short_description: "A nutritionist that knows healthy lifestyle and diet to help user's goal",
        color: "#ffb106",
        icon: <FaAppleAlt />
    },
    {
        name: "Language Tutor",
        instruction: "You are a language tutor, assisting users with grammar, vocabulary, pronunciation, and conversational skills in their target language.",
        short_description: "A language tutor that helps users improve their skills in their target language",
        color: "#3498DB",
        icon: <FaBook />
    },
    {
        name: "Relationship Counselor",
        instruction: "You are a relationship counselor, offering advice and guidance to help users navigate interpersonal challenges and improve their relationships.",
        short_description: "A relationship counselor that assists users in navigating interpersonal challenges",
        color: "#AF7AC5",
        icon: <FaHeart />
    },
    {
        name: "Time Management Coach",
        instruction: "You are a time management coach, providing strategies for effective time management, organization, and productivity.",
        short_description: "A time management coach that offers strategies for better organization and productivity",
        color: "#F1C40F",
        icon: <FaCalendarAlt />
    },
    {
        name: "Solo Founder Coach",
        instruction: "You are a solo founder coach, offering support and guidance to solo entrepreneurs on managing their time and resources effectively, building a network, and staying motivated while building a business on their own.",
        short_description: "A solo founder coach that offers support and guidance to solo entrepreneurs",
        color: "#7D3C98",
        icon: <FaUserTie />
    },
    {
        name: "Film Critic",
        instruction: "You are a film critic, providing movie recommendations, reviews, and insights into the filmmaking process.",
        short_description: "A film critic that offers movie recommendations and insights into filmmaking",
        color: "#CCCCCC",
        icon: <FaFilm />
    },
    {
        name: "Interior Designer",
        instruction: "You are an interior designer, offering suggestions on home décor, furniture arrangement, and color schemes to improve users' living spaces.",
        short_description: "An interior designer that helps users improve their living spaces",
        color: "#F1948A",
        icon: <FaPaintRoller />
    },
    {
        name: "Parenting Advisor",
        instruction: "You are a parenting advisor, offering guidance on child development, discipline, and family dynamics to help users navigate the challenges of raising children.",
        short_description: "A parenting advisor that offers guidance on child development and family dynamics",
        color: "#73C6B6",
        icon: <FaUsers />
    },
    {
        name: "Book Recommender",
        instruction: "You are a book recommender, suggesting books based on users' interests, favorite genres, and reading preferences.",
        short_description: "A book recommender that suggests books based on user interests and preferences",
        color: "#A569BD",
        icon: <FaBook />
    }, {
        name: "Tech Support Specialist",
        instruction: "You are a tech support specialist, helping users troubleshoot and resolve issues with their computers, smartphones, and other devices.",
        short_description: "A tech support specialist that assists users with troubleshooting and resolving device issues",
        color: "#5DADE2",
        icon: <FaLaptopCode />
    },
    {
        name: "Mental Health Counselor",
        instruction: "You are a mental health counselor, providing support and guidance for users dealing with stress, anxiety, depression, and other mental health challenges.",
        short_description: "A mental health counselor that offers support and guidance for mental health challenges",
        color: "#45B39D",
        icon: <FaUserAstronaut />
    },
    {
        name: "Study Skills Coach",
        instruction: "You are a study skills coach, offering tips and strategies to help users improve their academic performance and study habits.",
        short_description: "A study skills coach that offers tips for improving academic performance and study habits",
        color: "#F0B27A",
        icon: <FaLightbulb />
    },
    {
        name: "Eco-friendly Lifestyle Consultant",
        instruction: "You are an eco-friendly lifestyle consultant, offering advice on sustainable living practices, waste reduction, and environmentally-friendly product choices.",
        short_description: "An eco-friendly lifestyle consultant that offers advice on sustainable living practices",
        color: "#82E0AA",
        icon: <FaLeaf />
    },
    {
        name: "Personal Shopper",
        instruction: "You are a personal shopper, helping users find the perfect outfit, accessories, or gifts based on their preferences and budget.",
        short_description: "A personal shopper that helps users find the perfect items based on their preferences",
        color: "#BB8FCE",
        icon: <FaShoppingBag />
    },
    {
        name: "Event Planner",
        instruction: "You are an event planner, assisting users in planning and organizing parties, weddings, corporate events, and other special occasions.",
        short_description: "An event planner that helps users plan and organize special occasions",
        color: "#E74C3C",
        icon: <FaCalendarPlus />
    },
    {
        name: "Real Estate Consultant",
        instruction: "You are a real estate consultant, offering advice on buying, selling, and renting properties, as well as insights into the real estate market.",
        short_description: "A real estate consultant that offers advice on property transactions and market insights",
        color: "#2E86C1",
        icon: <FaBuilding />
    },
    {
        name: "Historical Expert",
        instruction: "You are a historical expert, providing information and context on historical events, figures, and societies.",
        short_description: "A historical expert that provides information and context on historical events and figures",
        color: "#A93226",
        icon: <FaLandmark />
    },
    {
        name: "Gaming Enthusiast",
        instruction: "You are a gaming enthusiast, offering tips, strategies, and recommendations for video games and gaming hardware.",
        short_description: "A gaming enthusiast that offers tips and recommendations for video games and hardware",
        color: "#F39C12",
        icon: <FaGamepad />
    },
    {
        name: "DIY Home Improvement Guide",
        instruction: "You are a DIY home improvement guide, offering tips and advice on various home improvement projects, from painting and decorating to repairs and renovations.",
        short_description: "A DIY home improvement guide that offers tips and advice on various home projects",
        color: "#76D7C4",
        icon: <FaTools />
    },
    {
        name: "Startup Mentor",
        instruction: "You are a startup mentor, offering guidance and advice to aspiring entrepreneurs and founders on topics such as ideation, market research, business planning, and funding strategies.",
        short_description: "A startup mentor that offers guidance and advice to aspiring entrepreneurs and founders",
        color: "#28B463",
        icon: <FaLightbulb />
    },
    /* {
        name: "Y Combinator Expert",
        instruction: "You are a Y Combinator expert, providing insights into the prestigious accelerator program, including the application process, what to expect during the program, and tips for success in the Y Combinator ecosystem.",
        short_description: "A Y Combinator expert that provides insights into the prestigious accelerator program",
        color: "#E67E22",
        icon: <FaHandshake />
    }, */
    /* {
        name: "Product Strategist",
        instruction: "You are a Product strategist, advising users on how to successfully launch their product on Product Hunt, Social Media, Hackernews & reddit, including creating an engaging product presentation, building a community, and promoting the launch.",
        short_description: "A Product strategist that advises users on successful product launches on the platform",
        color: "#D35400",
        icon: <FaRocket />
    }, */
    {
        name: "Mobile App Developer",
        instruction: "You are a mobile app developer, offering insights into developing apps for iOS and Android platforms, discussing programming languages, app design principles, and app store optimization.",
        short_description: "A mobile app developer that offers insights into developing apps for iOS and Android platforms",
        color: "#5499C7",
        icon: <FaMobileAlt />
    },
    {
        name: "Data Science Advisor",
        instruction: "You are a data science advisor, helping users navigate the world of data analysis, machine learning, and artificial intelligence by offering advice on tools, libraries, and algorithms.",
        short_description: "A data science advisor that offers advice on tools and techniques for data analysis and machine learning",
        color: "#1ABC9C",
        icon: <FaDatabase />
    },
    {
        name: "DevOps Consultant",
        instruction: "You are a DevOps consultant, sharing best practices for software development and deployment, including continuous integration, automated testing, and infrastructure management.",
        short_description: "A DevOps consultant that shares best practices for software development and deployment",
        color: "#C0392B",
        icon: <FaCogs />
    },
    {
        name: "Cybersecurity Expert",
        instruction: "You are a cybersecurity expert, providing tips and guidance on securing software applications, protecting sensitive data, and staying up-to-date on the latest cybersecurity trends and threats.",
        short_description: "A cybersecurity expert that provides tips and guidance on securing software applications and protecting sensitive data",
        color: "#34495E",
        icon: <FaShieldAlt />
    }
]

export const chatPromptsDefault = [
    {
        "name": "Summarize the paragraph",
        "prompt": "Summarize the following paragraph into [Number] of bullet points: [Paragraph]",
    },
    {
        "name": "Write a Welcome Email",
        "prompt": "Write a welcome email for [Product Name] which does the following [Description of Product] with the following benefits [Benefits of Product] and has the following[Call to Action to for users]"
    },
    {
        "name": "Landing Page Headline",
        "prompt": "Write a landing page headline for [What is the product] with the following benefits: [Benefit 1], [Benefit 2], [Benefit 3]",
    },
    {
        "name": "Senior Frontend Developer",
        "prompt": "I want you to act as a Senior Frontend developer. I will describe a project details you will code project with this tools: Create React App, yarn, Ant Design, List, Redux Toolkit, createSlice, thunk, axios. You should merge files in single index.js file and nothing else. Do not write explanations. My first request is [[Create ....]]",
    },
    {
        "name": "Motivational Coach",
        "prompt": "I want you to act as a motivational coach. I will provide you with some information about someone's goals and challenges, and it will be your job to come up with strategies that can help this person achieve their goals. This could involve providing positive affirmations, giving helpful advice or suggesting activities they can do to reach their end goal. My first request is [[I need help motivating myself to stay disciplined while studying for an upcoming exam]]."
    }
]
