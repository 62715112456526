
import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export const generateNanoId = (len = 16) => customAlphabet(alphabet, len)();

export const generateNanoIdPrefix = (prefix = 'comp', len = 4) => `${prefix}-${customAlphabet(alphabet, len)()}`;

export const generateLocalIdByRole = (role: 'ai' | 'user' | 'system' | 'assistant') => {
    const roleFixed = (role === 'assistant' || role === 'ai')
        ? 'ais'
        : role === 'system'
            ? 'sys'
            : `usr`;
    return `${roleFixed}${generateNanoId(13)}`
};

export const defaultSenderIdRole = {
    ai: 'ai',
    assistant: 'ai',
    system: 'ai',

    user: 'user'
}

export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
