import { api } from "@acme/client"
import { AiFillSetting, AiFillStar, AiOutlineCode, AiOutlineLoading, AiOutlineSetting, AiOutlineStar, MdOutlineCloudSync, MdOutlineSyncDisabled, Tooltip, toast } from "@acme/ui"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { selectAtom } from "jotai/utils"
import { useCallback, useMemo } from "react"
import Head from 'next/head';

import { chatFavoritesAtom, chatSessionActiveInfoAtom, chatSessionsSettingsAtom } from "../store/chat"
import { CodeModal } from "../popup/CodePopup"
import { codeModalAtom } from "../store/user"

export const ChatTitle = () => {
  const chatActive = useAtomValue(chatSessionActiveInfoAtom)
  return (
    <div className="w-full flex flex-row items-center justify-between px-4 text-white ">
      <h1 className="text-lg text-white z-10">
        {chatActive?.name || ''}
      </h1>
    </div>
  )
}

export const ChatTitleMenu = () => {
  const chatActive = useAtomValue(chatSessionActiveInfoAtom)
  const setCodeModal = useSetAtom(codeModalAtom)
  const setChatSettings = useSetAtom(chatSessionsSettingsAtom)
  const [chatFavorites, setChatFavorites] = useAtom(chatFavoritesAtom)
  const [isFavorite] = useAtom(useMemo(() => selectAtom(chatFavoritesAtom, (v) => v.some(a => a.chatSessionId === chatActive?.id)), [chatActive?.id]))
  const { mutate: mutateFavorite, isLoading: isLoadingFavorite } = api.chat.updateChatFavorite.useMutation(
    {
      onSuccess: (data) => {
        if (data) {
          // apiContext.chat.chatFavorites.invalidate()
          const { isFavorite, ...all } = data
          if (isFavorite) {
            const newFav = { chatSessionId: all.chatSessionId, created: all.created as Date, updated: all.updated as Date }
            setChatFavorites(prev => ([...prev, newFav]))
          } else {
            setChatFavorites(chatFavorites.filter(a => a.chatSessionId !== data.chatSessionId))
          }
        }
      }
    }
  )

  const onFavorite = useCallback(() => {
    if (chatActive?.isSync) {
      if (chatActive.isInit) {
        toast.error('Add favorite failed, please wait for the chat session to be synced and add your first message.')
        return;
      } else {
        mutateFavorite({
          id: chatActive.id,
          isFavorite: !isFavorite,
        })
      }
    } else {
      setChatFavorites(prev => {
        const newFav = { chatSessionId: chatActive?.id as string, created: new Date(), updated: new Date() }
        const isExist = prev.some(a => a.chatSessionId === chatActive?.id)
        if (isExist) {
          return prev.filter(a => a.chatSessionId !== chatActive?.id)
        }
        return ([...prev, newFav])
      })
      // toast.error('Add favorite failed, favorite only available for synced chat session.')
    }
  }, [chatActive?.id, chatActive?.isInit, chatActive?.isSync, isFavorite, mutateFavorite, setChatFavorites])

  const onCodePopup = useCallback(() => {
    setCodeModal({ isOpen: true })
  }, [setCodeModal])


  return (
    <>
      <Head>
        <title>{!!chatActive?.name ? `${chatActive?.name} - DapperGPT Chat` : 'DapperGPT - Chat'}</title>
      </Head>
      <CodeModal />
      <div className="w-full flex flex-row items-center justify-between px-4 gap-3 select-none">
        <div className="flex flex-row items-center gap-1 bg-gray-700 rounded p-[2px] px-[4px] mr-2 z-10">
          <p className="text-gray-400 dark:text-gray-400 hidden lg:flex flex-row items-center gap-1 text-[10px]">{chatActive?.isSync ? (<> <MdOutlineCloudSync className="text-lg" /><span>Synced</span></>) : (<><MdOutlineSyncDisabled className="text-lg" /><span>Not sync</span></>)}</p>
          <p className="text-gray-400 dark:text-gray-400 text-[10px] flex lg:hidden">{chatActive?.isSync ? (<> <MdOutlineCloudSync className="text-lg" /></>) : (<><MdOutlineSyncDisabled className="text-lg text-gray-500" /></>)}</p>
        </div>
        <Tooltip
          position="bottom"
          className="text-center"
          message={isFavorite ? 'Remove from favorite' : `Add to favorite`}
        >
          <div onClick={onFavorite} className={`${isFavorite ? 'bg-yellow-100' : ''} hover:bg-gray-700 rounded-lg h-9 w-9 flex items-center justify-center cursor-pointer`}>
            {isLoadingFavorite ? (
              <AiOutlineLoading className="text-white animate-spin text-xl" />
            ) : !isFavorite ? (
              <AiOutlineStar className="text-white text-xl" />
            ) : (
              <AiFillStar className="text-yellow-500 text-xl" />
            )}
          </div>
        </Tooltip>
        <Tooltip
          position="bottom"
          message="Generate Code"
        >
          <div onClick={onCodePopup} className="hover:bg-gray-700 rounded-lg h-9 w-9 flex items-center justify-center cursor-pointer">
            <AiOutlineCode className="text-lg text-white" />
          </div>
        </Tooltip>
        <Tooltip
          position="bottom"
          message="This Chat Settings"
        >
          <div onClick={() => setChatSettings(true)} className="hover:bg-gray-700 rounded-lg h-9 w-9 flex items-center justify-center cursor-pointer">
            <AiOutlineSetting className="text-lg text-white" />
          </div>
        </Tooltip>
      </div>
    </>
  )
}