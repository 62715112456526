import { Tab } from '@headlessui/react'
import { useMemo, type ReactNode } from 'react' // import ReactNode here

type Tab<T> = {
  id: string,
  title: string,
  content: T
}

type Props<T> = {
  tabs: Tab<T>[],
  onSelect?: (tabId: string) => void,
  renderTab: (selected: Tab<T> | undefined) => ReactNode,
  activeTabId: string
  containerClassName?: string
}

function classNames(...classes: string[]) { // add a type for the "classes" parameter
  return classes.filter(Boolean).join(' ')
}

export const Tabs = <T,>({ tabs, renderTab, onSelect, activeTabId, containerClassName }: Props<T>) => {
  const activeTab = useMemo(() => tabs.find((tab) => tab.id === activeTabId), [tabs, activeTabId])
  return (
    <div className={`w-full ${containerClassName}`}>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-neutral-500/20 p-1">
          {tabs.map((tab) => (
            <Tab
              key={tab.id} // use the tab id as key
              onClick={() => onSelect?.(tab.id)}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm leading-5 ',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-neutral-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow text-neutral-700 font-medium'
                    : 'hover:bg-white/[0.12] hover:text-gray-600 text-neutral-500 dark:text-neutral-400'
                )
              }
              value={tab.id} // use the tab id as value
            >
              {tab.title}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {renderTab(activeTab)}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}