import { deepEqual, defaultModelPrismaToString, generateLocalIdByRole, isModelAllowedVision, type ChatMessage } from "@acme/util";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useCallback, useMemo } from "react";
import { toast } from "@acme/ui";
import { chatAddMessageAtom, chatRegenerateMessageAtom, chatRemoveMessageAtom, chatSendMessageAtom, chatSendMessageCloudAtom, chatSessionActiveAtom, chatSessionActiveIdAtom, chatUpdateMessageAtom, chatUpdateTriggerAtom } from "../store/chat";
import { userAtom } from "../store/user";
import { apiKeyAtom } from "../store/setting";
import { selectAtom } from "jotai/utils";
import { composeContextAtom, composeContextImgsAtom } from "../store/util";
import { api, type RouterInputs } from "@acme/client";
import { useRouter } from "next/router";
import { ComposeMessage } from "./ComposeMessage";

const lastMessageAtom = selectAtom(chatSessionActiveAtom, (session) => (session?.messages || []).length > 1 ? session?.messages[session.messages.length - 1] || null : null, deepEqual)
const activeSessionInfoAtom = selectAtom(chatSessionActiveAtom, (session) => {
  if (!session) return null
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { messages, ...all } = session
  return all
}, deepEqual)

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const ChatCompose = () => {
  const router = useRouter()
  const [inputValue, setInputValue] = useAtom(composeContextAtom);
  const [inputImgsValue, setInputImgsvalue] = useAtom(composeContextImgsAtom);
  const apiKey = useAtomValue(apiKeyAtom)
  const user = useAtomValue(userAtom)
  const session = useAtomValue(activeSessionInfoAtom)
  const chatSessionActiveId = useAtomValue(chatSessionActiveIdAtom)
  const lastMessage = useAtomValue(lastMessageAtom)
  const [updateTriggers, setUpdateTriggers] = useAtom(chatUpdateTriggerAtom)
  const modelAllowVision = useMemo(() => isModelAllowedVision(session?.defaultModel), [session?.defaultModel])
  const onSetSession = useSetAtom(chatSessionActiveAtom)
  const onSendMessage = useSetAtom(chatSendMessageAtom)
  const onSendMessageCloud = useSetAtom(chatSendMessageCloudAtom)
  const onRegenerateLast = useSetAtom(chatRegenerateMessageAtom)
  const onAddMessageAction = useSetAtom(chatAddMessageAtom)
  const onUpdateMessageAction = useSetAtom(chatUpdateMessageAtom)
  const onDeleteMessageAction = useSetAtom(chatRemoveMessageAtom)

  const { mutateAsync: mutateAsyncCreateSession } = api.chat.createChatSession.useMutation()
  const { mutateAsync: mutateAsyncUpdateSession } = api.chat.updateChatSession.useMutation()
  const { mutateAsync: mutateAsyncDeleteSession } = api.chat.deleteChatSession.useMutation()
  const { mutateAsync: mutateAsyncAddMessage } = api.chat.addMessage.useMutation()
  const { mutateAsync: mutateAsyncUpdateMessage } = api.chat.updateMessage.useMutation()
  const { mutateAsync: mutateAsyncDeleteMessage } = api.chat.deleteMessage.useMutation()

  const onSend = async () => {
    if (lastMessage?.loading) {
      return;
    }
    if (chatSessionActiveId) {
      const message: ChatMessage = {
        id: generateLocalIdByRole('user'),
        chatSessionId: chatSessionActiveId,
        content: {
          contentType: 'text',
          content: inputValue
        },
        contentCustoms: inputImgsValue.map((img) => ({ contentType: 'image_url', content: { image_url: { url: img.url } } })),
        userId: user.id,
        created: new Date(),
        senderRole: "user"
      }
      const localMessageTemp = message
      try {
        setInputValue('')
        setInputImgsvalue([])

        if (session?.isSync && session?.teamId) {
          // Create session if new
          if (session.isSync && session.isInit) {
            const sessionInput = {
              ...session,
              teamId: session.teamId || '',
              localId: session.id || '',
            } satisfies RouterInputs['chat']['createChatSession']
            toast.loading("Initializing session...", { id: 'init-session' })
            const newSession = await mutateAsyncCreateSession(sessionInput)
            const { messages, ...newSessionData } = newSession
            onSetSession({
              ...session,
              ...newSessionData,
              isSync: true,
              isInit: false,
              isStreaming: session?.isStreaming || true,
              isImageGen: session?.isImageGen || false,
              defaultImageGenTriggers: undefined,
              localId: newSession.localId || '', name: newSession.name || '',
              defaultInstruction: newSession.defaultInstruction || '',
              defaultModel: newSession.defaultModel ? defaultModelPrismaToString(newSession.defaultModel) : undefined,
              created: newSession.created || new Date(),
              updated: newSession.updated || new Date(),
              order: newSession.order || undefined,
              orderUpdated: newSession.orderUpdated || undefined,

              // Prevent load messages from the cloud
              lastSync: new Date(),
            })
            if (newSession.id !== session.id) {
              await router.push({ query: { chatId: newSession.id } })
            }
            toast.dismiss('init-session')
          }
          await sleep(50)
          await onSendMessageCloud(message, apiKey?.key || '')
        } else {
          await onSendMessage(message, apiKey?.key || '')
        }
      } catch (e) {
        toast.dismiss('init-session')
        toast.error("Error")
        const localMessage = localMessageTemp.content.content
        setInputValue(typeof localMessage === 'string' ? localMessage : '')
      }
    }
  }

  const onRegenerate = async () => {
    if (chatSessionActiveId) {
      await onRegenerateLast({ sessionId: chatSessionActiveId }, apiKey?.key || '')
    }
  }

  const onCancelResponse = async () => {
    if (lastMessage?.abortSignal && lastMessage.abortSignal.abort) {
      lastMessage.abortSignal.abort()
    }
    onUpdateMessageAction({
      id: lastMessage?.id || '',
      loading: false,
      generating: false,
      imaging: false,
      abortSignal: null
    }, { isSkip: true })
    if (!lastMessage?.content?.content && lastMessage?.id) {
      onDeleteMessageAction(lastMessage?.id || '', lastMessage?.chatSessionId, true)
    }
  }

  //~ TRIGGERS
  const onUpdateSession = useCallback(async (chatSessionId: string, data: RouterInputs['chat']['updateChatSession']) => {
    setUpdateTriggers((prev) => prev.map((t) => {
      if (t.chatSessionId === chatSessionId) {
        return { ...t, loading: true }
      }
      return t
    }))
    console.log('hereee')
    await mutateAsyncUpdateSession(data)
    setUpdateTriggers((prev) => prev.filter((t) => t.chatSessionId !== chatSessionId))
  }, [mutateAsyncUpdateSession, setUpdateTriggers])

  const onDeleteSession = useCallback(async (chatSessionId: string) => {
    setUpdateTriggers((prev) => prev.map((t) => {
      if (t.chatSessionId === chatSessionId) return { ...t, loading: true }
      return t
    }))
    await mutateAsyncDeleteSession({ id: chatSessionId })
    setUpdateTriggers((prev) => prev.filter((t) => t.chatSessionId !== chatSessionId))
  }, [mutateAsyncDeleteSession, setUpdateTriggers])

  const onAddMessage = useCallback(async (message: RouterInputs['chat']['addMessage'], chatSessionId: string) => {
    setUpdateTriggers((prev) => prev.map((t) => {
      if (t.chatSessionId === chatSessionId) return { ...t, loading: true }
      return t
    }))
    try {
      // onAddMessageAction({ id: message.id, loading: true, loadingText: 'Updating...' }, { chatSessionId, isSkip: true })
      await mutateAsyncAddMessage(message)
      // const msgContent: ChatMessage['content'] | undefined = message?.content?.content ? { contentType: message.content.contentType, content: message.content.content } : undefined
      // onAddMessageAction({ ...msgContent ? { content: msgContent } : {}, id: message.id, loading: false, loadingText: '' }, { chatSessionId, isSkip: true })
    } catch (e) {
      // onAddMessageAction({ id: message.id, loading: false, loadingText: '', error: 'Update error' }, { chatSessionId, isSkip: true })
    }
    setUpdateTriggers((prev) => prev.filter((t) => t.chatSessionId !== chatSessionId))
  }, [mutateAsyncAddMessage, setUpdateTriggers])

  const onDeleteMessage = useCallback(async (messageId: string, chatSessionId: string) => {
    setUpdateTriggers((prev) => prev.map((t) => {
      if (t.chatSessionId === chatSessionId) return { ...t, loading: true }
      return t
    }))
    try {
      onUpdateMessageAction({ id: messageId, loading: true, loadingText: 'Deleting...' }, { chatSessionId, isSkip: true })
      await mutateAsyncDeleteMessage({ id: messageId, chatSessionId })
      onDeleteMessageAction(messageId, chatSessionId, true)
    } catch (e: any) {
      if (e?.message === 'Message not found') {
        onDeleteMessageAction(messageId, chatSessionId, true)
      }
      onUpdateMessageAction({ loading: false, loadingText: '', error: 'Delete error' }, { chatSessionId, isSkip: true })
    }
    setUpdateTriggers((prev) => prev.filter((t) => t.chatSessionId !== chatSessionId))
  }, [mutateAsyncDeleteMessage, onDeleteMessageAction, onUpdateMessageAction, setUpdateTriggers])

  const onUpdateMessage = useCallback(async (message: RouterInputs['chat']['updateMessage'], chatSessionId: string) => {
    setUpdateTriggers((prev) => prev.map((t) => {
      if (t.chatSessionId === chatSessionId) return { ...t, loading: true }
      return t
    }))
    try {
      onUpdateMessageAction({ id: message.id, loading: true, loadingText: 'Updating...' }, { chatSessionId, isSkip: true })
      await mutateAsyncUpdateMessage(message)
      const msgContent: ChatMessage['content'] | undefined = message?.content?.content ? { contentType: message.content.contentType, content: message.content.content } : undefined
      onUpdateMessageAction({ ...msgContent ? { content: msgContent } : {}, id: message.id, loading: false, loadingText: '' }, { chatSessionId, isSkip: true })
    } catch (e) {
      onUpdateMessageAction({ id: message.id, loading: false, loadingText: '', error: 'Update error' }, { chatSessionId, isSkip: true })
    }
    setUpdateTriggers((prev) => prev.filter((t) => t.chatSessionId !== chatSessionId))
  }, [mutateAsyncUpdateMessage, onUpdateMessageAction, setUpdateTriggers])

  useEffect(() => {
    if (updateTriggers) {
      const notLoadings = updateTriggers.filter((t) => !t.loading)
      notLoadings.map((t) => {
        if (t.update) onUpdateSession(t.chatSessionId, t.update)
        else if (t.delete) onDeleteSession(t.chatSessionId)
        else if (t.deleteMessage) onDeleteMessage(t.deleteMessage.id, t.chatSessionId)
        else if (t.updateMessage) onUpdateMessage(t.updateMessage, t.chatSessionId)
        else if (t.addMessage) onAddMessage(t.addMessage, t.chatSessionId)
      })
    }
  }, [onAddMessage, onDeleteMessage, onDeleteSession, onUpdateMessage, onUpdateSession, updateTriggers])

  if (!session?.id) return null
  return (
    <ComposeMessage
      onSend={onSend}
      onRegenerate={onRegenerate}
      onCancelResponse={onCancelResponse}
      isLastMessageLoading={lastMessage?.loading || lastMessage?.generating}
      isLoading={false}
      type="chat"
      isAllowImage={modelAllowVision}
    />
  )
}

ChatCompose.displayName = 'ChatCompose'
