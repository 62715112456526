import { api, type RouterOutputs } from '@acme/client';
import { AiOutlineLoading, Button, FaSignInAlt, MdOutlineFiberNew, SectionSettingsWrapper, TextInputAreaLabelForm, TextInputLabelForm, toast } from '@acme/ui';
import { generateNanoId } from '@acme/util';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { FiTrash, FiPlusCircle, FiCheck, FiX } from 'react-icons/fi';
import { teamActiveAtom, userAtom } from '../store/user';
import { signIn } from "next-auth/react";
import { useUserSubscription } from '../hooks';

type Profile = {
  isNew?: boolean
  settings?: any
  created?: Date | null
  updated?: Date | null
} & ProfileOutput;

type ProfileOutput = RouterOutputs['extension']['extensionProfiles'][number]

const defaultInstruction = { positive: { instruction: '', model: undefined, label: undefined }, negative: { instruction: '', model: undefined, label: undefined }, context: { instruction: '', model: undefined, label: undefined }, system: { instruction: '' } }
const emptyProfile = {
  id: '', name: '', userId: '', teamId: '', isPrivate: false,
  instructions: defaultInstruction, settings: {}, created: null, updated: null
}

export const ExtensionSettings = () => {
  const { isSubscribe } = useUserSubscription()
  const teamActive = useAtomValue(teamActiveAtom)
  const user = useAtomValue(userAtom)
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: session } = api.auth.getSession.useQuery();
  const { data: profilesData } = api.extension.extensionProfiles.useQuery(
    { teamId: teamActive?.id },
    {
      enabled: !!teamActive?.id && !!user?.id && !!session,
      onSuccess: (data) => {
        setProfiles(prev => {
          const newProfiles = prev.filter(p => p?.isNew)
          return [...data, ...newProfiles];
        });
      }
    }
  );
  const { mutateAsync: onCreateProfile, isLoading: isLoadingCreate } = api.extension.profileCreate.useMutation();
  const { mutateAsync: onUpdateProfile, isLoading: isLoadingUpdate } = api.extension.profileUpdate.useMutation();
  const { mutateAsync: onDeleteProfile, isLoading: isLoadingDelete } = api.extension.profileDelete.useMutation();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setSelectedProfile((prevState) => {
      const newInstructions: ProfileOutput['instructions'] = {
        ...defaultInstruction,
        ...prevState?.instructions,
        ...name === 'systemInstruction' ? {
          system: {
            ...prevState?.instructions?.system,
            instruction: value,
          }
        } : {},
        ...name === 'positiveInstruction' ? {
          positive: {
            ...prevState?.instructions?.positive,
            instruction: value,
          }
        } : {},
        ...name === 'negativeInstruction' ? {
          negative: {
            ...prevState?.instructions?.negative,
            instruction: value,
          }
        } : {},
        ...name === 'contextInstruction' ? {
          context: {
            ...prevState?.instructions?.context,
            instruction: value,
          }
        } : {},
      }

      return {
        ...emptyProfile,
        ...prevState,
        ...name === 'name' ? { name: value } : {},
        instructions: newInstructions
      }
    });
  };

  const handleProfileDelete = async (profileId: string) => {
    if (!teamActive?.id) {
      toast.error('You must be part of a team to delete a profile');
      return;
    }
    if (!selectedProfile?.isNew) {
      await onDeleteProfile({ profileId, teamId: selectedProfile?.teamId || '' });
    }
    setProfiles(profiles.filter((profile) => profile.id !== profileId));
    setSelectedProfile(emptyProfile);
    setShowDeleteModal(false);
  };

  const handleModalOpen = () => {
    setShowDeleteModal(true);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  const onAddProfile = () => {
    if (!isSubscribe) {
      toast.error('Profile Modification currently only available for Pro, Upgrade your account')
      return
    }
    if (profiles?.length && profiles.length > 2) {
      toast.error('You can only have 3 profiles per team')
      return
    }
    const newProfile = {
      id: generateNanoId(16),
      name: 'New Profile',
      userId: user?.id || '',
      teamId: teamActive?.id || '',
      isPrivate: false,
      instructions: {
        positive: { instruction: 'Write in Positive (context \"{{textinput.value}}\"):\n' },
        negative: { instruction: 'Write in Negative (context \"{{textinput.value}}\"):\n' },
        context: { instruction: '\"{{context}}\"' },
        system: { instruction: 'You are a helpful assistant that can help me write post, comments or replies.\nThe replies should be casual and professional' },
      },
      settings: {},
      created: new Date(),
      updated: new Date(),
      isNew: true,
    }
    setProfiles([...profiles, newProfile])
    setSelectedProfile(newProfile)
  }

  const onSave = async () => {
    if (!selectedProfile) return
    if (selectedProfile?.isNew) {
      const tempId = selectedProfile.id
      const newProfile = await onCreateProfile(selectedProfile)
      setSelectedProfile(newProfile)
      setProfiles(profiles.map(p => p.id === tempId ? { ...p, ...selectedProfile } : p))
    } else {
      const tempId = selectedProfile.id
      const updateProfile = await onUpdateProfile(selectedProfile)
      setSelectedProfile(updateProfile)
      setProfiles(profiles.map(p => p.id === tempId ? { ...p, ...selectedProfile } : p))
    }
  }

  const onDownload = () => {
    if (window) {
      // open chrome extension url
      window.open('https://chrome.google.com/webstore/detail/dappergpt-chrome-extensio/opibaobahpmecdlkgifnombagoogbkmj/', '_blank')
    }
  }

  return (
    <div className="bg-gray-100 dark:bg-neutral-700 min-h-screen overflow-y-scroll">
      <div className="container mx-auto py-6">
        {/* <h1 className="text-2xl font-bold mb-6 dark:text-white">Extension Profiles Settings</h1> */}
        {!session ? (
          <Button
            className={`w-full h-10 text-sm mt-4 p-4 border ${!session ? '!bg-[#2563eb] !text-white' : 'border-red-500 !bg-red-400 !text-white hover:bg-red-600 '} rounded-md`}
            onClick={() => signIn()}
          >
            <>
              <FaSignInAlt className="inline mr-2" />
              <p>Sign In</p>
            </>
          </Button>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className='flex flex-col gap-3'>
              <SectionSettingsWrapper title='Profiles' titleClassName='text-lg font-medium pb-1 -mt-1'>
                <div className="space-y-4">
                  {profiles.map((profile) => (
                    <div
                      key={profile.id}
                      className={`relative border dark:border-neutral-600 rounded-lg p-4 cursor-pointer hover:bg-neutral-100 ${selectedProfile && selectedProfile.id === profile.id ? 'bg-neutral-100 dark:bg-neutral-600 dark:border-neutral-400' : 'dark:hover:bg-neutral-700'
                        }`}
                      onClick={() => setSelectedProfile(profile)}
                    >
                      <div className="flex items-center justify-between">
                        {profile?.isNew && <MdOutlineFiberNew className='absolute left-1 top-1 text-lg text-red-600' />}
                        <h3 className="font-medium">
                          {profile.name}
                        </h3>
                        <div className="flex space-x-2">
                          {/* <FiEdit
                        className="text-indigo-500 hover:text-indigo-600 transition-all duration-200"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleProfileUpdate({ ...profile, name: 'Updated Profile' });
                        }}
                      /> */}
                          <FiTrash
                            className="text-red-500 hover:text-red-600 transition-all duration-200"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleModalOpen();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-6">
                  <button
                    className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 transition-all duration-200"
                    onClick={onAddProfile}
                  >
                    <FiPlusCircle />
                    <span>Add Profile</span>
                  </button>
                </div>
              </SectionSettingsWrapper>
              {user?.id && (
                <SectionSettingsWrapper title='Download Extension' titleClassName='text-lg font-medium pb-1 -mt-1'>
                  <Button onClick={onDownload}>
                    Download (Currently in Beta)
                  </Button>
                  <div className="space-y-2 mt-5">
                    <p className='font-bold'>Note:</p>
                    <ul className='ml-5'>
                      <li>We don&apos;t store your AI request data on our server</li>
                      {/* <li>In the current version, ignore the profiles 💻 Work,🖕 Casual and 🎭 Friends</li> */}
                      <li>The extension is in <span className='bg-gray-700 text-white p-1'>beta</span> and will be updated frequently</li>
                      <li>If you have any suggestions and idea, please let us know to hi@dappergpt.com</li>
                    </ul>
                  </div>
                </SectionSettingsWrapper>
              )}
            </div>
            {selectedProfile && (
              <SectionSettingsWrapper title={`Profile Details - ${selectedProfile?.name || ''}`} titleClassName='text-lg font-medium pb-1 -mt-1'>
                <div className="space-y-4">
                  <div>
                    <TextInputLabelForm
                      id='name'
                      label="Name"
                      name="name"
                      value={selectedProfile.name}
                      onChange={(e) => setSelectedProfile(prev => ({ ...emptyProfile, ...prev, name: e }))}
                    />
                  </div>
                  <div>
                    <TextInputAreaLabelForm
                      label="System Instruction"
                      name="systemInstruction"
                      inputClassName='min-h-[100px]'
                      value={selectedProfile.instructions?.system?.instruction}
                      onChange={(e, event) => handleInputChange(event)}
                    />
                  </div>
                  <div>
                    <TextInputAreaLabelForm
                      label="Context Message Format"
                      name="contextInstruction"
                      inputClassName='min-h-[100px]'
                      value={selectedProfile.instructions?.context?.instruction}
                      onChange={(e, event) => handleInputChange(event)}
                    />
                  </div>
                  <div className="flex justify-end gap-2">
                    <Button
                      loading={isLoadingCreate || isLoadingUpdate}
                      className="bg-blue-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-blue-600 transition-all duration-200"
                      onClick={onSave}
                    >
                      {(!isLoadingCreate && !isLoadingUpdate) && <FiCheck />}
                      <span>{selectedProfile?.isNew ? 'Create & ' : ''}Save</span>
                    </Button>

                    <Button
                      className="bg-gray-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-gray-600 transition-all duration-200"
                      onClick={() => setSelectedProfile(emptyProfile)}
                    >
                      <FiX />
                      <span>Cancel</span>
                    </Button>
                  </div>
                </div>

                <div className="text-gray-6s00 text-sm mb-3">
                  <p>Available propertes: </p>
                  <ul className="ml-5">
                    <li><strong>{`{{textinput.value}}`}</strong> - Current Textinput value</li>
                    <li><strong>{`{{context}}`}</strong> - Context (input filled in the context popup)</li>
                    <li><strong>{`{{site.name}}`}</strong> - Site Name (eg: twitter, facebook, youtube, etc)</li>
                  </ul>
                </div>
              </SectionSettingsWrapper>
            )}
            {showDeleteModal && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow">
                  <h2 className="text-xl font-bold mb-4">Delete Profile</h2>
                  <p>Are you sure you want to delete this profile?</p>
                  <div className="flex justify-end space-x-4 mt-6">
                    <Button
                      loading={isLoadingDelete}
                      className="bg-red-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-red-600 transition-all duration-200"
                      onClick={() => handleProfileDelete(selectedProfile?.id || '')}
                    >
                      {!isLoadingDelete && <FiCheck />}
                      <span>Delete</span>
                    </Button>
                    <Button
                      className="bg-gray-300 !text-gray-700 flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-gray-400 transition-all duration-200"
                      onClick={handleModalClose}
                    >
                      <FiX />
                      <span>Cancel</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

