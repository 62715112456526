import React, { memo, useCallback, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAtom, useAtomValue } from "jotai";
import { codeModalAtom, teamActiveAtom } from "../store/user";
import { type SubscriptionPlan, defaultPlans, dayjs, compileGptMessages, type GPTChatMessage, chatModels } from "@acme/util";
import { api } from "@acme/client";
import { subscribe } from "../setting/SettingsSection/Plans";
import { Button, SwitchWithLabel, Tabs, toast } from "@acme/ui";
import Editor from 'react-simple-code-editor';
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import { chatSessionActiveAtom } from "../store/chat";

export const CodeModal: React.FC = () => {
  const [{ isOpen }, setCodeModal] = useAtom(codeModalAtom);

  const onClose = () => {
    setCodeModal({ isOpen: false });
  };

  if (!isOpen) return null;
  return (
    <CodeModalComponent
      isOpen={isOpen}
      onClose={onClose}
      plans={defaultPlans}
    />
  );
};

type CodeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // onUpgrade: (plan: PlanProps) => void;
  plans?: SubscriptionPlan[];
};

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: { y: "-50vh", opacity: 0 },
  visible: { y: "0", opacity: 1, transition: { delay: 0.5 } },
};


const codeOptions = [
  { id: 'curl', title: 'curl', content: { test: null } },
  { id: 'axios', title: 'axios', content: { test: null } },
  { id: 'node', title: 'node', content: { test: null } },
  { id: 'python', title: 'python', content: { test: null } },
  { id: 'ruby', title: 'ruby', content: { test: null } },
  { id: 'go', title: 'go', content: { test: null } },
  { id: 'java', title: 'java', content: { test: null } },
]

type CodeOptionIds = 'curl' | 'axios' | 'node' | 'python' | 'ruby' | 'go' | 'java'

export const CodeModalComponent: React.FC<CodeModalProps> = memo(({
  isOpen,
  onClose,
  plans = defaultPlans,
}) => {
  const [code, setCode] = React.useState(
    `function add(a, b) {\n  return a + b;\n}`
  );
  const [messages, setMessages] = React.useState<GPTChatMessage[]>([]);
  const [activeOption, setActiveOptionState] = useState<CodeOptionIds>('curl')
  const [isTrimLimit, setIsTrimLimit] = useState<boolean>(false)
  const chatActive = useAtomValue(chatSessionActiveAtom)



  useEffect(() => {
    if (activeOption) {
      setCode(generateCodeSnippet(activeOption, 'API_KEY', messages, chatModels["gpt-3.5-turbo"].id, undefined))
    }
  }, [activeOption, messages])

  const hightlightWithLineNumbers = (input: string, language: string) =>
    highlight(input, language)
      .split("\n")
      .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join("\n");

  const onCopyCode = () => {
    const newEl = document.createElement('textarea');
    newEl.value = code;
    document.body.appendChild(newEl);
    newEl.select();
    document.execCommand('copy');
    document.body.removeChild(newEl);
    toast.success('Copied to clipboard')
  }

  const setMessage = useCallback(() => {
    if (chatActive) {
      const { newMessageFixed: newMessage, gptMessages, initInstruction } = compileGptMessages(chatActive, undefined, 8000)
      setMessages(gptMessages)
    }
  }, [chatActive])

  useEffect(() => {
    if (chatActive) {
      setMessage()
    }
  }, [chatActive, setMessage])


  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-[100000] flex items-center justify-center bg-gray-900 bg-opacity-50 h-screendvh"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={backdrop}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        >
          <motion.div
            className="bg-white rounded-lg shadow-xl w-full max-w-2xl m-auto relative"
            variants={modal}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-8 pt-5">
              <h1 className="text-2xl font-bold">Preview Code<span className="ml-2 bg-gray-600 text-white text-[10px] p-1">(preview feature)</span></h1>
              <p className="py-0 text-sm text-gray-600 mt-0 mb-4">Preview how the request to OpenAI will look like.</p>
              <Tabs
                tabs={codeOptions}
                onSelect={(tab) => setActiveOptionState(tab as 'curl')}
                activeTabId={activeOption}
                renderTab={(tab) => (
                  <div>
                  </div>
                )}
              />
              <div className="bg-gray-100 p-2 rounded-lg">
                <div className="codegen-editor">
                  <Editor
                    value={code}
                    onValueChange={code => setCode(code)}
                    padding={10}
                    textareaId="codeArea"
                    className="codeEditor max-h-[40vh] !overflow-y-auto"
                    highlight={code => hightlightWithLineNumbers(code, languages.js)}
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 12,
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center justify-between mt-4">
                <div className="flex flex-col gap-2">
                  {/* <SwitchWithLabel
                    label="Trim if prompts exceed limits"
                    size="small"
                    textClassName="text-sm text-gray-600"
                    value={isTrimLimit}
                    onChange={(val) => setIsTrimLimit(val)}
                  /> */}
                  {/* <SwitchWithLabel
                    label="Include API Key"
                    size="small"
                    textClassName="text-sm text-gray-600"
                    value={true}
                    onChange={() => null}
                  /> */}
                </div>
                <Button
                  loading={false}
                  className="!bg-blue-600 !text-white !py-2"
                  onClick={onCopyCode}
                >
                  Copy to Clipboard
                </Button>
              </div>
            </div>

            <div className="bg-neutral-200 rounded-b-lg w-full py-3 px-6 flex flex-row items-center justify-end">
              <Button
                loading={false}
                className="!bg-gray-600 !text-white !py-2"
                onClick={() => onClose()}
              >
                Close
              </Button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence >
  );
});
CodeModalComponent.displayName = "CodeModalComponent";

function getAuthorizationHeader(apiKey: string) {
  return { Authorization: `Bearer ${apiKey}` };
}

function headersToString(headers: Record<string, string>): string {
  return Object.keys(headers)
    .map((key) => `${key}: ${headers[key]}`)
    .join("\n");
}

function generateCodeSnippet(
  language: string,
  apiKey: string,
  messages: { role: string; content: string }[],
  model = chatModels["gpt-3.5-turbo"].id,
  temperature?: number
) {
  let headers;
  let body;
  switch (language.toLowerCase()) {
    case "curl":
      headers = {
        "Content-Type": "application/json",
        ...getAuthorizationHeader(apiKey),
      };

      body = JSON.stringify({
        model,
        messages,
        ...(temperature && { temperature }),
      });

      return `curl https://api.openai.com/v1/chat/completions \\
  -H "Content-Type: application/json" \\
  -H "${headersToString(headers)}" \\
  -d '${body}'`;

    case "json":
      body = JSON.stringify({
        model,
        messages,
        ...(temperature && { temperature }),
      });

      return `${body}`;

    case "python":
      return `import os
import openai
openai.api_key = "${apiKey}"
openai.Completion.create(
  engine="${model}",
  prompt=${JSON.stringify(messages)},
  temperature=${temperature ?? 0.7}
)`;

    case "node":
      return `import { Configuration, OpenAIApi } from "openai";
const configuration = new Configuration({
    apiKey: "${apiKey}",
});
const openai = new OpenAIApi(configuration);
const response = await openai.complete({
  engine: "${model}",
  prompt: ${JSON.stringify(messages)},
  temperature: ${temperature ?? 0.7},
});
console.log(response);`;

    case "axios":
      const axiosHeaders = {
        "Content-Type": "application/json",
        ...getAuthorizationHeader(apiKey),
      };

      const axiosData = {
        model,
        messages,
        ...(temperature && { temperature }),
      };

      return `import axios from "axios";
axios.defaults.headers.common = ${JSON.stringify(axiosHeaders)};
const response = await axios.post(
  "https://api.openai.com/v1/chat/completions",
  ${JSON.stringify(axiosData, null, 2)}
);
console.log(response);`;

    case "ruby":
      return `require "openai"
Openai.api_key = "${apiKey}"
completion = Openai.Completion.create(
  engine: "${model}",
  prompt: ${JSON.stringify(messages)},
  temperature: ${temperature ?? 0.7}
);
puts completion.choices.map { |c| c.text };`;

    case "java":
      const javaMessages = messages.map(
        ({ role, content }) => `new AIInput("${role}", "${content}")`
      );

      return `import com.openai.*;
import java.util.*;
Openai.api_key = "${apiKey}";
final AIEngine engine = AIEngine.valueOf("${model}");
final List<AIInput> inputs = Arrays.asList(
  ${javaMessages.join(",\n  ")}
);
final Map<String, Object> params = new HashMap<>();
if (${temperature !== undefined}) params.put(
  "temperature",
  ${temperature}
);
final AICompletion[][] completions = AICompletion.create(engine, inputs, 0, params);
System.out.println(String.join("\n", Arrays.asList(completions[0][0].getText())));`;

    case "go":
      const goData = {
        model,
        messages,
        ...(temperature && { temperature }),
      };

      return `package main
import (
    "fmt"
    "net/http"
    "strings"
)
func main() {
    apiUrl := "https://api.openai.com/v1/chat/completions"
    requestData := strings.NewReader(${JSON.stringify(goData)})
    request, err := http.NewRequest("POST", apiUrl, requestData)
    if err != nil {
        panic(err)
    }
    request.Header.Add("Content-Type", "application/json")
    request.Header.Add("Authorization", "${getAuthorizationHeader(apiKey)["Authorization"]}")
    client := &http.Client{}
    response, err := client.Do(request)
    if err != nil {
        panic(err)
    }
    defer response.Body.Close()
    fmt.Println("Response Code : ", response.StatusCode)
}`;
    default:
      return "";
  }
}
