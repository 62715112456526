import { useAtom } from "jotai"
import { atomWithStorage } from "jotai/utils"
import { useEffect } from "react"
import { useSafeLocalStorage } from "./useSafeLocalStorage"

const darkModeAtom = atomWithStorage<boolean | null>('dark-mode', true)

export const useDarkMode = (): [boolean, () => void] => {
    // const prefersDarkMode = usePrefersDarkMode()
    const [isEnabled, setDark] = useAtom(darkModeAtom)

    const setIsEnabled = () => {
        setDark(prev => !prev)
    }

    const enabled = isEnabled === undefined ? isEnabled : isEnabled

    useEffect(() => {
        if (window === undefined) return
        const root = document.documentElement
        if (enabled) {
            setDark(true)
            root.classList.remove('light')
            root.classList.add('dark')
        } else {
            root.classList.remove('dark')
            root.classList.add('light')
        }
    }, [enabled, setDark])

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [!!isEnabled, setIsEnabled]
}
