import { LayoutMain } from "./LayoutMain";
import { ExtensionSettings } from "../extension/ExtensionSettings";
import Head from "next/head";

export function LayoutExtension() {
  return (
    <>
      <Head>
        <title>Extension - DapperGPT</title>
      </Head>
      <LayoutMain
        leftMenu={null}
        leftMenuAction={null}
        title={
          <div className="w-full flex flex-row items-center justify-between px-4 text-white">
            <h1 className="text-lg text-gray-700 dark:text-white">Extension Setup</h1>
          </div>
        }
        titleMenu={null}
      >
        <div className="flex flex-col gap-2 pb-32 bg-gray-100 dark:bg-neutral-700 px-5">
          <ExtensionSettings />
        </div>
      </LayoutMain>
    </>
  );
}

