import { Button, SectionSettingsWrapper, TextInputLabelForm } from '@acme/ui';
import { generateNanoId } from '@acme/util';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useMemo, useState } from 'react'
import { apiKeyAnthropicAtom, apiKeyAtom } from '../../store/setting';
import { apiKeyModalAtom } from '../../store/user';

interface AccountProps { }

export const Account: React.FC<AccountProps> = () => {

  const [savedApiKeyOpenAI, setSavedApiKey] = useAtom(apiKeyAtom)
  const [savedApiKeyAnthropic, setSavedApiKeyAnthropic] = useAtom(apiKeyAnthropicAtom)

  const [isRemoveConfirm, setIsRemoveConfirm] = useState<boolean>(false)
  const setApiKeyModal = useSetAtom(apiKeyModalAtom)

  // Slice the API Key to hide the key but add * to show the length and also show last 3 characters
  const { apiKeySlicedHidden, apiKeyAnthropicHidden } = useMemo(() => {
    const apiKeyAnthropicKey = savedApiKeyAnthropic?.key
    const apiKey = savedApiKeyOpenAI?.key
    return {
      apiKeySlicedHidden: apiKey
        ? apiKey.slice(0, 3) + apiKey.slice(3, apiKey.length - 3).replace(/./g, '*') + apiKey.slice(apiKey.length - 3)
        : '',
      apiKeyAnthropicHidden: apiKeyAnthropicKey
        ? apiKeyAnthropicKey?.slice(0, 3) + apiKeyAnthropicKey.slice(3, apiKeyAnthropicKey?.length - 3).replace(/./g, '*') + apiKeyAnthropicKey?.slice(apiKeyAnthropicKey?.length - 3)
        : ''
    }
  }, [savedApiKeyOpenAI, savedApiKeyAnthropic])

  return (
    <>

      {isRemoveConfirm && (
        <div className='fixed top-0 right-0 z-50 w-screen h-screen flex items-center justify-center bg-gray-800 bg-opacity-50'>
          <div className="absolute top-0 left-0 w-screen h-screen m-5" onClick={() => setIsRemoveConfirm(false)} />
          <div className='bg-white w-92 rounded shadow z-50 p-5'>
            <p className='font-semibold text-lg'>Are you sure you want to delete the API Key?</p>
            <div className='flex flex-row items-center justify-center mt-5'>
              <Button
                className='bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md'
                onClick={() => setIsRemoveConfirm(false)}
              >
                Cancel
              </Button>
              <Button
                className='bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md ml-2'
                onClick={() => {
                  setSavedApiKey(null)
                  setIsRemoveConfirm(false)
                }}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col gap-4'>

        <SectionSettingsWrapper title='OpenAI API Key'>
          <p className='mb-3'>Fill your OpenAI API Key, {` `}  <a className='text-blue-600 underline' href="https://platform.openai.com/account/api-keys">Click here to check</a></p>
          <div className='my-5'>
            <TextInputLabelForm
              id="gpt-api-key"
              label='OpenAI API Key'
              placeholder='sk-'
              disabled={true}
              value={apiKeySlicedHidden}
            />
          </div>
          <div className='flex flex-row items-center justify-between'>
            <Button
              className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md'
              onClick={() => {
                setApiKeyModal(true)
                /* if (isUpdate) {
                  onSaveApiKey()
                }
                setIsUpdate(!isUpdate) */
              }}
            >
              Update
            </Button>
          </div>
        </SectionSettingsWrapper>

        <SectionSettingsWrapper title='Anthropic API Key'>
          <p className='mb-3'>Fill your Anthropic API Key, {` `} <a className='text-blue-600 underline' href="https://console.anthropic.com/settings/keys">Click here to check</a></p>
          <div className='my-5'>
            <TextInputLabelForm
              id="anthropic-api-key"
              label='Anthropic API Key'
              placeholder='sk-ant'
              disabled={true}
              value={apiKeyAnthropicHidden}
            />
          </div>
          <div className='flex flex-row items-center justify-between'>
            <Button
              className='bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md'
              onClick={() => {
                setApiKeyModal('anthropic')
              }}
            >
              Update
            </Button>
          </div>
        </SectionSettingsWrapper>
      </div>
    </>
  );
};