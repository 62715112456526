import React, { FC, Fragment, HTMLProps, useMemo } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { BiFirstPage, BiLastPage } from 'react-icons/bi'
import { ImSpinner8 } from 'react-icons/im'
import { Popover, Transition } from '@headlessui/react'

const noDataArr = [
  { id: 1, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 2, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 3, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 4, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 5, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 6, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 7, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 8, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 9, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 10, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 11, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 12, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 13, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
  { id: 14, preview1: 'No data', preview2: 'No data', preview3: 'No data', preview4: 'No data' },
]

type Person = {
  firstName: string
  lastName: string
  age: number
  visits: number
  progress: number
  status: 'relationship' | 'complicated' | 'single'
  createdAt: Date
  subRows?: Person[]
}

const range = (len: number) => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

const newPerson = (): Person => {
  return {
    firstName: 'John',
    lastName: 'Doe',
    age: 30,
    visits: 20,
    progress: 100,
    createdAt: new Date(),
    status: 'complicated'
  }
}

export function makeData(...lens: number[]) {
  const makeDataLevel = (depth = 0): Person[] => {
    const len = lens[depth]!
    return range(len).map((d): Person => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      }
    })
  }

  return makeDataLevel()
}


export const TableSort = () => {
  const rerender = React.useReducer(() => ({}), {})[1]

  const [sorting, setSorting] = React.useState<SortingState>([])

  const columns = React.useMemo<ColumnDef<Record<string, any>>[]>(
    () => [
      {
        header: 'Name',
        footer: props => props.column.id,
        columns: [
          {
            accessorKey: 'firstName',
            cell: info => info.getValue(),
            footer: props => props.column.id,
          },
          {
            accessorFn: row => row.lastName,
            id: 'lastName',
            cell: info => info.getValue(),
            header: () => <span>Last Name</span>,
            footer: props => props.column.id,
          },
        ],
      },
      {
        header: 'Info',
        footer: props => props.column.id,
        columns: [
          {
            accessorKey: 'age',
            header: () => 'Age',
            footer: props => props.column.id,
          },
          {
            header: 'More Info',
            columns: [
              {
                accessorKey: 'visits',
                header: () => <span>Visits</span>,
                footer: props => props.column.id,
              },
              {
                accessorKey: 'status',
                header: 'Status',
                footer: props => props.column.id,
              },
              {
                accessorKey: 'progress',
                header: 'Profile Progress',
                footer: props => props.column.id,
              },
            ],
          },
          {
            accessorKey: 'createdAt',
            header: 'Created At',
          },
        ],
      },
    ],
    []
  )

  const [data, setData] = React.useState(() => makeData(100000))
  const refreshData = () => setData(() => makeData(100000))

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  })

  return (
    <div className="p-2">
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, 10)
            .map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
      <div>{table.getRowModel().rows.length} Rows</div>
      <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div>
      <div>
        <button onClick={() => refreshData()}>Refresh Data</button>
      </div>
      <pre>{JSON.stringify(sorting, null, 2)}</pre>
    </div>
  )
}

/* const PageMoreButton = ({ instance }: { instance: TableInstance<any> }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? '' : 'text-opacity-90'}
                border text-sm rounded p-1 py-[2px] px-2`}
          >
            <span>...</span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-8 bg-white p-4">
                  <span className="flex items-center gap-1 text-sm">
                    <p className='w-[50px]'>Go to:</p>
                    <input
                      type="number"
                      defaultValue={instance.getState().pagination.pageIndex + 1}
                      onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        instance.setPageIndex(page)
                      }}
                      className="border px-2 py-[3px] rounded w-16 text-sm"
                    />
                  </span>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
} */

