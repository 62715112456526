import { PopupWrapper, TextInputLabelForm, SelectLabelForm, TextInputAreaLabelForm, Button, InstructionCard, InstructionCardNew, toast, PopupWrapperAnimated } from "@acme/ui"
import { chatInstructionsDefault, generateLocalIdByRole, generateNanoId, type GPTChatModel, openAiChatModels, type SavedInstruction, type SavedInstructionWithIconColor } from "@acme/util"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useMemo, useState } from "react"
import { chatSessionActiveAtom, chatSessionsSettingsAtom } from "../store/chat"
import { modelPopupAtom, newInstructionAtom, newInstructionPopupAtom, popupSettingsInstructionTriggerAtom, savedInstructionsAtom } from "../store/instruction"
import { noteSettingsAtom } from "../store/note"
import { noteActiveAtom } from "../store/note"
import { useRouter } from "next/router"
import { noteUpdateAtom } from "../store/note"
import { ChatSyncToCloudButton } from "../chat/ChatSettings"
import { api } from "@acme/client"
import { teamActiveAtom } from "../store/user"
import { FiCheck, FiX } from "@acme/ui"

export const InstructionsPopup = () => {
  const router = useRouter()
  const [search, setSearch] = useState('')
  const [newInsPopup, setNewInsPopup] = useAtom(newInstructionPopupAtom)

  const [savedInstructions, setSavedInstructions] = useAtom(savedInstructionsAtom)
  const [chatSession, setChatSession] = useAtom(chatSessionActiveAtom)
  const [note, setNote] = useAtom(noteActiveAtom)

  const isNoteSettingsOpen = useAtomValue(noteSettingsAtom)
  const isChatSettingsOpen = useAtomValue(chatSessionsSettingsAtom)
  const setNewIns = useSetAtom(newInstructionAtom)
  const setModelPopup = useSetAtom(modelPopupAtom)
  const setInsSettingsTrigger = useSetAtom(popupSettingsInstructionTriggerAtom)
  const setUpdateNote = useSetAtom(noteUpdateAtom)

  const pathType = useMemo<'note' | 'chat'>(() => {
    if (router.pathname.includes('note')) return 'note'
    return 'chat'
  }, [router.pathname])

  const allInstructions = useMemo<SavedInstructionWithIconColor[]>(() => {
    const defaultIns: SavedInstructionWithIconColor[] = chatInstructionsDefault
      .map(a => {
        return {
          id: generateNanoId(14),
          name: a.name,
          isDefault: true,
          color: a.color,
          icon: a.icon,
          description: a.short_description,
          instruction: a.instruction,
          created: new Date(),
        }
      })

    const savedFixed: SavedInstructionWithIconColor[] = (savedInstructions || []).map(a => {
      return {
        ...a,
        isDefault: false,
      }
    })

    return [...savedFixed, ...defaultIns]
      .filter(a => a.name.toLowerCase().includes(search.toLowerCase())
        || (a?.description || '').toLowerCase().includes(search.toLowerCase())
        || a.instruction.toLowerCase().includes(search.toLowerCase())
      )

  }, [savedInstructions, search])

  const onClose = () => setModelPopup({ open: false, type: '' })

  const onSelect = (id: string) => {
    const selected = allInstructions.find(a => a.id === id)
    const messages = chatSession?.messages || []

    // If any settings is open, trigger it
    if (isNoteSettingsOpen) {
      setInsSettingsTrigger({ type: 'note', instruction: selected?.instruction })
      onClose()
      return;
    } else if (isChatSettingsOpen) {
      setInsSettingsTrigger({ type: 'chat', instruction: selected?.instruction })
      onClose()
      return;
    }

    // If chat
    if (selected) {
      if (note && pathType === 'note') {
        toast.error("Not implemented yet")
        /* if (!note.isInit && note.isSync) {
          setUp
        }
        setNote({
          ...note,
          defaultInstruction: selected?.instruction,
        })
        onClose()
        return; */
      } else if (chatSession) {
        // if init and no message, add it as default instruction
        if (chatSession.isInit && chatSession.messages.length === 0) {
          setChatSession({
            ...chatSession,
            ...selected.defaultModel && { defaultModel: selected.defaultModel },
            defaultInstruction: selected?.instruction,
          })
        } else {
          setChatSession({
            ...chatSession,
            messages: [
              ...messages,
              {
                id: generateLocalIdByRole('system'),
                chatSessionId: chatSession.id,
                content: {
                  contentType: 'text', content: selected?.description
                },
                model: selected.defaultModel,
                userId: '',
                senderRole: 'system',
                created: new Date(),
              }
            ]
          })
        }
        onClose()
      }
    }
  }

  const onAddNew = () => {
    setNewInsPopup(true)
    setNewIns({ name: '', instructions: '', defaultModel: 'gpt-3.5-turbo', description: '', isUpdateId: '' })
  }

  return (
    <>
      <PopupWrapperAnimated
        show
        onClose={onClose}
        className="bg-white w-84 rounded shadow z-50 p-5 relative"
        containerClassName="!items-start pt-[15vh]"
      >
        <p className='font-semibold text-lg'>AI Instruction Character</p>
        <TextInputLabelForm
          id="search"
          label=""
          className="mt-3 mb-5"
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e)}
          skipDarkClass
        />
        <div className="max-h-[80vh] lg:max-h-[500px] overflow-y-auto relative">
          <div className='grid grid-cols-1 lg:grid-cols-2 mt-5 gap-3 max-w-full w-full lg:w-[50vw]'>
            <InstructionCardNew onAdd={onAddNew} />
            {allInstructions.map(a => (
              <InstructionCard
                key={a.name}
                className="w-full !flex-1"
                title={a.name}
                color={a.color || '#000000'}
                icon={a.icon}
                description={a.description || a.instruction.slice(0, 30)}
                isDefault={a.isDefault}

                onClick={() => {
                  onSelect(a.id)
                }}
                onUpdate={() => {
                  setNewInsPopup(true)
                  const { id, name, instruction, defaultModel, description } = a
                  setNewIns({
                    name, instructions: instruction, defaultModel: defaultModel || 'gpt-3.5-turbo',
                    description: '',
                    isUpdateId: a.id,
                    isUpdateSync: a.isSync
                  })
                }}
              />
            ))}
          </div>
        </div>
      </PopupWrapperAnimated>
      {newInsPopup && <InstructionNewPopup onClose={() => setNewInsPopup(false)} />}
    </>
  )
}

export const InstructionNewPopup = ({ onClose }: { onClose: () => void }) => {
  const [newIns, setNewIns] = useAtom(newInstructionAtom)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const setSavedInstructions = useSetAtom(savedInstructionsAtom)
  const teamActive = useAtomValue(teamActiveAtom)
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = api.instruction.createInstruction.useMutation({
    onSuccess: (data) => {
      if (newIns?.isUpdateId && !newIns?.isUpdateSync && newIns?.isSync) {
        setSavedInstructions(prev => {
          return prev.map(a => {
            if (a.id === newIns.isUpdateId) return { ...a, isSync: true }
            return a
          })
        })
      } else {
        setSavedInstructions(prev => {
          return [{ ...data, defaultModel: undefined, isSync: true }, ...prev]
        })
      }
      onClose()
    },
    onError: () => toast.error("Something went wrong")
  })
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = api.instruction.updateInstruction.useMutation({
    onSuccess: () => onClose(),
    onError: (err, input) => {
      if (err?.message === 'Instruction not found') {
        setSavedInstructions(prev => {
          return prev.map(a => {
            if (a.localId === input?.localId) {
              return { ...a, ...input, isSync: false }
            }
            return a
          })
        })
        onClose()
      } else {
        toast.error("Something went wrong")
      }
    }
  })
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = api.instruction.deleteInstruction.useMutation({
    onSuccess: () => {
      setSavedInstructions(prev => {
        return prev.filter(a => a.id !== newIns.isUpdateId)
      })
      onClose()
    },
    onError: (err) => {
      if (err?.message === 'Instruction not found') {
        setSavedInstructions(prev => {
          return prev.filter(a => a.id !== newIns.isUpdateId)
        })
        onClose()
      } else {
        toast.error("Something went wrong")
      }
    }
  })

  const onAddNew = () => {
    if (newIns.isUpdateId) {
      const updateInstruction: Partial<SavedInstruction> = {
        description: newIns.description,
        instruction: newIns.instructions,
        name: newIns.name,
        defaultModel: newIns.defaultModel,
        updated: new Date()
      }
      setSavedInstructions(prev => {
        return prev.map(a => {
          if (a.id === newIns.isUpdateId) return { ...a, ...updateInstruction }
          return a
        })
      })
      if (newIns?.isUpdateSync) {
        mutateUpdate({ id: newIns.isUpdateId, localId: newIns?.isUpdateId, teamId: teamActive?.id || '', ...updateInstruction })
      } else if (!newIns?.isUpdateSync && newIns.isSync) {
        mutateCreate({
          teamId: teamActive?.id || '',
          name: newIns.name,
          instruction: newIns.instructions,
          ...newIns?.defaultModel ? { defaultModel: newIns.defaultModel } : {}
        })
      } else {
        onClose()
      }
    } else {
      const newInstruction: SavedInstruction = {
        id: generateNanoId(14),
        description: newIns.description,
        instruction: newIns.instructions,
        name: newIns.name,
        defaultModel: newIns.defaultModel,
        created: new Date(),
      }
      if (newIns?.isSync) {
        mutateCreate({ ...newInstruction, teamId: teamActive?.id || '' })
      } else {
        setSavedInstructions(prev => {
          return [...prev, newInstruction]
        })
        onClose()
      }
    }
  }

  const onDelete = () => {
    if (newIns?.isUpdateSync && newIns.isUpdateId) {
      mutateDelete({ localId: newIns.isUpdateId, teamId: teamActive?.id || '' })
    } else {
      setSavedInstructions(prev => {
        return prev.filter(a => a.id !== newIns.isUpdateId)
      })
      onClose()
    }
  }

  return (
    <PopupWrapperAnimated show onClose={onClose} className="bg-white w-full lg:w-[50%] lg:min-w-[300px] rounded shadow z-[100] p-5 relative">
      {showDeleteModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4">Delete</h2>
            <p>Are you sure you want to delete this?</p>
            <div className="flex justify-end space-x-4 mt-6">
              <Button
                loading={isLoadingDelete}
                className="bg-red-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-red-600 transition-all duration-200"
                onClick={() => onDelete()}
              >
                {!isLoadingDelete && <FiCheck />}
                <span>Delete</span>
              </Button>
              <Button
                className="bg-gray-300 !text-gray-700 flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-gray-400 transition-all duration-200"
                onClick={() => setShowDeleteModal(false)}
              >
                <FiX />
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      )}

      <div>
        <p className="font-bold text-lg">{newIns?.isUpdateId ? 'Update' : 'Add New'} Instruction</p>
        <div className="flex flex-col gap-1 mt-5">
          <div className="flex flex-row items-center gap-3">
            <TextInputLabelForm
              id="name"
              label="Name"
              value={newIns.name}
              onChange={(e) => setNewIns({ ...newIns, name: e })}
              size="sm"
              placeholder="Name"
              skipDarkClass
            />
            {/* <div className="relative">
              <SelectLabelForm
                id="defaultModel"
                className="!w-[220px]"
                inputClassName="!text-sm"
                size="sm"
                value={newIns.defaultModel}
                onChange={(e) => setNewIns({ ...newIns, defaultModel: e as GPTChatModel })}
                label="Default Model"
                options={[...openAiChatModels]}
              />
              <a
                href="https://platform.openai.com/docs/guides/chat"
                target="_blank" rel="noreferrer"
                className="absolute right-0 top-0 mt-1 mr-4 text-xs text-blue-600 font-medium hover:underline"
              >
                What is this?
              </a>
            </div> */}
          </div>
          <TextInputAreaLabelForm
            label="Instructions"
            size="sm"
            className="mt-3 mb-5"
            inputClassName="min-h-[150px] text-left"
            placeholder="You are a..."
            value={newIns.instructions}
            onChange={(e) => setNewIns({ ...newIns, instructions: e })}
            skipDarkClass
          />
          {/* <Button className="absolute bottom-[6.1rem] right-6 text-sm !py-1 !bg-gray-500">
            Generate
          </Button> */}
        </div>
        <div className="flex flex-col gap-3 mb-5">
          {(!newIns?.isUpdateId || (newIns?.isUpdateId && !newIns?.isUpdateSync)) && (
            <ChatSyncToCloudButton
              className="!text-gray-500"
              value={!!newIns.isSync}
              onChange={(e) => setNewIns({ ...newIns, isSync: e })}
            />
          )}
          {newIns?.isUpdateId && newIns.isUpdateSync && (
            <p className="text-neutral-600 text-sm">Synced to cloud</p>
          )}
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={onAddNew}
            className="w-full"
          >
            {newIns?.isUpdateId ? 'Update' : 'Add'}
          </Button>
          {newIns?.isUpdateId &&
            <Button
              onClick={() => setShowDeleteModal(true)}
              className="w-full !bg-transparent !text-red-600"
            >
              Delete
            </Button>
          }
        </div>
      </div>
    </PopupWrapperAnimated>
  )
}

