import { toast } from "@acme/ui";
import { type BlockNote, produce, type Note, type GPTChatMessage, openAiChatRequest, convertMarkdownFullToBlockNoteMarkdown, compileNoteGptMessages, nextApiChatTitleGenerate, noteEditorManageAiMessage, noteDefaultInstruction, nextApiSendNote, noteListDefault, generateNanoId } from "@acme/util";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { apiKeyAtom } from "./setting";
import { composeContextAtom, sendErrorHandlerAtom } from "./util";
import { type BlockNoteEditor, type PartialBlock } from '@blocknote/core'
import { playNotificationSound } from "./chat";
import { apiKeyModalAtom, teamActiveAtom, userAtom } from "./user";

export type NoteThinkingType = { id: string, noteId: Note['id'], blockId?: BlockNote['id'], context?: string, abortSignal?: AbortController, error?: string, loading?: boolean }

//~ ATOM
export const noteActiveIdAtom = atom<Note['id'] | null>(null);
export const notesAtom = atomWithStorage<Note[]>("notes", noteListDefault);
export const notesFavoritesAtom = atomWithStorage<{ noteId: string, order?: number, orderUpdated?: Date, created: Date, updated: Date }[]>("chatFavorites", []);
export const noteTriggerAtom = atom<{ scrollToBottom?: boolean; resetContext?: string; scrollToId?: string }>({ scrollToBottom: false, resetContext: '' });
export const noteThinkingAtom = atom<NoteThinkingType[]>([]);
export const noteSettingsAtom = atom(false)
export const noteComposeSettingsAtom = atomWithStorage<{ lang: '', action: 'continue' | 'write' }>('noteComposeSettings', { lang: '', action: 'continue' })

//~ SELECTOR
export const noteActiveAtom = atom(
  (get) => {
    const id = get(noteActiveIdAtom);
    const notes = get(notesAtom);
    return notes.find(s => s.id === id) || null;
  },
  (get, set, note: Partial<Note>, newId?: string) => {
    if (note) {
      const notes = get(notesAtom);
      const sessionActiveIdAtom = get(noteActiveIdAtom);
      const sessionIdUpdate = note.id || sessionActiveIdAtom;
      const newnotes = produce(notes, draft => {
        const find = draft.find(s => s.id === sessionIdUpdate);
        if (find) {
          if (newId) {
            find.id = newId
            // if change the id of the note, set focus to the new note
            /* setTimeout(() => {
              set(noteActiveIdAtom, newId)
            }, 300); */
          };
          if (note.name) find.name = note.name;
          if (note.created) find.created = note.created;
          if (note.order) find.order = note.order;
          if (note.updated) find.updated = note.updated;
          if (note.orderUpdated) find.orderUpdated = note.orderUpdated;
          if (note.content) find.content = note.content;
          if (note.markdown) find.markdown = note.markdown;
          if (note.lastSync) find.lastSync = note.lastSync;
          if (note.order) find.order = note.order;
          if (note.orderUpdated) find.orderUpdated = note.orderUpdated;
          if (note.defaultInstruction) find.defaultInstruction = note.defaultInstruction;

          if (note.isInit || note.isInit === false) find.isInit = note.isInit;
          if (note.isSync || note.isSync === false) find.isSync = note.isSync;
          if (note.isUnsaved || note.isUnsaved === false) find.isUnsaved = note.isUnsaved;
          if (note.loading || note.loading === false) find.loading = note.loading;
        }
      })
      set(notesAtom, newnotes);
    }
  }
)

//~ MUTATION
export const noteSendAtom = atom(
  null,
  async (get, set, editor: BlockNoteEditor, param: { action: 'write' | 'continue' | 'select', target?: 'after' | 'cursor', context?: string }, apiKeyPassed?: string) => {
    const { action, target } = param
    const noteActive = get(noteActiveAtom);
    const apiKeyStored = get(apiKeyAtom)
    const contextData = get(composeContextAtom)
    const context = param.context || contextData || ''
    const apiKey = apiKeyStored?.key || apiKeyPassed
    if (!apiKey) {
      toast.error("Please provide an API key");
      set(apiKeyModalAtom, true)
      return null
    }

    if (noteActive) {
      console.log({ context })
      const noteActiveId = noteActive.id;
      let refactorMessages: GPTChatMessage[] = []
      const { thinkingId, modelFixed, gptMessages } = await compileNoteGptMessages(editor, noteActive, { target, action, context })
      refactorMessages = gptMessages
      try {
        //~ SET LOADING
        const abortController = new AbortController();

        //~ ADD TO THINKING
        set(
          noteThinkingAtom,
          prev => {
            const newThinking = [...prev, { id: thinkingId, noteId: noteActiveId, loading: true, abortSignal: abortController, context: context }]
            return newThinking
          }
        )

        //~ SET LOADING
        const loadingBlocks: PartialBlock[] = [{
          id: thinkingId,
          type: 'paragraph',
          content: [{ text: 'AI is thinking....', type: 'text', styles: {} }],
          props: {
            backgroundColor: '', textAlignment: 'left', textColor: ''
          },
          children: []
        }]
        await noteEditorManageAiMessage(editor, loadingBlocks, target)

        //~ FETCHING
        let newMarkdown = ''
        if (noteActive?.isSync) {
          const result = await nextApiSendNote({
            noteId: noteActiveId,
            apiKey,
            defaultModel: noteActive.defaultModel,
            isSessionInit: noteActive?.isInit,
            prevMessages: refactorMessages,
          }, abortController)
          newMarkdown = result?.markdown || ''
        } else {
          const gpt = await openAiChatRequest(
            apiKey,
            modelFixed,
            gptMessages,
            abortController
          );

          //~ SET LOADING
          gpt.choices.map(choice => {
            newMarkdown += choice.message.content
            refactorMessages.push({
              role: 'assistant',
              content: choice?.message?.content,
            })
          })
        }

        newMarkdown = convertMarkdownFullToBlockNoteMarkdown(newMarkdown)
        const newBlocks = await editor.markdownToBlocks(newMarkdown)
        editor.removeBlocks([thinkingId])
        await noteEditorManageAiMessage(editor, newBlocks, target)

        playNotificationSound(get)
        set(noteTriggerAtom, { scrollToBottom: true })

        //~ REMOVE FROM NOTE THINKING
        set(noteThinkingAtom, prev => prev.filter(s => s.noteId !== noteActiveId))

        //~ SET TITLE IF NEW NOTE
        if (noteActive.name === 'New Note') {
          await set(noteGenerateTitleAtom, editor, noteActive.id)
        }

      } catch (error: any) {
        set(
          noteThinkingAtom,
          prev => {
            /* const find = prev.find(s => s.noteId === noteActiveId && s.id === thinkingId);
            if (find) {
              find.error = error.message || 'There are some error';
              find.loading = false;
            }
            return prev; */
            return prev.filter(s => s.noteId !== noteActiveId && s.id !== thinkingId)
          }
        )
        editor.removeBlocks([thinkingId])
        set(noteTriggerAtom, { ...context ? { resetContext: context } : {} })
        set(sendErrorHandlerAtom, { error })
        set(composeContextAtom, context || '')
        console.error(error)
      }
    }
  }
)

export const noteUpdateAtom = atom(
  null,
  (get, set, note: Partial<Note>) => {
    if (note?.id) {
      const notes = get(notesAtom);
      const newnotes = produce(notes, draft => {
        const find = draft.find(s => s.id === note.id);
        if (find) {
          if (note.name) find.name = note.name;
          if (note.created) find.created = note.created;
          if (note.order) find.order = note.order;
          if (note.updated) find.updated = note.updated;
          if (note.orderUpdated) find.orderUpdated = note.orderUpdated;
          if (note.content) find.content = note.content;
          if (note.markdown) find.markdown = note.markdown;
          if (note.defaultModel) find.defaultModel = note.defaultModel;
          if (note.defaultInstruction) find.defaultInstruction = note.defaultInstruction;
          if (note.isInit !== undefined) find.isInit = note.isInit;
          if (note.isSync !== undefined) find.isSync = note.isSync;
        }
      })
      set(notesAtom, newnotes);
    }
  }
)

export const noteAddAtom = atom(
  null,
  (get, set, noteData: Partial<Note>) => {
    if (noteData) {
      const user = get(userAtom)
      const teamActive = get(teamActiveAtom)

      const newId = generateNanoId(16)

      set(
        notesAtom,
        prev => {
          const prevFixed = prev || []
          const firstSession = prevFixed.length > 0 ? prevFixed[0] : null
          return [
            {
              id: newId,
              content: [],
              name: 'New Note',
              markdown: '',
              created: new Date(),
              ...teamActive && { teamId: teamActive.id },
              ...user && { userId: user.id },
              defaultInstruction: noteDefaultInstruction.system,
              defaultContinueInstruction: noteDefaultInstruction.continue,
              defaultWriteInstruction: noteDefaultInstruction.write,
              defaultSelectInstruction: noteDefaultInstruction.select,
              isInit: true,
              ...noteData,
              order: firstSession?.order ? firstSession.order - 1 : 100,
              orderUpdated: new Date(),
            },
            ...prevFixed,
          ]
        })
    }
  }
)

export const noteRemoveAtom = atom(
  null,
  (get, set, noteId: Note['id']) => {
    if (noteId) {
      set(notesAtom, (prev) => prev.filter(s => s.id !== noteId));
    }
  }
)

export const noteGenerateTitleAtom = atom(
  null,
  async (get, set, editor: BlockNoteEditor, noteId: string) => {
    const notes = get(notesAtom);
    const note = notes.find(s => s.id === noteId);
    const apiKey = get(apiKeyAtom)?.key;

    if (note) {
      const modelFixed = note.defaultModel
        || 'gpt-3.5-turbo';

      const { gptMessages } = await compileNoteGptMessages(editor, note, { action: 'titleGenerate', context: '' })

      if (!note.isSync) {
        if (!apiKey) {
          toast.error('Please set API key first')
          return;
        }
        const gpt = await openAiChatRequest(
          apiKey,
          modelFixed,
          gptMessages
        );
        const newTitle = gpt.choices[0]?.message?.content?.replace('Chat', '').trim().replace(/^"|"$/g, '');
        set(noteUpdateAtom, { name: newTitle, id: note.id })
      } else {
        const { newTitle } = await nextApiChatTitleGenerate({
          prevMessages: gptMessages,
          chatSessionId: note.id,
          defaultModel: modelFixed,
          apiKey,
          isUpdateTitle: true,
        })
        // set(chatUpdateTriggerAtom, prev => ([...prev, { chatSessionId: session.id, update: { id: session.id, name: newTitle || '' } }]))
        set(noteUpdateAtom, { name: newTitle || '', id: note.id })
      }
    }
  }
)
