import { type SubscriptionPlan } from "@acme/util";
import { motion } from "framer-motion";
import { AiOutlineLoading } from "react-icons/ai";
import { FaExclamationTriangle } from "react-icons/fa";

type PlanCardProps = {
  plan: SubscriptionPlan;
  onCheckout: (planId: string) => void;
  isBestDeal?: boolean;
  isLoading?: boolean;
};

export const PlanCard: React.FC<PlanCardProps> = ({ plan, onCheckout, isBestDeal = false, isLoading = false }) => {
  const { id, name, price, interval, discount } = plan;
  const formattedPrice = price.toFixed(2);
  const discountedPrice = ((price * (100 - (discount || 0))) / 100).toFixed(2);
  const months = interval === "yearly" ? 12 : 6;
  const monthlyPrice = (Number(discountedPrice) / months).toFixed(2);

  const limitedOfferVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const containerVariants = {
    hover: { scale: 1.05, boxShadow: "0 4px 30px rgba(22, 33, 106, 0.1)" },
  };

  return (
    <motion.div
      className="bg-white p-6 rounded-lg shadow-md relative"
      whileHover="hover"
      variants={containerVariants}
      transition={{ type: "spring", stiffness: 300, duration: 300 }}
    >
      {(((discount || 0) > 0) || interval === 'lifetime') && (
        <motion.div
          className="bg-red-500 text-white flex items-center justify-center px-2 py-1 text-xs font-bold rounded absolute -top-3 left-4"
          initial="hidden"
          animate="visible"
          variants={limitedOfferVariants}
          transition={{ ease: "easeInOut", duration: 0.5 }}
        >
          <FaExclamationTriangle className="mr-1" />
          {interval === 'lifetime'
            ? 'Limited Offer'
            : (
              <>
                Limited Offer: {discount}% OFF
              </>
            )}
        </motion.div>
      )}
      <h3 className="text-2xl font-bold mb-4">{name}</h3>
      <p className="text-lg">
        <span className="line-through text-gray-400 text-lg">${formattedPrice}</span>{" "}
        <span className="text-blue-600 font-bold text-xl">${discountedPrice}</span>
      </p>
      {interval === 'lifetime' ? (
        <p className="text-sm text-blue-500 mb-4">
          One time payment
        </p>
      ) : (
        <>

          <p className="text-sm text-gray-500 mb-4">per {interval} </p>
          <p className="text-sm text-blue-500 mb-4">
            Only ${monthlyPrice}/month
          </p>
        </>
      )
      }
      <motion.button
        className="bg-blue-500 w-full text-white px-4 py-2 rounded shadow hover:bg-blue-600"
        onClick={() => onCheckout(id)}
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        {isLoading ? (
          <AiOutlineLoading className="animate-spin text-white text-xl text-center w-full" />
        ) : (
          <>Subscribe</>
        )}
      </motion.button>
    </motion.div >
  );
};
