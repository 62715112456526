import { PopupWrapper, TextInputLabelForm, TextInputAreaLabelForm, Button, InstructionCard, InstructionCardNew, toast, FiCheck, FiX, PopupWrapperAnimated } from "@acme/ui"
import { chatPromptsDefault, generateNanoId, type SavedPrompt, type SavedPromptWithIconColor } from "@acme/util"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useMemo, useState } from "react"
import { modelPopupAtom, newPromptAtom, savedPromptsAtom } from "../store/instruction"
import { composeContextAtom } from "../store/util"
import { teamActiveAtom } from "../store/user"
import { api } from "@acme/client"
import { ChatSyncToCloudButton } from "../chat/ChatSettings"
import { useRouter } from "next/router"

export const PromptPopup = () => {
  const router = useRouter()
  const [search, setSearch] = useState('')
  const [isAdding, setIsAdding] = useState(false)

  const [savedPrompts, setSavedPrompts] = useAtom(savedPromptsAtom)
  const setComposeContext = useSetAtom(composeContextAtom)
  const setNewPrompt = useSetAtom(newPromptAtom)
  const setModelPopup = useSetAtom(modelPopupAtom)

  const pathType = useMemo<'note' | 'chat'>(() => {
    if (router.pathname.includes('note')) return 'note'
    return 'chat'
  }, [router.pathname])

  const allPrompts = useMemo<SavedPromptWithIconColor[]>(() => {
    const defaultIns: SavedPromptWithIconColor[] = chatPromptsDefault
      .map(a => {
        return {
          id: generateNanoId(14),
          name: a.name,
          isDefault: true,
          prompt: a.prompt,
          created: new Date(),
          updated: new Date(),
        }
      })

    const savedFixed: SavedPromptWithIconColor[] = (savedPrompts || []).map(a => {
      return {
        ...a,
        isDefault: false,
      }
    })

    return [...savedFixed, ...defaultIns]
      .filter(a => a.name.toLowerCase().includes(search.toLowerCase())
        || a.prompt.toLowerCase().includes(search.toLowerCase())
      )

  }, [savedPrompts, search])

  const onClose = () => setModelPopup({ open: false, type: '' })

  const onSelect = (id: string) => {
    const selected = allPrompts.find(a => a.id === id)
    // If chat
    if (selected) {
      setComposeContext(selected.prompt)
      onClose()
    }
  }

  const onAddNew = () => {
    setIsAdding(true)
    setNewPrompt({ name: '', prompt: '', isUpdateId: '' })
  }

  return (
    <>
      <PopupWrapperAnimated
        show
        onClose={onClose}
        className="bg-white w-84 rounded shadow z-50 p-5 relative"
        containerClassName="!items-start pt-[15vh]"
      >
        <p className='font-semibold text-lg'>AI Prompt</p>
        <TextInputLabelForm
          id="search"
          label=""
          className="mt-3 mb-5"
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e)}
          skipDarkClass
        />
        <div className="max-h-[80vh] lg:max-h-[500px] overflow-y-auto relative">
          <div className='grid grid-cols-1 lg:grid-cols-2 mt-5 gap-3 max-w-full w-full lg:w-[50vw]'>
            <InstructionCardNew onAdd={onAddNew} />
            {allPrompts.map(a => (
              <InstructionCard
                key={a.name}
                className="w-full !flex-1"
                title={a.name}
                color={'#000'}
                icon={a.icon}
                description={''}
                isDefault={a.isDefault}
                onClick={() => {
                  onSelect(a.id)
                }}
                onUpdate={() => {
                  setIsAdding(true)
                  const { id, name, prompt } = a
                  setNewPrompt({
                    name,
                    prompt: prompt,
                    isUpdateId: a.id,
                    isUpdateSync: a.isSync
                  })
                }}
              />
            ))}
          </div>
        </div>
      </PopupWrapperAnimated>
      {isAdding && <PromptNewPopup onClose={() => setIsAdding(false)} />}
    </>
  )
}

export const PromptNewPopup = ({ onClose }: { onClose: () => void }) => {
  const [newPrompt, setNewPrompt] = useAtom(newPromptAtom)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const setSavedPrompts = useSetAtom(savedPromptsAtom)
  const teamActive = useAtomValue(teamActiveAtom)
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = api.instruction.createPrompt.useMutation({
    onSuccess: (data) => {
      if (newPrompt?.isUpdateId && !newPrompt?.isUpdateSync && newPrompt?.isSync) {
        setSavedPrompts(prev => {
          return prev.map(a => {
            if (a.id === newPrompt.isUpdateId) return { ...a, isSync: true }
            return a
          })
        })
      } else {
        setSavedPrompts(prev => [{ ...data, isSync: true }, ...prev])
      }
      onClose()
    },
    onError: () => toast.error("Something went wrong")
  })
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = api.instruction.updatePrompt.useMutation({
    onSuccess: () => onClose(),
    onError: (err, input) => {
      if (err?.message === 'Prompt not found') {
        setSavedPrompts(prev => {
          return prev.map(a => {
            if (a.localId === input?.localId) {
              return { ...a, ...input?.prompt && { prompt: input?.prompt }, ...input?.name && { name: input?.name } }
            }
            return a
          })
        })
        onClose()
      } else {
        toast.error("Something went wrong")
      }
    }
  })
  const { mutate: mutateDelete, isLoading: isLoadingDelete } = api.instruction.deletePrompt.useMutation({
    onSuccess: (data) => {
      setSavedPrompts(prev => {
        return prev.filter(a => a.id !== newPrompt.isUpdateId)
      })
      onClose()
    },
    onError: (err) => {
      if (err?.message === 'Prompt not found') {
        setSavedPrompts(prev => {
          return prev.filter(a => a.id !== newPrompt.isUpdateId)
        })
        onClose()
      } else {
        toast.error("Something went wrong")
      }
    }
  })

  const onAddNew = () => {
    if (newPrompt.isUpdateId) {
      const updatePrompt: Partial<SavedPrompt> = {
        prompt: newPrompt.prompt,
        name: newPrompt.name,
      }
      setSavedPrompts(prev => {
        return prev.map(a => {
          if (a.id === newPrompt.isUpdateId) return { ...a, ...updatePrompt }
          return a
        })
      })
      if (newPrompt?.isUpdateSync) {
        mutateUpdate({ id: newPrompt.isUpdateId, localId: newPrompt?.isUpdateId, teamId: teamActive?.id || '', ...updatePrompt })
      } else if (!newPrompt?.isUpdateSync && newPrompt.isSync) {
        mutateCreate({
          teamId: teamActive?.id || '',
          name: newPrompt.name,
          prompt: newPrompt.prompt
        })
      } else {
        onClose()
      }
    } else {
      const newPromptFixed: SavedPrompt = {
        id: generateNanoId(14),
        name: newPrompt.name,
        prompt: newPrompt.prompt,
        created: new Date(),
        updated: new Date(),
      }
      if (newPrompt?.isSync) {
        mutateCreate({ ...newPromptFixed, teamId: teamActive?.id || '' })
      } else {
        setSavedPrompts(prev => {
          return [...prev, newPromptFixed]
        })
        onClose()
      }
    }
  }

  const onDelete = () => {
    if (newPrompt?.isUpdateSync && newPrompt.isUpdateId) {
      mutateDelete({ localId: newPrompt.isUpdateId, teamId: teamActive?.id || '' })
    } else {
      setSavedPrompts(prev => {
        return prev.filter(a => a.id !== newPrompt.isUpdateId)
      })
      onClose()
    }
  }

  return (
    <PopupWrapperAnimated show onClose={onClose} className="bg-white w-full lg:w-[50%] lg:min-w-[300px] rounded shadow z-[100] p-5 relative">
      {showDeleteModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4">Delete</h2>
            <p>Are you sure you want to delete this?</p>
            <div className="flex justify-end space-x-4 mt-6">
              <Button
                loading={isLoadingDelete}
                className="bg-red-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-red-600 transition-all duration-200"
                onClick={() => onDelete()}
              >
                {!isLoadingDelete && <FiCheck />}
                <span>Delete</span>
              </Button>
              <Button
                className="bg-gray-300 !text-gray-700 flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-gray-400 transition-all duration-200"
                onClick={() => setShowDeleteModal(false)}
              >
                <FiX />
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      )}

      <div>
        <p className="font-bold text-lg">{newPrompt?.isUpdateId ? 'Update' : 'Add New'} Prompt</p>
        <div className="flex flex-col gap-1 mt-5">
          <div className="flex flex-row items-center gap-3">
            <TextInputLabelForm
              id="name"
              label="Name"
              value={newPrompt.name}
              onChange={(e) => setNewPrompt({ ...newPrompt, name: e })}
              size="sm"
              placeholder="Name"
              skipDarkClass
            />
          </div>
          <TextInputAreaLabelForm
            label="Prompts"
            size="sm"
            className="mt-3 mb-5"
            inputClassName="min-h-[150px] text-left"
            placeholder="You are a..."
            value={newPrompt.prompt}
            onChange={(e) => setNewPrompt({ ...newPrompt, prompt: e })}
            skipDarkClass
          />
          {/* <Button className="absolute bottom-[6.1rem] right-6 text-sm !py-1 !bg-gray-500">
            Generate
          </Button> */}
        </div>
        <div className="flex flex-col gap-3 mb-5">
          {(!newPrompt?.isUpdateId || (newPrompt?.isUpdateId && !newPrompt?.isUpdateSync)) && (
            <ChatSyncToCloudButton
              className="!text-gray-500"
              value={!!newPrompt.isSync}
              onChange={(e) => setNewPrompt({ ...newPrompt, isSync: e })}
            />
          )}
          {newPrompt?.isUpdateId && newPrompt.isUpdateSync && (
            <p className="text-neutral-600 text-sm">Synced to cloud</p>
          )}
        </div>
        <div className="flex flex-col gap-2 mt-3">
          <Button
            loading={isLoadingCreate || isLoadingUpdate}
            onClick={onAddNew}
            className="w-full"
          >
            {newPrompt?.isUpdateId ? 'Update' : 'Add'}
          </Button>
          {newPrompt?.isUpdateId &&
            <Button
              onClick={() => setShowDeleteModal(true)}
              className="w-full !bg-transparent !text-red-600"
            >
              Delete
            </Button>
          }
        </div>
      </div>
    </PopupWrapperAnimated>
  )
}




