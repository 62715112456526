type Props = {
  children: React.ReactNode;
  className?: string;
  size?: 'h1' | 'h2' | 'h3' | 'headline' | 'display';
}

export const TextHeading = ({
  children, className, size = 'h2'
}: Props) => {
  if (size === 'h2') {
    return (
      <h2 className={`text-xl ${className}`}>{children}</h2>
    );
  }
  if (size === 'h3') {
    return (
      <h3 className={`text-lg ${className}`}>{children}</h3>
    );
  }
  return (
    <h1 className={`text-2xl font-semibold ${className}`}>{children}</h1>
  );
};