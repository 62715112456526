import { type SubscriptionPlan } from "../interface";

export const defaultPlans: SubscriptionPlan[] = [
    {
        id: 'lifetime1',
        stripeProductId: process.env['STRIPE_PRICE_LIFETIME'] || 'price_1N7JOULEwDqtKLytER8is0AU',
        name: 'Lifetime Plan',
        shortName: 'Lifetime',
        price: 39,
        originalPrice: 99,
        interval: 'lifetime',
        discount: 40,
    },
    {
        id: 'plus1annually',
        stripeProductId: process.env['STRIPE_PRICE_ID_1Y'] || 'price_1N7JTOLEwDqtKLytFvZmtHu7',
        name: 'Yearly Plan',
        shortName: 'Yearly with Cloud',
        price: 99,
        interval: 'yearly',
        discount: 65.66,
    }, /* {
    id: 'plus1halfyearly',
    stripeProductId: process.env['STRIPE_PRICE_ID_HY'] || 'price_1MxZGELEwDqtKLytNopdyAwX',
    name: '6 Month Plan',
    shortName: '6 Months',
    price: 45,
    interval: 'halfyearly',
    discount: 63.33,
} */
]

export const yearlySubEqualText = [

    { text: "☕ Less than a cup of coffee", key: "yearly-1" },
    { text: "🚌 Cheaper than a bus ride", key: "yearly-2" },
    { text: "🍫 Buying a candy bar", key: "yearly-3" },
    { text: "🍿 A movie ticket", key: "yearly-4" },
    { text: "🚌 Cheaper than a bus ride", key: "yearly-5" },
    { text: "🍫 Buying a candy bar", key: "yearly-6" },
    { text: "🍪 Comparable to a small snack", key: "yearly-7" },
    { text: "🎵 Less than a song on iTunes", key: "yearly-8" },
    { text: "📱 Like an inexpensive app purchase", key: "yearly-9" },
    { text: "📺 A fraction of your Netflix subscription", key: "yearly-10" },
    { text: "💧 Less than a bottle of water", key: "yearly-11" },
    { text: "🅿️ Similar to a cheap parking fee", key: "yearly-12" },
    { text: "🍬 Comparable to a single pack of gum", key: "yearly-13" },

];

export const halfYearlySubEqualText = [
    { text: "🧋 Just like a boba", key: "halfYearly-1" },
    { text: "🍔 Cheaper than a fast-food meal", key: "halfYearly-2" },
    { text: "🥐 A pastry from the bakery", key: "halfYearly-3" },
    { text: "📖 Similar to a magazine subscription", key: "halfYearly-4" },
    { text: "🎧 A small portion of your Spotify sub", key: "halfYearly-5" },
    { text: "🎬 Less than a movie rental", key: "halfYearly-6" },
    { text: "🎮 Comparable to a mobile game in-app purchase", key: "halfYearly-7" },
    { text: "🎁 Cheaper than a small gift", key: "halfYearly-8" },
    { text: "🍫 Like a single protein bar", key: "halfYearly-9" },
    { text: "🍵 Comparable to a cup of premium tea", key: "halfYearly-10" },
];