export const siteResultCountProperty = '__count__'

export enum IntegrationTypes {
  sheets = 'sheets',
  mysql = 'mysql',
  mongodb = 'mongodb',
  postgresql = 'postgresql',
  mssql = 'mssql',
  firebase = 'firebase',
  airtable = 'airtable',
}

export type IntegrationTypesSQL = 'mysql' | 'postgresql' | 'mssql'

export const integrationList = [
  {
    name: "mysql",
    title: "MySQL",
    type: 'Database',
    img: '/img/integration/mysql.png',
    active: true,
    inactiveInfo: ''
  },
  {
    name: "postgresql",
    title: "PostgresQL",
    type: 'Database',
    img: "/img/integration/postgres.png",
    active: true,
    inactiveInfo: ''
  },
  {
    name: "sheets",
    title: "Google Sheets",
    type: 'Spreadsheet',
    img: '/img/integration/sheets.png',
    active: false,
    inactiveInfo: 'Temporarily unavailable'
  },
  {
    name: "mongodb",
    title: "MongoDB",
    type: 'Database',
    img: "/img/integration/mongodb.png",
    active: false,
    inactiveInfo: ''
  },
  {
    name: "firebase",
    title: "Firebase",
    type: 'Database',
    img: "/img/integration/firebase.png",
    active: false,
    inactiveInfo: ''
  },
  {
    name: "airtable",
    title: "Airtable",
    type: 'Database',
    img: "/img/integration/airtable.png",
    active: false,
    inactiveInfo: ''
  },
] as const


export const integrationTypeImg = {
  [IntegrationTypes.sheets]: '/img/integration/sheets.png',
  [IntegrationTypes.mysql]: '/img/integration/mysql.png',
  [IntegrationTypes.mongodb]: '/img/integration/mongodb.png',
  [IntegrationTypes.postgresql]: '/img/integration/postgres.png',
  [IntegrationTypes.mssql]: '/img/integration/mssql.png',
  [IntegrationTypes.firebase]: '/img/integration/firebase.png',
  [IntegrationTypes.airtable]: '/img/integration/airtable.png',
}

export const integrationTypeTableName = {
  [IntegrationTypes.sheets]: 'Sheets',
  [IntegrationTypes.mysql]: 'Tables',
  [IntegrationTypes.mongodb]: 'Collections',
  [IntegrationTypes.postgresql]: 'Tables',
  [IntegrationTypes.mssql]: 'Tables',
  [IntegrationTypes.firebase]: 'Collections',
  [IntegrationTypes.airtable]: 'Tables',
}