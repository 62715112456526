import { Anthropic } from '@anthropic-ai/sdk';
import { type MessageParam } from "@anthropic-ai/sdk/resources";
import { type GPTChatModel, type GPTChatMessage, type GPTChatResult, type GPTChatMessageContentText, type GPTChatMessageContentImage, type OpenAiChatRequestHandlerExtra } from './openai';
import { VERCEL_URL, NODE_ENV } from '../constant';

const domain = VERCEL_URL || '';
const env = NODE_ENV || '';


export const convertGptMessagesContentsToString = (content: string | (GPTChatMessageContentText | GPTChatMessageContentImage)[]) => {
    if (typeof content === 'string') return content
    return content?.map((content: (GPTChatMessageContentText | GPTChatMessageContentImage)) => {
        if (content.type === 'image_url') {
            return content.image_url?.url
        }
        return content.text
    }).join("\n\n")
}

export const convertGptMessagesToAnthropicMessages = (messages: GPTChatMessage[]): { messages: Array<MessageParam>, system: string } => {
    let systemMessage = ''
    const antMessages = messages.map((message) => {
        if (message.role === 'system') {
            systemMessage = convertGptMessagesContentsToString(message.content)
            return null
        }
        return {
            role: (message.role === 'assistant' ? 'assistant' : 'user') satisfies 'assistant' | 'user',
            content: convertGptMessagesContentsToString(message.content)
        };
    }).filter((message) => message !== null) as Array<MessageParam>

    return {
        messages: antMessages,
        system: systemMessage
    }
}


export const anthropicChatRequestHandler = async <T extends OpenAiChatRequestHandlerExtra | undefined>(
    apiKey: string,
    model: GPTChatModel,
    messages: GPTChatMessage[],
    signal?: AbortController,
    extra?: T
): Promise<T extends { useStreaming: true } ? null : GPTChatResult> => {
    try {

        const anthropic = new Anthropic({
            baseURL: (env === 'development' ? 'http://' : 'https://') + domain + '/anthropic/',
            apiKey,
        });

        let firstCall = true

        // Anthropic requires first message to be user message
        const { messages: antMessages, system } = convertGptMessagesToAnthropicMessages(messages)

        if (extra?.useStreaming && extra?.onMessage) {
            anthropic.messages.stream({
                messages: antMessages,
                model: model,
                system,
                max_tokens: 4096,
            }).on('text', (text, textSnapshot) => {
                extra?.onMessage?.(textSnapshot, firstCall)
                if (firstCall) {
                    firstCall = false
                }
            }).on('error', (error) => {
                console.error(error);
                throw new Error(error.message);
            }).on('end', () => {
                extra?.onMessage?.('[DONE]', false, true)
            })
            return null as any;
        } else {

            const request = await anthropic.messages.create({
                messages: antMessages,
                model: model,
                system,
                max_tokens: 4096,
            })

            const todayDateInt = new Date().getTime();

            const requestAsGPT: GPTChatResult = {
                id: request.id,
                // created as date int
                created: todayDateInt,
                choices: request.content.map((content, idx) => {
                    return {
                        message: {
                            content: content.text,
                            role: request.role
                        },
                        finish_reason: request.stop_reason || '',
                        index: idx,
                    }
                }),
                usage: {
                    prompt_tokens: request.usage.input_tokens,
                    completion_tokens: request.usage.output_tokens,
                    total_tokens: request.usage.input_tokens + request.usage.output_tokens,
                },
                object: 'chat.completion'
            }
            return requestAsGPT as any;
        }
    } catch (error: any) {
        let message =
            error?.response?.data?.error?.message || error?.message || 'There are some error';
        if (message.startsWith('Error: ')) {
            message = message.replace('Error: ', '');
        }
        const isModelNotAvailable =
            message.startsWith('The model:') && message.endsWith('does not exist');
        if (isModelNotAvailable) {
            message = `${message}, make sure you have access or allowed to use this model.`;
        }
        throw new Error(message);
    }
};
