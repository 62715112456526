/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect } from "react";

const hasIgnoredClass = (element: any, ignoredClass: any) =>
    (element.correspondingElement
        ? element.correspondingElement
        : element
    ).classList.contains(ignoredClass)

const isInIgnoredElement = (element: any, ignoredClass: string) => {
    do {
        if (hasIgnoredClass(element, ignoredClass)) {
            return true
        }
    } while ((element = element.parentElement))

    return false
}

export function useOnClickOutside(ref: RefObject<HTMLElement>, handler: { (): any; (arg0: any): void; }, ignoredClass = ['sidebar', 'popup', 'react-confirm-alert-overlay']) {
    useEffect(
        () => {
            const listener = (event: { target: any; }) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                const checking = ignoredClass?.some(a => isInIgnoredElement(event.target, a))
                if (checking) {
                    return
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}
