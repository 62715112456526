
import { SectionSettingsWrapper, TextInputLabelForm } from '@acme/ui';
import { type Session } from 'next-auth';
import React from 'react';

interface ProfileProps {
  session?: Session | null
}

export const Profile: React.FC<ProfileProps> = ({ session }) => {

  return (
    <div>
      <SectionSettingsWrapper title='User'>
        <TextInputLabelForm
          id='userId'
          label='User ID'
          className='w-full mb-3'
          placeholder='Your User ID'
          disabled
          value={session?.user?.id || ''}
          onChange={() => { }}
        />
        <div className='grid grid-cols-2 gap-3'>
          <TextInputLabelForm
            id='name'
            label='Name'
            placeholder='Your Name'
            disabled
            value={session?.user?.name || ''}
            onChange={() => { }}
          />
          <TextInputLabelForm
            id='email'
            label='Email'
            placeholder='Email Address'
            disabled
            value={session?.user?.email || ''}
            onChange={() => { }}
          />
        </div>
      </SectionSettingsWrapper>
    </div>
  );
};