import { useState, useEffect, type RefObject } from 'react';

type useScrollToBottomReturnType = [boolean, () => void];

export const useScrollToBottom = (
    elementRef: RefObject<HTMLElement>,
    threshold: number,
    enabled: boolean,
    debounceTimeout = 100
): useScrollToBottomReturnType => {
    const [notAtBottom, setNotAtBottom] = useState(true);
    let debounceTimer: ReturnType<typeof setTimeout>;

    const handleScroll = () => {
        if (elementRef.current && enabled) {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            debounceTimer = setTimeout(() => {
                const isShow = elementRef.current!.scrollTop < elementRef.current!.scrollHeight - elementRef.current!.clientHeight - threshold;
                setNotAtBottom(isShow);
            }, debounceTimeout);
        }
    };

    useEffect(() => {
        const el = elementRef.current;
        if (el && enabled) {
            el.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            if (el && enabled) {
                el.removeEventListener('scroll', handleScroll);
            }
        };
    }, [elementRef, enabled]);

    const scrollToBottom = () => {
        if (elementRef.current && enabled) {
            elementRef.current.scrollTo({
                top: elementRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    return [notAtBottom, scrollToBottom];
};