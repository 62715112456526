import { useState, useRef, useEffect } from "react";

export function useDebounceCallback(callback, delay, dependencies) {
    const [args, setArgs] = useState(null);
    const lastArgs = useRef(args);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (args && lastArgs.current.id === args.id) {
                callback(...args);
            }
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [args, delay, callback]);

    return (...newArgs) => {
        lastArgs.current = newArgs;
        setArgs(newArgs);
    };
}