import { BiChevronLeft, useDarkMode, Button, FaSun, FaMoon, AiFillCrown, BiExtension } from "@acme/ui";
import { type ReactNode, useState, useMemo, useEffect } from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAtomValue, useSetAtom } from "jotai";
import { upgradeModalAtom, userAtom } from "../store/user";
import { useUserSubscription } from "../hooks";
import { isMobile } from 'react-device-detect';
import { FaRegStickyNote, BsChatSquareText, BsFillGearFill } from "@acme/ui";

const menus = [
  {
    path: '/chat',
    label: 'Chat',
    shortLabel: 'Chat',
    emoji: '💬',
    icon: <BsChatSquareText />
  },
  /* {
    path: '/note',
    label: 'Note',
    shortLabel: 'Note',
    emoji: '📝',
    icon: <FaRegStickyNote />
  },
  {
    path: '/extension',
    label: 'Extension',
    shortLabel: 'Ext',
    emoji: '💻',
    icon: <BiExtension />
  }, */
];

export const MainLeftMenu = ({ action, children }: { action: ReactNode, children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(true)
  const router = useRouter()

  const isHideMenuContent = useMemo(() => {
    // if /extension/*/**  or /settings/*/** then hide menu content
    if (router.pathname.startsWith('/extension') || router.pathname.startsWith('/settings')) {
      return true;
    }
    return false;
  }, [router.pathname]);

  useEffect(() => {
    if (isMobile) {
      setIsOpen(false);
    }
  }, []);

  return (
    <>
      <div className={`${!isOpen ? 'w-0 max-w-[0px] translate-x-[-500px]' : 'flex-shrink-0'} z-[10000] transition-all duration-200 ${!isHideMenuContent ? 'lg:w-72 lg:min-w-[19rem]' : 'w-14 min-w-[3.5rem] lg:min-w-[3.5rem]'}`} />
      <div
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        className={`fixed lg:hidden ${isHideMenuContent ? '!hidden' : ''} top-[75vh] bg-neutral-900 border-l border-l-neutral-500 z-[100] ${!isOpen ? 'translate-x-[0rem]' : 'translate-x-[19rem]'} transition-all duration-200 z-[10000] shadow-xl rounded-r-xl px-[2px] py-4`}
      >
        <BiChevronLeft className={`text-2xl text-white ${!isOpen ? 'rotate-180' : ''}`} />
      </div>
      <LeftMenu action={action} isOpen={isOpen} isHideMenuContent={isHideMenuContent}>
        {children}
      </LeftMenu>
      <div
        className={`fixed lg:hidden top-0 left-0 ${!isOpen ? 'w-0 h-0' : `${isHideMenuContent ? '' : 'w-screen h-screendvh'}`} bg-neutral-700 z-40 bg-opacity-30`}
        onClick={() => setIsOpen(!isOpen)}
      />
    </>
  )
}

export const LeftMenu = ({ children, action, isOpen = false, isHideMenuContent = false }: { children: ReactNode, action: ReactNode, isOpen: boolean, isHideMenuContent?: boolean }) => {
  const router = useRouter();
  const { isSubscribe } = useUserSubscription()
  const user = useAtomValue(userAtom)
  const [darkMode, setDarkMode] = useDarkMode()
  const onSetUpgradeModal = useSetAtom(upgradeModalAtom)
  const activePath = useMemo(() => {
    const path = router.pathname.split('/')[1];
    if (path === 'extension') return '/extension';
    if (path === 'settings') return '/settings';
    if (path === 'note') return '/note';
    if (path === 'chat' || path === '/') return '/chat'
    return path
  }, [router.pathname]);

  const onGoToAccount = () => {
    router.push('/settings?menu=account')
  }

  const onUpgrade = () => {
    const today = new Date();
    onSetUpgradeModal({ isOpen: true, lastOpen: today, subscribed: isSubscribe ? true : undefined })
  }

  return (
    <div className={`${isOpen ? 'translate-x-0' : `${!isHideMenuContent ? 'translate-x-[-19rem]' : ''} lg:translate-x-0`} z-[10000] transition-all duration-200 fixed h-screendvh ${!isHideMenuContent ? 'w-[19rem]' : 'w-14'} top-0 flex flex-row`}>
      <div className="w-14 bg-[#0b141a] left-0 h-screendvh flex flex-col border-r border-r-neutral-700 z-[60]">
        <div className="w-full flex-1 relative p-2 px-1 pt-[12px] flex flex-col gap-3 items-center justify-start">
          {menus.map((a, i) => (
            <LeftMenuSmallItem
              key={a.path}
              path={a.path}
              label={a.label}
              icon={a.icon}
              shortLabel={a.shortLabel}
              emoji={a.emoji}
              active={a.path === activePath}
            />
          ))}
        </div>
        <div className="w-full relative p-2 pt-[12px] flex flex-col gap-3 items-center justify-start">
          <div className="flex items-center justify-center h-[82px]">
            <div
              className="relative h-[80px] w-10 bg-neutral-800 rounded-lg shadow-md cursor-pointer transition-all duration-300 p-1"
              onClick={() => setDarkMode()}
            >
              <div className={`absolute top-1 w-8 h-8 bg-${darkMode ? "gray-900" : "gray-700"}
                    rounded-lg shadow-md transform ${darkMode ? "translate-y-[37px]" : "translate-y-0"} transition-transform duration-300`}
              ></div>
              <div className={`flex items-center justify-center w-8 h-8 absolute ${darkMode ? "opacity-50" : "opacity-100"}
                  transition-opacity duration-300`}>
                <FaSun className="text-yellow-400" />
              </div>
              <div className={`absolute top-0 flex items-center justify-center w-8 h-8 mt-[41px] ${darkMode ? "opacity-100" : "opacity-50"} transition-opacity duration-300`}>
                <FaMoon className="text-blue-400" />
              </div>
            </div>
          </div>
          {[{ path: '/settings', label: 'Settings', emoji: '⚙️', shortDesc: 'Settings', icon: <BsFillGearFill /> }].map((a, i) => (
            <div key={a.path} className='relative group cursor-pointer'>
              <LeftMenuSmallItem
                key={a.path}
                icon={a.icon}
                path={a.path}
                shortLabel={a.shortDesc}
                label={a.label}
                emoji={a.emoji}
                active={a.path === activePath}
              />
            </div>
          ))}
        </div>
      </div>
      {!isHideMenuContent && (
        <div className="w-[15.5rem] bg-[#0b141a] z-50 h-screendvh flex flex-col">
          <div className="w-full h-full relative">
            <div className="">
              <div className="p-2 pb-2">
                {/*
                <div className="flex flex-row items-center gap-3 bg-gray-300 rounded p-1 px-4">
                  <img src="https://picsum.photos/200" alt="avatar" className="rounded-full w-8 h-" />
                  <div className="flex flex-col mt-2 flex-1">
                    <p className="font-bold text-gray-800 text-sm">Team Name</p>
                    <p className="text-xs text-gray-600">12 Members</p>
                  </div>
                  <FaChevronDown />
                </div>
                */}
                <div className="mt-0">
                  {action}
                </div>
              </div>
              <div className="flex-1 min-h-[calc(100vh-190px)] max-h-[calc(100vh-190px)] overflow-y-auto pb-20">
                {children}
              </div>
            </div>
            <div className="absolute bottom-0 w-full px-2 py-2">
              <div className="h-auto flex flex-col gap-3 my-3">
                <div className='bg-neutral-700 rounded-lg border border-neutral-600'>
                  <div onClick={onGoToAccount} className='flex flex-row items-center gap-3 p-3 pb-2 cursor-pointer'>
                    <img
                      src={`https://ui-avatars.com/api/?name=${user?.name || 'John+Doe'}&background=2463EB&color=fff&size=128`}
                      alt="avatar" className="rounded-full w-8 h-8" />
                    <div className="flex flex-col gap-1">
                      <p className='text-white text-sm'>{user?.name || 'Not Logged In'}</p>
                      <p className='text-gray-200 text-xs'>{user?.email || 'unknown @.com'}</p>
                    </div>
                  </div>
                  {!isSubscribe && (
                    <div className='flex flex-row gap-3 p-2 cursor-pointer'>
                      <Button onClick={onUpgrade} className="group w-full !py-2 text-sm border-2 border-orange-600 bg-orange-700 hover:border-orange-500 hover:!bg-orange-600 hover:!opacity-100">
                        <AiFillCrown className='w-4 h-4 text-orange-200' />
                        <p className="text-gray-200 group-hover:text-white">Upgrade Account</p>
                      </Button>
                    </div>
                  )}
                </div>
                {/* {['Settings', 'Get Help'].map(a => (
                  <Link key={a} href={a === 'Settings' ? '/settings' : a === 'Get Help' ? '/help' : '/account'}>
                    <Button
                      onClick={(e) => e.stopPropagation()}
                      className="w-full bg-transparent py-[2px]"
                    >
                      <div className="flex flex-row items-center justify-start gap-2 w-full">
                        <CgBulb className="w-[30px]" />
                        <p className="text-sm">{a}</p>
                      </div>
                    </Button>
                  </Link>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='bg-gray-500 bg-gray-600 bg-gray-700' />
    </div>
  )
}


const LeftMenuSmallItem = (a: { path: string, label: string, emoji: string, icon: ReactNode, shortLabel: string, active?: boolean }) => {
  return (
    <div key={a.path} className='relative group cursor-pointer flex flex-col'>
      <Link href={a.path}>
        <Button
          className={`!p-2 !w-10 !h-[54px] !rounded-lg flex flex-col items-center !gap-1 transition duration-150 ${a?.active ? `!bg-[#535353] text-gray-200 border border-[#2d2e30]` : `hover:!bg-[#404040] bg-transparent !text-gray-400`}`}
          onClick={() => {
            console.log('click')
          }}
        >
          <p className="h-[16px] mb-0 pb-0">{a.icon}</p>
          <p className={`h-[18px] -mt-[2px] py-0 text-[9px] ${a.active ? 'font-semibold' : 'font-normal'}`}>
            {a.shortLabel}
          </p>
        </Button>
      </Link>
      <div className="absolute top-[-20px] w-auto left-0 px-2 py-1 rounded-full bg-black text-white text-xs items-center justify-center invisible opacity-0 transition duration-150 group-hover:opacity-100 group-hover:visible">
        {a.label}
      </div>
    </div>
  )
}
/*  ${a?.active ? 'bg-gradient-to-r from-blue-500 via-blue-600 to-blue-600' : '!bg-neutral-800'} */
