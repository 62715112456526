import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const userAtom = atomWithStorage<{
    id: string;
    name?: string | null;
    email?: string | null;
    avatar?: string;
    created?: Date;
    isSynced?: boolean | null;

    isLocal?: boolean;
}>(
    "user",
    {
        id: "",
    }
);

export const teamActiveAtom = atomWithStorage<{ id: string; name: string } | null>("teamActive", null);

export const userSubscriptionAtom = atom<{
    id: string; planPurchase: { plan: { name: string }; }; created: Date | null; planPurchaseId: string; startDate: Date | null; endDate: Date | null;
} | null | undefined>(null)

export const upgradeModalAtom = atomWithStorage<{ isOpen?: boolean, lastOpen?: Date, subscribed?: boolean }>('upgradeModalAtom', {})
export const codeModalAtom = atom({ isOpen: false })

export const apiKeyModalAtom = atom<boolean | 'anthropic'>(false)
