import { LayoutMain } from "./LayoutMain";
import { NoteEditor } from "../note/NoteEditor";
import { NoteList } from "../note/NoteList";
import { NoteAction } from "../note/NoteAction";
import { NoteTitle, NoteTitleMenu } from "../note/NoteTitle";
import { NoteSettings } from "../note/NoteSettings";

export function LayoutNote() {
  return (
    <>
      <LayoutMain
        leftMenu={<NoteList />}
        title={<NoteTitle />}
        leftMenuAction={<NoteAction />}
        titleMenu={<NoteTitleMenu />}
      >
        <div className="flex flex-col gap-2 pb-32">
          <NoteEditor />
          {/* Middle Content */}
          <NoteSettings />
        </div>
      </LayoutMain>
    </>
  );
}

