import { LayoutMain } from "./LayoutMain";
import SettingsPage from "../setting/Settings";
import Head from "next/head";

export function LayoutSettings() {
  return (
    <>
      <Head>
        <title>Settings - DapperGPT</title>
      </Head>
      <LayoutMain
        leftMenu={null}
        leftMenuAction={null}
        title={
          <div className="w-full flex flex-row items-center justify-between px-4 text-white dark:text-gray-800">
            <h1 className="text-lg text-gray-700 dark:text-white">Settings</h1>
          </div>
        }
        titleMenu={null}
      >
        <div className="flex flex-col gap-2 pb-32 bg-gray-50 dark:bg-neutral-600 px-5 min-h-screen">
          <SettingsPage />
        </div>
      </LayoutMain>
    </>
  );
}

