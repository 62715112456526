import { type ChatMessage, compileGptMessages, generateNanoId, dayjs, type ChatSession, type GPTChatMessage, type GPTChatResult, isJsonString, openAiChatRequestHandler, type ChatModel, nextApiSendMessage, defaultModelPrismaToString, defaultModelGptLimit, type InputAPISend, nextApiAddMessages, chatFormatImageGen, chatImageGenParseResult, openAiDallEImageGenerationRequest, convertGPTContentToChatContent, aiChatRequestHandler, isModelAnthropic } from "@acme/util";
import { type Getter, type Setter } from "jotai";
import { chatUpdateTriggerAtom, chatSessionActiveAtom, chatAddMessageAtom, chatConversationTriggerAtom, chatUpdateMessageAtom, chatRemoveMessageAtom, playNotificationSound, chatGenerateTitleAtom, chatSessionsAtom } from "../chat";
import { apiKeyAnthropicAtom, apiKeyAtom, chatDefaultSettingsAtom } from "../setting";
import { sendErrorHandlerAtom } from "../util";
import { toast } from "@acme/ui";
import { userSubscriptionAtom } from "../user";

type OnMessageCallback = (message: string, firstCall?: boolean) => void;

export const handleSendMessageInit = async (
    get: Getter,
    set: Setter,
    session: ChatSession,
    message: ChatMessage,
    type: 'client' | 'cloud' | 'regenerate',
    isStreaming?: boolean
) => {
    const chatSessionId = session.id;
    set(chatConversationTriggerAtom, { scrollToBottom: true })
    if (message.senderRole === 'user') {
        const modelFixed = message.model
            || session?.messages?.[0]?.model
            || session.defaultModel
            || 'gpt-3.5-turbo';

        const chatDefaultSettings = get(chatDefaultSettingsAtom)
        const defaultMaxTokensBySetting = (typeof chatDefaultSettings?.defaultMaxContextTokens === 'number' && chatDefaultSettings?.defaultMaxContextTokens)
            ? chatDefaultSettings?.defaultMaxContextTokens
            : defaultModelGptLimit(modelFixed).maxTokens

        const { newMessageFixed: newMessage, gptMessages, initInstruction } = compileGptMessages(session, message, defaultMaxTokensBySetting)
        // if has init instruction, then update it
        if (initInstruction) {
            if (type === 'cloud') {
                set(chatUpdateTriggerAtom, prev => ([...prev, { chatSessionId, update: { id: chatSessionId, defaultInstruction: initInstruction } }]))
            }
            set(chatSessionActiveAtom, { defaultInstruction: initInstruction })
        }
        set(chatAddMessageAtom, newMessage)

        const aiTempMessageId = generateNanoId(14)

        //~ SET LOADING
        const abortController = new AbortController();
        const aiMessage: ChatMessage = {
            id: aiTempMessageId,
            chatSessionId: session.id,
            content: {
                contentType: 'text',
                content: '',
            },
            lastSync: dayjs().toDate(),
            userId: '',
            loading: true,
            parentId: newMessage.id,
            senderRole: 'assistant',
            created: dayjs().toDate(),
            model: modelFixed,
            abortSignal: abortController
        }

        try {
            const openAiApiKey = get(apiKeyAtom)?.key || ''
            const anthropicApiKey = get(apiKeyAnthropicAtom)?.key || ''

            const isAnthropic = isModelAnthropic(modelFixed)
            const modelSourceType = isAnthropic ? 'anthropic' : 'openai'

            if (type === 'client') {
                const onMessage = handleSendMessageInitClient(get, set, session, message, aiMessage, modelSourceType)
                await handleSendMessageFetchClient(get, set, session, newMessage, aiMessage, { openAi: openAiApiKey, anthropic: anthropicApiKey }, gptMessages, modelFixed, abortController, onMessage, isStreaming)
            } else if (type === 'cloud') {
                const onMessage = handleSendMessageInitCloud(get, set, session, message, aiMessage, modelSourceType)
                await handleSendMessageFetchCloud(get, set, session, newMessage, aiMessage, { openAi: openAiApiKey, anthropic: anthropicApiKey }, gptMessages, modelFixed, abortController, onMessage, isStreaming)
            }
            handleSendMessagePostFetch(get, set)

        } catch (error) {
            handleSendMessageError(get, set, session, aiMessage.id, error, isStreaming)
        }

        handleSendMessageFinally(get, set, session, newMessage)
        return null
    }
}


//~ INIT
export const handleSendMessageInitClient = (
    get: Getter,
    set: Setter,
    session: ChatSession,
    message: ChatMessage,
    aiMessage: ChatMessage,
    type: 'openai' | 'anthropic' = 'openai'
) => {

    set(chatAddMessageAtom, aiMessage, session.id)
    return handleSendMessageOnMessage(get, set, session, aiMessage, undefined, type)
}

export const handleSendMessageInitCloud = (
    get: Getter,
    set: Setter,
    session: ChatSession,
    message: ChatMessage,
    aiMessage: ChatMessage,
    type: 'openai' | 'anthropic' = 'openai'
) => {
    set(chatAddMessageAtom, aiMessage, session.id)

    return handleSendMessageOnMessage(
        get,
        set,
        session,
        aiMessage,
        {
            // when last call update the message in the cloud
            lastCall: async (combinedMessage: string, newAIMessage: ChatMessage) => {
                set(chatUpdateMessageAtom, { id: newAIMessage.id, abortSignal: null, loading: false, generating: false, imaging: false }, { chatSessionId: session?.id, isSkip: true })
                await nextApiAddMessages({
                    messages: [{
                        ...newAIMessage,
                        content: {
                            contentType: 'text',
                            content: combinedMessage
                        }
                    }],
                    chatSessionId: session.id,
                })
            }
        },
        type
    )
}

export const handleSendMessageOnMessage = (
    get: Getter,
    set: Setter,
    session: ChatSession,
    aiMessage: ChatMessage,
    callbacks?: {
        lastCall?: (combinedMessage: string, newAIMessage: ChatMessage) => void | Promise<void>
    },
    type: 'openai' | 'anthropic' = 'openai'
) => {
    // Remove is init
    if (session.isInit) set(chatSessionActiveAtom, { isInit: false });

    //~ STREAMING CONFIG
    let messageTemp = ''
    const onMessage = (message: string, firstCall?: boolean, lastCall?: boolean) => {
        if (firstCall) {
            set(chatUpdateMessageAtom, { id: aiMessage?.id, loading: false, generating: true }, { chatSessionId: session?.id, isSkip: true })
        } else if (lastCall) {
            if (callbacks?.lastCall) {
                callbacks.lastCall(messageTemp, aiMessage)
            }
            else {
                set(chatUpdateMessageAtom, { id: aiMessage?.id, loading: false, generating: false, imaging: false }, { chatSessionId: session?.id, isSkip: true })
            }
        }


        // Process partial message here
        if (message === '[DONE]') {
            set(chatUpdateMessageAtom, { id: aiMessage?.id, loading: false, generating: false, imaging: false }, { chatSessionId: session?.id, isSkip: true })
            return
        }

        if (type === 'openai') {

            if (message.startsWith('data: ')) {
                // sometimes there is \n\n or \n at the end of the message, so we need to remove it but only if it's at the end, remember sometimes it's multiple lines
                const parseMessage = message.replace('data: ', '')
                if (parseMessage === '[DONE]') return
                if (!isJsonString(parseMessage)) return
                const gpt = JSON.parse(parseMessage) as GPTChatResult;
                if (gpt?.choices) {
                    const combinedChoices = gpt.choices.map(choice => choice?.delta?.content).join(' ');
                    messageTemp += combinedChoices
                    set(chatUpdateMessageAtom, {
                        id: aiMessage.id,
                        content: {
                            contentType: 'text',
                            content: messageTemp,
                        },
                    }, { chatSessionId: session.id, isSkip: true });
                }
            }
        } else if (type === 'anthropic') {
            if (message !== '[DONE]') {
                set(chatUpdateMessageAtom, {
                    id: aiMessage.id,
                    content: {
                        contentType: 'text',
                        content: message,
                    },
                }, { chatSessionId: session.id, isSkip: true });
            }
        }
    };
    return onMessage
}


//~ FETCH
export const handleSendMessageFetchClient = async (
    get: Getter,
    set: Setter,
    session: ChatSession,
    /** New message / last message **/
    newMessage: ChatMessage,
    /** Temporary message to be removed **/
    aiTempMessage: ChatMessage | undefined,
    apiKey: {
        openAi: string,
        anthropic: string
    },
    gptMessages: GPTChatMessage[],
    modelFixed: ChatModel,
    abortController: AbortController,
    onMessage?: OnMessageCallback,
    isStreaming?: boolean
) => {
    //~ FETCHING
    if (session?.isImageGen) {

        const validate = handleSendMessageImageGenValidate(newMessage, session)
        const userSubscription = get(userSubscriptionAtom)
        if (validate && userSubscription?.id) {
            toast.success('Generating image (this feature is in preview)')
            const imgResult = await handleSendMessageImageGen(get, set, session, newMessage, aiTempMessage, apiKey, gptMessages, abortController)
            if (imgResult) {
                const flatImageUrls = imgResult?.imageUrls.flatMap(imageUrl => {
                    return imageUrl.urls.map(url => {
                        return { image: url.url, prompt: imageUrl.prompt };
                    });
                });

                const newAIImgMessage: ChatMessage = {
                    id: generateNanoId(14),
                    chatSessionId: session.id,
                    content: {
                        contentType: 'text',
                        content: imgResult?.parsedResult?.messageReply
                            ? `${imgResult?.parsedResult?.messageReply}\n\nImages:\n\n${flatImageUrls?.flatMap(img => `${img.prompt}\n![${img.prompt}](${img.image})`).join('\n\n')}`
                            : ''
                    },
                    userId: '',
                    senderRole: 'assistant',
                    // convert gpt.created to date as it is a number
                    created: new Date(),
                    model: modelFixed,
                    parentId: newMessage.id,
                }
                set(chatAddMessageAtom, newAIImgMessage, session.id)
                if (aiTempMessage) {
                    set(chatRemoveMessageAtom, aiTempMessage?.id, session.id)
                }
                return;
            }
        }
    }

    const gpt = await aiChatRequestHandler(
        apiKey,
        modelFixed,
        gptMessages,
        abortController,
        { ...isStreaming ? { useStreaming: true } : {}, onMessage: onMessage }
    );

    if (isStreaming) {
    } else {
        //~ SET LOADING
        aiTempMessage && set(chatRemoveMessageAtom, aiTempMessage.id, session.id)

        if (gpt?.choices) {
            gpt.choices.map(choice => {
                const { content, contentCustoms } = convertGPTContentToChatContent(choice.message.content)
                const aiMessage: ChatMessage = {
                    id: generateNanoId(14),
                    chatSessionId: session.id,
                    content: content,
                    contentCustoms: contentCustoms,
                    userId: '',
                    senderRole: choice.message.role || 'assistant',
                    // convert gpt.created to date as it is a number
                    created: dayjs.unix(gpt.created).toDate(),
                    model: modelFixed,
                    parentId: newMessage.id,
                }
                set(chatAddMessageAtom, aiMessage, session.id)
            })
        }
    }
}

export const handleSendMessageFetchCloud = async (
    get: Getter,
    set: Setter,
    session: ChatSession,
    /** New message / last message (will be replaced) **/
    newMessage: ChatMessage,
    /** Temporary message to be removed **/
    aiTempMessage: ChatMessage | undefined,
    apiKey: {
        openAi: string,
        anthropic: string
    },
    gptMessages: GPTChatMessage[],
    modelFixed: ChatModel,
    abortController: AbortController,
    onMessage?: OnMessageCallback,
    isStreaming?: boolean,
    apiParam?: Partial<InputAPISend>,
    extraParam?: { type?: 'regenerate' }
) => {
    //~ FETCHING
    const chatSessionId = session.id
    const message = newMessage

    // Is Image
    if (session?.isImageGen && apiKey) {
        const validate = handleSendMessageImageGenValidate(newMessage, session)
        const userSubscription = get(userSubscriptionAtom)
        if (validate && userSubscription?.id) {
            toast.success('Generating image (this feature is in preview)')
            const imgResult = await handleSendMessageImageGen(get, set, session, newMessage, aiTempMessage, apiKey, gptMessages, abortController)
            if (imgResult) {
                console.log({ imgResult })
                const flatImageUrls = imgResult?.imageUrls.flatMap(imageUrl => {
                    return imageUrl.urls.map(url => {
                        return { image: url.url, prompt: imageUrl.prompt };
                    });
                });

                const newAIImgMessage: ChatMessage = {
                    id: generateNanoId(14),
                    chatSessionId: session.id,
                    content: {
                        contentType: 'text',
                        content: imgResult?.parsedResult?.messageReply
                            ? `${imgResult?.parsedResult?.messageReply}\n\nImages:\n\n${flatImageUrls?.flatMap(img => `${img.prompt}\n![${img.prompt}](${img.image})`).join('\n\n')}`
                            : ''
                    },
                    userId: '',
                    senderRole: 'assistant',
                    // convert gpt.created to date as it is a number
                    created: new Date(),
                    model: modelFixed,
                    parentId: newMessage.id,
                }
                set(chatAddMessageAtom, newAIImgMessage, session.id)
                if (aiTempMessage) {
                    set(chatRemoveMessageAtom, aiTempMessage?.id, session.id)
                }
                return;
            }
        }
    }

    // if cloud and api key exist and streaming then call in local before add it
    if (isStreaming && apiKey) {
        if (extraParam?.type !== 'regenerate') {
            await nextApiAddMessages({
                chatSessionId: session.id,
                messages: [{
                    ...newMessage,
                    content: {
                        contentType: newMessage.content.contentType || 'text',
                        content: newMessage.content.content || ''
                    },
                }]
            });
        }
        await aiChatRequestHandler(
            apiKey,
            modelFixed,
            gptMessages,
            abortController,
            { ...isStreaming ? { useStreaming: true } : {}, onMessage: onMessage }
        );
    } else {
        const newApiMessages = await nextApiSendMessage({
            prevMessages: gptMessages,
            defaultModel: modelFixed,
            apiKey,
            message: {
                ...message,
                ...message?.content && {
                    content: {
                        contentType: message.content.contentType || 'text',
                        content: message.content.content || ''
                    },
                    totalTokens: message.totalTokens || undefined,
                },
                ...message?.model && { model: message.model },
            },
            ...apiParam,
        }, abortController);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return
        const newMessages: ChatMessage[] = newApiMessages.messages.map(a => ({
            ...a,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            parentId: a.parentId ?? undefined,
            replyToId: a.replyToId ?? undefined,
            userId: a.userId ?? undefined,
            model: a.model ? defaultModelPrismaToString(a.model) : undefined,
            content: a.content,
            contentCustoms: a.contentCustoms ?? undefined,
            totalTokens: a.totalTokens ?? undefined,
            updated: a.updated ?? undefined,
        }))

        //~ REMOVE TEMP MESSAGES AND REPLACE
        set(chatRemoveMessageAtom, newMessage.id, chatSessionId, true)
        aiTempMessage && set(chatRemoveMessageAtom, aiTempMessage.id, chatSessionId, true)
        set(chatAddMessageAtom, newMessages, chatSessionId)
    }
}

//~ IMAGE DALL-E
export const defaultImageGenTriggersFormats = [{ 'text': 'generate image' }, { 'text': 'generate picture' }, { 'text': 'generate visual' }]
export const handleSendMessageImageGenValidate = (
    newMessage: ChatMessage,
    session: ChatSession,
) => {
    const newMessageContent = Array.isArray(newMessage.content?.content)
        ? newMessage.content?.content.join(' ')
        : newMessage.content?.content

    const imageGenTriggers = session?.defaultImageGenTriggers || defaultImageGenTriggersFormats

    if (imageGenTriggers) {
        const isImageGen = imageGenTriggers.some(trigger => {
            if (trigger.text) {
                return newMessageContent?.toLowerCase().includes(trigger.text.toLowerCase())
            }
            return false
        })
        return isImageGen
    }
    return false
}
export const handleSendMessageImageGen = async (
    get: Getter,
    set: Setter,
    session: ChatSession,
    /** New message / last message **/
    newMessage: ChatMessage,
    /** Temporary message to be removed **/
    aiTempMessage: ChatMessage | undefined,
    apiKey: {
        openAi: string,
        anthropic: string
    },
    gptMessages: GPTChatMessage[],
    abortController: AbortController,
) => {
    const gptMessagesFixed = chatFormatImageGen(newMessage, gptMessages, true)
    const gpt = await aiChatRequestHandler(
        apiKey,
        'gpt-3.5-turbo',
        gptMessagesFixed,
        abortController
    )
    let parsedResult: { prompts: string[]; messageReply: string; } | null = null
    const imageUrls: { prompt: string, urls: { url: string }[] }[] = []
    const resultContent = gpt?.choices?.[0]?.message?.content
    if (resultContent) {
        parsedResult = chatImageGenParseResult(resultContent)
        if (parsedResult?.prompts?.length) {
            // fix prompts to max 3
            const parsedPromptsFix = parsedResult.prompts.slice(0, 3)
            await Promise.all(
                parsedPromptsFix.map(async (prompt, index) => {
                    const urls = await openAiDallEImageGenerationRequest(apiKey.openAi, prompt)
                    imageUrls.push({ prompt, urls })
                    return urls
                })
            )

        }
    }
    return {
        parsedResult: parsedResult,
        imageUrls: imageUrls
    }
}


//~ POST FETCH
export const handleSendMessagePostFetch = (
    get: Getter,
    set: Setter,
) => {

    //~ PLAY SOUND AND SCROLL TO BOTTOM
    playNotificationSound(get)
    set(chatConversationTriggerAtom, { scrollToBottom: true })
}

//~ ERROR
export const handleSendMessageError = async (
    get: Getter,
    set: Setter,
    session: ChatSession,
    aiTempMessageId: string,
    error: Error | any,
    isStreaming?: boolean
) => {
    // Skip check if error is abort
    // set(chatUpdateMessageAtom, { error: error?.message || 'There are some error', id: aiTempMessageId, loading: false }, { chatSessionId: session?.id || '', isSkip: true })


    const isCloud = session?.isSync && !session?.isInit
    // If not streaming then remove the message
    if (!isStreaming) {
        set(chatRemoveMessageAtom, aiTempMessageId, session.id, true)
    } else {
        // If streaming and cloud then still add the message
        if (isCloud) {
            const findSession = get(chatSessionsAtom).find(a => a.id === session.id)
            const findMessage = findSession?.messages.find(a => a.id === aiTempMessageId)
            if (findMessage) {
                await nextApiAddMessages({
                    messages: [{
                        ...findMessage,
                        content: {
                            contentType: 'text',
                            content: findMessage.content.content || ''
                        }
                    }],
                    chatSessionId: session.id,
                })
            }
        }
    }
    set(sendErrorHandlerAtom, { error })
}

//~ FINALLY
const handleSendMessageFinally = (
    get: Getter,
    set: Setter,
    session: ChatSession,
    newMessage: ChatMessage,
) => {
    //~ SET TITLE IF NEW CHAT
    if (session.name === 'New Chat' && (session.messages.length || 0) <= 1) {
        void set(chatGenerateTitleAtom, session.id, newMessage)
    }
}

