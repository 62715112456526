import { api } from "@acme/client"
import { PopupWrapper, Button, AiOutlineLoading, ButtonClose, toast } from "@acme/ui"
import { useAtom, useSetAtom } from "jotai"
import { useMemo, useCallback, useEffect, useState, memo } from "react"
import { teamActiveAtom, userAtom } from "../store/user"
import { isLoadAPIKeyAtom, isLoadAPIKeyTriggerAtom, isLoadedAtom } from "../store/util"
import { signIn } from 'next-auth/react';
import { AccountSyncPopup } from "./LayoutAll"
import { ChatQuickOption, apiKeyAnthropicAtom, apiKeyAtom, apiKeyExistAtom, chatQuickOptionsAtom, chatQuickOptionsDefaultAction } from "../store/setting"
import { type SavedInstruction, generateNanoId, type SavedPrompt } from "@acme/util"
import { useRouter } from "next/router"
import { savedInstructionsAtom, savedPromptsAtom } from "../store/instruction"

export const LayoutInit = memo(() => {
  const router = useRouter()

  const [user, setUser] = useAtom(userAtom)
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom)
  const [teamActive, setTeamActive] = useAtom(teamActiveAtom)
  const [isSyncLoad, setIsSyncLoad] = useState(false)

  const [apiKey, setApiKey] = useAtom(apiKeyAtom)
  const [apiKeyAnthropic, setApiKeyAnthropic] = useAtom(apiKeyAnthropicAtom)

  const [isLoadAPIKey, setIsLoadAPIKey] = useAtom(isLoadAPIKeyAtom)
  const [isLoadAPIKeyTrigger, setIsLoadAPIKeyTrigger] = useAtom(isLoadAPIKeyTriggerAtom)
  const [chatQuickOptions, setChatQuickOptions] = useAtom(chatQuickOptionsAtom)
  const setSavedInstructions = useSetAtom(savedInstructionsAtom)
  const setSavedPrompts = useSetAtom(savedPromptsAtom)
  const setApiKeyExist = useSetAtom(apiKeyExistAtom)

  const { data: session, isLoading: isLoadingSession } = api.auth.getSession.useQuery(undefined, {
    enabled: router.isReady,
    onSuccess: (data) => {
      if (!data?.user) {
        setUser(prev => ({ id: "", ...prev?.isLocal ? { isLocal: true } : {} }))
        setTeamActive(null)
      } else {
        setUser(prev => {
          const newId = data?.user?.id || prev?.id
          const newEmail = data?.user?.email || prev?.email
          const newName = data?.user?.name || prev?.name || ''
          return { ...prev, ...data?.user, id: newId, email: newEmail, name: newName }
        })
      }
    },
    onError: (error) => {
      console.log({ errorHere: error })
    }
  });
  const { data: userData, isLoading: isLoadingUser } = api.user.me.useQuery(undefined, { enabled: !!session?.user });
  const { data: syncData, isLoading: isLoadingSync } = api.sync.syncLoad.useQuery(
    { userTeamId: teamActive?.id || '' },
    {
      enabled: isSyncLoad && !!teamActive?.id,
      onSuccess: (data) => {
        setIsSyncLoad(false)
        setIsLoaded(prev => ({ ...prev, status: true, lastLoad: new Date() }))
      }
    }
  );
  const { isLoading: isLoadingAPIKey } = api.user.teamAPIKey.useQuery(
    { teamId: teamActive?.id || '' },
    {
      enabled: isLoadAPIKey && isLoadAPIKeyTrigger && !!teamActive?.id,
      onSuccess: (data) => {
        setIsLoadAPIKey(false)
        setIsLoadAPIKeyTrigger(false)
        if (data?.keyOpenAI) {
          setApiKey({ key: data?.keyOpenAI ?? '', created: new Date(), id: data?.id ?? generateNanoId(14) })
        }
        if (data?.keyAnthropic) {
          setApiKeyAnthropic({ key: data?.keyAnthropic ?? '', created: new Date(), id: data?.id ?? generateNanoId(14) })
        }
      },
      onError: (error) => {
        toast.error(error.message || 'Failed to get API Key')
        setIsLoadAPIKey(false)
        setIsLoadAPIKeyTrigger(false)
      }
    }
  )
  const { data: apiKeyCheck, isLoading: isLoadingAPIKeyCheck } = api.user.teamHasAPIKey.useQuery(
    { teamId: teamActive?.id || '' },
    {
      enabled: !!user?.id && !!teamActive?.id,
      onSuccess: (data) => {
        if (data) {
          setApiKeyExist(true)
        }
      }
    }
  );
  // load once every hour
  const { isLoading: isLoadingInstruction } = api.instruction.instructions.useQuery(
    { teamId: teamActive?.id || '' },
    {
      onSuccess: (data) => {
        setSavedInstructions(prev => {
          // if exist then update, else add. the exist and new update add isSync: true
          const newInstructions: SavedInstruction[] = data.map(({ defaultModel, ...i }) => ({ ...i, isSync: true }))
          const merged = ([...prev, ...newInstructions] as SavedInstruction[]).reduce((acc, curr) => {
            const x = acc.find(item => item.id === curr.id);
            if (!x) {
              return acc.concat([curr]);
            } else {
              return acc;
            }
          }, [] as SavedInstruction[]);
          return merged
        })
      },
      enabled: !!teamActive?.id, refetchOnWindowFocus: false, refetchOnMount: false, refetchInterval: 3600000
    }
  )
  const { isLoading: isLoadingPrompt } = api.instruction.prompts.useQuery(
    { teamId: teamActive?.id || '' },
    {
      onSuccess: (data) => {
        setSavedPrompts(prev => {
          // if exist then update, else add. the exist and new update add isSync: true
          const newPrompts: SavedPrompt[] = data.map((i) => ({ ...i, isSync: true }))
          const merged = ([...prev, ...newPrompts] as SavedPrompt[]).reduce((acc, curr) => {
            const x = acc.find(item => item.id === curr.id);
            if (!x) {
              return acc.concat([curr]);
            } else {
              return acc;
            }
          }, [] as SavedPrompt[]);
          return merged
        })
      },
      enabled: !!teamActive?.id, refetchOnWindowFocus: false, refetchOnMount: false, refetchInterval: 3600000
    }
  )

  const userExist = useMemo(() => {
    if (!user?.id && !user.isLocal) return false;
    return true;
  }, [user]);

  // Set user when logged in
  const onSetLocal = useCallback(() => {
    setUser(prev => ({ ...prev, id: '', isLocal: true }));
  }, [setUser]);

  // Detect when user is logged in
  useEffect(() => {
    if (userData && router.isReady) {
      setUser(prev => ({ ...prev, ...userData, isLocal: false }))
    }
  }, [userData, setUser, apiKey?.key, setIsLoadAPIKey, router.isReady]);

  // Detect when user logged in and don't have api key but has saved api key in cloud
  useEffect(() => {
    if (user?.id && !!apiKeyCheck && !apiKey?.key && !apiKeyAnthropic?.key && router?.isReady) {
      setIsLoadAPIKey(true)
    }
  }, [user?.id, apiKeyCheck, apiKey?.key, setIsLoadAPIKey, router?.isReady, apiKeyAnthropic?.key])

  // If user logged in & data not loaded then load data
  useEffect(() => {
    if (!userExist || isLoaded?.status) return;
    else (userExist && !isLoaded?.status) && setIsSyncLoad(true);
  }, [userExist, isLoaded, setIsLoaded]);

  // If no team active and user exist and userData has loaded then set team active
  useEffect(() => {
    if (!userExist || !userData || teamActive) return;
    else if (userExist && userData && userData?.teams?.[0] && !teamActive) {
      setTeamActive(userData?.teams?.[0])
    }
  }, [userExist, userData, teamActive, setTeamActive]);

  // Set chat quick options if not exist
  useEffect(() => {
    if (chatQuickOptions.list?.length) {
      // check if there is value from chatQuickOptionsDefaultAction not exist then add it
      const ifThereIsValueNotInChatQuickOptions = chatQuickOptionsDefaultAction?.filter(i => !chatQuickOptions?.list?.some(j => j.value === i.value));
      if (ifThereIsValueNotInChatQuickOptions?.length) {
        const filteredPrompt = chatQuickOptions.list.filter(i => i?.action === 'prompt')
        const filteredNonPrompt = [...chatQuickOptions.list.filter(i => i?.action !== 'prompt'), ...ifThereIsValueNotInChatQuickOptions]
        setChatQuickOptions(prev => ({ ...prev, list: [...filteredNonPrompt, ...filteredPrompt] }))
      }
    }
  }, [router.isReady, chatQuickOptions.list.length, chatQuickOptions.list, setChatQuickOptions]);

  return (
    <>
      {/** //~SIGNIN  */}
      <PopupWrapper className='!bg-transparent' containerClassName="z-[100001]" noClose onClose={() => null} show={!session && !isLoadingSession && !userExist}>
        <div className='max-w-full w-[400px] max-h-full h-[180px] text-white p-2'>
          <div className='bg-white rounded p-5 text-gray-700'>
            <h1 className='text-2xl font-bold text-center'>Sign In</h1>
            <div className='flex flex-col items-center justify-center mt-5 gap-4'>
              <p className="mb-4">Sign-in or Create an Account</p>
              <Button onClick={signIn} className='py-1 !bg-blue-700 !text-white'>
                {/* <FaGoogle className='inline mr-2' /> */}
                <p>Sign In Now 🔥</p>
              </Button>
              <div className='mt-3'>
                <p onClick={onSetLocal} className='underline text-sm cursor-pointer'>I just want to try</p>
              </div>
            </div>
          </div>
          <ButtonClose onClick={onSetLocal} className='absolute top-2 right-2' />
        </div>
      </PopupWrapper>
      {/** //~ API KEY CLOUD  */}
      <PopupWrapper className='!bg-transparent' containerClassName="z-[100002]" noClose onClose={() => null} show={isLoadAPIKey && !!teamActive?.id && router?.isReady}>
        <div className='max-w-full w-[400px] max-h-full h-auto text-white p-2'>
          <div className='bg-white text-gray-800 rounded p-5'>
            <h1 className='text-2xl font-bold text-center'>Get API Key</h1>
            <div className='flex flex-col items-center justify-center mt-10 gap-4'>
              <p>You have saved API Key in the cloud, do you wanna get and save it locally</p>
              <p>Saving API Key locally will be used if you have non-cloud chats / notes</p>
              <Button
                loading={isLoadingAPIKey || isLoadAPIKeyTrigger}
                onClick={() => setIsLoadAPIKeyTrigger(true)}
                className='py-1 !bg-blue-700 !text-white mt-4'
              >
                <p>Get & Save Locally</p>
              </Button>
              <div className='mt-3'>
                <p onClick={() => setIsLoadAPIKey(false)} className='underline text-sm cursor-pointer'>No, I use cloud all the time</p>
              </div>
            </div>
          </div>
        </div>
      </PopupWrapper>
      {/** //~LOADING  */}
      <PopupWrapper className='z-[100009]' containerClassName="z-[100009]" onClose={() => { }} noClose show={isLoadingSession || (isLoadingUser && !!session?.user)}>
        <div>
          <AiOutlineLoading className='animate-spin text-2xl text-blue-700' />
        </div>
      </PopupWrapper>
      {/** //~SYNC  */}
      <PopupWrapper className='!bg-transparent' containerClassName="z-[100001]" noClose onClose={() => null} show={!!session && !isLoadingSession && !!user?.id && !user?.isSynced}>
        <div className='max-w-full w-[400px] max-h-full h-[400px] text-white p-2'>
          <div className='bg-white text-gray-800 rounded p-5'>
            <h1 className='text-2xl font-bold text-center'>{/* Sync */}Add API Key</h1>
            <p className='text-center'>Add your API Key or save to cloud</p>
            <div className='flex flex-col items-center justify-center mt-2 gap-4'>
              <AccountSyncPopup />
            </div>
          </div>
          <div className="z-[100001]" />
        </div>
      </PopupWrapper>
    </>
  )
})

LayoutInit.displayName = 'LayoutInit'
