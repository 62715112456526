import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useEffect, useMemo } from 'react';
import { PlanCard, SectionSettingsWrapper, Button, toast } from "@acme/ui";
import { type Session } from "next-auth";
import { useAtomValue, useSetAtom } from "jotai";
import { teamActiveAtom, upgradeModalAtom } from "../../store/user";
import axios from "axios";
import { useRouter } from "next/router";
import { api } from "@acme/client";
import { dayjs, defaultPlans, type SubscriptionPlan } from "@acme/util";
import { useUserSubscription } from "../../hooks/useSubscription";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);

export async function subscribe(planId: string, teamId: string) {
  const { data } = await axios.post<{ sessionId: string }>("/api/stripe/create-checkout-session", {
    planId,
    teamId
  });

  const stripe = await stripePromise;
  if (stripe) {
    await stripe.redirectToCheckout({ sessionId: data.sessionId });
  }
}

const plans = defaultPlans

export const Plans: React.FC<{ session?: Session | null }> = ({ session }) => {
  const teamActive = useAtomValue(teamActiveAtom)
  const router = useRouter();
  const [isSubLoading, setIsSubLoading] = useState<null | string>(null)
  const [isManageLoading, setIsManageLoading] = useState(false)

  const { subscription, isLoading: isLoadingSub, onRefetch: onRefetchSub } = useUserSubscription()

  const handleCheckout = async (planId: string) => {
    if (session) {
      try {
        const plan = plans.find(plan => plan.id === planId)
        setIsSubLoading(planId || null)
        // Trigger the Stripe Checkout process here
        console.log("Initiating checkout for plan:", planId);
        setTimeout(() => {
          setIsSubLoading(null)
        }, 5000);
        await subscribe(planId, teamActive?.id || '');
        setIsSubLoading(null)
      } catch (error) {
        console.error("Error during checkout:", error);
        toast.error('Error during checkout')
        setIsSubLoading(null)
      }
    } else {
      toast.error('Please login to upgrade your account')
    }
  }

  const loadPortal = async () => {
    try {
      setIsManageLoading(true)
      const { data } = await axios.get("/api/stripe/portal");
      router.push(data.url);
      setIsManageLoading(false)
    } catch (error) {
      setIsManageLoading(false)
      toast.error('Error opens subscription portal')
    }
  };

  const { planName, endDate, created } = useMemo(() => {
    if (subscription) {
      return {
        planName: subscription?.planPurchase?.plan?.name,
        endDate: dayjs(subscription?.endDate).format('DD MMM YYYY'),
        created: dayjs(subscription?.created).format('DD MMM YYYY')
      }
    }
    return { planName: '', endDate: '', created: '' }
  }, [subscription])

  return (

    <div className='flex flex-col gap-5'>
      <SectionSettingsWrapper title='Current Plan'>
        {subscription ? (
          <div className="flex flex-col gap-2 mb-5">
            <h2 className="text-lg font-semibold mb-4">Current Plan</h2>
            <p className="">
              <strong>Plan Name:</strong> {planName}
            </p>
            {planName !== 'Lifetime' && (
              <p className="">
                <strong>End Date:</strong> {endDate}
              </p>
            )}
            <p className="">
              <strong>Created:</strong> {created}
            </p>
          </div>
        ) : (
          <>
            <h2 className="text-lg font-semibold mb-4">You Currently have no Plan</h2>
            <Button loading={isLoadingSub} className="!py-2 text-sm bg-blue-500 mb-4" onClick={onRefetchSub}>Refresh</Button>
          </>
        )}

        {planName === 'Lifetime' ? (
          <>
            <p className="">Lifetime Self-Serve add-on subscription is currently in progress</p>
            <p className="">Contact us to <a href="mailto:hi@dappergpt.com" className="underline">hi@dappergpt.com</a> for more info</p>
          </>
        ) : (
          <Button
            loading={isManageLoading}
            className="!py-2 text-sm bg-gray-500" onClick={() =>/*  setUpgradeModal({ isOpen: true }) */ loadPortal()}
          >
            Manage subscription
          </Button>
        )}
      </SectionSettingsWrapper>

      {
        !planName && (
          <SectionSettingsWrapper title='Plans'>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
              {plans.map((plan, index) => (
                <PlanCard
                  key={`${plan.id}${index}`}
                  plan={plan}
                  isLoading={isSubLoading === plan.id}
                  onCheckout={handleCheckout}
                />
              ))}
            </div>
          </SectionSettingsWrapper>
        )
      }
    </div >
  );
};

