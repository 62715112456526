import { BiTrash, Button, PopupWrapperAnimated, TextInputLabelForm } from "@acme/ui"
import { composeContextImgsAtom } from "../store/util";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from "@acme/util";
import { sessionDefaultModelAtom } from "../chat";
import { modelPopupAtom } from "../store/instruction";

{/** UNUSED */ }
export const ComposeVisualPopup = () => {
    const [popup, setPopup] = useAtom(modelPopupAtom);
    return null
    if (popup?.open && popup?.type === 'attach-file') {
        return <ComposeVisualPopupContent />
    }
    return null
}

export const ComposeVisualPopupContent = () => {
    const [inputImgsValue, setInputImgsValue] = useAtom(composeContextImgsAtom);
    const [imgsDebounced, setImgsDebounced] = useState<string[]>([]);
    const [popup, setPopup] = useAtom(modelPopupAtom);
    const sessionModel = useAtomValue(sessionDefaultModelAtom)

    const onRemove = (index: number) => {
        if (inputImgsValue.length === 1) {
            setInputImgsValue([{ url: '' }])
            return;
        }
        setInputImgsValue((prev) => {
            const newImgs = [...prev];
            newImgs.splice(index, 1);
            return newImgs;
        })
    }

    const onUpdate = (index: number, value: string) => {
        setInputImgsValue((prev) => {
            const newImgs = [...prev];
            newImgs[index] = { url: value };
            return newImgs;
        })
    }

    const onAdd = () => {
        setInputImgsValue((prev) => {
            const newImgs = [...prev];
            newImgs.push({ url: '' });
            return newImgs;
        })
    }

    const onSetImgsDebounced = useCallback(debounce((value: typeof inputImgsValue) => {
        setImgsDebounced(value.map((img) => img.url));
    }, 1000), [])

    useEffect(() => {
        onSetImgsDebounced(inputImgsValue);
    }, [inputImgsValue, onSetImgsDebounced])

    return (
        <PopupWrapperAnimated className="!bg-white !p-0" onClose={() => setPopup({ open: false, type: '' })} show={popup?.open}>
            <div className='max-w-full w-screen lg:w-[600px] max-h-full h-[auto] text-gray-700 px-8 py-4'>
                <div className='rounded'>
                    <h1 className='text-xl font-bold text-left'>Attach Image(s)</h1>
                    <div className='flex flex-col items-start justify-center my-5 mb-10 gap-4'>
                        {inputImgsValue.map((img, index) => (
                            <div className="flex flex-row items-center justify-between gap-3 w-full" key={index}>
                                <div className="relative w-10 h-10">
                                    <img
                                        src={imgsDebounced[index] || img.url}
                                        className="img-visual-preview w-10 h-10 rounded-md"
                                        alt="preview"
                                    />
                                </div>
                                <TextInputLabelForm
                                    id="name"
                                    label=""
                                    placeholder="Enter Image URL..."
                                    inputClassName="text-xs"
                                    size="sm"
                                    value={img.url}
                                    onChange={(e) => onUpdate(index, e)}
                                    skipDarkClass
                                />
                                <button>
                                    <BiTrash
                                        onClick={() => onRemove(index)}
                                        className="text-red-700 w-5 h-5" />
                                </button>
                            </div>
                        ))}
                        <Button
                            size="sm"
                            onClick={onAdd}
                            className="bg-gray-400 text-sm ml-auto"
                        >
                            Add +
                        </Button>
                    </div>
                    <Button
                        size="sm"
                        className="w-full"
                        onClick={() => setPopup({ open: false, type: '' })}
                    >
                        Done
                    </Button>
                </div>
            </div>
        </PopupWrapperAnimated>
    )
}
