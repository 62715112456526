import "../styles/globals.css";
import type { AppType } from "next/app";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { api } from "@acme/client";
import { Toaster } from "@acme/ui";
import '../styles/yopta.css';
import "@blocknote/core/style.css";
import { LayoutAll } from "@acme/feature";
import { useEffect } from "react";

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {

  useEffect(() => {
    // @ts-ignore
    import('preline')
  }, [])

  return (
    <>
      <SessionProvider session={session}>
        <Toaster
          containerStyle={{
            zIndex: 9999999999,
          }}
          toastOptions={{
            style: {
              borderRadius: '10px',
              zIndex: 9999999999,
            },
          }}
        />
        <LayoutAll>
          <Component {...pageProps} />
        </LayoutAll>
      </SessionProvider>
    </>
  );
};

export default api.withTRPC(MyApp);
