import React, { ReactNode } from "react"

export const ConditionalRenderByTypes = React.memo(({ disallowed = [], allowed = [], type = '', children }: { disallowed?: string[], allowed: string[] | 'all', type: string | undefined, children: ReactNode }) => {
  if (disallowed.includes(type)) return null
  if (allowed.includes(type) || allowed === 'all') {
    return <>{children}</>
  }
  return null
})

ConditionalRenderByTypes.displayName = 'ConditionalRenderByTypes'