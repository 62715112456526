import { AiOutlineLoading, BsChevronDown, BsChevronUp, FaSearch } from "@acme/ui";
import { type ChatSession, type Note, debounce, type ChatMessage } from "@acme/util";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { chatSessionActiveIdAtom, chatSessionsAtom } from "../store/chat";
import { noteActiveIdAtom, notesAtom } from "../store/note";
import { useRouter } from "next/router";
import { isSearchAtom, searchHistoryAtom } from "../store/util";

type Page = { id: string, name: string, path: string, keyword: string }

const pages: Page[] = [
  { id: 'chat', name: 'chat', path: '/chat', keyword: 'chat, gpt' },
  { id: 'note', name: 'note', path: '/note', keyword: 'notes, text editor, reminder' },
  { id: 'extension', name: 'extension', path: '/extension', keyword: 'extension, chrome, firefox, safari, opera, edge, ie, browser' },
  { id: 'settings', name: 'settings', path: '/settings', keyword: 'settings, account, profile, team, billing, subscription, payment, plan, upgrade, downgrade, cancel' },
];
export type SearchResult = { name: string, type: 'page' | 'note' | 'chat', keywordFound: string, id: string, contentId?: string }[]

/* function search(currentPage: 'chat' | 'note', combinedList: (ChatSession | Note | Page)[], searchTerm: string): { name: string, type: 'page' | 'note' | 'chat', keyword: string }[] {
  const results: { name: string, type: 'page' | 'note' | 'chat', keyword: string }[] = [];

  const chatSessions: ChatSession[] = [];
  const notes: Note[] = [];
  const pages: Page[] = [];

  // separate the items into different arrays by type
  for (const item of combinedList) {
    if (item.hasOwnProperty('message')) {
      chatSessions.push(item as ChatSession);
    } else if (item.hasOwnProperty('markdown')) {
      notes.push(item as Note);
    } else {
      pages.push(item as Page);
    }
  }

  // search chat sessions if current page is chat, otherwise search notes first
  const searchOrder = currentPage === 'chat' ? [chatSessions, notes, pages] : [notes, chatSessions, pages];

  for (const typeArr of searchOrder) {
    for (const item of typeArr) {
      // @ts-ignore
      const matchName = item.name.toLowerCase().includes(searchTerm.toLowerCase());

      let matchKeyword = false;
      if ('keyword' in item) {
        // @ts-ignore
        matchKeyword = item.keyword.toLowerCase().includes(searchTerm.toLowerCase());
      }

      let matchContent = false;
      if ('messages' in item) {
        // @ts-ignore
        for (const message of item.messages) {
          const messageContent: string = Array.isArray(message?.content?.content)
            ? message.content.content.map((c) => c).join(' ')
            : message.content?.content || '';
          const content = messageContent?.toLowerCase() || '';
          // @ts-ignore
          if (content.includes(searchTerm.toLowerCase())) {
            matchContent = true;
            break;
          }
        }
      } else if ('markdown' in item) {
        const markdown = item.markdown?.toLowerCase() || '';
        // @ts-ignore
        if (markdown.includes(searchTerm.toLowerCase())) {
          matchContent = true;
        }
      }

      if (matchName || matchContent || matchKeyword) {
        results.push({
          name: item.name,
          type: 'messages' in item ? 'chat' : 'markdown' in item ? 'note' : 'page',
          keyword: 'keyword' in item ? item.keyword : '',
        });

        if (results.length === 5) {
          return results;
        }
      }
    }
  }

  return results;
} */

/* function search(currentPage: 'chat' | 'note', combinedList: (ChatSession | Note | Page)[], keyword: string): { name: string, type: 'page' | 'note' | 'chat', keyword: string, id?: string }[] {
  const result: { name: string, type: 'page' | 'note' | 'chat', keyword: string, id?: string }[] = [];

  const maxResults = 5;
  let numResults = 0;

  // search chat messages first, if currentPage is 'chat' or 'note'
  if (currentPage === 'chat' || currentPage === 'note') {
    for (const item of combinedList) {
      if ('messages' in item) {
        for (const msg of item.messages) {
          if (numResults >= maxResults) {
            break;
          }

          const msgContentParsed = Array.isArray(msg.content.content) ? msg.content.content.join(' ') : msg.content.content;
          const msgContent = `${msgContentParsed || ''}`.toLowerCase();
          const keywordIndex = msgContent.indexOf(keyword.toLowerCase());

          if (keywordIndex !== -1) {
            const id = ('content' in item) ? item?.content[0].id : undefined;

            result.push({ name: item.name, type: 'chat', keyword: keyword, id: id });
            numResults++;
          }
        }
      }
    }
  }

  // search notes next, if currentPage is 'note'
  if (currentPage === 'note') {
    for (const item of combinedList) {
      if ('content' in item) {
        for (const note of item.content) {
          if (numResults >= maxResults) {
            break;
          }

          const noteContent = note.content.map(c => c.text).join(' ').toLowerCase();
          const keywordIndex = noteContent.indexOf(keyword.toLowerCase());

          if (keywordIndex !== -1) {
            result.push({ name: item.name, type: 'note', keyword: keyword, id: note.id });
            numResults++;
          }
        }
      }
    }
  }

  // search pages last
  for (const item of combinedList) {
    if (numResults >= maxResults) {
      break;
    }

    const nameIndex = item.name.toLowerCase().indexOf(keyword.toLowerCase());

    if (nameIndex !== -1) {
      result.push({ name: item.name, type: 'page', keyword: keyword });
      numResults++;
    }
  }

  return result;
} */


function search(currentPage: 'chat' | 'note', combinedList: (ChatSession | Note | Page)[], query: string): SearchResult {
  const results: SearchResult = []

  // Sort the combinedList by the priority of the types and properties
  combinedList.sort((a, b) => {
    const aNameStartsWithQuery = a.name.toLowerCase().startsWith(query.toLowerCase());
    const bNameStartsWithQuery = b.name.toLowerCase().startsWith(query.toLowerCase());

    if (aNameStartsWithQuery && bNameStartsWithQuery) {
      return 0;
    } else if (aNameStartsWithQuery) {
      return -1;
    } else if (bNameStartsWithQuery) {
      return 1;
    } else if (a.hasOwnProperty(currentPage) && !b.hasOwnProperty(currentPage)) {
      return -1;
    } else if (b.hasOwnProperty(currentPage) && !a.hasOwnProperty(currentPage)) {
      return 1;
    } else {
      return 0;
    }
  })

  for (const item of combinedList) {
    // Check if the name or content of the item matches the query
    const nameMatch = item.name.toLowerCase().includes(query.toLowerCase())
    let contentMatch = false
    let contentId: string | undefined
    let keywordFound = ''

    if (item.hasOwnProperty('messages')) {
      // If it's a chat session, search the messages content
      // @ts-ignore
      for (const msg1 of item.messages!) {
        const msg = msg1 as ChatMessage
        const messageContent: string = Array.isArray(msg?.content?.content)
          ? msg.content.content.join(' ')
          : msg.content?.content || '';
        contentMatch = messageContent.toLowerCase().includes(query.toLowerCase())

        if (contentMatch) {
          const beforeIndex = messageContent.toLowerCase().indexOf(query.toLowerCase());
          const afterIndex = beforeIndex + query.length;
          const before = beforeIndex > 0 ? '...' : '';
          const after = afterIndex < messageContent.length ? '...' : '';
          keywordFound = before + messageContent.slice(beforeIndex, afterIndex) + after;
          contentId = msg.id
          break
        }
      }
    } else if (item.hasOwnProperty('content')) {
      // If it's a note, search the note content
      // @ts-ignore
      for (const nt of item.content!) {
        const note = nt as Note
        contentMatch = note.content.some(text => {
          // @ts-ignore
          const noteContent = text?.content?.map(a => a.text || '').join(' ') || text?.text || ''
          if (noteContent) {
            const isInclude = noteContent.toLowerCase().includes(query.toLowerCase())
            if (isInclude) {
              const beforeIndex = noteContent.toLowerCase().indexOf(query.toLowerCase());
              const afterIndex = beforeIndex + query.length;
              const before = beforeIndex > 0 ? '...' : '';
              const after = afterIndex < noteContent.length ? '...' : '';
              keywordFound = before + noteContent.slice(beforeIndex, afterIndex) + after;
              return isInclude
            }
          }
          return false
        })

        if (contentMatch) {
          contentId = note.id
          break
        }
      }
    } else {
      // If it's a page, search the keyword
      const keyword = (item as Page).keyword || ''
      contentMatch = keyword.toLowerCase().includes(query.toLowerCase())

      if (contentMatch) {
        keywordFound = keyword
      }
    }
    if (nameMatch || contentMatch) {
      results.push({
        name: item.name,
        type: item.hasOwnProperty('messages') ? 'chat' : item.hasOwnProperty('content') ? 'note' : 'page',
        keywordFound,
        id: item.id,
        contentId
      })

      if (results.length === 5) {
        break
      }
    }
  }

  return results
}

export const PopupSearch = () => {
  const isSearch = useAtomValue(isSearchAtom)

  if (!isSearch) {
    return null
  }
  return (
    <PopupSearchComponent />
  )
}


export function PopupSearchComponent() {
  const router = useRouter()
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const chatSessions = useAtomValue(chatSessionsAtom);
  const notes = useAtomValue(notesAtom);
  const [results, setResults] = useState<{
    name: string;
    type: "note" | "chat" | "page";
    keywordFound: string;
    id: string
    contentId?: string
  }[]>([]);
  const setIsSearch = useSetAtom(isSearchAtom);
  const [searchHistoryPlain, setSearchHistory] = useAtom(searchHistoryAtom)
  const chatSessionActiveId = useAtomValue(chatSessionActiveIdAtom)
  const noteActiveId = useAtomValue(noteActiveIdAtom)

  const searchHistory = useMemo(() => {
    const pathname = router.pathname
    const currentPage = pathname === '/chat' ? 'chat'
      : pathname === '/note' ? 'note'
        : pathname === '/extension' ? 'extension'
          : pathname === '/settings' ? 'settings'
            : ''

    return searchHistoryPlain.filter(item => {
      if (currentPage === 'chat') {
        if (chatSessionActiveId === item.id) return false
        if (item?.type === 'page' && item?.name === 'chat') return false
      } else if (currentPage === 'note') {
        if (noteActiveId === item.id) return false
        if (item?.type === 'page' && item?.name === 'note') return false
      } else if (currentPage === 'extension') {
        if (item?.type === 'page' && item?.name === 'extension') return false
      } else if (currentPage === 'settings') {
        if (item?.type === 'page' && item?.name === 'settings') return false
      }
      return true
    })
  }, [searchHistoryPlain, chatSessionActiveId, noteActiveId, router.pathname])

  const currentPage = useMemo(() => {
    if (router.pathname === '/chat') return 'chat';
    if (router.pathname === '/note') return 'note';
    return 'chat'
  }, [router.pathname])

  const handleSearch = useCallback(async (keyword: string) => {
    const combinedData = [...pages, ...chatSessions, ...notes]
    const results = search(currentPage, combinedData, keyword);

    if (results.length > 0 && selectedIndex >= results.length) {
      setSelectedIndex(results.length - 1);
    } else if (results.length === 0) {
      setSelectedIndex(0);
    }

    setResults(results);
    setIsLoading(false);
  }, [chatSessions, notes, currentPage, selectedIndex]);

  const onSearchDebounce = useCallback(
    debounce((keyword: string) => {
      handleSearch(keyword);
    }, 500),
    [])

  useEffect(() => {
    if (query.length > 0) {
      setIsLoading(true);
      onSearchDebounce(query);
    }
  }, [query]);

  const onClick = useCallback((item: SearchResult[number]) => {
    setSearchHistory(prev => {
      // if id exist then push to top if not exist then push to top
      const findIndex = (prev || []).findIndex(a => a.id === item.id)
      if (findIndex > -1) {
        // prev.splice(findIndex, 1)
        // remove without splice
        [...prev].splice(findIndex, 1)
      }
      return [item, ...prev]
    })
    if (item.type === 'chat') {
      router.push(`/chat?chatId=${item.id}&messageId=${item.contentId}`)
    } else if (item.type === 'note') {
      router.push(`/note?noteId=${item.id}&contentId=${item.contentId}`)
    } else {
      const findPage = pages.find(page => page.id === item.id)
      if (findPage) router.push(findPage.path)
    }
    setIsSearch(false)
  }, [router, setIsSearch])

  const onClose = useCallback(() => {
    setIsSearch(false)
  }, [setIsSearch])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      e.preventDefault();
      onClose();
      return;
    }
    const len = !!query ? results.length : searchHistory.length
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, len - 1));
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === "Enter" && selectedIndex >= 0 && selectedIndex < len) {
      e.preventDefault();
      const result = !!query
        ? results[selectedIndex] satisfies SearchResult[number] | undefined
        : searchHistory[selectedIndex] satisfies SearchResult[number] | undefined
      if (result) {
        onClick(result)
      }
    }
  }, [query, results, searchHistory, selectedIndex, onClose, onClick]);

  useEffect(() => {
    // set focus 
    const input = document.getElementById('search-input')
    if (input) {
      input.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown])

  return (
    <>
      <div className="fixed z-[10001] inset-0 overflow-y-auto">
        <div className="block items-start justify-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" onClick={onClose}>
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          <div
            onClick={e => e.stopPropagation()}
            className="lg:inline-block align-bottom bg-gray-50 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex flex-col items-start">

                <div className="w-full">
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FaSearch className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search-input"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block border w-full pl-10 p-3 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Search..."
                      value={query}
                      autoComplete="off"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div className="text-left pt-3 w-full">
                  <div className="flex flex-row gap-3">
                    {isLoading && <AiOutlineLoading className="animate-spin h-5 w-5 text-gray-400" />}
                    {results.length > 0 && (
                      <h3
                        className="text-base leading-6 font-medium text-gray-900"
                        id="modal-headline"
                      >
                        Results...
                      </h3>
                    )}
                  </div>
                  <div className="mt-2 flex flex-col gap-1">
                    {(results.length > 0 && !!query) ? (
                      results.map((result, index) => (
                        <ResultCard
                          key={`${result.name}-${index}`}
                          result={result}
                          index={index}
                          selectedIndex={selectedIndex}
                          onClick={onClick}
                        />
                      ))
                    ) : (searchHistory.length > 0 && !query) ? (
                      <>
                        {searchHistory.map((result, index) => (
                          <ResultCard
                            key={`${result.name}-${index}`}
                            result={result}
                            index={index}
                            selectedIndex={selectedIndex}
                            onClick={onClick}
                          />
                        ))}
                      </>
                    ) : query ? (
                      <>
                        <p className="text-gray-500 mt-2">No results found 😔</p>
                      </>
                    ) : (
                      <>
                        <p className="text-gray-500 mt-2">Type something</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm transition-all duration-200"
                onClick={onClose}
              >
                Close
              </button>

              <div className="hidden lg:flex w-full flex-row justify-start items-center gap-5 p-1 text-right text-xs">
                <p className="text-neutral-500">ESC to Close</p>
                <p className="flex flex-row items-center gap-1 text-neutral-500">
                  <BsChevronUp />
                  <BsChevronDown />
                  to Navigate
                </p>
                <p className="text-neutral-500">Enter to Select</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
      .bg-primary {
        background-color: #4b5563;
      }
      
      .text-primary {
        color: #4b5563;
      }
      
      .border-primary {
        border-color: #4b5563;
      }
      
      .focus-primary {
        box-shadow: 0 0 0 3px rgba(75, 85, 99, 0.45);
      }
      `}</style>
    </>
  );
}

const ResultCard = ({ result, index, selectedIndex, onClick }: { result: SearchResult[number], index: number, selectedIndex: number, onClick: (s: SearchResult[number]) => void }) => {
  const { id, name, contentId, type, keywordFound } = result
  return (
    <div
      className={`px-4 py-2 flex flex-row gap-3 items-center rounded-lg shadow-sm ${selectedIndex === index ? "bg-blue-200" : "bg-white"
        } hover:bg-blue-100 cursor-pointer`}
      onClick={() => onClick(result)}
    >
      <div className="text-xl">
        {type === 'page'
          ? <>🔗</>
          : type === 'chat'
            ? <>💬</>
            : <>📝</>
        }
      </div>
      <div>
        <p className="text-[10px] font-light text-neutral-500">
          {type}
        </p>
        <h4 className="text-sm font-medium text-gray-900">
          {name}
        </h4>
        <p className="text-gray-500 mt-1 text-xs">
          {type === 'page' ? '' : keywordFound}
        </p>
      </div>
    </div>
  )
}
