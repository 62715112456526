import { toast } from '@acme/ui';
import { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export const speechLanguages = [
    { "name": "Afrikaans", "code": "af-ZA" },
    { "name": "Bahasa Indonesia", "code": "id-ID" },
    { "name": "Bahasa Melayu", "code": "ms-MY" },
    { "name": "Català", "code": "ca-ES" },
    { "name": "Čeština", "code": "cs-CZ" },
    { "name": "Deutsch", "code": "de-DE" },
    { "name": "English (Australia)", "code": "en-AU" },
    { "name": "English (Canada)", "code": "en-CA" },
    { "name": "English (India)", "code": "en-IN" },
    { "name": "English (New Zealand)", "code": "en-NZ" },
    { "name": "English (South Africa)", "code": "en-ZA" },
    { "name": "English (United Kingdom)", "code": "en-GB" },
    { "name": "English (United States)", "code": "en-US" },
    { "name": "Español (Argentina)", "code": "es-AR" },
    { "name": "Español (Bolivia)", "code": "es-BO" },
    { "name": "Español (Chile)", "code": "es-CL" },
    { "name": "Español (Colombia)", "code": "es-CO" },
    { "name": "Español (Costa Rica)", "code": "es-CR" },
    { "name": "Español (Ecuador)", "code": "es-EC" },
    { "name": "Español (El Salvador)", "code": "es-SV" },
    { "name": "Español (España)", "code": "es-ES" },
    { "name": "Español (Estados Unidos)", "code": "es-US" },
    { "name": "Español (Guatemala)", "code": "es-GT" },
    { "name": "Español (Honduras)", "code": "es-HN" },
    { "name": "Español (México)", "code": "es-MX" },
    { "name": "Español (Nicaragua)", "code": "es-NI" },
    { "name": "Español (Panamá)", "code": "es-PA" },
    { "name": "Español (Paraguay)", "code": "es-PY" },
    { "name": "Español (Perú)", "code": "es-PE" },
    { "name": "Español (Puerto Rico)", "code": "es-PR" },
    { "name": "Español (República Dominicana)", "code": "es-DO" },
    { "name": "Español (Uruguay)", "code": "es-UY" },
    { "name": "Español (Venezuela)", "code": "es-VE" },
    { "name": "Euskara", "code": "eu-ES" },
    { "name": "Français", "code": "fr-FR" },
    { "name": "Galego", "code": "gl-ES" },
    { "name": "Hrvatski", "code": "hr-HR" },
    { "name": "IsiZulu", "code": "zu-ZA" },
    { "name": "Íslenska", "code": "is-IS" },
    { "name": "Italiano (Italia)", "code": "it-IT" },
    { "name": "Italiano (Svizzera)", "code": "it-CH" },
    { "name": "Magyar", "code": "hu-HU" },
    { "name": "Nederlands", "code": "nl-NL" },
    { "name": "Norsk bokmål", "code": "nb-NO" },
    { "name": "Polski", "code": "pl-PL" },
    { "name": "Português (Brasil)", "code": "pt-BR" },
    { "name": "Português (Portugal)", "code": "pt-PT" },
    { "name": "Română", "code": "ro-RO" },
    { "name": "Slovenčina", "code": "sk-SK" },
    { "name": "Suomi", "code": "fi-FI" },
    { "name": "Svenska", "code": "sv-SE" },
    { "name": "Türkçe", "code": "tr-TR" },
    { "name": "български", "code": "bg-BG" },
    { "name": "Pусский", "code": "ru-RU" },
    { "name": "Српски", "code": "sr-RS" },
    { "name": "한국어", "code": "ko-KR" },
    { "name": "中文 (普通话)", "code": "cmn-Hans-CN" },
    { "name": "中文 (香港普通话)", "code": "cmn-Hans-HK" },
    { "name": "中文 (台灣)", "code": "cmn-Hant-TW" },
    { "name": "粵語 (香港)", "code": "yue-Hant-HK" },
    { "name": "日本語", "code": "ja-JP" },
    { "name": "Lingua latīna", "code": "la" }
] as const

export const speechLanguagesOptions = speechLanguages.map(({ name, code }) => ({ label: `${name} - ${code}`, value: code }));

export type SpeechLanguageCode = typeof speechLanguages[number]['code'];

export const useSpeechText = ({ lang }: { lang?: SpeechLanguageCode }) => {
    const [isRecording, setIsRecording] = useState(false);
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition({});

    useEffect(() => {
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            toast.error("Your browser does not support speech recognition.");
        }
    }, []);

    const startRecording = () => {
        setIsRecording(true);
        SpeechRecognition.startListening({ continuous: true, ...lang ? { language: lang } : {} });
    };

    const stopRecording = () => {
        setIsRecording(false);
        SpeechRecognition.stopListening();
    };

    const reset = () => {
        setIsRecording(false);
        resetTranscript();
    };

    return {
        isRecording,
        isSupported: browserSupportsSpeechRecognition,
        transcription: transcript,
        startRecording,
        stopRecording,
        reset
    };
};
