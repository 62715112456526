import React, { useState, type ReactNode } from "react"
import { BiMoney, BiTask, BsGear, BsKey, BsPerson, Button, ConditionalRenderWithElse, FaSignOutAlt } from "@acme/ui";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Account } from "./SettingsSection/Account";
import { Preference } from "./SettingsSection/Preference";
import { Plans } from "./SettingsSection/Plans";
import { api } from "@acme/client";
import { signIn, signOut } from "next-auth/react";
import { EXTENSION_ID } from "@acme/util";
import { useSetAtom } from "jotai";
import { deleteNonSyncAtom } from "../store/setting";
import { RoadmapSection } from "./SettingsSection/Roadmap";
import { Profile } from "./SettingsSection/Profile";

const menus = [
  {
    title: 'Preference',
    path: 'preference',
    icon: <BsGear className="inline mr-2" />,
  }, {
    title: 'User',
    path: 'user',
    icon: <BsPerson className="inline mr-2" />,
  }, {
    title: 'Plan',
    path: 'plan',
    icon: <BiMoney className="inline mr-2" />,
  }, {
    title: 'API',
    path: 'api',
    icon: <BsKey className="inline mr-2" />,
  }, {
    title: 'Roadmap & Support',
    path: 'roadmap',
    icon: <BiTask className="inline mr-2" />,
  }
]


export const SettingsPage = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const { data: session } = api.auth.getSession.useQuery();
  const { data: secretMessage } = api.auth.getSecretMessage.useQuery(
    undefined, // no input
    { enabled: !!session?.user },
  );
  const onDeleteData = useSetAtom(deleteNonSyncAtom)

  const router = useRouter();

  const activeMenu = useMemo(() => {
    const { menu } = router.query;
    return menu || 'api';
  }, [router.query]);

  const MenuItem = ({ title, active, icon, path, isDisabled }: { isDisabled?: boolean, title: string; active: boolean, icon: ReactNode, path: string }) => (
    <button
      className={`w-full flex flex-row items-center gap-3 p-2 px-4 rounded text-left ${isDisabled ? 'opacity-60' : 'hover:bg-gray-100 dark:hover:bg-neutral-700'} ${active ? 'bg-slate-100 text-gray-800 dark:bg-neutral-600 dark:border-neutral-500 dark:text-gray-100 border font-bold' : ''
        }`}
      onClick={() => !!isDisabled ? null : router.push(`/settings?menu=${path}`)}
    >
      {icon}
      <p>{title}</p>
    </button>
  );

  const menusFixed = useMemo(() => {
    return menus.map(menu => {
      return {
        ...menu,
        ...menu.title !== 'API' && { isDisabled: false } //!session }
      }
    })
  }, [])

  const onSignOut = async () => {
    try {
      if (chrome.runtime && chrome?.runtime?.sendMessage) {
        const extensionId = EXTENSION_ID
        chrome.runtime.sendMessage(
          extensionId,
          { type: 'set-signout' },
          (response) => { }
        );
      }
      setShowDeleteModal(false)
      setIsDeleteConfirm(true)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onDeleteDataSync = async (removeNonSync?: boolean) => {
    setIsLoading(true)
    onDeleteData({ removeNonSync })
    await signOut()
    setIsLoading(false)
    setIsDeleteConfirm(false)
    router.reload()
  }

  return (
    <div className="bg-gray-50 dark:bg-neutral-600 min-h-screen overflow-y-scroll">
      <div className="container mx-auto py-6">
        {/*  <h1 className="text-2xl font-bold mb-4 ml-1">Account Settings</h1> */}
        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-10 md:col-span-3 h-full">
            <div className="border border-gray-200 bg-white dark:bg-[#0B141A] dark:border-neutral-700 dark:text-white rounded-lg p-3 flex flex-col gap-2">
              {menusFixed.map(menu => (
                <MenuItem
                  key={menu.path}
                  title={menu.title}
                  icon={menu.icon}
                  path={menu.path}
                  active={activeMenu === menu.path}
                  isDisabled={!!menu?.isDisabled}
                />
              ))}
              {session?.user && (
                <p className="text-center text-sm mb-2 text-gray-400 mt-4 border-t border-gray-200 pt-4">
                  {session && <span>Logged in as {session?.user?.name}</span>}
                </p>
              )}
            </div>
          </div>
          <div className="col-span-10 md:col-span-7">
            <div className="">
              <ConditionalRenderWithElse
                show={(activeMenu === 'user' || activeMenu === 'plan') && !session}
                elseChildren={
                  <>
                    {activeMenu === 'user' && (
                      <>
                        <Profile session={session} />
                        <Button
                          loading={isLoading}
                          className={`w-full lg:w-[300px] ml-auto h-10 text-sm mt-4 p-4 border ${!session ? '!bg-[#2563eb] !text-white' : 'border-red-500 !bg-red-400 !text-white hover:bg-red-600 '} rounded-md`}
                          onClick={() => setShowDeleteModal(true)}
                        >
                          <>
                            <FaSignOutAlt className="inline mr-2" />
                            <p>Sign Out</p>
                          </>
                        </Button>
                      </>
                    )}
                    {activeMenu === 'plan' && <Plans session={session} />}
                  </>
                }
              >
                <Button
                  className={`w-full h-10 text-sm mt-4 p-4 border ${!session ? '!bg-[#2563eb] !text-gray-100' : 'border-red-500 !text-red-500 hover:bg-red-600 '} rounded-md`}
                  onClick={session ? () => setShowDeleteModal(true) : () => void signIn()}
                >
                  {!session ? (
                    <>
                      <FaSignOutAlt className="inline mr-2" />
                      <p>Sign In</p>
                    </>
                  ) : (
                    <>
                      <FaSignOutAlt className="inline mr-2" />
                      <p>Sign Out</p>
                    </>
                  )}
                </Button>
              </ConditionalRenderWithElse>
              {activeMenu === 'preference' && <Preference session={session} />}
              {activeMenu === 'api' && <Account />}
              {activeMenu === 'roadmap' && <RoadmapSection />}
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4">Signout?</h2>
            <p>Are you sure you want to signout?</p>
            <div className="flex justify-end space-x-4 mt-6">
              <Button
                className="bg-red-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-red-600 transition-all duration-200"
                loading={isLoading}
                onClick={() => onSignOut()}
              >
                <span>Yes</span>
              </Button>
              <Button
                className="bg-gray-300 !text-gray-700 flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-gray-400 transition-all duration-200"
                onClick={() => setShowDeleteModal(false)}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
      )}


      {isDeleteConfirm && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-[100007]">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4">Delete All Non Sync Chats and Notes?</h2>
            <p>You have several non-sync to cloud data like chat sessions, notes, etc.<br />Do you want to delete that also?</p>
            <div className="flex justify-end space-x-4 mt-6">
              <Button
                className="bg-red-500 text-white flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-red-600 transition-all duration-200"
                loading={isLoading}
                onClick={() => onDeleteDataSync(true)}
              >
                <span>Yes</span>
              </Button>
              <Button
                className="bg-gray-300 !text-gray-700 flex flex-row items-center gap-2 px-4 py-1 h-10 rounded hover:bg-gray-400 transition-all duration-200"
                onClick={() => onDeleteDataSync(false)}
              >
                <span>No</span>
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="!bg-red-500" />
    </div>
  );
};

export default SettingsPage;