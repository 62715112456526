import { type ReactNode } from "react"
import { BsGear, BsPlus } from "react-icons/bs"

export type InstructionCardProps = {
  title: string
  description: string
  className?: string
  titleClassName?: string
  color?: string
  icon?: React.ReactNode
  isDefault?: boolean

  onClick: () => void
  onUpdate?: () => void
}

export const InstructionCard = ({ title, titleClassName = '', isDefault = true, description, className = '', color, icon, onClick, onUpdate }: InstructionCardProps) => {
  return (
    <button
      style={{
        ...color ? { backgroundColor: color } : {}
      }}
      onClick={onClick}
      className={`bg-green-400 text-white rounded-lg p-5 shadow w-[250px] hover:shadow-lg group hover:opacity-80 relative ${className}`}
    >
      <div className="flex flex-row items-center gap-3 mb-2">
        {icon}
        <p className={`text-xl font-bold text-left ${titleClassName}`}>{title}</p>
      </div>
      <p className="text-left">
        {description}
      </p>
      {!isDefault && (
        <div className="absolute transition-all z-50 duration-300 right-3 top-3 bg-neutral-700 group-hover:opacity-70 hover:!opacity-100 group-hover:visible opacity-0 invisible p-2 rounded-xl">
          <BsGear
            onClick={(e) => {
              e.stopPropagation()
              onUpdate && onUpdate()
            }}
            className="text-white"
          />
        </div>
      )}
    </button>
  )
}

export const InstructionCardSmall = ({ title, titleClassName = '', isDefault = true, description, className = '', color, icon, onClick, onUpdate, extraNode }: InstructionCardProps & { extraNode?: ReactNode }) => {
  return (
    <button
      style={{
        ...color ? { backgroundColor: color } : {}
      }}
      onClick={onClick}
      className={`rebg-green-400 text-white flex flex-col items-center justify-center rounded-lg p-5 shadow w-[100px] hover:shadow-lg group hover:opacity-80 relative ${className}`}
    >
      {extraNode}
      <div className="flex flex-row items-center gap-3 mb-2">
        {icon}
      </div>
      <p className={`text-base font-semibold text-center ${titleClassName}`}>{title}</p>
      {!isDefault && (
        <div className="absolute transition-all z-50 duration-300 right-3 top-3 bg-neutral-700 group-hover:opacity-70 hover:!opacity-100 group-hover:visible opacity-0 invisible p-2 rounded-xl">
          <BsGear
            onClick={(e) => {
              e.stopPropagation()
              onUpdate && onUpdate()
            }}
            className="text-white"
          />
        </div>
      )}
    </button>
  )
}

export const InstructionCardNew = ({ onAdd }: { onAdd: () => void }) => {
  return (
    <button
      className={`bg-gray-200 text-white rounded-lg p-5 w-full  hover:shadow-lg hover:opacity-80`}
      onClick={onAdd}
    >
      <div className="flex flex-col items-center justify-center gap-1 mb-2">
        <BsPlus className="text-4xl text-gray-700" />
        <p className="text-2xl font-bold text-gray-700 text-center">
          Add New
        </p>
      </div>
    </button>
  )
}
