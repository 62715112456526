import React, { type FC } from 'react'
import { useDelayUnmount } from '../Hooks/useDelayUnmount'

interface IConditionalRender {
  show: boolean
  children?: React.ReactNode
  className?: string
  elseChildren?: React.ReactNode
  timeout?: number
}

const mountedStyle = { animation: "inAnimation 400ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 200ms ease-out",
  animationFillMode: "forwards"
};

export const ConditionalRenderFade: FC<IConditionalRender> = (props) => {
  const { show = false, className, children, timeout = 300 } = props
  const showDiv = useDelayUnmount(show, timeout);

  if (showDiv) {
    return (
      <div
        className={`h-auto w-auto ${className}`}
        style={show ? mountedStyle : unmountedStyle}
      >{children}</div>
    )
  }
  return <>{props?.elseChildren || null}</>
}

export default ConditionalRenderFade
