import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import { Button, BiTrash, BiX, BiRefresh, BiText, BsGear, AiOutlineLoading, BiSend, BsMicMuteFill, BsFillMicFill, PopupWrapper, SelectLabelForm, TextLabel, SwitchWithLabel, AiFillPlayCircle, Tooltip, AiFillStop, BiImageAlt, IoMdAttach } from '@acme/ui';
import { composeContextImgsAtom, composeContextAtom } from '../store/util';
import { modelPopupAtom } from '../store/instruction';
import { type SpeechLanguageCode, speechLanguagesOptions, useSpeechText } from '../hooks';
import { Range } from 'react-range';
import { useTextToSpeech, useTextToSpeechVoices } from '../hooks/useTextToSpeech';
import { type ChatQuickOption, chatQuickOptionsAtom, speechToTextSettingsAtom, textToSpeechSettingsAtom } from '../store/setting';
import { ComposeVisualPopup } from '../popup/ComposeVisualPopup';

type OnSendAction = 'write' | 'continue';

type OnSendFunction<T extends 'note' | 'chat'> = T extends 'note'
  ? ((action: OnSendAction) => Promise<void>)
  : (() => void);
type Props<T extends 'note' | 'chat'> = {
  onSend: OnSendFunction<T>;
  onRegenerate?: () => void;
  onCancelResponse: () => void;
  isLastMessageLoading?: boolean;
  isLoading?: boolean;
  type?: T;
  isAllowImage?: boolean;
};


export const ComposeMessage = memo(<T extends 'note' | 'chat'>({
  onSend,
  onRegenerate,
  onCancelResponse,
  isLastMessageLoading,
  isLoading,
  type,
  isAllowImage = false
}: Props<T>) => {
  //const { onSend, onRegenerate, isLoading, isLastMessageLoading, onCancelResponse, type } = props;
  const [inputValue, setInputValue] = useAtom(composeContextAtom);
  const [textSpeech, setTextSpeech] = useAtom(textToSpeechSettingsAtom)
  const [speechText, setSpeechText] = useAtom(speechToTextSettingsAtom)
  const [openSettingsMobile, setOpenSettingsMobile] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { isRecording, startRecording, stopRecording, transcription, isSupported, reset: resetRecording } = useSpeechText({ ...speechText.language ? { lang: speechText.language } : {} })
  const [textSpeechTest, setTextSpeechTest] = useState('')
  const { speak, stop, isSpeaking } = useTextToSpeech()
  const { voicesOptions } = useTextToSpeechVoices()
  const setModelPopup = useSetAtom(modelPopupAtom)

  const isInputStarted = useMemo(() => inputValue.length > 0, [inputValue.length])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const onSendFixed = () => {
    if (!inputValue) return;
    if (type === 'note') {
      onSend('continue')
    } else {
      (onSend as (() => void))();
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // if enter + shift then skip and not mobile
    if (e.keyCode === 13 && e.shiftKey) {
      return;
    } else if (e.keyCode === 13) {
      // if mobile then skip
      if (window.innerWidth <= 768) {
        return;
      }
      e.preventDefault();
      onSendFixed()
      // submit
    }
  };

  const onSpeak = () => {
    if (isSpeaking) {
      stop()
    } else {
      speak({
        text: textSpeechTest,
        voiceURI: textSpeech.voiceURI,
        pitch: textSpeech.pitch,
        rate: textSpeech.rate,
        // volume: textSpeech.volume,
      })
    }
  }

  useEffect(() => {
    if (transcription && isRecording) {
      setInputValue(prev => transcription)
    }
  }, [transcription, setInputValue, isRecording])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'inherit';
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = `${scrollHeight}px`;
    }
  }, [inputValue]);

  return (
    <>
      <ComposeVisualPopup />
      <div className={`absolute flex flex-row gap-1 lg:gap-3 items-end justify-center bottom-0 pb-3 pt-3 lg:pt-8 px-1 z-40 left-0
              // Put inside the box
              w-[calc(100%-15px)] mb-2 rounded-b-lg ml-2
              border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-neutral-800 md:!bg-transparent dark:md:bg-vert-dark-gradient`}
      >
        <div className="flex flex-row gap-2 items-center absolute top-[-40px] lg:top-[-10px]">
          <ComposeMessageQuickAction
            type={type}
            inputValue={inputValue}
            setInputValue={setInputValue}
            stopRecording={stopRecording}
            startRecording={startRecording}
            isRecording={isRecording}
            resetRecording={resetRecording}
            isLastMessageLoading={isLastMessageLoading}
            onCancelResponse={onCancelResponse}
            onRegenerate={onRegenerate}
            openSettingsMobile={openSettingsMobile}

            onSend={onSendFixed}

            isAllowImage={isAllowImage}
          />
        </div>

        {/*
        <Tooltip
          message={'Set Response'}
          popupClassName='w-[max-content] top-[-34px] left-[-30px]'
          position='top'
        >
          <Button
            onClick={onSend}
            className={`w-10 mt-auto h-10 min-w-[2.4rem] min-h-[2.4rem] flex items-center cursor-pointer justify-center rounded-full bg-neutral-500 shadow-neutral-600 dark:bg-neutral-500 dark:shadow-neutral-700 shadow-sm !p-1`}
          >
            <BsPersonFill className='text-xl' />
          </Button>
        </Tooltip>
        */}
        <div className={`flex flex-row items-stretch-old ${(isInputStarted && isAllowImage) ? 'items-end' : 'items-start'} dark:bg-neutral-800 dark:text-white bg-white border dark:border-neutral-500 border-gray-300 justify-between transition duration-200 rounded-xl rounded-br-none  shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] hover:shadow-[rgba(13,_38,_76,_0.3)_0px_9px_20px] focus-within:shadow-[rgba(13,_38,_76,_0.3)_0px_9px_20px]  w-full lg:w-[50vw] h-auto`}>
          <div className='px-3 py-2 pb-1 w-full max-h-[80vh] overflow-y-scroll pr-4'>
            <textarea
              ref={inputRef}
              value={inputValue}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              style={{ overflow: 'hidden' }}
              className="flex-1 bg-transparent border-none outline-none resize-none w-full placeholder:text-base"
              placeholder={type === 'note' ? 'Type a context or instruction...' : "Type a message"}
            />
          </div>
          <SendButton
            className={`lg:hidden flex rounded-lg mb-1 mr-1 min-w-[2.3rem] min-h-[2.3rem] !bg-transparent shadow-none dark:text-white text-gray-600 ${(isLastMessageLoading || !inputValue) ? 'opacity-20 hover:opacity-20 hover:bg-transparent' : ''}`}
            textClassName="dark:text-white text-gray-600"
            onSend={onSendFixed}
            isLastMessageLoading={isLastMessageLoading}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-2 w-[50px]">
          {/* {(type === 'note') ? ( */}
          <>
            <div className='top-[-30px] left-[-20px]' />

            {(isRecording || !inputValue) ? (
              <div className='flex flex-col gap-2 ml-3 md:ml-0'>
                {isAllowImage && (
                  <Tooltip
                    message={'Attach Image(s)'}
                    className='hidden md:flex'
                    popupClassName='top-[-30px] left-[-35px]'
                    position='top'
                  >
                    <Button
                      onClick={() => setModelPopup({ open: true, type: 'attach-file' })}
                      className={`${(isRecording || !inputValue) ? 'lg:w-6 lg:h-6 lg:!min-w-[1.9rem] lg:!min-h-[1.9rem]' : 'lg:hidden'} ${isRecording ? 'bg-red-600 shadow-red-700' : 'bg-blue-600  shadow-blue-700'} ml-auto w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center cursor-pointer justify-center rounded-full  shadow-sm !p-2 mr-auto`}
                    >
                      <IoMdAttach className="text-gray-100 text-lg" />
                    </Button>
                  </Tooltip>
                )}

                <Tooltip
                  message={isRecording ? 'Stop' : 'Start Voice to Text'}
                  popupClassName='top-[-30px] left-[-35px]'
                  position='top'
                >
                  <Button
                    onClick={() => {
                      if (isRecording) {
                        stopRecording()
                        resetRecording()
                      } else {
                        if (!speechText?.language) {
                          setSpeechText(prev => ({ ...prev, show: true }))
                        } else {
                          startRecording()
                        }
                      }
                    }}
                    className={`${(isRecording || !inputValue) ? 'lg:w-6 lg:h-6 lg:!min-w-[1.9rem] lg:!min-h-[1.9rem]' : 'lg:hidden'} ${isRecording ? 'bg-red-600 shadow-red-700' : 'bg-blue-600  shadow-blue-700'} ml-auto w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center cursor-pointer justify-center rounded-full  shadow-sm !p-2 mr-auto`}
                  >
                    {isRecording ? (
                      <BsMicMuteFill className="text-red-200 text-lg animate-[pulse_1s_ease-in-out_infinite]" />
                    ) : (
                      <BsFillMicFill className="text-gray-100 text-lg" />
                    )}
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <Tooltip
                message={'Send Message'}
                popupClassName='w-[max-content] top-[-34px] left-[-35px]'
                position='top'
              >
                <SendButton
                  className="lg:flex hidden mr-auto"
                  onSend={onSendFixed}
                  isLastMessageLoading={isLastMessageLoading}
                />
              </Tooltip>
            )}
          </>
          {/* ) : (
        <SendButton
          className="md:flex hidden"
          onSend={onSendFixed}
          isLastMessageLoading={isLastMessageLoading}
        />
        )} */}
          <Button
            onClick={() => setOpenSettingsMobile(prev => !prev)}
            className={`md:hidden ml-auto w-8 h-8 min-w-[2rem] min-h-[2rem] flex items-center cursor-pointer justify-center rounded-full  ${openSettingsMobile ? 'bg-gray-600  shadow-gray-700' : 'bg-gray-400  shadow-gray-500'} shadow-sm !p-2`}
          >
            <BsGear />
          </Button>
        </div>
      </div>
      <PopupWrapper className='!bg-transparent' containerClassName="z-[100002]" onClose={() => setSpeechText(prev => ({ ...prev, show: false }))} show={speechText.show}>
        <div className='max-w-full w-[400px] max-h-full h-auto text-white p-2'>
          <div className='bg-white text-gray-800 rounded p-5'>
            <h1 className='text-2xl font-bold text-center'>Voice to Text Settings 🗣️</h1>
            <div className='flex flex-col items-center justify-center mt-5 gap-4'>
              <p className='text-left'>Set your voice to text settings here and make sure the Microphone access is enabled</p>
              <SelectLabelForm
                id="language"
                label='Select Language'
                options={speechLanguagesOptions}
                value={speechText.language || 'en-US'}
                onChange={(val) => setSpeechText(prev => ({ ...prev, language: val as SpeechLanguageCode }))}
                skipDarkClass
              />
              <Button onClick={() => setSpeechText(prev => ({ ...prev, show: false }))} className='py-1 !bg-blue-700 !text-white mt-2 w-full'>
                <p>Done</p>
              </Button>
            </div>
          </div>
        </div>
      </PopupWrapper>

      <PopupWrapper className='!bg-transparent' containerClassName="z-[100002]" onClose={() => setTextSpeech(prev => ({ ...prev, show: false }))} show={textSpeech.show}>
        <div className='max-w-full w-[400px] max-h-full h-auto text-white p-2'>
          <div className='bg-white text-gray-800 rounded p-5'>
            <h1 className='text-2xl font-bold text-center'>Text to Voice Settings 🗣️</h1>
            <div className='flex flex-col items-center justify-center mt-7 gap-4'>
              <SelectLabelForm
                id="language"
                label='Voice'
                options={voicesOptions}
                value={textSpeech.voiceURI}
                onChange={(val) => setTextSpeech(prev => ({ ...prev, voiceURI: val }))}
              />
              <div className='relative w-full'>
                <RangeWithLabel
                  label={`Rate: ${textSpeech.rate}`}
                  values={[textSpeech.rate]}
                  min={0}
                  max={2}
                  minLabel='Slow'
                  maxLabel='Fast'
                  onChange={(val) => setTextSpeech(prev => ({ ...prev, rate: val[0] as number }))}
                />
              </div>
              <div className='relative w-full'>
                <RangeWithLabel
                  label={`Pitch: ${textSpeech.pitch}`}
                  values={[textSpeech.pitch]}
                  min={0}
                  max={2}
                  minLabel='Low'
                  maxLabel='High'
                  onChange={(val) => setTextSpeech(prev => ({ ...prev, pitch: val[0] as number }))}
                />
              </div>

              <div className="w-full relative">
                <SwitchWithLabel
                  textClassName={`!text-md dark:!text-gray-500 text-gray-800 !text-gray-600`}
                  label="Show Play Button"
                  value={true}
                  onChange={(e) => {
                    setTextSpeech(prev => ({ ...prev, showPlayButton: e }))
                  }}
                />
              </div>

              <div className='border-gray-300 w-full border-b' />
              <div className='w-full flex flex-col items-start'>
                <TextLabel size={'md'}>Test Voice</TextLabel>
                <input
                  type="text"
                  //className={`w-full text-gray-800 dark:text-gray-200 dark:bg-gray-800 bg-gray-100 rounded-md border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent px-3 py-2`}
                  className='w-full text-gray-800 text-sm bg-gray-100 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent px-3 py-2'
                  placeholder="Type something..."
                  value={textSpeechTest}
                  onChange={(val) => setTextSpeechTest(val.target.value)}
                />
                <Button className='!bg-gray-400 !py-1 !text-sm mt-2' onClick={onSpeak}>
                  {isSpeaking ? (
                    <>
                      <AiFillStop />
                      <p>Stop</p>
                    </>
                  ) : (
                    <>
                      <AiFillPlayCircle />
                      <p>Play</p>
                    </>
                  )}
                </Button>
              </div>
              <div className='border-gray-300 w-full border-b' />

              <Button onClick={() => setTextSpeech(prev => ({ ...prev, show: false }))} className='py-1 !bg-blue-700 !text-white mt-3 w-full'>
                <p>Done</p>
              </Button>
            </div>
          </div>
        </div>
      </PopupWrapper>
    </>
  )
})
ComposeMessage.displayName = 'ComposeMessage'

type ComposeMessageQuickActionProps = {
  type?: 'note' | 'chat'
  inputValue: string
  setInputValue: (val: string) => void
  stopRecording: () => void
  resetRecording: () => void
  startRecording: () => void
  isRecording: boolean
  isLastMessageLoading?: boolean
  onCancelResponse: () => void
  onRegenerate?: () => void
  openSettingsMobile: boolean
  onSend: () => void
  isAllowImage?: boolean
}

const ComposeMessageQuickAction = ({
  type,
  inputValue, setInputValue,
  stopRecording, resetRecording, isRecording,
  isLastMessageLoading, onCancelResponse, onRegenerate,
  openSettingsMobile,
  onSend,
  isAllowImage = false
}: ComposeMessageQuickActionProps) => {
  const [speechText, setSpeechText] = useAtom(speechToTextSettingsAtom)
  const chatQuickOptions = useAtomValue(chatQuickOptionsAtom)
  const composeImgs = useAtomValue(composeContextImgsAtom)

  const { isFindGenerate, isFindPromptPopup, otherOptions } = useMemo(() => {
    const findRegenerate = chatQuickOptions.list.some((item) => item.action === 'regenerate' && item.enable)
    const findPromptPopup = chatQuickOptions.list.some((item) => item.action === 'promptPopup' && item.enable)
    const otherOptions = chatQuickOptions.list.filter((item) => item.action !== 'regenerate' && item.action !== 'promptPopup' && item.enable)
    return { isFindGenerate: findRegenerate, isFindPromptPopup: findPromptPopup, otherOptions }
  }, [chatQuickOptions])
  const { composeImgsLength, composeImgsPreview } = useMemo(() => {
    return {
      composeImgsLength: composeImgs?.filter((item) => item.url)?.length,
      composeImgsPreview: composeImgs?.filter((item) => item.url)?.slice(0, 3)
    }
  }, [composeImgs])

  const onModelPopup = useSetAtom(modelPopupAtom)

  const onOtherClick = useCallback((item: ChatQuickOption) => {
    if (item?.prompt) {
      setInputValue(item?.prompt)
      if (item?.isAutoSend) {
        setTimeout(() => {
          onSend()
        }, 100);
      }
    }
  }, [onSend, setInputValue])

  return (
    <>
      {inputValue.length > 0 && (
        <Button
          className="!bg-red-500 !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
          onClick={() => setInputValue('')}
        >
          <BiTrash />
          <p>Clear</p>
        </Button>
      )}
      {isRecording && (
        <Button
          className="!bg-red-500 !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
          onClick={() => {
            setSpeechText(prev => ({ ...prev, show: true }))
            stopRecording()
            resetRecording()
          }}
        >
          <p>Voice to Speech in {speechText.language}</p>
        </Button>
      )}

      {isAllowImage && (
        <Button
          onClick={() => onModelPopup({ open: true, type: 'attach-file' })}
          className={`${composeImgsLength ? '!bg-neutral-700' : '!bg-neutral-500'}  !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] ${openSettingsMobile ? 'flex' : 'hidden'} lg:flex`}
        >
          {composeImgsLength ? (
            <div className='flex flex-row items-center gap-1'>
              {composeImgsPreview?.map((item, index) => (
                <img
                  key={index}
                  src={item.url}
                  style={{
                    height: '1.2rem',
                    width: '1.2rem',
                  }}
                  className={`rounded-md border border-gray-200`}
                />
              ))}
            </div>
          ) : (
            <BiImageAlt className='text-white' />
          )}
          <p>{composeImgsLength ? `${composeImgsLength} images` : 'Add Image(s)'}</p>
        </Button>
      )}

      {isLastMessageLoading ? (
        <Button
          className="!bg-red-500 !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]"
          onClick={onCancelResponse}
        >
          <BiX />
          <p>Cancel Response</p>
        </Button>
      ) : (
        <>
          {(type !== 'note' && isFindGenerate) && (
            <Button
              className={`!bg-neutral-500 !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] ${openSettingsMobile ? 'flex' : 'hidden'} lg:flex`}
              onClick={() => {
                onRegenerate && onRegenerate()
              }}
            >
              <BiRefresh />
              <p>Regenerate</p>
            </Button>
          )}
          {isFindPromptPopup && (
            <Button
              onClick={() => onModelPopup({ open: true, type: 'prompt' })}
              className={`!bg-neutral-500 !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] ${openSettingsMobile ? 'flex' : 'hidden'} lg:flex`}
            >
              <BiText />
              <p>Prompts</p>
            </Button>
          )}
          {otherOptions.map((item, index) => (
            <Button
              onClick={() => onOtherClick(item)}
              className={`!bg-neutral-500 !py-2 text-xs shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] ${openSettingsMobile ? 'flex' : 'hidden'} lg:flex`}
            >
              <p>{item.label}</p>
            </Button>
          ))}
        </>
      )}
    </>
  )
}

const SendButton = memo(({ onSend, isLastMessageLoading, className = '', textClassName }: { onSend: () => void, className?: string, textClassName?: string, isLastMessageLoading?: boolean }) => {
  return (
    <Button
      onClick={onSend}
      className={`w-10 mt-auto h-10 min-w-[2.6rem] min-h-[2.6rem] flex items-center cursor-pointer justify-center rounded-full  bg-blue-600  shadow-blue-700 shadow-sm !p-2 ${className}`}
    >
      {isLastMessageLoading
        ? <AiOutlineLoading className={`animate-spin text-2xl ${textClassName}`} />
        : <BiSend className={`text-2xl ${textClassName}`} />
      }
    </Button>
  )
})

interface RangeProps {
  values: number[]
  onChange: (values: number[]) => void
  min: number
  max: number
  step?: number
  minLabel?: string
  maxLabel?: string
  size?: 'sm' | 'md'
  labelClassName?: string
}

export const RangeWithLabel = memo(({ label, step, size = 'md', labelClassName, ...props }: { label: string } & RangeProps) => {
  const valuesFirst = props?.values?.[0]
  return (
    <div className='relative w-full'>
      <TextLabel size={size} className={labelClassName}>{label}</TextLabel>
      <Range
        step={step || 0.1}
        max={(valuesFirst && (valuesFirst > props.max)) ? valuesFirst : props.max}
        min={(valuesFirst && (valuesFirst < props.min)) ? valuesFirst : props.min}
        values={props.values}
        onChange={(values) => props.onChange(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              height: '3px',
              borderRadius: 30,
              width: '100%',
              backgroundColor: '#CCC'
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '20px',
              width: '20px',
              borderRadius: 10,
              backgroundColor: '#999'
            }}
          />
        )}
      />
      <div className='flex justify-between'>
        <p className='text-xs text-gray-500'>{props.minLabel}</p>
        <p className='text-xs text-gray-500'>{props.maxLabel}</p>
      </div>
    </div>
  )
})

SendButton.displayName = 'SendButton'
