import { BiSearch, Button, FiCommand, MdNoteAdd, toast } from "@acme/ui"
import { generateNanoId, noteDefaultInstruction } from "@acme/util"
import { useAtomValue, useSetAtom } from "jotai"
import { noteActiveIdAtom, noteAddAtom, notesAtom } from "../store/note"
import { isSearchAtom } from "../store/util"
import { useRouter } from "next/router"
import { teamActiveAtom, userAtom } from "../store/user"
import { useUserSubscription } from "../hooks"

export const NoteAction = () => {
  const router = useRouter()
  const setActiveNote = useSetAtom(noteActiveIdAtom)
  const setIsSearch = useSetAtom(isSearchAtom)
  const setAddNewNote = useSetAtom(noteAddAtom)
  const { isAllowAddNote } = useUserSubscription()

  const onNewNote = () => {
    const newId = generateNanoId(14)
    if (!isAllowAddNote?.allow) {
      toast.error(isAllowAddNote?.message || 'You have reached the limit of notes.')
      return
    }
    setAddNewNote({ id: newId })
    setActiveNote(newId)
    router.push({ query: { noteId: newId } })
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-gray-100">Notes</h1>
        <Button
          className="w-[100px] h-8 !bg-blue-600"
          onClick={onNewNote}
        >
          <MdNoteAdd />
          New
        </Button>
      </div>
      <div className="flex flex-row gap-1 mb-2">
        <Button
          onClick={() => setIsSearch(true)}
          className="flex-1 flex flex-row !justify-start !pl-4 items-start !p-0 h-10 bg-neutral-700"
        >
          <BiSearch />
          <p className="text-sm font-thin flex flex-row items-center">Search or <span className="flex flex-row gap-[3px] items-center ml-1"><FiCommand className="text-xs" /> K</span>...</p>
        </Button>
      </div>
    </>
  )
}
