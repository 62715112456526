import React, { type ChangeEvent, type FC } from 'react'
import { TextLabel } from '../Text/Body'

interface Props {
  value: string | number
  onChange: (val: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void
  className?: string
  containerClassName?: string
  disabled?: boolean
  placeholder?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  name?: string
  labelClassName?: string
}

export const TextInputArea: FC<Props> = ({
  value,
  onChange,
  className = '',
  containerClassName = '',
  disabled = false,
  placeholder = '',
  iconLeft = null,
  iconRight = null,
  name = '',
}) => {

  const onChangeFunction = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value, e)
    }
  }

  return (
    <div className={`bg-gray-100 rounded-lg flex flex-row gap-2 items-center ${iconLeft ? 'pl-2' : ''} ${containerClassName}`}>
      {iconLeft ? iconLeft : null}
      <textarea
        {...name ? { name } : {}}
        className={`${className} px-2 py-2 w-full bg-transparent outline-none`}
        onChange={onChangeFunction}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      >
        {/* {value} */}
      </textarea>
      {iconRight}
    </div>
  )
}

export const TextInputAreaLabelForm = ({ label, value, labelClassName, disabled = false, onChange, placeholder, name, className, inputClassName, size, skipDarkClass }: Props & { label: string; size?: 'sm' | 'md'; inputClassName?: string, skipDarkClass?: boolean }) => {
  return (
    <div className={`w-full ${className}`}>
      <TextLabel className={labelClassName} size={size} skipDarkClass={skipDarkClass}>{label}</TextLabel>
      <textarea
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        className={`border-2 placeholder-gray-500 outline-none rounded-xl py-[7px] pt-[9px] px-4 border-gray-200 focus:border-gray-400 w-full  ${!skipDarkClass ? 'dark:bg-neutral-700 dark:border-neutral-400 dark:placeholder-neutral-300' : ''} ${size === 'sm' ? 'text-sm' : ''} ${inputClassName}`}
      />
    </div>
  );
}

export default TextInputArea