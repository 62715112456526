// pages/extension-auth.tsx
import { api } from '@acme/client';
import { AiOutlineLoading, FaCheck, FaExclamationTriangle } from '@acme/ui';
import { EXTENSION_ID } from '@acme/util';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

export const ExtensionAuth = () => {
  const router = useRouter()
  const [state, setState] = useState<{ state: "success" | "error" | null, message: string }>({ state: null, message: '' });
  const { data: session, isLoading, isError } = api.auth.getSessionExtension.useQuery();
  const { data: userMe, isLoading: isLoadingTeams } = api.user.me.useQuery();

  const loadingState = useMemo(() => {
    if (state.state === "success") {
      return "success"
    } else if (state.state === "error") {
      return "error"
    } else {
      return "authenticating"
    }
  }, [state])

  useEffect(() => {
    if (router.isReady && !isLoading && userMe && state?.state !== 'error') {
      if (session && !session?.error) {
        // Replace 'your-extension-id' with your actual extension ID
        const extensionId = EXTENSION_ID

        const team = userMe.teams[0];
        if (!team) {
          setState({ state: "error", message: "No team found" })
          return;
        }
        const sessionPassed = {
          ...session,
          team,
        }
        chrome.runtime.sendMessage(
          extensionId,
          {
            type: 'set-session',
            session: sessionPassed
          },
          (response) => {
            if (response?.success) {
              setState({
                state: "success",
                message: "Login successful!"
              })
            } else {
              setState({
                state: "error",
                message: "Failed to set login in extension, make sure the extension is installed and enabled."
              })
            }
          }
        );

        window.postMessage({ type: 'set-session', session }, '*');
      } else if (session?.error) {
        setState({ state: "error", message: session.message })
      } else {
        signIn()
      }
    } else if (router.isReady && !userMe && !isLoadingTeams) {
      signIn()
    }
  }, [router.isReady, session, isLoading, isLoadingTeams, userMe, state?.state]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="relative w-64 h-64">
        <div
          className={`absolute inset-0 flex flex-col items-center justify-center transition-all duration-500 ease-in-out transform ${loadingState === "authenticating"
            ? ""
            : "scale-0 opacity-0 pointer-events-none"
            }`}
        >
          <AiOutlineLoading className="w-12 h-12 text-gray-500 animate-spin mb-2" />
          <p className="text-gray-500">Authenticating...</p>
        </div>
        <div
          className={`absolute inset-0 flex flex-col items-center justify-center text-green-500 transition-all duration-500 ease-in-out transform ${loadingState === "success"
            ? ""
            : "scale-0 opacity-0 pointer-events-none"
            }`}
        >
          <FaCheck className="w-12 h-12 mb-2" />
          <p className="text-green-500">{state.message || 'Login successful!'}</p>
        </div>
        <div
          className={`absolute inset-0 flex flex-col items-center justify-center text-yellow-500 transition-all duration-500 ease-in-out transform ${loadingState === "error"
            ? ""
            : "scale-0 opacity-0 pointer-events-none"
            }`}
        >
          <FaExclamationTriangle className="w-12 h-12 mb-2" />
          <p className="text-yellow-500 text-center">{state?.message || 'Error occurred!'}</p>
        </div>
        {/* <div className="absolute top-0 flex items-center justify-center">
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded-md focus:outline-none hover:bg-blue-600 transition-colors duration-300 ease-in-out"
            onClick={handleSuccess}
          >
            Login
          </button>
          <button
            className="ml-2 px-4 py-2 text-white bg-red-500 rounded-md focus:outline-none hover:bg-red-600 transition-colors duration-300 ease-in-out"
            onClick={() => { setLoadingState("authenticating") }}
          >
            Loading
          </button>
          <button
            className="ml-2 px-4 py-2 text-white bg-red-500 rounded-md focus:outline-none hover:bg-red-600 transition-colors duration-300 ease-in-out"
            onClick={handleError}
          >
            Error
          </button>
        </div> */}
      </div>
    </div>

  )
};
