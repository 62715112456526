import { BiNote, BsGear, Button, FaCommentDots, HeaderMenu } from '@acme/ui'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { type ReactNode, useMemo } from 'react'
import { toast } from "@acme/ui";

interface HeaderMainProps {
  title: string | ReactNode
  className?: string
  children: ReactNode
}

const menus = [
  {
    title: 'Chat',
    path: '',
    icon: <FaCommentDots className="inline mr-2" />,
  }, {
    title: 'Note',
    path: 'note',
    icon: <BiNote className="inline mr-2" />,
  }
]

export const HeaderMain = ({ title, className = '', children }: HeaderMainProps) => {
  const router = useRouter()

  const pathActive = useMemo(() => {
    if (router.asPath.includes('settings')) return 'settings'
    if (router.asPath.includes('note')) return 'note'
    return ''
  }, [router.asPath])

  return (
    <div className={'fixed top-0 w-screen ' + className}>
      <HeaderMenu title={title}>
        {children}
        {/* {menus.map((menu) => (
          <Link
            href={`/${menu.path}`}
            key={menu.path}
            className={`${pathActive === menu.path ? 'bg-blue-800 text-white shadow' : 'bg-blue-50  text-gray-500 hover:bg-blue-100'} p-2 rounded-xl flex flex-row items-center gap-2 px-4`}
          >
            {menu.icon}
            <p>{menu.title}</p>
          </Link>
        ))}
        <Link
          href={`/settings`}
        >
          <div
            className={`${pathActive === 'settings' ? 'bg-blue-800 text-white shadow' : 'bg-blue-50  text-gray-500'} p-2 py-3 flex flex-row items-center gap-2 rounded px-4`}
          >
            <BsGear />
          </div>
        </Link> */}
      </HeaderMenu>
    </div>
  )
}