import Link from 'next/link'

export const FooterSite = () => {
    return (
        <footer className="bg-blue-900 text-white py-4 px-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-sm flex flex-row items-center gap-2">
                    <Link href="/privacy" className="hover:underline">
                        Privacy Policy
                    </Link>{' '}
                    |{' '}
                    <Link href="/blog" className="hover:underline">
                        Terms and Conditions
                    </Link>
                    |{' '}
                    <Link href="/blog" className="hover:underline">
                        Blog
                    </Link>
                </div>

                <div className="">
                    <a href="https://www.producthunt.com/posts/dappergpt?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-dappergpt" target="_blank">
                        <img
                            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=389637&theme=light&period=daily"
                            alt="DapperGPT - Better&#0032;UI&#0032;for&#0032;ChatGPT&#0032;with&#0032;customize&#0032;chat&#0044;&#0032;notes&#0032;&#0038;&#0032;extension | Product Hunt"
                            style={{ width: '250px', height: '54px' }} width="250" height="54"
                        />
                    </a>
                </div>
                {/* <a href="https://www.producthunt.com/posts/duply-co?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-duply&#0045;co" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383129&theme=dark" alt="Duply&#0046;co - The&#0032;Ultimate&#0032;Image&#0032;Generation&#0032;Tool | Product Hunt" style={{ width: 250, height: 54 }} width="250" height="54" />
                </a> */}
                {/* <iframe className="border-none" src="https://cards.producthunt.com/cards/products/530359" width="500" height="405" frameborder="0" scrolling="no" allowfullscreen></iframe> */}
                {/* <a
                    href="https://www.producthunt.com/posts/your-app-name?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-your-app-name"
                    target="_blank"
                >
                    <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=YOUR_POST_ID&theme=light"
                        alt="Your App Name - Enhance your experience using GPT with a better UI and useful features. | Product Hunt"
                        style={{ width: 250, height: 54 }}
                        width="250"
                        height="54"
                    />
                </a> */}
            </div>
        </footer>
    )
}