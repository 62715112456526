import { BsInfoCircleFill, BsTrash, Button, ConditionalRender, PopupWrapperAnimated, SectionSettingsWrapper, SelectLabelForm, SwitchWithLabel, TextInputLabelForm, TextLabel, Tooltip, toast } from '@acme/ui';
import { type Session } from 'next-auth';
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router'
import { useAtom } from 'jotai';
import { type ChatQuickOption, chatDefaultSettingsAtom, chatQuickOptionsAtom, generalDefaultSettingsAtom, textToSpeechSettingsAtom } from '../../store/setting';
import { type ChatModel, openAiChatModels } from '@acme/util';
import { RangeWithLabel } from '../../chat/ComposeMessage';
import { useOnClickOutside, useUserSubscription } from '../../hooks';

interface GeneralProps {
  session?: Session | null
}

export const Preference: React.FC<GeneralProps> = ({ session }) => {
  const router = useRouter()

  const [activeOption, setActiveOptionState] = useState<'general' | 'chat' | 'note'>('general')
  const [chatDefault, setChatdefault] = useAtom(chatDefaultSettingsAtom)
  const [generalDefault, setGeneralDefault] = useAtom(generalDefaultSettingsAtom)
  const [textSpeech, setTextSpeech] = useAtom(textToSpeechSettingsAtom)

  useEffect(() => {
    if (router.isReady) {
      const { tab } = router.query
      if (tab === 'general') {
        setActiveOptionState('general')
      } else if (tab === 'chat') {
        setActiveOptionState('chat')
      } else if (tab === 'note') {
        setActiveOptionState('note')
      }
    }
  }, [router.isReady, router.query])

  return (
    <div className="flex flex-col gap-4">
      {/* <SectionSettingsWrapper title="Preferences" isShowTitle={true}>
        <Tabs
          tabs={[
            { id: 'general', title: 'General', content: { test: null } },
            { id: 'chat', title: 'Chat', content: { test: null } },
            { id: 'note', title: 'Note', content: { test: null } },
          ]}
          onSelect={(tab) => setActiveOption(tab as 'general' | 'chat' | 'note')}
          activeTabId={activeOption}
          renderTab={(tab) => (
            <div>
            </div>
          )}
        />
        <div className="mb-[-20px]" />
      </SectionSettingsWrapper> */}

      <ConditionalRender show={activeOption === 'general'}>
        <SectionSettingsWrapper title="General" isShowTitle={true}>

          <GroupWrapper
            title="Sound after Complete?"
          >
            <div className='col-span-9 items-center flex'>
              <SwitchWithLabel
                label=''
                onChange={(val) => setGeneralDefault(prev => ({ ...prev, soundCompletion: val }))}
                value={!!generalDefault?.soundCompletion}
                textClassName='text-gray-500'
              />
            </div>
          </GroupWrapper>
        </SectionSettingsWrapper>

        <SectionSettingsWrapper title="Chat" isShowTitle={true}>
          <div className='flex flex-col gap-6'>
            <GroupWrapper
              title={<>Show Play Button<br /><span className='text-xs'>(text to voice)</span></>}
            >
              <div className='col-span-9 flex items-center'>
                <SwitchWithLabel
                  label=''
                  onChange={() => setTextSpeech(prev => ({ ...prev, showButton: !textSpeech?.showButton }))}
                  value={!!textSpeech?.showButton}
                  textClassName='text-gray-500'
                />
              </div>
            </GroupWrapper>

            <GroupWrapper
              title={<>Default Model</>}
            >
              <div className='flex flex-col w-full'>
                <SelectLabelForm
                  id="defaultModel"
                  className="!w-[100%]"
                  inputClassName="!text-sm"
                  labelClassName='!text-gray-500'
                  size="md"
                  value={chatDefault?.defaultModel || ''}
                  onChange={(e) => setChatdefault(prev => ({ ...prev, defaultModel: e as ChatModel }))}
                  label=""
                  options={[...openAiChatModels]}
                />
                <div className="w-full rounded-lg px-3 py-1 bg-green-300 mt-1 text-[10px] text-neutral-800">
                  {chatDefault?.defaultModel?.includes('gpt-4') && (
                    <>GPT - 4 API is currently is on waitlist. If you haven't got the access join the waitlist here: <a href="https://openai.com/waitlist/gpt-4-api" target="_blank" rel="noreferrer" className="text-blue-600 font-medium hover:underline">https://openai.com/waitlist/gpt-4-api</a></>
                  )}
                  {chatDefault.defaultModel?.includes('gpt-3.5') && (
                    <>Learn more here <a href="https://platform.openai.com/docs/models/gpt-3-5" target="_blank" rel="noreferrer" className="text-blue-600 font-medium hover:underline">https://platform.openai.com/docs/models/gpt-3-5</a></>
                  )}
                </div>
              </div>
            </GroupWrapper>

            <GroupWrapper
              title={<>Default Max Tokens</>}
            >
              <div className='flex flex-col w-full'>
                <SelectLabelForm
                  id="defaultModel"
                  className="!w-[100%]"
                  inputClassName="!text-sm"
                  labelClassName='!text-gray-500'
                  size="md"
                  value={typeof chatDefault?.defaultMaxContextTokens === 'number' ? 'manual' : 'auto'}
                  onChange={(e) => setChatdefault(prev => ({ ...prev, defaultMaxContextTokens: e === 'manual' ? 4000 : '' }))}
                  label=""
                  options={[
                    { label: 'Auto (Suites the Model)', value: 'auto' },
                    { label: 'Set Manual', value: 'manual' },
                  ]}
                />
                {typeof chatDefault?.defaultMaxContextTokens === 'number' ? (
                  <div className='mt-5'>
                    <RangeWithLabel
                      label={`Max Tokens: ${textSpeech.pitch}`}
                      values={[textSpeech.pitch]}
                      size='sm'
                      labelClassName='mb-3'
                      step={100}
                      min={1000}
                      max={32000}
                      minLabel='1000'
                      maxLabel='32000'
                      onChange={(val) => setTextSpeech(prev => ({ ...prev, pitch: val[0] as number }))}
                    />
                    <p className='text-[10px] text-gray-500 dark:text-neutral-300 mt-3 text-left'>Setting this value too high or not suits the model will result in error.</p>
                    <p className='text-[10px] text-gray-500 dark:text-neutral-300 mt-1 text-left'>Check your model: <a className='text-blue-500 underline' href='https://platform.openai.com/docs/models/gpt-4' target='_blank'>https://platform.openai.com/docs/models/gpt-4</a></p>
                  </div>
                ) : (
                  <p className='text-[10px] text-gray-500 dark:text-neutral-300 mt-1 text-left'>Auto will use the default max tokens for the model. Set Manual will use the value below.</p>
                )}
              </div>
            </GroupWrapper>

            <SectionQuickOptions />

          </div>
        </SectionSettingsWrapper >
      </ConditionalRender >

      <ConditionalRender show={true}>
        <SectionSettingsWrapper title="Support" isShowTitle={false}>
          <div>
            <div className="p-3 rounded-lg text-gray-800">
              <div className="flex flex-col items-start mb-0">
                <div className="mb-0">
                  <p className="text-gray-500">This preferences <strong>currently only saved in your browser</strong>. We are working on a way to save it in our server so you can use it across devices.</p>
                </div>
              </div>
            </div>
          </div>
        </SectionSettingsWrapper>
      </ConditionalRender>
    </div >
  );
};

const SectionQuickOptions = () => {
  const router = useRouter()
  const { isSubscribe } = useUserSubscription()

  const [{ list: chatQuickOptions }, setChatQuickOptions] = useAtom(chatQuickOptionsAtom)
  const [newQuickOption, setNewQuickOption] = useState({ name: '', prompt: '', isAutoSend: true, show: false })
  const [openQuickActionInfo, setOpenQuickActionInfo] = useState(false)

  const quickActionInfoRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(quickActionInfoRef, () => { setOpenQuickActionInfo(false) }, ['quickActionInfo']);

  const setActiveOption = (option: 'general' | 'chat' | 'note') => {
    if (option === 'general') {
      router.push('/settings?menu=preference&tab=general')
    } else if (option === 'chat') {
      router.push('/settings?menu=preference&tab=chat')
    } else if (option === 'note') {
      router.push('/settings?menu=preference&tab=note')
    }
  }

  const onEnableChatQuickOption = (val: boolean, value: string) => {
    const currentChecked = chatQuickOptions.filter((item) => item.enable).length
    if (currentChecked >= 4 && val) {
      toast.error('You can only enable 4 quick options')
      return
    }
    setChatQuickOptions((prev) => {
      const newList = prev.list.map((item) => {
        if (item.value === value) {
          return { ...item, enable: val }
        }
        return item
      })
      return { ...prev, list: newList }
    })
  }

  const onAutoChatQuickOption = (val: boolean, value: string) => {
    setChatQuickOptions((prev) => {
      const newList = prev.list.map((item) => {
        if (item.value === value) {
          return { ...item, isAutoSend: val }
        }
        return item
      })
      return { ...prev, list: newList }
    })
  }

  const onRemoveChatQuickOption = (value: string) => {
    setChatQuickOptions((prev) => {
      const newList = prev.list.filter((item) => item.value !== value)
      return { ...prev, list: newList }
    })
  }

  const onAddNewQuickOption = () => {
    const newQuick: ChatQuickOption = { action: 'prompt', label: newQuickOption.name, prompt: newQuickOption.prompt, enable: true, isAutoSend: newQuickOption.isAutoSend, value: `prompt:::${newQuickOption.name}` }
    const isNameExist = chatQuickOptions.find((item) => item.value === newQuick.value)
    if (isNameExist) {
      toast.error('Name already exist, please use another name')
      return
    }
    setChatQuickOptions((prev) => {
      const newList: ChatQuickOption[] = [...prev?.list, newQuick]
      return { ...prev, list: newList }
    })
    setNewQuickOption({ name: '', prompt: '', isAutoSend: true, show: false })
  }

  const onQuickOptionPopup = () => {
    if (!isSubscribe) {
      toast.error('You need to subscribe to use this feature')
      return
    }
    setNewQuickOption(prev => ({ ...prev, show: true }))
  }

  return (
    <>
      <GroupWrapper
        title={(
          <>
            Quick Options
            <br />
            <span className='text-xs flex flex-row gap-1 items-center'>(Max 4)<BsInfoCircleFill className='mt-1 cursor-pointer' onClick={() => setOpenQuickActionInfo(prev => !prev)} /></span>
          </>
        )}
        titleChild={(
          <>
            <div ref={quickActionInfoRef} className='absolute top-14 flex flex-col bg-gray-200 p-2 shadow-md gap-2 items-center rounded-lg z-50 quickActionInfo'>
              {openQuickActionInfo && (
                <>
                  <p className='text-gray-600 text-xs'>Quick actions are shortcuts you found on top of the chat input.</p>
                  <img src='/images/img-quick-actions.png' className='w-[400px] min-w-[400px] max-w-screen-lg  h-auto rounded-lg' />
                </>
              )}
            </div>
          </>
        )}
        titleContainerClassName='!items-start'
      >
        <div className='flex flex-col w-full gap-3 dark:text-neutral-200'>
          <div className='grid grid-cols-12 gap-2 text-sm text-gray-500 dark:text-neutral-400 font-semibold'>
            <div className='col-span-2 flex items-center'>
              <p>Enable?</p>
            </div>
            <div className={`col-span-4 flex items-center`}>
              <p>Label</p>
            </div>
            <div className={`col-span-5 flex items-center`}>
              <p>Auto Send / Description</p>
            </div>
          </div>
          {chatQuickOptions.map(item => (
            <div key={item.value} className='border border-b border-gray-50 dark:border-neutral-700 shadow-sm rounded-lg p-2 transition-all duration-300 hover:shadow'>
              <div className='grid grid-cols-12 gap-2'>
                <div className='col-span-2 flex items-center'>
                  <SwitchWithLabel
                    label=''
                    onChange={(val) => onEnableChatQuickOption(val, item.value)}
                    value={!!item?.enable}
                    textClassName='text-gray-500'
                  />
                </div>
                <div className={`col-span-4 flex items-center`}>
                  {item?.action === 'prompt' ? (
                    <Tooltip
                      message={`Prompt: ${item?.prompt}`}
                      position='top'
                      popupClassName='w-[200px] left-[-45px] mt-3'
                    >
                      <TextLabel size={'sm'} className={'flex flex-col'}>{item.label}</TextLabel>
                    </Tooltip>
                  ) : (
                    <TextLabel size={'sm'} className={'flex flex-col'}>{item.label}</TextLabel>
                  )}
                </div>
                <div className='col-span-5 flex items-center relative'>
                  {item?.action === 'prompt' ? (
                    <Tooltip
                      message={`Auto send when click`}
                      position='top'
                      className=''
                      popupClassName='w-[150px] left-[-45px] mt-3'
                    >
                      <SwitchWithLabel
                        label=''
                        size='small'
                        onChange={(val) => onAutoChatQuickOption(val, item.value)}
                        value={!!item?.isAutoSend}
                        textClassName='text-gray-500'
                        switchClassName={!!item?.isAutoSend ? '!bg-blue-400' : ''}
                      />
                    </Tooltip>
                  ) : (
                    <p className='text-gray-500 dark:text-neutral-400 text-sm'>{item.desc}</p>
                  )}
                </div>
                {item?.action === 'prompt' && (
                  <div className={`col-span-1 flex items-center`}>
                    <Button className='!py-2 text-sm bg-red-400 !px-2' onClick={() => onRemoveChatQuickOption(item.value)}>
                      <BsTrash />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
          <Button className='!py-1 text-sm bg-gray-500' onClick={onQuickOptionPopup}>
            Add More
          </Button>
        </div>
      </GroupWrapper>


      <PopupWrapperAnimated className='!bg-transparent' containerClassName="z-[100002]" onClose={() => setNewQuickOption(prev => ({ ...prev, show: false }))} show={newQuickOption.show}>
        <div className='max-w-full w-[400px] max-h-full h-auto text-white p-2'>
          <div className='bg-white text-gray-800 rounded p-5'>
            <h1 className='text-2xl font-bold text-center'>Add New Quick Action</h1>
            <div className='flex flex-col items-center justify-center mt-4 gap-4'>
              <div className='w-full flex flex-col items-start'>
                <TextInputLabelForm
                  id='name'
                  size='sm'
                  label='Name'
                  placeholder='Type the prompt name...'
                  value={newQuickOption?.name || ''}
                  onChange={(val) => setNewQuickOption(prev => ({ ...prev, name: val }))}
                  skipDarkClass
                />
              </div>

              <div className='w-full flex flex-col items-start'>
                <TextInputLabelForm
                  id='prompt'
                  size='sm'
                  label='Prompt'
                  placeholder='Type your Prompt...'
                  value={newQuickOption?.prompt || ''}
                  onChange={(val) => setNewQuickOption(prev => ({ ...prev, prompt: val }))}
                  skipDarkClass
                />
              </div>
              <div className='border-gray-300 w-full border-b' />
              <div className="w-full relative">
                <SwitchWithLabel
                  textClassName={`!text-md dark:!text-gray-500 text-gray-800 !text-gray-600`}
                  label="Auto Send When Clicked"
                  size='small'
                  value={newQuickOption?.isAutoSend || false}
                  onChange={(e) => {
                    setNewQuickOption(prev => ({ ...prev, isAutoSend: e }))
                  }}
                />
              </div>

              <Button onClick={onAddNewQuickOption} className='py-1 !bg-blue-700 !text-white mt-5 w-full'>
                <p>Done</p>
              </Button>

              <Button onClick={() => setNewQuickOption(prev => ({ ...prev, show: false }))} className='py-1 !bg-transparent !text-gray-500 mt-0 w-full'>
                <p>Cancel</p>
              </Button>
            </div>
          </div>
        </div>
      </PopupWrapperAnimated>
    </>
  )
}

const GroupWrapper = ({ children, title, titleChild, titleContainerClassName = '' }: { children: React.ReactNode, title: React.ReactNode, titleChild?: React.ReactNode, titleContainerClassName?: string }) => {
  return (
    <div className='grid grid-cols-12 gap-2 border-b border-b-neutral-100 dark:border-b-neutral-700 p-3 py-6'>
      <div className={`col-span-3 flex items-center ${titleContainerClassName} relative`}>
        <TextLabel size={'md'} className={'flex flex-col'}>{title}</TextLabel>
        {titleChild}
      </div>
      <div className='col-span-9 flex items-center'>
        {children}
      </div>
    </div>
  )
}