import { Block, type BlockRaw, type Note } from "../interface";
import { type BlockNoteEditor, type PartialBlock } from '@blocknote/core'
import { type GPTChatMessage } from "./openai";
import { defaultModelGptLimit, generateGptMessageLimitFromGpt, generateGptMessageLimitFromGptTokens } from "./chat";
import { noteDefaultInstruction, type ChatModel } from "../constant";

export function generateNotePrompt(note: Note, type: 'write' | 'continue' | 'select', context?: string) {
    const noteInstruction = type === 'select'
        ? noteDefaultInstruction.select
        : type === 'continue'
            ? note.defaultContinueInstruction || noteDefaultInstruction.continue
            : note.defaultWriteInstruction || noteDefaultInstruction.write
    const noteMarkdown = note.markdown || ''
    const noteName = note.name || ''
    const noteContext = context || ''
    // replace the string if found {{note.name}}, {{note.markdown}}, {{context}}
    const noteInstructionReplace = noteInstruction
        .replaceAll('{{note.name}}', noteName)
        .replaceAll('{{note.markdown}}', noteMarkdown)
        .replaceAll('{{context}}', noteContext)
    console.log({ noteInstructionReplace, context, noteContext })
    return noteInstructionReplace
}

export function generateNoteTitlePrompt(markdown: string) {
    return `Generate Title from this note content:
-----
${markdown || ''}
-----
Generate a very short chat title for 1-3 words (prevent adding word Note at the end and wrap with quote)): `
}

export function generateMarkdownChatLimit(markdown: string, limitWords: number): string {
    const sentences = markdown.split(/[.!?]\s+/);
    const words = markdown.split(/\s+/);

    if (words.length <= limitWords) {
        return markdown;
    }

    let result = '';
    let i = 0;
    let j = sentences.length - 1;
    let currentWordCount = 0;

    while (i <= j) {
        const sentencesI = sentences[i]
        const sentencesJ = sentences[j]
        const firstSentenceWords = (sentencesI) ? sentencesI.split(/\s+/).length : 0;
        const lastSentenceWords = (sentencesJ) ? sentencesJ.split(/\s+/).length : 0;

        if (currentWordCount + firstSentenceWords + lastSentenceWords <= limitWords) {
            if (i === j) {
                result += sentences[i] + '.';
            } else {
                result += sentences[i] + '..., ..., ...' + sentences[j] + '.';
            }
            currentWordCount += firstSentenceWords + lastSentenceWords;
            i++;
            j--;
        } else {
            break;
        }
    }

    return result;
}

export function convertMarkdownFullToBlockNoteMarkdown(inputMarkdown: string): string {
    // Remove code blocks (```...```)
    const removeCodeBlocks = /```[\s\S]*?```/g;
    inputMarkdown = inputMarkdown.replace(removeCodeBlocks, (match) => {
        return match
            .slice(3, -3)
            .replace(/[\r\n]+/g, ' ')
            .trim();
    });

    // Remove inline code (single backticks)
    const removeInlineCode = /`[^`]*`/g;
    inputMarkdown = inputMarkdown.replace(removeInlineCode, (match) => {
        return match.slice(1, -1);
    });

    // Remove images
    const removeImages = /!\[([^\]]*)\]\(([^)]+)\)/g;
    inputMarkdown = inputMarkdown.replace(removeImages, '$1');

    // Remove tables
    const removeTables = /\|[^|]+\|[\s\S]*?\n(?:\|-+\|)+[\s\S]*?\n(?:\|[^|]*\|[\s\S]*?)*\n?/g;
    inputMarkdown = inputMarkdown.replace(removeTables, '');

    // REMOVE {{CURSOR}} string
    inputMarkdown = inputMarkdown.replace(/{{CURSOR}}/g, '');

    return inputMarkdown;
}

type CompileGptMessagesReturnType<T extends Note | undefined> = {
    // newMessageFixed: T extends Note ? Note : undefined;
    gptMessages: GPTChatMessage[];
    thinkingId: string;
    modelFixed: ChatModel;
    // initMessage: null;
    // initInstruction: string | null;
}

export const compileNoteGptMessages = async <T extends Note | undefined>(
    editor: BlockNoteEditor,
    noteActive: Note,
    param: {
        target?: 'after' | 'cursor',
        action: 'continue' | 'write' | 'titleGenerate' | 'select',
        context: string
    }
): Promise<CompileGptMessagesReturnType<T>> => {
    try {
        const noteActiveId = noteActive.id
        const { target = 'after', action, context } = param
        const blocksAll = editor.topLevelBlocks
        const cursorPos = editor.getTextCursorPosition()
        let currentMarkdown = ''
        if (target === 'cursor') {
            /* const cursorBlock = blocksAll.findIndex(s => s.id === cursorPos.block?.id)
            const blocksTrimmed = [...blocksAll].slice(0, cursorBlock)
            const firstBlocksMarkdown = await editor.blocksToMarkdown(blocksTrimmed)
            currentMarkdown += firstBlocksMarkdown
            currentMarkdown += '{{CURSOR}}'
            const allBlocksMarkdown = await editor.blocksToMarkdown(blocksAll)
            const afterBlocks = [...blocksAll].slice(cursorBlock)
            const afterBlocksMarkdown = await editor.blocksToMarkdown(afterBlocks)
            console.log({ afterBlocks, afterBlocksMarkdown, allBlocksMarkdown })
            //currentMarkdown += await editor.blocksToMarkdown(afterBlocks)
            currentMarkdown += '\n.....' */
            const blocksAllFixed: BlockRaw[] = blocksAll.map(s => {
                if (s.id === cursorPos.block?.id) {
                    return { ...s, content: [{ text: '{{CURSOR}}', content: [], type: 'text', styles: {} }] }
                }
                return s
            })
            currentMarkdown = await editor.blocksToMarkdown(blocksAllFixed)

        } else {
            currentMarkdown = await editor.blocksToMarkdown(blocksAll)
        }

        let refactorMessages: GPTChatMessage[] = []
        let modelFixed = noteActive.defaultModel || 'gpt-3.5-turbo';

        if (action === 'titleGenerate') {
            modelFixed = 'gpt-3.5-turbo'
            refactorMessages = [
                { role: 'system', content: noteActive?.defaultInstruction || noteDefaultInstruction.system },
                { role: 'user', content: `${generateNoteTitlePrompt(generateMarkdownChatLimit(currentMarkdown, 2000))}` }
            ]
        } else {
            refactorMessages = [
                {
                    role: 'system',
                    content: noteActive?.defaultInstruction || noteDefaultInstruction.system
                },
                {
                    role: 'user',
                    content: `${generateNotePrompt({ ...noteActive, markdown: generateMarkdownChatLimit(currentMarkdown, 2000) }, action, context)}`
                }
            ]
        }

        const thinkingId = `${noteActiveId}-${Date.now()}`

        return {
            // gpt api messages
            gptMessages: generateGptMessageLimitFromGptTokens(refactorMessages, undefined, defaultModelGptLimit(modelFixed).maxWords),
            thinkingId,
            modelFixed,
        }
    } catch (error) {
        console.error(error)
        return {
            gptMessages: [],
            thinkingId: '',
            modelFixed: 'gpt-3.5-turbo',
        }
    }
}

export const noteEditorManageAiMessage = async (editor: BlockNoteEditor, newBlocks: PartialBlock[], targetPassed?: 'after' | 'cursor') => {
    const blocksAll = editor.topLevelBlocks
    const lastBlock = blocksAll[blocksAll.length - 1]
    const cursorPos = editor.getTextCursorPosition()
    const target = targetPassed || 'after'

    if (target === 'cursor') {
        if (cursorPos.block) {
            editor.insertBlocks(newBlocks, cursorPos.block, 'before')
        }
    } else if (lastBlock) {
        if (lastBlock.content.length === 0) {
            editor.insertBlocks(newBlocks, lastBlock, 'before')
        } else {
            editor.insertBlocks(newBlocks, lastBlock, 'after')
        }
    } else {
        const allNewBlocks = [...blocksAll, ...newBlocks]
        editor.removeBlocks(blocksAll)
        const firstBlock = editor.topLevelBlocks[0]
        if (firstBlock)
            editor.replaceBlocks([firstBlock], allNewBlocks)
    }
}