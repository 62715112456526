import { api } from "@acme/client"
import { BsTrash, Button, FiPlusCircle, IoMdWarning, PopupWrapper, PopupWrapperAnimated, SelectLabelForm, SwitchWithLabel, Tabs, TextInputAreaLabelForm, TextInputLabelForm, Tooltip, toast } from "@acme/ui"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { memo, useEffect, useState } from "react"
import { chatSessionActiveAtom, chatSessionsSettingsAtom } from "../store/chat"
import { useUserSubscription } from "../hooks"
import { modelPopupAtom, popupSettingsInstructionTriggerAtom } from "../store/instruction"
import { type ChatModel, openAiChatModels, defaultModelPrismaToString, type ChatMessage, messageToChatMessage } from "@acme/util"
import { teamActiveAtom } from "../store/user"
import { defaultImageGenTriggersFormats } from "../store/function/chat-util"

export const ChatSettings = memo(() => {
  const [isOpen, setIsOpen] = useAtom(chatSessionsSettingsAtom)
  if (!isOpen) return null
  return (
    <ChatSettingsComponent
      onClose={() => setIsOpen(false)}
    />
  )
})
ChatSettings.displayName = 'ChatSettings'

export const ChatSettingsComponent = ({ onClose }: { onClose: () => void }) => {
  const teamActive = useAtomValue(teamActiveAtom)
  const [chatSession, setChatSession] = useAtom(chatSessionActiveAtom)
  const [activeOption, setActiveOption] = useState<'ins' | 'img'>('ins')
  const [tempValue, setTempValue] = useState({
    name: chatSession?.name || '',
    defaultInstruction: chatSession?.defaultInstruction || '',
    defaultImageGenTriggers: chatSession?.defaultImageGenTriggers || defaultImageGenTriggersFormats,
    isSync: chatSession?.isSync || false,
    defaultModel: chatSession?.defaultModel || 'gpt-3.5-turbo',
    isStreaming: chatSession?.isStreaming || false,
    isImageGen: chatSession?.isImageGen || false,
  })
  const [insSettingsTrigger, setInsSettingsTrigger] = useAtom(popupSettingsInstructionTriggerAtom)
  const setModelPopup = useSetAtom(modelPopupAtom)

  const { isSubscribe } = useUserSubscription()

  const { mutateAsync: mutateUpdateChat, isLoading } = api.chat.updateChatSession.useMutation(
    {
      onSuccess: (data) => {
        if (data && chatSession) {
          setChatSession({
            id: chatSession.id,
            name: tempValue.name,
            defaultInstruction: tempValue.defaultInstruction,
            defaultModel: tempValue.defaultModel,
            isStreaming: tempValue.isStreaming,
            isSync: true,
            isInit: false
          })
          onClose()
        }
      }
    }
  )
  const { mutateAsync: mutateCreate, isLoading: isLoadingCreate } = api.chat.createChatSession.useMutation(
    {
      onSuccess: (data) => {
        if (data && chatSession) {
          setChatSession({
            ...chatSession,
            ...data?.messages?.length && { messages: data.messages.map(m => messageToChatMessage(m)) },
            id: data.id,
            name: data.name || tempValue.name,
            isStreaming: data.isStreaming || tempValue.isStreaming,
            defaultInstruction: data.defaultInstruction || tempValue.defaultInstruction,
            defaultModel: data.defaultModel ? defaultModelPrismaToString(data.defaultModel) : tempValue.defaultModel || 'gpt-3.5-turbo',
            isSync: true,
            isInit: false
          })
          onClose()
        }
      }
    }
  )
  const { mutateAsync: mutateDelete, isLoading: isLoadingDelete } = api.chat.deleteChatSession.useMutation()

  const onSave = async () => {
    if (chatSession) {
      // Remove chat session from the cloud
      if (chatSession?.isSync && !chatSession?.isInit && !tempValue.isSync) {
        await mutateDelete({ id: chatSession.id })
        setChatSession({
          id: chatSession.id,
          name: tempValue.name,
          defaultInstruction: tempValue.defaultInstruction,
          defaultModel: tempValue.defaultModel,
          isStreaming: tempValue.isStreaming,
          isImageGen: tempValue.isImageGen,
          isSync: false,
          updated: new Date(),
        })
      } else {
        // Create or update chat session
        if (chatSession.isSync && !chatSession.isInit) {
          await mutateUpdateChat({
            id: chatSession.id,
            name: tempValue.name,
            defaultInstruction: tempValue.defaultInstruction,
            defaultModel: tempValue.defaultModel,
            isStreaming: tempValue.isStreaming,
            isImageGen: tempValue.isImageGen,
            ...tempValue?.isImageGen && { defaultImageGenTriggers: tempValue.defaultImageGenTriggers },
          })
          // Update chat session
        } else if (tempValue.isSync && teamActive?.id) {
          await mutateCreate({
            localId: chatSession.id,
            name: tempValue.name,
            teamId: teamActive?.id,
            defaultInstruction: tempValue.defaultInstruction,
            defaultModel: tempValue.defaultModel,
            isStreaming: tempValue.isStreaming,
            isImageGen: tempValue.isImageGen,
            ...tempValue?.isImageGen && { defaultImageGenTriggers: tempValue.defaultImageGenTriggers },
            messages: chatSession.messages.map(m => ({
              ...m,
              localId: m.id,
              chatSessionId: chatSession.id,
            })),
            order: chatSession.order || 100,
            orderUpdated: chatSession?.orderUpdated || new Date(),
          })
        } else {
          setChatSession({
            id: chatSession.id,
            name: tempValue.name,
            defaultInstruction: tempValue.defaultInstruction,
            defaultModel: tempValue.defaultModel,
            isStreaming: tempValue.isStreaming,
            isImageGen: tempValue.isImageGen,
            ...tempValue?.isImageGen && { defaultImageGenTriggers: tempValue.defaultImageGenTriggers },
          })
        }
      }
      onClose()
    }
  }

  const onRemoveImgGenTriggers = (index: number) => {
    setTempValue(prev => ({
      ...prev,
      defaultImageGenTriggers: prev.defaultImageGenTriggers.filter((_, i) => i !== index)
    }))
  }

  const onAddImgGenTriggers = () => {
    setTempValue(prev => ({
      ...prev,
      defaultImageGenTriggers: [...prev.defaultImageGenTriggers, { text: '' }]
    }))
  }

  const onEditImgGenTrigger = (index: number, trigger: string) => {
    setTempValue(prev => ({
      ...prev,
      defaultImageGenTriggers: prev.defaultImageGenTriggers.map((t, i) => i === index ? { ...t, trigger } : t)
    }))
  }

  useEffect(() => {
    if (chatSession && (chatSession.name && chatSession.defaultInstruction && chatSession.isSync)) {
      setTempValue({
        name: chatSession.name,
        defaultInstruction: chatSession.defaultInstruction,
        defaultImageGenTriggers: chatSession.defaultImageGenTriggers || defaultImageGenTriggersFormats,
        isSync: chatSession.isSync,
        defaultModel: chatSession.defaultModel || 'gpt-3.5-turbo',
        isStreaming: chatSession.isStreaming || false,
        isImageGen: chatSession.isImageGen || false,
      })
    }
  }, [chatSession])

  useEffect(() => {
    if (insSettingsTrigger.type === 'chat') {
      setTempValue(prev => ({ ...prev, defaultInstruction: insSettingsTrigger.instruction || '' }))
      setInsSettingsTrigger({})
    }
  }, [insSettingsTrigger, setInsSettingsTrigger])

  return (
    <PopupWrapperAnimated className="!bg-white !p-0" onClose={onClose} show>
      <div className='max-w-full w-screen lg:w-[600px] max-h-full h-[auto] text-gray-700 px-8 py-4'>
        <div className='rounded'>
          <h1 className='text-2xl font-bold text-left'>Chat Session</h1>
          <div className='flex flex-col items-start justify-center mt-5 gap-4'>
            <TextInputLabelForm
              id="name"
              label="Name"
              size="sm"
              onChange={(e) => setTempValue({ ...tempValue, name: e })}
              value={tempValue.name}
              skipDarkClass
            />

            <div className="relative w-full">
              <SelectLabelForm
                id="defaultModel"
                className="!w-[100%]"
                inputClassName="!text-sm"
                size="sm"
                value={tempValue.defaultModel}
                onChange={(e) => setTempValue(prev => ({ ...prev, defaultModel: e as ChatModel }))}
                label="Default Model"
                options={[...openAiChatModels]}
                skipDarkClass
              />
              <a
                href="https://platform.openai.com/docs/guides/chat"
                target="_blank" rel="noreferrer"
                className="absolute right-[-5px] top-0 mt-1 mr-4 text-xs text-blue-600 font-medium hover:underline"
              >
                What is this?
              </a>
              <div className="w-full rounded-lg p-3 bg-green-300 mt-2 text-[13px]">
                {tempValue?.defaultModel.includes('gpt-4') && (
                  <>GPT - 4 API is currently is on waitlist. If you haven't got the access join the waitlist here: <a href="https://openai.com/waitlist/gpt-4-api" target="_blank" rel="noreferrer" className="text-blue-600 font-medium hover:underline">https://openai.com/waitlist/gpt-4-api</a></>
                )}
                {tempValue.defaultModel.includes('gpt-3.5') && (
                  <>Learn more here <a href="https://platform.openai.com/docs/models/gpt-3-5" target="_blank" rel="noreferrer" className="text-blue-600 font-medium hover:underline">https://platform.openai.com/docs/models/gpt-3-5</a></>
                )}
              </div>
            </div>

            <div className="relative w-full">
              {tempValue?.isImageGen ? (
                <div className="bg-gray-50 rounded-lg p-2 border">
                  <Tabs
                    tabs={[
                      { id: 'ins', title: 'Instruction', content: { test: null } },
                      { id: 'img', title: 'Image Generation', content: { test: null } },
                    ]}
                    onSelect={(tab) => setActiveOption(tab as 'ins')}
                    activeTabId={activeOption}
                    renderTab={(tab) => (
                      <>
                        {tab?.id === 'img' ? (
                          <>
                            <p className="text-sm text-gray-800">Image Generation will trigger when your chat input contains</p>
                            <div className="mt-2">
                              <div className="flex flex-col gap-2 mb-3">
                                {tempValue.defaultImageGenTriggers.map((item, itemIndex) => {
                                  return (
                                    <div key={item.text} className="flex flex-row gap-2 items-center">
                                      <input
                                        type="text"
                                        className="border border-gray-300 rounded-md w-full px-3 py-2 text-sm text-gray-800"
                                        value={item.text}
                                        onChange={(e) => onEditImgGenTrigger(itemIndex, e.target.value)}
                                      />
                                      <BsTrash
                                        onClick={() => onRemoveImgGenTriggers(itemIndex)}
                                        className="text-lg text-red-600 cursor-pointer"
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                              <button
                                className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 transition-all duration-200"
                                onClick={onAddImgGenTriggers}
                              >
                                <FiPlusCircle />
                                <span>Add More</span>
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <TextInputAreaLabelForm
                              label="Instruction"
                              inputClassName="min-h-[200px] w-full"
                              size="sm"
                              onChange={(e) => setTempValue({ ...tempValue, defaultInstruction: e })}
                              value={tempValue.defaultInstruction}
                              skipDarkClass
                            />
                            <Button
                              className="absolute bottom-3 right-3 text-xs !py-2 bg-gray-600"
                              onClick={() => setModelPopup({ open: true, type: 'instruction' })}
                            >
                              Get from Instruction
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  />
                </div>
              ) : (
                <>
                  <TextInputAreaLabelForm
                    label="Instruction"
                    inputClassName="min-h-[200px] w-full"
                    size="sm"
                    onChange={(e) => setTempValue({ ...tempValue, defaultInstruction: e })}
                    value={tempValue.defaultInstruction}
                    skipDarkClass
                  />
                  <Button
                    className="absolute bottom-3 right-3 text-xs !py-2 bg-gray-600"
                    onClick={() => setModelPopup({ open: true, type: 'instruction' })}
                  >
                    Get from Instruction
                  </Button>
                </>
              )}
            </div>
            <ChatSyncToCloudButton
              value={tempValue.isSync}
              className="!text-gray-600"
              onChange={(e) => setTempValue({ ...tempValue, isSync: e })}
            />

            <SwitchWithLabel
              textClassName={`!text-sm dark:text-gray-200 text-gray-800 !text-gray-600`}
              label="Stream Response?"
              value={tempValue?.isStreaming}
              onChange={(e) => setTempValue({ ...tempValue, isStreaming: e })}
            />
            <Tooltip
              message="This will generate an image on Dall-E based on the response or chats, This is still in preview and may not work for all responses."
              className="!text-gray-600"
            >
              <div>

                {/** TODO: Remove all the code related to image generation */}
                {/* <SwitchWithLabel
                  textClassName={`!text-sm dark:text-gray-200 text-gray-800 !text-gray-600`}
                  label="Image Generate (preview)?"
                  value={tempValue?.isImageGen}
                  onChange={(e) => {
                    if (!isSubscribe && !!e) {
                      toast.error('Currently only available for paid users')
                      return
                    }
                    setTempValue({ ...tempValue, isImageGen: e })
                  }}
                /> */}

                {tempValue?.isImageGen && (
                  <div className="p-1 px-2 flex flex-row gap-2 items-center bg-gray-200 rounded-lg mt-4">
                    <div className="text-xs text-gray-700">
                      <p>The image generation will use Dall-E with your API key and only trigger based on your settings</p>
                    </div>
                  </div>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
        {(chatSession?.isSync && !chatSession?.isInit && !tempValue?.isSync) && (
          <div className="p-1 px-2 flex flex-row gap-2 items-center bg-red-500 rounded-lg mt-4">
            <IoMdWarning className="text-white" />
            <div className="text-xs text-white">
              <p>Are you sure you want to unsync and remove the data on the cloud?</p>
              <p>This will remove your chat and chat session on the server</p>
            </div>
          </div>
        )}
      </div>
      <div className="bg-neutral-200 rounded-b-lg w-full py-3 px-6 flex flex-row items-center justify-end">
        <Button
          className="!bg-transparent !text-gray-700 !mr-3"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          loading={isLoading || isLoadingCreate || isLoadingDelete}
          className="!bg-blue-600 !text-white !py-2"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </PopupWrapperAnimated>
  )
}

export const ChatSyncToCloudButton = memo(({ value, onChange, className }: { value: boolean, onChange: (val: boolean) => void, className?: string }) => {
  const { isAllowAddChatSession } = useUserSubscription()

  if (!isAllowAddChatSession?.allowSync && !value) return null
  return (
    <SwitchWithLabel
      textClassName={`!text-sm dark:text-gray-200 text-gray-800 ${className}`}
      label="Save to cloud?"
      value={value}
      onChange={(e) => onChange(e)}
    />
  )
})

ChatSyncToCloudButton.displayName = 'ChatSyncToCloudButton'
