/* eslint-disable @typescript-eslint/ban-ts-comment */
import { type Descendant, type Node, Text } from 'slate'
import { format as sqlFormatterExp } from 'sql-formatter';

export const sqlFormatter = sqlFormatterExp;

export const slateSerialize = (nodes: Node[]) => {
    console.log({ nodes })
    return nodes.map(n => serialize(n)).join('\n ')
}

const escapeHtml = (html: string) => {
    const text = document.createTextNode(html)
    const div = document.createElement('div')
    div.appendChild(text)
    return div.innerHTML
}

const serialize = (node: Node): string => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)
        // @ts-ignore
        if (node?.bold) {
            string = `<strong>${string}</strong>`
        }
        return string
    }

    // @ts-ignore
    const children = node.children.map(n => serialize(n)).join('')

    // @ts-ignore
    switch (node.type) {
        case 'paragraph':
            return `${children}`
        case 'property':
            // @ts-ignore
            return `{{${node.source}.${node.name}}}`
        case 'component':
            // @ts-ignore
            return `{{component.${node.name}}}`
        default:
            return children
        /*  case 'quote':
             return `<blockquote><p>${children}</p></blockquote>`
         case 'paragraph':
             return `<p>${children}</p>`
         case 'link':
             // @ts-ignore
             return `<a href="${escapeHtml(node.url)}">${children}</a>`
         default:
             return children */
    }
}

export const slateDeserializeSQL = (text: string): Descendant[] => {
    /* return text.split('\n').map(line => {
        const [type, ...rest] = line.split(' ')
        return {
            type,
            children: rest.map(s => ({ text: s }))
        }
    }) */
    return text.split('\n').map(line => {
        return {
            type: 'paragraph',
            children: [{ text: line }]
        }
    })
}

