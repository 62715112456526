
export const BASE_URL = process.env["NEXT_PUBLIC_ENDPOINT_API"] || 'https://api.dappergpt.com'
export const APP_URL = process.env["NEXT_PUBLIC_ENDPOINT_APP"] || 'https://app.dappergpt.com'
export const APP_HOST = process.env["NEXT_PUBLIC_ENDPOINT_APP_DOMAIN"] || 'dappergpt.com'
export const NODE_ENV = process.env["NODE_ENV"] || 'development'
export const COOKIE_DOMAIN_ORIGIN = process.env["NEXT_PUBLIC_COOKIE_DOMAIN_ORIGIN"] || '.dappergpt.com'
export const VERCEL_URL = process.env["VERCEL_URL"] || 'dappergpt.com'
export const COOKIE_KEYS = {
    accessToken: 'accessToken',
    session: 'session'
}
export const EXTENSION_ID = process.env['NEXT_PUBLIC_EXTENSION_ID'] || 'opibaobahpmecdlkgifnombagoogbkmj'