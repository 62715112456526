import axios from 'axios';
import { type Message } from '@prisma/client';
import { GPTChatMessage, type GPTChatResult } from './openai';
import { chatModels, type ChatModel, chatModelsList, chatModelsListDb } from '../constant';
import { type BlockNote } from '../interface';
import z from 'zod';
import { zfd } from "zod-form-data";

export const nextApi = axios.create({
    baseURL: `${process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://dappergpt.com'}/api`,
    headers: {
        'Content-Type': 'application/json'
    }
});

// FIX DAPPERGPT

//~ SEND
export const zodGPTContentMessage = z.object({
    role: z.enum(['user', 'system', 'assistant']),
    content: z
        .string()
        .or(
            z.array(
                z.object({
                    type: z.enum(['text']),
                    text: z.string()
                }).or(
                    z.object({
                        type: z.enum(['image_url']),
                        image_url: z.object({
                            url: z.string()
                        })
                    })
                )
            )
        )
})
export const zodDefaultModelPrisma = z.enum(chatModelsListDb)
export const zodDefaultModelString = z.enum(chatModelsList)

export type ResultSend = { messages: Message[]; error?: any, message?: string }
export type InputAPISend = typeof zodValidateAPISend['_input']
export type GPTContentMessageZod = typeof zodGPTContentMessage['_input']
export const zodValidateAPISend = z.object({
    message: z.object({
        id: z.string().optional(),
        localId: z.string().optional(),
        chatSessionId: z.string(),
        parentId: z.string().optional(),
        replyToId: z.string().optional(),
        model: zodDefaultModelString.optional(),
        senderRole: z.enum(['user', 'system', 'assistant']).optional(),
        content: z.object({
            contentType: z.enum(['text']).optional(),
            content: z.string().or(z.array(z.string())).nullable()
        }),
        totalTokens: z.number().optional(),
        created: z.date().or(z.string()).optional(),
        updated: z.date().or(z.string()).optional()
    }),
    // Either to update or create the message
    messageAction: z.enum(['create', 'update', 'skip']).optional(),
    prevMessages: z.array(zodGPTContentMessage).optional(),
    defaultModel: zodDefaultModelString.optional(),
    apiKey: z.object({
        openAi: z.string().optional(),
        anthropic: z.string().optional()
    }),
    isSessionInit: z.boolean().optional()
})

export const nextApiSendMessage = async (data: InputAPISend, signal?: AbortController) => {
    const response = await nextApi.post<ResultSend>(
        '/chat/send',
        data,
        {
            signal: signal?.signal,
        }
    )
    return response.data
}

//~ ADD MESSAGES
export type ResultAddMessages = { messages: Message[]; error?: any, message?: string }
export type InputAPIAddMessages = typeof zodValidateAPIAddMessages['_input']
export const zodValidateAPIAddMessages = z.object({
    messages: z.array(
        z.object({
            id: z.string().optional(),
            localId: z.string().optional(),
            chatSessionId: z.string(),
            parentId: z.string().optional(),
            replyToId: z.string().optional(),
            model: zodDefaultModelString.optional(),
            senderRole: z.enum(['user', 'system', 'assistant']).optional(),
            content: z.object({
                contentType: z.enum(['text']).optional(),
                content: z.string().or(z.array(z.string())).nullable()
            }),
            totalTokens: z.number().optional(),
            created: z.date().or(z.string()).optional(),
            updated: z.date().or(z.string()).optional()
        })
    ).optional(),
    request: z.object({
        chatSessionId: z.string(),
        promptTokens: z.number().optional(),
        completionTokens: z.number().optional(),
        totalTokens: z.number().optional(),
        model: zodDefaultModelString.optional(),
    }).optional(),
    chatSessionId: z.string(),
    isSessionInit: z.boolean().optional()
})

export const nextApiAddMessages = async (data: InputAPIAddMessages, signal?: AbortController) => {
    const response = await nextApi.post<ResultAddMessages>(
        '/chat/messages',
        data,
        {
            signal: signal?.signal,
        }
    )
    return response.data
}




//~ CHAT TITLE GENERATE
export type ResultChatTitleGenerate = { gpt?: GPTChatResult, newTitle?: string, message?: string, error?: string }
export type InputChatTitleGenerate = {
    prevMessages?: GPTChatMessage[],
    defaultModel?: ChatModel,
    apiKey?: string,
    chatSessionId: string,
    isUpdateTitle?: boolean,
}
export const nextApiChatTitleGenerate = async (data: InputChatTitleGenerate, signal?: AbortController) => {
    const response = await nextApi.post<ResultChatTitleGenerate>(
        '/chat/request',
        data,
        {
            signal: signal?.signal,
        }
    )
    return response.data
}


//~ NOTE SEND
export type ResultNoteSend = {
    blocks: BlockNote[];
    markdown: string;
    error?: any
    message?: string
}
export const zodValidateNoteAPISend = z.object({
    prevMessages: z.array(
        z.object({
            role: z.enum(['user', 'system', 'assistant']),
            content: z.string()
        })
    ).optional(),
    defaultModel: zodDefaultModelString.optional(),
    apiKey: z.string().optional(),
    noteId: z.string(),
    isSessionInit: z.boolean().optional()
})
export type InputNoteAPISend = typeof zodValidateNoteAPISend['_input']

export const nextApiSendNote = async (data: InputNoteAPISend, signal?: AbortController) => {
    const response = await nextApi.post<ResultNoteSend>(
        '/note/send',
        data,
        {
            signal: signal?.signal,
        }
    )
    return response.data
}

//~ NOTE TITLE GENRATE
export type ResultNoteTitleGenerate = { gpt?: GPTChatResult, newTitle?: string, message?: string, error?: string }
export type InputNoteTitleGenerate = {
    prevMessages?: { role: 'user' | 'system' | 'assistant', content: string }[],
    defaultModel?: ChatModel,
    apiKey?: string,
    noteId: string,
    isUpdateTitle?: boolean,
}
export const nextApiNoteTitleGenerate = async (data: InputNoteTitleGenerate, signal?: AbortController) => {
    const response = await nextApi.post<ResultNoteTitleGenerate>(
        '/note/request',
        data,
        {
            signal: signal?.signal,
        }
    )
    return response.data
}

//~ TRANSCRIBE
export type ResultAPITranscribe = { text: string, message?: string, error?: string }
export type InputAPITranscribe = {
    chatSessionId?: string | undefined;
    noteId?: string | undefined;
    apiKey?: string | undefined;
    teamId: string;
    audio: Blob;
}
export const nextApiTranscribe = async (data: InputAPITranscribe, signal?: AbortController) => {
    const formData = new FormData();
    formData.append('chatSessionId', data.chatSessionId ?? '');
    formData.append('noteId', data.noteId ?? '');
    formData.append('apiKey', data.apiKey ?? '');
    formData.append('teamId', data.teamId);
    formData.append('audio', data.audio);

    const response = await nextApi.post<ResultAPITranscribe>(
        '/chat/transcribe',
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            signal: signal?.signal,
        }
    )
    return response.data
}

/* async function chatCompletion(messages, stream = false) {
    const apiKey = "YOUR_API_KEY";
    const apiUrl = "https://api.openai.com/v1/chat/completions";

    const headers = new Headers({
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiKey}`,
    });

    const body = JSON.stringify({
        model: chatModels["gpt-3.5-turbo"].id,
        messages: messages,
        stream: stream,
    });

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: headers,
            body: body,
        });

        if (stream) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            while (true) {
                const { value, done } = await reader.read();
                if (done) break;

                const result = decoder.decode(value);
                console.log(result);
            }
        } else {
            const data = await response.json();
            console.log(data);
        }
    } catch (error) {
        console.error("Error:", error);
    }
}

// Example usage:
const messages = [
    {
        role: "user",
        content: "Hello!",
    },
];

chatCompletion(messages, false); */