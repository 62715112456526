import { api } from "@acme/client"
import { Button, PopupWrapper, PopupWrapperAnimated, SelectLabelForm, SwitchWithLabel, Tabs, TextInputAreaLabelForm, TextInputLabelForm, toast } from "@acme/ui"
import { type ChatModel, noteDefaultInstruction, openAiChatModels } from "@acme/util"
import { useAtom, useAtomValue } from "jotai"
import { memo, useEffect, useMemo, useState } from "react"
import { noteActiveAtom, noteSettingsAtom } from "../store/note"
import { teamActiveAtom } from "../store/user"
import { useUserSubscription } from "../hooks"

export const NoteSettings = memo(() => {
  const [isOpen, setIsOpen] = useAtom(noteSettingsAtom)
  if (!isOpen) return null
  return (
    <NoteSettingsComponent
      onClose={() => setIsOpen(false)}
    />
  )
})
NoteSettings.displayName = 'NoteSettings'

export const NoteSettingsComponent = ({ onClose }: { onClose: () => void }) => {
  const teamActive = useAtomValue(teamActiveAtom)
  const [activeOption, setActiveOption] = useState<'ins' | 'con' | 'write'>('ins')
  const [note, setNote] = useAtom(noteActiveAtom)
  const [tempValue, setTempValue] = useState<{
    name: string; defaultInstruction: string; defaultModel: ChatModel; defaultContinueInstruction: string; defaultWriteInstruction: string; defaultSelectInstruction: string; isSync: boolean;
  }>({
    name: note?.name || '',
    defaultInstruction: note?.defaultInstruction || '',
    isSync: note?.isSync || false,
    defaultContinueInstruction: note?.defaultContinueInstruction || '',
    defaultWriteInstruction: note?.defaultWriteInstruction || '',
    defaultSelectInstruction: note?.defaultSelectInstruction || '',
    defaultModel: 'gpt-3.5-turbo'
  })
  const nameShowed = useMemo(() => {
    if (note?.name && note?.name.length > 15) {
      return note.name.slice(0, 15) + '...'
    }
    return note?.name
  }, [note?.name])

  const { mutateAsync: mutateUpdateNote, isLoading } = api.note.updateNote.useMutation(
    {
      onSuccess: (data) => {
        if (data && note) {
          setNote({
            id: note.id,
            name: tempValue.name,
            defaultInstruction: tempValue.defaultInstruction,
            isSync: true,
            isInit: false
          })
          onClose()
        }
      }
    }
  )

  const { mutateAsync: mutateCreateNote, isLoading: isLoadingNote } = api.note.createNote.useMutation(
    {
      onSuccess: (data) => {
        if (data && note) {
          setNote({
            id: note.id,
            name: tempValue.name,
            defaultInstruction: tempValue.defaultInstruction,
            isSync: true,
            isInit: false
          }, data?.id)
          onClose()
        }
      }
    }
  )

  useEffect(() => {
    if (note) {
      setTempValue({
        name: note.name,
        defaultModel: note.defaultModel || 'gpt-3.5-turbo',
        defaultInstruction: note?.defaultInstruction || '',
        defaultContinueInstruction: note.defaultContinueInstruction || noteDefaultInstruction.continue,
        defaultWriteInstruction: note.defaultWriteInstruction || noteDefaultInstruction.write,
        defaultSelectInstruction: note.defaultSelectInstruction || noteDefaultInstruction.continue,
        isSync: note.isSync || false,
      })
    }
  }, [note, activeOption])

  const onSave = async () => {
    if (note) {
      if (!note.isSync && tempValue.isSync) {
        if (!teamActive?.id) return toast.error('Please select a team to sync or refresh the page')
        await mutateCreateNote({
          id: note.id,
          name: tempValue.name,
          teamId: teamActive?.id || '',
          content: note?.content,
          markdown: note?.markdown,
          defaultModel: tempValue.defaultModel,
          defaultInstruction: tempValue?.defaultInstruction || '',
          defaultContinueInstruction: tempValue.defaultContinueInstruction,
          defaultWriteInstruction: tempValue.defaultWriteInstruction,
        })
      } else if (note.isSync && !note.isInit) {
        await mutateUpdateNote({
          id: note.id,
          name: tempValue.name,
          defaultInstruction: tempValue.defaultInstruction,
          defaultContinueInstruction: tempValue.defaultContinueInstruction,
          defaultWriteInstruction: tempValue.defaultWriteInstruction,
        })
      } else {
        setNote({
          id: note.id,
          name: tempValue.name,
          defaultInstruction: tempValue.defaultInstruction,
          defaultContinueInstruction: tempValue.defaultContinueInstruction,
          defaultWriteInstruction: tempValue.defaultWriteInstruction,
        })
        onClose()
      }
    }
  }

  return (
    <PopupWrapperAnimated className="!bg-white !p-0" onClose={onClose} show>
      <div className='max-w-full w-screen lg:w-[600px] max-h-full h-[auto] text-gray-700 px-8 py-4'>
        <div className='rounded'>
          <h1 className='text-xl font-bold text-left'>Note Settings {nameShowed}</h1>
          <div className='flex flex-col items-start justify-center mt-5 gap-4'>
            <TextInputLabelForm
              id="name"
              label="Name"
              size="sm"
              onChange={(e) => setTempValue({ ...tempValue, name: e })}
              value={tempValue.name}
              skipDarkClass
            />

            <div className="relative w-full">
              <SelectLabelForm
                id="defaultModel"
                className="!w-[100%]"
                inputClassName="!text-sm"
                size="sm"
                value={tempValue.defaultModel}
                onChange={(e) => setTempValue(prev => ({ ...prev, defaultModel: e as ChatModel }))}
                label="Default Model"
                options={[...openAiChatModels]}
                skipDarkClass
              />
              <a
                href="https://platform.openai.com/docs/guides/chat"
                target="_blank" rel="noreferrer"
                className="absolute right-0 top-0 mt-1 mr-4 text-xs text-blue-600 font-medium hover:underline"
              >
                What is this?
              </a>
              <div className="w-full rounded-lg p-3 bg-green-300 mt-2 text-[13px]">
                {tempValue.defaultModel.includes('gpt-4') && (
                  <>GPT - 4 API is currently is on waitlist. If you haven't got the access join the waitlist here: <a href="https://openai.com/waitlist/gpt-4-api" target="_blank" rel="noreferrer" className="text-blue-600 font-medium hover:underline">https://openai.com/waitlist/gpt-4-api</a></>
                )}
                {tempValue.defaultModel.includes('gpt-3.5') && (
                  <>Learn more here <a href="https://platform.openai.com/docs/models/gpt-3-5" target="_blank" rel="noreferrer" className="text-blue-600 font-medium hover:underline">https://platform.openai.com/docs/models/gpt-3-5</a></>
                )}
              </div>
            </div>
            {(note?.isInit || !note?.isSync) && (
              <NoteSyncToCloudButton
                className="!text-sm !text-gray-500"
                value={tempValue.isSync}
                onChange={(e) => setTempValue({ ...tempValue, isSync: e })}
              />
            )}
            <Tabs
              tabs={[
                { id: 'ins', title: 'Instruction', content: { test: null } },
                { id: 'con', title: 'Continue Format', content: { test: null } },
                /* { id: 'write', title: 'Write Format', content: { test: null } }, */
                /* { id: 'select', title: 'Select Format', content: { test: null } }, */
              ]}
              onSelect={(tab) => setActiveOption(tab as 'ins')}
              activeTabId={activeOption}
              renderTab={(tab) => (
                <>
                  {tab?.id === 'ins' ? (
                    <TextInputAreaLabelForm
                      label="Instruction"
                      inputClassName="min-h-[200px]"
                      size="sm"
                      onChange={(e) => setTempValue({ ...tempValue, defaultInstruction: e })}
                      value={tempValue.defaultInstruction}
                      skipDarkClass
                    />
                  ) : (
                    <>
                      {tab?.id === 'select' ? (
                        <p className="text-sm text-gray-800">This is the format send when you <strong>Select texts</strong></p>
                      ) : (
                        <p className="text-sm text-gray-800">This is the format send when you <strong>{tab?.id === 'con' ? 'Continue Write with AI' : 'Write'}</strong></p>
                      )}

                      {tab?.id === 'con' ? (
                        <TextInputAreaLabelForm
                          label="Format"
                          inputClassName="min-h-[200px]"
                          size="sm"
                          onChange={(e) => setTempValue({ ...tempValue, defaultContinueInstruction: e })}
                          value={tempValue.defaultContinueInstruction}
                          skipDarkClass
                        />
                      ) : (
                        <TextInputAreaLabelForm
                          label="Format"
                          inputClassName="min-h-[200px]"
                          size="sm"
                          onChange={(e) => setTempValue({ ...tempValue, defaultWriteInstruction: e })}
                          value={tempValue.defaultWriteInstruction}
                          skipDarkClass
                        />
                      )}

                      <div className="text-gray-6s00 text-sm mb-3">
                        <p>Available propertes: </p>
                        <ul className="ml-5">
                          <li><strong>{`{{note.name}}`}</strong> - Note Name or Title</li>
                          <li><strong>{`{{note.markdown}}`}</strong> - Note Markdown (will cut the text if it's too long)</li>
                          <li><strong>{`{{note.markdown.selection}}`}</strong> - Note Markdown selection</li>
                          <li><strong>{`{{context}}`}</strong> - Text you wrote down on message box if exist</li>
                        </ul>
                      </div>
                    </>
                  )}
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="bg-neutral-200 rounded-b-lg w-full py-3 px-6 flex flex-row items-center justify-end">
        <Button
          className="!bg-transparent !text-gray-700 !mr-3"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          loading={isLoading || isLoadingNote}
          className="!bg-blue-600 !text-white !py-2"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </PopupWrapperAnimated >
  )
}

export const NoteSyncToCloudButton = memo(({ value, onChange, className }: { value: boolean, onChange: (val: boolean) => void, className?: string }) => {
  const { isAllowAddNote } = useUserSubscription()

  if (!isAllowAddNote?.allowSync && !value) return null
  return (
    <SwitchWithLabel
      textClassName={`!text-sm dark:text-gray-200 text-gray-800 ${className}`}
      label="Save to cloud?"
      value={value}
      onChange={(e) => onChange(e)}
    />
  )
})


NoteSyncToCloudButton.displayName = 'NoteSyncToCloudButton'

