import { produce, generateNanoId, type ChatMessage, type ChatSession, generateLocalIdByRole, compileGptMessages, nextApiChatTitleGenerate, openAiChatModels, openAiChatRequestHandler, chatSessionListDefault, defaultModelGptLimit, tokenizer, countTokens, messageToMessageToken, messageContentTokens, ChatModel, getGPTContentToTextString, GPTChatMessage, aiChatRequestHandler, isModelAnthropic } from "@acme/util";
import { Getter, atom } from "jotai";
import { atomWithStorage } from "jotai/utils"
import { toast } from "@acme/ui";
import { apiKeyAnthropicAtom, apiKeyAtom, chatDefaultSettingsAtom, generalDefaultSettingsAtom } from "./setting";
import { type RouterInputs } from "@acme/client";
import { apiKeyModalAtom, teamActiveAtom, userAtom } from "./user";
import { handleSendMessageError, handleSendMessageFetchClient, handleSendMessageFetchCloud, handleSendMessageInit, handleSendMessageInitCloud, handleSendMessageOnMessage, handleSendMessagePostFetch } from "./function/chat-util";

export const playNotificationSound = (get: Getter) => {
  const { soundCompletion = true } = get(generalDefaultSettingsAtom);
  if (soundCompletion) {
    const audio = new Audio('/notification1.mp3');
    void audio.play();
  }
};

//~ ATOM
export const chatSessionActiveIdAtom = atom<string | null>(null);
export const chatSessionsAtom = atomWithStorage<ChatSession[]>("chatSessions", chatSessionListDefault);
export const chatFavoritesAtom = atomWithStorage<{ chatSessionId: string, created: Date, updated: Date, isSync?: boolean }[]>("chatFavorites", [{ chatSessionId: chatSessionListDefault?.[0]?.id || '', created: new Date(), updated: new Date() }]);
export const chatConversationTriggerAtom = atom<{ scrollToBottom?: boolean, scrollToId?: string }>({ scrollToBottom: false });
export const chatSessionsSettingsAtom = atom(false)
export const chatUpdateTriggerAtom = atom<{
  chatSessionId: string, loading?: boolean,
  update?: RouterInputs['chat']['updateChatSession']
  delete?: RouterInputs['chat']['deleteChatSession'],
  addMessage?: RouterInputs['chat']['addMessage'],
  deleteMessage?: RouterInputs['chat']['deleteMessage'],
  updateMessage?: RouterInputs['chat']['updateMessage']
}[]>([])


//~ SELECTOR
export const chatSessionActiveInfoAtom = atom(
  (get): ChatSession | null => {
    const id = get(chatSessionActiveIdAtom);
    const sessions = get(chatSessionsAtom);
    const session = sessions.find(s => s.id === id) || null;
    if (!session) return null;
    const { messages, ...allSession } = session;
    return { ...allSession, messages: [] } satisfies ChatSession
  })
export const chatSessionActiveAtom = atom(
  (get) => {
    const id = get(chatSessionActiveIdAtom);
    const sessions = get(chatSessionsAtom);
    return sessions.find(s => s.id === id) || null;
  },
  (get, set, session: Partial<ChatSession>) => {
    if (session) {
      const sessions = get(chatSessionsAtom);
      const sessionActiveIdAtom = get(chatSessionActiveIdAtom);
      const sessionIdUpdate = session.id || sessionActiveIdAtom;
      const newSessions = produce(sessions, draft => {
        const find = draft.find(s => s.id === sessionIdUpdate);
        if (find) {
          if (session?.id) find.id = session.id;
          if (session.messages)
            find.messages = [
              ...session.messages || []
            ]
          if (session.name) find.name = session.name;
          if (session.created) find.created = session.created;
          if (session.updated) find.updated = session.updated;
          if (session.defaultModel) find.defaultModel = session.defaultModel;
          if (session.lastSync) find.lastSync = session.lastSync;
          if (session.order) find.order = session.order;
          if (session.orderUpdated) find.orderUpdated = session.orderUpdated;
          if (session.defaultInstruction) find.defaultInstruction = session.defaultInstruction;
          if (session.isStreaming || session.isStreaming === false) find.isStreaming = session.isStreaming;
          if (session.isImageGen || session.isImageGen === false) find.isImageGen = session.isImageGen;

          if (session.isSync || session.isSync === false) find.isSync = session.isSync;
          if (session.isInit || session.isInit === false) find.isInit = session.isInit;
          if (session.loading || session.loading === false) find.loading = session.loading;
        }
        /*  else {
            draft.push(session);
        } */
      })
      set(chatSessionsAtom, newSessions);
    }
  }
)


//~ MUTATION
export const chatSessionsAddNewAtom = atom(
  null,
  async (get, set, params?: {
    isAllowAddChatSession: {
      allow: boolean;
      allowSync: boolean;
      message: string;
    },
    defaultModel?: ChatModel
  }) => {
    const newId = generateNanoId(14)
    const { isAllowAddChatSession, defaultModel } = params || {}

    // If user still allow to add new chat session
    if (!isAllowAddChatSession?.allow) {
      toast.error(isAllowAddChatSession?.message || 'You have reached the limit of chats.')
      return
    }
    const teamActive = get(teamActiveAtom)
    const user = get(userAtom)
    set(chatSessionsAtom, prev => {
      const prevFixed = prev || []
      const prevSession = prevFixed.length > 0 ? prevFixed[prevFixed.length - 1] : null
      const firstSession = prevFixed.length > 0 ? prevFixed[0] : null
      return [
        {
          id: newId,
          localId: newId,
          name: 'New Chat',
          defaultModel: defaultModel || openAiChatModels[0]?.value,
          messages: [],
          created: new Date(),
          updated: new Date(),
          ...teamActive && { teamId: teamActive.id },
          ...user && { userId: user.id },
          isStreaming: true,


          isSync: false,
          isInit: true,
          //order: prevSession ? (prevSession?.order || 0) + 100 : 100,
          order: firstSession?.order ? firstSession.order - 1 : 100,
          orderUpdated: new Date(),
        },
        ...prevFixed,
      ]
    })
    return newId
  }
)
export const chatSessionUpdateAtom = atom(
  null,
  (get, set, session: Partial<ChatSession>) => {
    if (session?.id) {
      const sessions = get(chatSessionsAtom);
      const newSessions = produce(sessions, draft => {
        const find = draft.find(s => s.id === session.id);
        if (find) {
          if (session.messages) find.messages = session.messages;
          if (session.name) find.name = session.name;
          if (session.created) find.created = session.created;
          if (session.defaultModel) find.defaultModel = session.defaultModel;
        }
      })
      set(chatSessionsAtom, newSessions);
    }
  }
)

export const chatSendMessageAtom = atom(
  null,
  async (get, set, message: ChatMessage, apiKey: string) => {
    const session = get(chatSessionActiveAtom);
    if (!apiKey) {
      toast.error("Please provide an API key");
      set(apiKeyModalAtom, true)
      return null
    }

    if (session) {
      const isStreaming = !!session?.isStreaming
      await handleSendMessageInit(
        get,
        set,
        session,
        messageToMessageToken(message),
        'client',
        isStreaming
      )
    }
  }
)

export const chatSendMessageCloudAtom = atom(
  null,
  async (get, set, message: ChatMessage, apiKey: string) => {
    const session = get(chatSessionActiveAtom);

    if (session) {
      const chatSessionId = session.id;
      const isStreaming = !!session?.isStreaming

      await handleSendMessageInit(
        get,
        set,
        session,
        messageToMessageToken(message),
        'cloud',
        isStreaming
      )
    }
  }
)

export const chatGenerateTitleAtom = atom(
  null,
  async (get, set, sessionId: string, message?: Partial<ChatMessage>) => {
    const sessions = get(chatSessionsAtom);
    const session = sessions.find(s => s.id === sessionId);

    if (session) {
      const modelFixed = message?.model
        || session.defaultModel
        || 'gpt-3.5-turbo';
      const { gptMessages } = compileGptMessages(session, undefined, defaultModelGptLimit(modelFixed).maxWords)
      if (!session.isSync) {
        const openAiApiKey = get(apiKeyAtom)?.key;
        const anthropicApiKey = get(apiKeyAnthropicAtom)?.key

        if (isModelAnthropic(modelFixed) && !anthropicApiKey) {
          toast.error('Please set API key first')
          return;
        } else if (!isModelAnthropic(modelFixed) && !openAiApiKey) {
          toast.error('Please set API key first')
          return;
        }

        const gpt = await aiChatRequestHandler(
          {
            openAi: openAiApiKey || '',
            anthropic: anthropicApiKey || ''
          },
          modelFixed,
          [
            ...gptMessages,
            {
              content: 'Generate a very short chat title for this conversation as character or personality less than 25 characters (prevent adding word Chat at the end and wrap with quote)): ',
              role: 'user',
            }
          ]
        );
        const gptContentString = getGPTContentToTextString(gpt.choices[0]?.message?.content || '')
        const newTitle = gptContentString?.replace('Chat', '')?.trim()?.replace(/^"|"$/g, '');
        set(chatSessionUpdateAtom, { name: newTitle, id: session.id })
      } else {
        const apiKey = get(apiKeyAtom)?.key;

        const messagePromptTitle: GPTChatMessage = {
          content: 'Generate a very short chat title for this conversation as character or personality less than 25 characters (prevent adding word Chat at the end and wrap with quote)): ',
          role: 'user',
        }

        const { newTitle } = await nextApiChatTitleGenerate({
          prevMessages: [
            ...gptMessages,
            messagePromptTitle,
          ],
          chatSessionId: session.id,
          defaultModel: modelFixed,
          apiKey,
          isUpdateTitle: true,
        })
        // set(chatUpdateTriggerAtom, prev => ([...prev, { chatSessionId: session.id, update: { id: session.id, name: newTitle || '' } }]))
        set(chatSessionUpdateAtom, { name: newTitle || '', id: session.id })
      }
    }
  }
)

export const chatAddMessageAtom = atom(
  null,
  (get, set, message: ChatMessage | ChatMessage[], sessionId?: string) => {
    let session: ChatSession | null | undefined;
    if (sessionId) {
      const sessions = get(chatSessionsAtom);
      session = sessions.find(s => s.id === sessionId);
    } else {
      session = get(chatSessionActiveAtom);
    }

    if (session) {
      const newSession = produce(session, draft => {
        if (Array.isArray(message)) {
          draft.messages.push(...message);
        } else {
          draft.messages.push(message);
        }
      })
      set(chatSessionActiveAtom, newSession)
    }
  }
)

export const chatRemoveMessageAtom = atom(
  null,
  // isSkip is for synced session to force remove message
  (get, set, id: string, sessionId?: string, isSkip?: boolean) => {
    let session: ChatSession | null | undefined;
    if (sessionId) {
      const sessions = get(chatSessionsAtom);
      session = sessions.find(s => s.id === sessionId);
    } else {
      session = get(chatSessionActiveAtom);
    }

    if (!session) {
      console.error('remove', { id, session })
      toast.error("Session not found");
      return null
    }

    if (session) {
      const chatSessionId = session.id;
      if (session.isSync && !session?.isInit && !isSkip) {
        set(chatUpdateTriggerAtom, prev => ([...prev, { chatSessionId, deleteMessage: { id, chatSessionId } }]))
      } else {
        const newSession = produce(session, draft => {
          draft.messages = draft.messages.filter(m => m.id !== id);
        })
        set(chatSessionActiveAtom, newSession)
      }
    }
  }
)

export const chatUpdateMessageAtom = atom(
  null,
  (get, set, message: Partial<ChatMessage>, param?: { chatSessionId?: string, isSkip?: boolean }) => {
    const { chatSessionId, isSkip } = param || {}
    let session: ChatSession | null | undefined;

    //~ VALIDATION
    if (chatSessionId) {
      const sessions = get(chatSessionsAtom);
      session = sessions.find(s => s.id === chatSessionId);
    } else {
      session = get(chatSessionActiveAtom);
    }

    if (!session) {
      return null
    }

    //~ IF SYNC
    if (session?.isSync && !session?.isInit && !isSkip) {
      const chatSessionId = session.id;
      if (chatSessionId && message?.id) {
        set(chatUpdateTriggerAtom, prev => ([
          ...prev,
          {
            chatSessionId,
            updateMessage: {
              ...message,
              chatSessionId,
              id: message.id || '',
              ...message.content?.content
                ? {
                  content: {
                    content: message.content?.content || '',
                    contentType: 'text'
                  },
                  totalTokens: messageContentTokens(message.content),
                }
                : {
                  content: undefined
                }
            }
          }
        ]))
      }
    } else {
      //~ IF NOT SYNC
      set(chatSessionActiveAtom, produce(session, draft => {
        const find = draft.messages.find(m => m.id === message.id);
        if (find && message) {
          Object.assign(find, {
            ...message,
            ...message.content ? {
              totalTokens: messageContentTokens(message.content)
            } : {},
            updated: new Date()
          })
        }
      }))
    }
  }
)

export const chatRegenerateMessageAtom = atom(
  null,
  async (get, set, update: { sessionId?: string, messageId?: string, messageContent?: ChatMessage['content'] }, apiKeyPassed?: string) => {
    const { sessionId, messageId } = update
    let session: ChatSession | null | undefined;
    if (sessionId) {
      const sessions = get(chatSessionsAtom);
      session = sessions.find(s => s.id === sessionId);
    } else {
      session = get(chatSessionActiveAtom);
    }

    const openAiApiKey = get(apiKeyAtom)?.key;
    const anthropicApiKey = get(apiKeyAnthropicAtom)?.key

    const isAnthropic = isModelAnthropic(session?.defaultModel || 'gpt-3.5-turbo')

    const apiKey = {
      openAi: !isAnthropic ? (apiKeyPassed || openAiApiKey || '') : (openAiApiKey || ''),
      anthropic: isAnthropic ? (anthropicApiKey || '') : (anthropicApiKey || ''),
    }

    if (session && session.messages.length > 0) {
      const sessionMessages = [...session.messages]
      const messageIdx = messageId
        ? sessionMessages.findIndex(m => m.id === messageId)
        : sessionMessages.length - 1

      if (messageIdx === -1) {
        toast.error('Message not found')
        return;
      }
      const isStreaming = session?.isStreaming

      const message = { ...sessionMessages[messageIdx], ...update?.messageContent ? { content: update?.messageContent } : {} } as ChatMessage

      // get all messages before the message + the message
      const messagesBefore = [...sessionMessages.slice(0, messageIdx), message]

      // Current message or last message
      const currentMessage = messageId
        ? sessionMessages.find(m => m.id === messageId) as ChatMessage
        : sessionMessages[sessionMessages.length - 1] as ChatMessage

      const modelFixed = currentMessage?.model
        || session.defaultModel
        || 'gpt-3.5-turbo';
      const modelSourceType = isAnthropic ? 'anthropic' : 'openai'

      const chatDefaultSettings = get(chatDefaultSettingsAtom)
      const defaultMaxTokensBySetting = (typeof chatDefaultSettings?.defaultMaxContextTokens === 'number' && chatDefaultSettings?.defaultMaxContextTokens)
        ? chatDefaultSettings?.defaultMaxContextTokens
        : defaultModelGptLimit(modelFixed).maxTokens

      const { newMessageFixed: newMessage, gptMessages } = compileGptMessages({ ...session, messages: messagesBefore }, undefined, defaultMaxTokensBySetting)

      const chatSessionId = session.id

      let newAIMessage: ChatMessage | undefined;

      if (!currentMessage) return;

      try {
        //~ SET AI RESPONSE LOADING
        const abortController = new AbortController();
        const newAIMessage: ChatMessage = {
          id: generateLocalIdByRole('ai'),
          chatSessionId: chatSessionId,
          content: {
            contentType: 'text',
            content: '',
          },
          userId: '',
          senderRole: 'assistant',
          created: new Date(),
          loading: true,
          abortSignal: abortController,
        }

        //~ FETCHING
        if (session?.isSync && !session?.isInit) {
          const onMessage = handleSendMessageInitCloud(get, set, session, currentMessage, newAIMessage)
          const currentMsgContentString = Array.isArray(currentMessage?.content?.content)
            ? currentMessage?.content?.content.join(' ')
            : currentMessage?.content?.content
          await handleSendMessageFetchCloud(
            get,
            set,
            session,
            {
              ...currentMessage,
              totalTokens: countTokens(currentMsgContentString || ''),
            },
            newAIMessage,
            apiKey,
            gptMessages,
            modelFixed,
            abortController,
            onMessage,
            isStreaming,
            { messageAction: 'update' },
            { type: 'regenerate' }
          )
        } else {
          console.log('STORE')
          set(chatAddMessageAtom, newAIMessage, chatSessionId)
          sessionMessages.push(newAIMessage)
          const modelSourceType = isAnthropic ? 'anthropic' : 'openai'
          const onMessage = handleSendMessageOnMessage(get, set, session, newAIMessage || currentMessage, undefined, modelSourceType)
          await handleSendMessageFetchClient(
            get,
            set,
            session,
            currentMessage,
            newAIMessage,
            apiKey,
            gptMessages,
            modelFixed,
            abortController,
            onMessage,
            isStreaming
          )
        }

        handleSendMessagePostFetch(get, set)

        //  set(chatSessionActiveAtom, newSessionReply);
      } catch (error: any) {
        if (error?.message === 'canceled') {
          return;
        }
        handleSendMessageError(get, set, session, newAIMessage?.id || '', error)
      }

    }
  }
)

