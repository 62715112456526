import { isModelAnthropic } from "../constant";
import { anthropicChatRequestHandler } from "./anthropic";
import { type GPTChatMessage, type GPTChatModel, type GPTChatResult, openAiChatRequestHandler, type OpenAiChatRequestHandlerExtra } from "./openai";

export const aiChatRequestHandler = async <T extends OpenAiChatRequestHandlerExtra | undefined>(
    apiKey: {
        openAi: string,
        anthropic: string
    },
    model: GPTChatModel,
    messages: GPTChatMessage[],
    signal?: AbortController,
    extra?: T
): Promise<T extends { useStreaming: true } ? null : GPTChatResult> => {
    if (isModelAnthropic(model)) {
        return anthropicChatRequestHandler(apiKey?.anthropic || '', model, messages, signal, extra)
    } else {
        return openAiChatRequestHandler(apiKey?.openAi || '', model, messages, signal, extra)
    }
}
