import { memo, Suspense, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react'
import { AiOutlineLoading, BiBulb, BiCheckbox, BiCheckCircle, Button, ConditionalRender, PopupWrapper, SwitchWithLabel, TextInputAreaLabelForm } from "@acme/ui";
import { api } from '@acme/client';
import { useAtom, useAtomValue } from 'jotai';
import { teamActiveAtom, userAtom } from '../store/user';
import { Tab } from '@headlessui/react';
import { chatSessionsAtom } from '../store/chat';
import { notesAtom } from '../store/note';
import { savedInstructionsAtom, savedPromptsAtom } from '../store/instruction';
import { apiKeyAtom } from '../store/setting';
import { generateNanoId } from '@acme/util';
import { Tooltip } from "@acme/ui"
import { useRouter } from 'next/router';
import { LayoutInit } from './LayoutInit';
import { appStorageAtom, appVersionCurrent } from '../store/util';

export function LayoutAll({ children }: { children: ReactNode }) {
  const router = useRouter();
  const path = router.pathname;
  const isIncludeInit = useMemo(() => {
    if (path === '/') return false;
    if (path === '/privacy') return false;
    if (path === '/tnc') return false;
    if (path === '/blog') return false;
    return true;
  }, [path]);

  return (
    <>
      {(isIncludeInit && router.isReady) && <LayoutInit />}
      <LayoutVersionCheck />
      {children}
    </>
  );
}

const LayoutVersionCheck = memo(() => {
  const router = useRouter()
  const [newVersionPopup, setNewVersionPopup] = useState(false);
  // this is the version when user first load
  const [localVersionLoad, setLocalVersionLoad] = useState(false);

  const [localVersion, setLocalVersion] = useAtom(appStorageAtom);

  const { data: dataVersion, isLoading: isLoadingVersion } = api.auth.getLatestVersion.useQuery(undefined, {
    onSuccess: (data) => {
      // check if version is different and if last check is more than 30 minutes
      if (localVersion?.lastCheck) {
        const lastCheck = new Date(localVersion?.lastCheck);
        const now = new Date();
        const diff = now.getTime() - lastCheck.getTime();
        const diffMinutes = Math.floor(diff / 1000 / 60);
        if (diffMinutes < 5) return;
      }
      if (data?.versionNumber && (data?.versionNumber > localVersion?.number)) {
        setNewVersionPopup(true);
      }
    },
    // staleTime 5 minutes
    staleTime: 1000 * 60 * 5
  })

  const onClickUpdate = useCallback(() => {
    setLocalVersion((prev) => ({
      ...prev,
      ...dataVersion?.versionNumber && { number: dataVersion?.versionNumber },
      ...dataVersion?.version && { version: dataVersion?.version },
      lastCheck: new Date()
    }))
    setNewVersionPopup(false);
    router.reload();
  }, [dataVersion?.version, dataVersion?.versionNumber, router, setLocalVersion])

  const onClickSkip = useCallback(() => {
    setLocalVersion((prev) => ({
      ...prev,
      lastCheck: new Date()
    }))
    setNewVersionPopup(false);
  }, [setLocalVersion])

  useEffect(() => {
    if (!localVersionLoad && router.isReady) {
      setLocalVersion((prev) => ({ ...prev, lastCheck: new Date(), number: appVersionCurrent.number, version: appVersionCurrent.version }))
      setLocalVersionLoad(true);
    }
  }, [localVersionLoad, router.isReady, setLocalVersion])

  return (
    <>
      {/** //~ VERSION  */}
      <PopupWrapper className='!bg-transparent' containerClassName="z-[100003]" noClose onClose={() => null} show={newVersionPopup && router?.isReady}>
        <div className='max-w-full w-[400px] max-h-full h-auto text-white p-4'>
          <div className='bg-gray-800 rounded-lg shadow-xl px-10 py-8'>
            <h1 className='text-3xl font-bold text-center mb-8 text-gray-100'>New Version Available</h1>
            <div className='text-gray-100 mb-8 text-xl'>
              <p className='mb-6'>Good news - there is a new version available.</p>
            </div>
            <div className='text-center mt-4 flex flex-col gap-5'>
              <Button onClick={onClickUpdate} className='px-8 !py-2 bg-blue-500 text-white rounded-full hover:bg-blue-700 transition duration-300 ease-in-out'>
                <p className='text-lg'>Refresh Now</p>
              </Button>
              <p onClick={onClickSkip} className='text-sm cursor-pointer text-gray-400 hover:text-gray-300 transition duration-300 ease-in-out'>Skip This Version</p>
            </div>
          </div>
        </div>
      </PopupWrapper>
    </>
  )
})
LayoutVersionCheck.displayName = 'LayoutVersionCheck';

export const AccountSyncPopup = () => {
  const { data: session } = api.auth.getSession.useQuery();
  const [step, setStep] = useState(3);
  const [syncing, setSyncing] = useState(false);
  const [loadingText, setLoadingText] = useState('Finishing...');

  const { mutate: mutateSetSynced, isLoading: isLoadingSyncingAccount } = api.user.meSynced.useMutation({
    onSuccess: () => setUser(prev => ({ ...prev, isSynced: true }))
  });
  const { mutateAsync: mutateInit, isLoading: isLoadingInit, isSuccess: isSuccessInit } = api.sync.syncInit.useMutation();
  const { mutate: mutateChat, isLoading: isLoadingChat, isSuccess: isSuccessChat } = api.sync.syncChatSession.useMutation();
  const { mutate: mutateNote, isLoading: isLoadingNote, isSuccess: isSuccessNote } = api.sync.syncNotes.useMutation();
  const { mutate: mutateInstruction, isLoading: isLoadingIns, isSuccess: isSuccessIns } = api.sync.syncInstructions.useMutation();
  const { mutate: mutatePrompt, isLoading: isLoadingPrompt, isSuccess: isSuccessPrompt } = api.sync.syncPrompts.useMutation();

  const chatSessionsList = useAtomValue(chatSessionsAtom);
  const notesList = useAtomValue(notesAtom);
  const instructionsList = useAtomValue(savedInstructionsAtom)
  const promptsList = useAtomValue(savedPromptsAtom)
  const [user, setUser] = useAtom(userAtom);
  const [teamActive, setTeamActive] = useAtom(teamActiveAtom);

  const onSync = useCallback(async (data: { apiKey?: string } & ChatNote & Ins) => {
    const userId = session?.user.id

    setSyncing(true);
    const { id: userTeamId, name: userTeamName } = await mutateInit({ apiKey: data.apiKey });
    setTeamActive({ id: userTeamId, name: userTeamName })

    const checkedChats = data.chats.filter(a => a.checked);
    const checkedNotes = data.notes.filter(a => a.checked);
    const checkedInstructions = data.instructions.filter(a => a.checked);
    const checkedPrompts = data.prompts.filter(a => a.checked);

    /* const chats: RouterInputs['sync']['syncChatSession']['chatSessions'] = chatSessionsList
      .filter(a => checkedChats.some(b => b.id === a.id))
      .map(a => {
        const defaultModel = a.defaultModel || a?.messages?.[0]?.model || 'gpt-3.5-turbo'
        return {
          ...a,
          userId,
          defaultModel,
          messages: a.messages.map(({ userId, senderRole, ...b }) => ({ ...b, senderRole, ...senderRole === 'user' && { userId } })),
        }
      });
    mutateChat({ chatSessions: chats });
 
    const notes: RouterInputs['sync']['syncNotes']['notes'] = notesList
      .filter(a => checkedNotes.some(b => b.id === a.id))
      .map(a => ({ ...a, userId }));
 
    mutateNote({ notes, userTeamId });
 
    const instructions: RouterInputs['sync']['syncInstructions']['instructions'] = instructionsList
      .filter(a => checkedInstructions.some(b => b.id === a.id))
      .map(a => ({ ...a }));
 
    mutateInstruction({ instructions, userTeamId });
 
    const prompts: RouterInputs['sync']['syncPrompts']['prompts'] = promptsList
      .filter(a => checkedPrompts.some(b => b.id === a.id))
      .map(a => ({ ...a, userId }));
 
    mutatePrompt({ prompts, userTeamId }); */
  }, [chatSessionsList, instructionsList, mutateChat, mutateInit, mutateInstruction, mutateNote, mutatePrompt, notesList, promptsList, session?.user.id, setTeamActive]);

  useEffect(() => {
    if (step === 3 && syncing) {
      if (isLoadingChat) {
        setLoadingText('Syncing Chat Sessions...')
      } else if (isLoadingNote) {
        setLoadingText('Syncing Notes...')
      } else if (isLoadingIns) {
        setLoadingText('Syncing Instructions...')
      } else if (isLoadingPrompt) {
        setLoadingText('Syncing Prompts...')
      } else if (isLoadingInit) {
        setLoadingText('Preparing...')
      } else if (isLoadingSyncingAccount) {
        setLoadingText('Preparing...')
      } else {
        setLoadingText('Preparing...')
        setSyncing(false)
      }
    }
  }, [step, isLoadingChat, isLoadingNote, isLoadingIns, isLoadingPrompt, setUser, user, isLoadingInit, syncing, isLoadingSyncingAccount])

  useEffect(() => {
    if (/* isSuccessChat && isSuccessNote && isSuccessIns && isSuccessPrompt &&  */isSuccessInit) {
      mutateSetSynced()
    }
  }, [isSuccessChat, isSuccessNote, isSuccessIns, isSuccessPrompt, isSuccessInit, mutateSetSynced])

  return (
    <div className='w-full relative transition-all duration-300'>
      {/* <Button className='absolute z-20' onClick={() => setSyncing(!syncing)}>Test</Button> */}
      <div className={`relative transition-all duration-300 ${(syncing || isLoadingSyncingAccount) ? 'opacity-0 invisible ' : 'opacity-100 visible'}`}>
        <Suspense fallback={<Text />}>
          <Tabs step={step} setStep={setStep} onSync={onSync} />
        </Suspense>
      </div>
      <div className={`transition-all duration-500 absolute w-full h-full top-0 flex flex-col items-center justify-center gap-2  ${(syncing || isLoadingSyncingAccount) ? 'animate-pulse opacity-100 visible' : 'opacity-0 invisible'}`}>
        <AiOutlineLoading className='animate-spin text-3xl' />
        <p className='text-xl font-bold'>{loadingText}</p>
        <p>Do not close this window</p>
      </div>
    </div>
  )
}

const Text = () => <p>Loading...</p>

const today = new Date();

const Tabs = memo(({ step, setStep, onSync }: TabsProps) => {
  const { isReady } = useRouter()
  const [timeoutLocalStore, setTimeoutLocalStore] = useState(false);
  const [datas, setDatas] = useState<ChatNote>({
    chats: [],
    notes: [],
  })
  const [isAddApiKey, setIsAddApiKey] = useState(false);
  const [ins, setIns] = useState<Ins>({
    instructions: [],
    prompts: []
  });
  const [setup, setSetup] = useState(false);
  const [setup2, setSetup2] = useState(false);

  const chatSessionsList = useAtomValue(chatSessionsAtom);
  const notesList = useAtomValue(notesAtom);
  const instructionsList = useAtomValue(savedInstructionsAtom)
  const promptsList = useAtomValue(savedPromptsAtom)
  const [apiKey, setApiKey] = useAtom(apiKeyAtom);

  const datasFixed = useMemo(() => {
    return {
      chats: chatSessionsList.map((chatSession) => ({ id: chatSession.id, title: chatSession.name, checked: true })),
      notes: notesList.map((note) => ({ id: note.id, title: note.name, checked: true })),
    }
  }, [notesList, chatSessionsList])

  const insFixed = useMemo(() => {
    return {
      instructions: instructionsList
        .filter(a => !a.isDefault)
        .map((instruction) => ({ id: instruction.id, title: instruction.name || instruction.instruction.slice(0, 20), checked: true })),
      prompts: promptsList
        .filter(a => !a.isDefault)
        .map((prompt) => ({ id: prompt.id, title: prompt.name || prompt.prompt.slice(0, 20), checked: true })),
    }
  }, [instructionsList, promptsList])

  const hasChecked = useMemo(() => {
    return {
      chats: datas.chats.some(a => a.checked),
      notes: datas.notes.some(a => a.checked),
      instructions: ins.instructions.some(a => a.checked),
      prompts: ins.prompts.some(a => a.checked),
    }
  }, [datas, ins])

  const onCheckDatas = (property: 'chats' | 'notes' | 'instructions' | 'prompts', id: string) => {
    if (property === 'instructions' || property === 'prompts') {
      setIns(prev => ({
        ...prev,
        [property]: prev[property].map((data) => {
          if (data.id === id) return { ...data, checked: !data.checked }
          return data;
        })
      }))
    } else {
      setDatas(prev => ({
        ...prev,
        [property]: prev[property].map((data) => {
          if (data.id === id) return { ...data, checked: !data.checked }
          return data;
        })
      }))
    }
  }

  const onCheckDatasAll = (property: 'chats' | 'notes' | 'instructions' | 'prompts', checked: boolean) => {
    if (property === 'instructions' || property === 'prompts') {
      setIns(prev => ({
        ...prev,
        [property]: prev[property].map((data) => {
          return { ...data, checked }
        })
      }))
    } else {
      setDatas(prev => ({
        ...prev,
        [property]: prev[property].map((data) => {
          return { ...data, checked }
        })
      }))
    }
  }

  const onNext = () => {
    if (step === 3) {
      onSync({
        ...datas,
        ...ins,
        // if sync to cloud then add the api key
        ...isAddApiKey ? { apiKey: apiKey?.key } : {}
      })
    } else {
      if (step === 1 && instructionsList.length === 0 && promptsList.length === 0) {
        setStep(step + 2)
      } else {
        setStep(step + 1)
      }
    }
  }

  const keys1 = Object.keys(datas) as ('chats' | 'notes')[]
  const keys2 = Object.keys(ins) as ('instructions' | 'prompts')[]

  useEffect(() => {
    if (isReady && timeoutLocalStore && datasFixed && (!datas.chats?.length || !datas.notes.length) && !setup) {
      console.log({ datasFixed })
      setDatas(datasFixed)
      setSetup(true)
    }
  }, [datas.chats?.length, datas.notes.length, datasFixed, setup, isReady, timeoutLocalStore])

  useEffect(() => {
    if (isReady && timeoutLocalStore && insFixed && (!ins.instructions?.length || !ins.prompts.length) && !setup2) {
      setIns(insFixed)
      setSetup2(true)
    }
  }, [ins.instructions?.length, ins.prompts.length, insFixed, setup2, isReady, timeoutLocalStore])

  useEffect(() => {
    if (isReady && !timeoutLocalStore) {
      setTimeout(() => {
        setTimeoutLocalStore(true)
      }, 1000);
    }
  }, [isReady, timeoutLocalStore])

  return (
    <>
      <div className="w-full max-w-md px-1 py-5 pb-10 sm:px-0" >
        <ConditionalRender show={step <= 2}>
          <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              {Object.keys(step === 2 ? ins : datas).map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 capitalize',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-white shadow'
                        : 'text-blue-300 hover:bg-white/[0.12] hover:text-white'
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
              {(data) => {
                const key = step === 2 ? keys2[data.selectedIndex] || 'chats' : keys1[data.selectedIndex] || 'chats'
                const isUnchecked = hasChecked[key]
                return (
                  <>
                    {Object
                      .values(step === 2 ? ins : datas)
                      .map((posts, idx) => (
                        <Tab.Panel
                          key={idx}
                          className={classNames(
                            'rounded-xl bg-white p-3 pr-0',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                          )}
                        >
                          <Button
                            onClick={() => onCheckDatasAll(key, !isUnchecked)}
                            className='!py-1 mb-1 text-sm ml-auto mr-2'
                          >
                            {isUnchecked ? 'Unchecked All' : 'Checked All'}
                          </Button>
                          <div className='max-h-[300px] overflow-y-auto'>
                            <div className='bg-gray-100 pr-1 pl-1 rounded-lg mr-2 py-1'>
                              {posts.length < 1 && (
                                <p className='text-center text-gray-700'>
                                  No {key} found
                                </p>
                              )}
                              <ul className="w-full flex flex-col gap-1">
                                {(posts || []).map((post) => (
                                  <li
                                    key={post.id}
                                    onClick={() => onCheckDatas(key, post.id)}
                                    className={`relative cursor-pointer rounded-md p-2 text-gray-800 hover:bg-gray-300 flex flex-row items-center gap-3 ${post.checked ? 'bg-gray-200' : ''}`}
                                  >
                                    <div>
                                      {post.checked ? <BiCheckCircle className='text-xl text-blue-600' /> : <BiCheckbox className='text-xl' />}
                                    </div>
                                    <h3 className="text-sm font-medium leading-5">
                                      {post.title}
                                    </h3>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Tab.Panel>
                      ))}
                  </>
                )
              }}
            </Tab.Panels>
          </Tab.Group>
        </ConditionalRender>
        <ConditionalRender show={step === 3}>
          <div className='flex flex-col gap-2'>
            <div className='bg-yellow-300 text-gray-800 rounded-lg p-3'>
              <div className='flex flex-row items-center gap-3'>
                <BiBulb />
                <p>Don't have open ai api key?</p>
                <a href='https://platform.openai.com/account/api-keys' target='_blank' rel='noreferrer' className='text-blue-600 underline'>Get it here</a>
              </div>
            </div>
            <TextInputAreaLabelForm
              value={apiKey?.key || ''}
              onChange={(e) => setApiKey(prev => ({ id: prev?.id || generateNanoId(14), created: today, ...prev, key: e }))}
              label='OpenAI API Key'
              placeholder='API Key'
              className='!py-1 text-black'
            />
            <Tooltip message="API Key should be added if you have synced data, public chat / note or extension">
              <SwitchWithLabel
                value={isAddApiKey}
                onChange={() => setIsAddApiKey(prev => !prev)}
                size='small'
                textClassName='text-xs'
                label='Save your key to cloud (only if you save chat / note in the cloud or using our chrome extension)'
              />
            </Tooltip>
          </div >
        </ConditionalRender>
      </div>

      <div className='flex flex-row items-center gap-1'>
        {/* {step !== 1 && (
          <Button
            onClick={() => setStep(step > 1 ? step - 1 : 1)}
            className='!py-2 mb-1 w-[130px] text-black-700 !bg-transparent border'
          >
            Previous
          </Button>
        )} */}
        <Button
          onClick={onNext}
          className='!py-2 mb-1 w-full text-black-700 !bg-black'
        >
          {step === 3 ? 'Done' : 'Next'}
        </Button>
      </div>
      <Button
        onClick={onNext}
        className='!py-2 mb-1 w-full text-black-700 !bg-transparent !text-gray-700'
      >
        Skip
      </Button>
    </>
  )
})

Tabs.displayName = 'Tabs'

type TabsProps = {
  step: number;
  setStep: (step: number) => void;
  onSync: (data: { apiKey?: string } & ChatNote & Ins) => void;
}

type ChatNote = {
  chats: {
    id: string;
    title: string;
    checked: boolean;
  }[];
  notes: {
    id: string;
    title: string;
    checked: boolean;
  }[];
}

type Ins = {
  instructions: {
    id: string;
    title: string;
    checked: boolean;
  }[];
  prompts: {
    id: string;
    title: string;
    checked: boolean;
  }[];
}

const classNames = (class1: string, class2?: string, class3?: string, class4?: string, class5?: string) => {
  return `${class1} ${class2} ${class3} ${class4} ${class5}`
}
