// import { NotionEditor } from "@acme/ui"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import dynamic from "next/dynamic"
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { noteActiveAtom, noteActiveIdAtom, noteAddAtom, notesAtom, noteThinkingAtom } from "../store/note";
import { useRouter } from "next/router";
import { type BlockNote, debounce, generateNanoId, defaultModelPrismaToString, dayjs, Note, type NotePrisma, deepEqual } from "@acme/util";
import { Button, PopupWrapper } from "@acme/ui";
import { api } from "@acme/client";
import { notesNoContentAtom } from "./NoteList";
import { useDebounceCallback } from "../hooks";

const NoteEditorDynamic = dynamic(() => import("./NoteEditorDynamic"), {
  ssr: false,
});

export const NoteEditor = memo(() => {
  const { push, query, isReady } = useRouter()
  const [isOverwrite, setIsOverwrite] = useState<{ show: boolean, onUseCloud?: () => void, onUseLocal?: () => void, onUseNew?: () => void }>({ show: false })
  const notes = useAtomValue(notesNoContentAtom)
  const [note, setNote] = useAtom(noteActiveAtom)
  const [noteActiveId, setActiveNoteId] = useAtom(noteActiveIdAtom)
  const [thinkings, onUpdateThinking] = useAtom(noteThinkingAtom)
  const setNotes = useSetAtom(notesAtom)
  const setAddNewNote = useSetAtom(noteAddAtom)

  const isThinking = useMemo(() => thinkings.some(a => a.noteId === noteActiveId && a.loading), [thinkings, noteActiveId])
  const { isLoading: isSaving, mutate: onSaveNoteCloud } = api.note.updateNote.useMutation({
    onError: () => {
      console.log('error')
      setNote({ isUnsaved: true })
    },
    onSuccess: () => {
      setNote({ isSync: true, isUnsaved: false, lastSync: new Date() })
    },
  })

  // Currently using stale time but keep this
  const shouldResync = useMemo(() => {
    if (note?.isSync && !note?.isInit) {
      // if lastSync more than 1 minutes ago then resync
      if (!note.lastSync) return true
      else if (note.lastSync && new Date(note.lastSync).getTime() < new Date().getTime() - 1000 * 60 * 1) {
        return true
      }
      else return false
    }
    return false
  }, [note?.isSync, note?.isInit, note?.lastSync])

  const setNoteFromNoteData = (notedata: NotePrisma) => {
    setNote({
      name: notedata?.name || note?.name || '',
      content: notedata?.content || note?.content || [],
      markdown: notedata?.markdown || note?.markdown || '',
      defaultModel: notedata?.defaultModel ? defaultModelPrismaToString(notedata?.defaultModel) : note?.defaultModel || undefined,
      defaultContinueInstruction: notedata?.defaultContinueInstruction || note?.defaultContinueInstruction || '',
      defaultWriteInstruction: notedata?.defaultWriteInstruction || note?.defaultWriteInstruction || '',
      defaultSelectInstruction: notedata?.defaultSelectInstruction || note?.defaultSelectInstruction || '',
      updated: notedata?.updated ? new Date(notedata?.updated) : note?.updated || new Date(),
      orderUpdated: notedata?.orderUpdated ? new Date(notedata?.orderUpdated) : note?.orderUpdated || new Date(),
      lastSync: new Date()
    })
  }

  const { isLoading: isLoadingSync } = api.note.note.useQuery(
    { id: note?.id || '' },
    {
      enabled: !!note?.id && !!note.isSync && !note.isInit,// && shouldResync,
      onSuccess: (data) => {
        if (data && note) {
          const { team, ...notedata } = data
          const serverToUpdate = dayjs(notedata?.updated).isAfter(dayjs(note?.updated));

          if (serverToUpdate) {
            if (!note.isUnsaved) {
              setNoteFromNoteData(notedata)
            } else {
              const dataCloud = data?.content
              const dataLocal = note?.content

              if (!deepEqual(dataCloud, dataLocal)) {
                // Show a prompt to the user to decide whether to overwrite the local changes or create a new note.
                setIsOverwrite({
                  show: true,
                  onUseCloud: () => {
                    setNoteFromNoteData(notedata)
                    setIsOverwrite({ show: false })
                  },
                  onUseLocal: () => {
                    onSaveNoteCloud({ id: note.id, content: note.content, markdown: note.markdown })
                    setIsOverwrite({ show: false })
                  },
                  onUseNew: () => {
                    setIsOverwrite({ show: false })
                    const newId = generateNanoId(14)
                    setAddNewNote({
                      id: newId,
                      name: `${note.name} Copy`,
                      content: note.content,
                      markdown: note.markdown,
                    })
                    setActiveNoteId(newId)
                    pushRouter(newId)
                  }
                })
              }
            }
          }
        }
      },
      cacheTime: 0.5 * (60 * 1000),
      // staleTime 20 seconds
      staleTime: 20 * 1000,
    }
  )

  const pushRouter = useCallback(async (id: string) => {
    await push({ query: { ...query, noteId: id } })
  }, [push, query])

  useEffect(() => {
    if (!note && isReady && notes !== undefined) {
      if (query.noteId && notes.length) {
        const findNote = notes.find((n) => n.id === query.noteId)
        if (findNote) {
          setActiveNoteId(findNote.id)
        }
      } else if (notes.length && notes[0]) {
        setActiveNoteId(notes[0].id)
        pushRouter(notes[0].id)
      } else {
        const newId = generateNanoId(14)
        setNotes(prev => [...prev, { id: newId, name: 'New Note', content: [], markdown: '', created: new Date() }])
        setActiveNoteId(newId)
        pushRouter(newId)
      }
    }
  }, [isReady, note, notes, pushRouter, query.noteId, setActiveNoteId, setNotes])

  const onCancelResponse = async () => {
    await Promise.all(
      thinkings.map(a => { a.abortSignal?.abort() })
    )
    onUpdateThinking(prev => prev.filter(a => a.noteId !== noteActiveId))
  }

  const onDebounceSave = useCallback(debounce((blocks: BlockNote[], markdown: string /* onGetMarkdown: () => Promise<string> */, noteParam: { id: string, isSync?: boolean }) => {
    const { id, isSync } = noteParam
    if (isSync) {
      const blocksFixed = blocks.map(b => {
        const { abortSignal, error, loading, ...rest } = b
        return rest
      })
      //const markdown = await onGetMarkdown()
      if (isLoadingSync && !!note?.isSync && !note?.isInit) return
      onSaveNoteCloud({ id: id, content: blocksFixed, markdown })
    }
  }, 3000), [])

  const onDebounceChange = useDebounceCallback(async (value: BlockNote[], onGetMarkdown: () => Promise<string>, noteParam: { id: string; isSync?: boolean | undefined; }) => {
    const markdown = await onGetMarkdown()
    setNote({ id: note?.id, content: value, markdown })
    if (note?.isSync) {
      onDebounceSave(value, markdown, { id: note?.id, isSync: note?.isSync })
    }
    return null
  }, 500, [note?.id, note?.isSync, onDebounceSave, setNote])

  if (!note) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2">
      {/* <div className="absolute top-10 z-0">
        <ChatBubble
          role="system"
          message={{ content: note.defaultInstruction, contentType: 'text' }}
          id={""}
          sessionId={""}
        />
      </div> */}

      <PopupWrapper show={isOverwrite?.show} onClose={() => null}>
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-bold mb-4">Conflict</h2>
            <p>There is a data in the cloud newer than your local data. What do you want to do?</p>
            <div className="flex flex-col justify-start gap-3 space-x-4 mt-6">
              <Button
                className="bg-red-500 text-white flex flex-row items-center gap-2 !py-1"
                onClick={() => isOverwrite?.onUseCloud && isOverwrite?.onUseCloud()}
              >
                <span>Use the Cloud and Discard Local</span>
              </Button>
              <Button
                className="bg-red-500 text-white flex flex-row items-center gap-2 !py-1 !ml-0"
                onClick={() => isOverwrite?.onUseLocal && isOverwrite?.onUseLocal()}
              >
                <span>Use Local data and Update the Cloud</span>
              </Button>
              <Button
                className="bg-red-500 text-white flex flex-row items-center gap-2 !py-1 !ml-0"
                onClick={() => isOverwrite?.onUseNew && isOverwrite?.onUseNew()}
              >
                <span>Add Local Data to new Note</span>
              </Button>
            </div>
          </div>
        </div>
      </PopupWrapper>
      {isThinking && (
        <PopupWrapper containerClassName="!absolute !w-full !bg-opacity-30" onClose={() => null} noClose>
          <div className="w-[200px]">
            <div className="bg-white font-bold">
              AI is Thinking...
            </div>
            <div>
              Wait for a moment
            </div>
            <div className="mt-3 text-xs text-gray-500">
              Note: We are working to improve this experience
            </div>
            <Button onClick={onCancelResponse} className="!py-1 w-full mt-4 bg-red-500">
              Cancel
            </Button>
          </div>
        </PopupWrapper>
      )}
      <div className="pt-10 pr-2 pb-20" style={{ width: "100%; height:100vh; background:white" }}>
        {isLoadingSync && (
          <p className="absolute top-12 right-12 text-xs text-gray-800 dark:text-neutral-200">Syncing...</p>
        )}
        <NoteEditorDynamic
          id={note?.id || ''}
          isSync={note?.isSync}
          value={note?.content || []}
          onChange={onDebounceChange}
        />
      </div>
      {/* Middle Content */}
    </div>
  )
})

NoteEditor.displayName = "NoteEditor"
