import { AnimatePresence, motion } from "framer-motion"
import { ButtonClose } from "../Button"
import { memo, useEffect } from "react"

type Props = {
  show?: boolean
  children: React.ReactNode
  containerClassName?: string
  className?: string
  onClose: () => void
  title?: string
  noClose?: boolean
}

export const PopupWrapper = ({ show, children, className, onClose, containerClassName, noClose = false }: Props) => {
  if (!show) return null
  return (
    <div className={`fixed top-0 right-0 z-50 w-screen h-screen flex items-center justify-center bg-gray-800 bg-opacity-50 ${containerClassName}`}>
      <div className="absolute top-0 left-0 w-screen h-screen m-5" onClick={onClose} />
      <div className={`bg-white rounded shadow z-50 p-5 relative ${className}`}>
        {children}
        {noClose ? null : <ButtonClose onClick={onClose} />}
      </div>
    </div>
  )
}

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: { y: "-50vh", opacity: 0 },
  visible: { y: "0", opacity: 1, transition: { delay: 0.2 } },
};


export const PopupWrapperAnimated = memo(({ show, ...allProps }: Props) => {
  if (!show) return null
  return (
    <PopupWrapperAnimatedComponent
      show={show}
      {...allProps}
    />
  )
})
PopupWrapperAnimated.displayName = 'PopupWrapperAnimated'

const PopupWrapperAnimatedComponent = ({ show, children, className, onClose, containerClassName, noClose = false }: Props) => {

  // function to close popup when click esc 
  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  // event listener when component mount
  useEffect(() => {
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <AnimatePresence mode="wait">
      {show && (
        <motion.div
          className={`fixed py-5 inset-0 z-[100000] flex items-center justify-center bg-gray-900 bg-opacity-50 h-screendvh max-h-screendvh overflow-y-auto ${containerClassName}`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={backdrop}
          transition={{ duration: 0.2 }}
          onClick={onClose}
        >
          <motion.div
            className={`absolute inset-0 backdrop-blur-sm bg-opacity-50 max-h-full w-full h-full`}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={backdrop}
            transition={{ duration: 0.2 }}
            onClick={onClose}
          />
          <motion.div
            className={`rounded-lg shadow-xl w-auto relative z-10 ${className}`}
            variants={modal}
            onClick={(e) => e.stopPropagation()}
          >
            {/* <ButtonClose className="-mt-5 -mr-5" onClick={onClose} /> */}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
