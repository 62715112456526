import { type ReactNode } from "react"

interface HeaderMainProps {
  title: string | ReactNode
  children: ReactNode
}

export const HeaderMenu = ({ title, children }: HeaderMainProps) => {
  return (
    <header className="w-full p-1 lg:px-4 h-12 border-b border-gray-200 dark:border-neutral-700 z-10">
      {/* Header Content */}
      <div className="flex flex-row items-center justify-between h-full">
        <h1 className="text-lg font-semibold">
          {title}
        </h1>
        <div className="flex flex-row gap-2 items-center">
          {children}
        </div>
      </div>
    </header>
  )
}