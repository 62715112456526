import { api } from "@acme/client"
import { AiFillCrown, AiOutlineKey, BiChevronDown, BsChat, BsFillCheckCircleFill, BsPeople, BsPlus, Button, ConditionalRenderWithElse, InstructionCardSmall, TextInputLabelForm, useLatestMessagesAlternatives } from "@acme/ui"
import { type ChatModel, deepEqual, openAiChatModels, type ChatMessage, defaultModelPrismaToString, type SavedInstructionWithIconColor, chatInstructionsDefault, generateNanoId, generateLocalIdByRole } from "@acme/util"
import { Menu, Transition } from "@headlessui/react"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { selectAtom } from "jotai/utils"
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { chatConversationTriggerAtom, chatSessionActiveAtom, chatSessionsAddNewAtom } from "../store/chat"
import { modelPopupAtom, newInstructionPopupAtom, savedInstructionsAtom } from "../store/instruction"
import { apiKeyModalAtom, upgradeModalAtom, userAtom } from "../store/user"
import { ChatBubble } from "./ChatBubble"
import { useRouter } from "next/router"
import { ChatSyncToCloudButton } from "./ChatSettings"
import { useUserSubscription } from "../hooks"
import { apiKeyAtom, chatDefaultSettingsAtom } from "../store/setting"

export const ChatConversation = () => {
  const router = useRouter()
  const contentId = router.query?.messageId as string
  const bottomRef = useRef<HTMLDivElement>(null);
  const [chatSession, setChatSession] = useAtom(chatSessionActiveAtom)
  const [activeSessionIdScrolled, setActiveSessionIdScrolled] = useState('')
  const [chatConversationTrigger, setChatConversationTrigger] = useAtom(chatConversationTriggerAtom)

  const shouldResync = useMemo(() => {
    if (chatSession?.isSync && !chatSession?.isInit) {
      // if lastSync more than 1 minutes ago then resync
      if (!chatSession.lastSync) return true
      else if (chatSession.lastSync && new Date(chatSession.lastSync).getTime() < new Date().getTime() - 1000 * 60 * 1) {
        return true
      }
      else return false
    }
    return false
  }, [chatSession?.isSync, chatSession?.isInit, chatSession?.lastSync])

  const { isLoading } = api.chat.chatSession.useQuery(
    { id: chatSession?.id || '' },
    {
      enabled: !!chatSession?.id && !!chatSession.isSync && !chatSession.isInit && shouldResync,
      onSuccess: (data) => {
        if (data && chatSession) {
          const dataMessages: ChatMessage[] = data.messages.map(m => ({
            ...m,
            parentId: m.parentId || undefined,
            replyToId: m.replyToId || undefined,
            userId: m.userId || undefined,
            model: m.model ? defaultModelPrismaToString(m.model) : undefined,
            content: m.content,
            contentCustoms: m?.contentCustoms || undefined,
            totalTokens: m.totalTokens || undefined,
            updated: m.updated || undefined,
          }))
          const { team, messages, localId, ...allOther } = data
          setChatSession({ ...chatSession, lastSync: new Date(), messages: dataMessages })
        }
      }
    }
  )

  const messages = chatSession?.messages || []

  const scrollTo = (id?: string) => {
    if (bottomRef.current && bottomRef.current.scrollIntoView) {
      if (!id)
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      else {
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  useEffect(() => {
    if (chatSession?.id !== activeSessionIdScrolled) {
      scrollTo()
      setActiveSessionIdScrolled(chatSession?.id || '')
    }
  }, [activeSessionIdScrolled, chatSession?.id])

  useEffect(() => {
    if (chatConversationTrigger?.scrollToBottom) {
      scrollTo()
      setChatConversationTrigger(prev => ({ ...prev, scrollToBottom: false }))
    }
  }, [chatConversationTrigger?.scrollToBottom, setChatConversationTrigger])

  useEffect(() => {
    if (chatConversationTrigger?.scrollToId) {
      scrollTo(chatConversationTrigger?.scrollToId)
      setChatConversationTrigger(prev => ({ ...prev, scrollToId: undefined }))
    }
  }, [chatConversationTrigger?.scrollToId, setChatConversationTrigger])

  useEffect(() => {
    if (contentId && router?.isReady) {
      // remove contentId from url except other params
      const replaceUrl = async () => {
        const { pathname, query } = router
        delete query.messageId
        await router.replace({ pathname, query }, undefined, { shallow: true })
      }
      setTimeout(() => {
        scrollTo(contentId)
        replaceUrl()
      }, 300);
    }
  }, [contentId, router])

  return (
    <div key={chatSession?.id}>
      <ConditionalRenderWithElse
        show={messages.length > 0 || !!chatSession?.defaultInstruction}
        elseChildren={
          <>
            {!chatSession?.id ? (
              <ChatConversationNotSelected />
            ) : (
              <ChatConversationInit />
            )}
          </>
        }
      >
        <>
          <ChatMessages
            key={chatSession?.id}
            defaultInstruction={chatSession?.defaultInstruction}
            messages={messages}
            chatSessionId={chatSession?.id || ''}
          />
          <div className="pt-10" ref={bottomRef}></div>
        </>
      </ConditionalRenderWithElse>
    </div >
  )
}

const ChatMessages = ({ messages, defaultInstruction, chatSessionId }: { chatSessionId: string, messages: ChatMessage[], defaultInstruction?: string }) => {
  const messagesFixed = useLatestMessagesAlternatives(messages || [])
  const user = useAtomValue(userAtom)
  return (
    <>
      {defaultInstruction && (
        <ChatBubble
          key={`instruction-${chatSessionId}`}
          id={'instruction'}
          sessionId={''}
          isMe={false}
          role={'system'}
          message={{ contentType: 'text', content: defaultInstruction || '' }}
        />
      )}
      {messages
        .map(a => (
          <ChatBubble
            key={`${chatSessionId}-${a.id}`}
            id={a.id}
            sessionId={a.chatSessionId}
            isMe={a.userId === user.id && a.senderRole === 'user'}
            role={a.senderRole}
            created={a.created}
            message={a.content}
            messageCustoms={a.contentCustoms}

            isGenerating={a.generating}
            isLoading={a.loading}
            loadingText={a.loadingText}
            abortCtrl={a.abortSignal}
          />
        ))
      }
    </>
  )
}
ChatMessages.displayName = 'ChatMessages'

const ChatConversationNotSelected = memo(() => {
  const router = useRouter()
  const { isAllowAddChatSession, isSubscribe } = useUserSubscription()
  const apiKey = useAtomValue(apiKeyAtom)
  const onNewChat = useSetAtom(chatSessionsAddNewAtom)
  const setApiKeyModal = useSetAtom(apiKeyModalAtom)
  const onSetUpgradeModal = useSetAtom(upgradeModalAtom)
  const chatDefaultSettings = useAtomValue(chatDefaultSettingsAtom)

  const handleNewChat = async () => {
    const newId = await onNewChat({ isAllowAddChatSession, ...chatDefaultSettings?.defaultModel ? { defaultModel: chatDefaultSettings.defaultModel } : {} })
    if (newId) {
      router.push({ query: { chatId: newId } })
    }
  }

  const onUpgrade = () => {
    const today = new Date();
    onSetUpgradeModal({ isOpen: true, lastOpen: today, subscribed: isSubscribe ? true : undefined })
  }

  return (
    <div className="flex flex-col items-center justify-center p-5">
      <div className="w-full lg:w-[60vw] flex flex-col justify-start items-start px-4 pt-8">
        {/* <div className="flex flex-row gap-3 items-center text-black dark:text-white">
          <h1 className="text-3xl font-bold">Start Conversation</h1>
        </div> */}
        <div className="text-neutral-800 dark:text-white">
          <h1 className="font-bold text-5xl">DapperGPT</h1>
          <div>A better ChatGPT Experience <span> - Free to use</span></div>

          <div className="mb-3">
            <a href="https://www.producthunt.com/posts/dappergpt?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-dappergpt" target="_blank">
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=389637&theme=light&period=daily"
                alt="DapperGPT - Better&#0032;UI&#0032;for&#0032;ChatGPT&#0032;with&#0032;customize&#0032;chat&#0044;&#0032;notes&#0032;&#0038;&#0032;extension | Product Hunt"
                style={{ width: '200px', height: '54px' }} width="250" height="54"
              />
            </a>
          </div>
          <div className="mt-8 grid grid-rows-1 sm:grid-cols-2 gap-y-2 gap-x-6 w-full">

            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Save to Local or Cloud</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Smart Search</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Customize Chat</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>AI-Powered Note</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Chrome Extension (autofill post or comment)</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Pin Favorite Chat</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Save Prompts</p>
            </div>
            <div className="flex flex-row items-center gap-2">
              <BsFillCheckCircleFill />
              <p>Code Preview</p>
            </div>
          </div>
          {!isSubscribe && (
            <Button
              onClick={onUpgrade}
              className="flex flex-row items-center gap-2 bg-orange-500 md:w-[180px] rounded-md !py-2 px-4 mt-4 text-white text-semibold shadow-md shadow-orange-400/20 dark:shadow-none"
            >
              <AiFillCrown />
              View all features
            </Button>
          )}
        </div>
        {!apiKey?.key ? (
          <div className="mt-16 gap-4 flex flex-col items-start dark:text-white">
            <p className="text-sm">To get started, let&apos;s setup <a href="https://help.openai.com/en/articles/4936850-where-do-i-find-my-secret-api-key" target="_blank" rel="noreferrer" className="underline">your OpenAI API Key</a></p>
            <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2">
              <Button
                onClick={() => setApiKeyModal(true)}
                className="flex flex-row items-center rounded-full px-6 !py-2 !bg-black"
              >
                <AiOutlineKey /><span className="ml-1 whitespace-nowrap">Setup API Key</span>
              </Button>
              <Button onClick={handleNewChat} className="flex flex-row items-center rounded-full px-6 !py-2 !bg-blue-700">
                <BsChat className="text-xl" />
                <span className="ml-1 whitespace-nowrap">Start Chat</span>
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <Button onClick={handleNewChat} className="flex flex-row items-center rounded-full px-6 !py-2 !bg-blue-700">
              <BsChat className="text-xl" />
              <span className="ml-1 whitespace-nowrap">Start a New Chat</span>
            </Button>
          </div>
        )}
        <div className="dark:text-gray-400 text-gray-700 mt-5 text-sm">
          <div>DapperGPT is currently in beta and more updates & features are coming soon!</div>
          <div>If you have any feedback, please let us know on <a href="mailto:hi@dappergpt.com" target="_blank" rel="noreferrer" className="underline">Email hi@dappergpt.com</a></div>
        </div>
        <div className="mt-5">
          <div className="relative">
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="mt-5 flex flex-row flex-wrap gap-4 justify-start">
              {/* <InstructionCard
                title="Start Conversation"
                icon={<BsChat className="text-xl" />}
                description="Create a new chat session"
                onClick={handleNewChat}
                className="!bg-blue-600"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
ChatConversationNotSelected.displayName = 'ChatConversationNotSelected'

const ChatConversationInit = () => {
  const setNewInstructionPopup = useSetAtom(newInstructionPopupAtom)
  const [modelPopup, setModelPopup] = useAtom(modelPopupAtom)
  const [search, setSearch] = useState('')
  const [chatSession, setChatSession] = useAtom(chatSessionActiveAtom)
  const savedInstructions = useAtomValue(savedInstructionsAtom)

  const allInstructions = useMemo<SavedInstructionWithIconColor[]>(() => {
    const defaultIns: SavedInstructionWithIconColor[] = chatInstructionsDefault
      .map(a => {
        return {
          id: generateNanoId(14),
          name: a.name,
          isDefault: true,
          color: a.color,
          icon: a.icon,
          description: a.short_description,
          instruction: a.instruction,
          created: new Date(),
        }
      })

    const savedFixed: SavedInstructionWithIconColor[] = (savedInstructions || []).map(a => {
      return {
        ...a,
        isDefault: false,
      }
    })

    // only show 8
    return [...savedFixed, ...defaultIns]
      .filter(a => a.name.toLowerCase().includes(search.toLowerCase())
        || (a?.description || '').toLowerCase().includes(search.toLowerCase())
        || a.instruction.toLowerCase().includes(search.toLowerCase())
      )
      .slice(0, 7)

  }, [savedInstructions, search])

  const onSelect = (id: string) => {
    const selected = allInstructions.find(a => a.id === id)
    const messages = chatSession?.messages || []

    // If chat
    if (selected) {
      if (chatSession) {
        // if init and no message, add it as default instruction
        if (chatSession.isInit && chatSession.messages.length === 0) {
          setChatSession({
            ...chatSession,
            ...selected.defaultModel && { defaultModel: selected.defaultModel },
            defaultInstruction: selected?.instruction,
          })
        } else {
          setChatSession({
            ...chatSession,
            messages: [
              ...messages,
              {
                id: generateLocalIdByRole('system'),
                chatSessionId: chatSession.id,
                content: {
                  contentType: 'text', content: selected?.description
                },
                model: selected.defaultModel,
                userId: '',
                senderRole: 'system',
                created: new Date(),
              }
            ]
          })
        }
      }
    }
  }

  const onNew = () => {
    setModelPopup({ open: true, type: 'instruction' })
    setNewInstructionPopup(true)
  }

  return (
    <div className="flex flex-col items-center justify-start h-[70vh] pt-14 p-5">
      <div className="w-full lg:w-[60vw] flex flex-col justify-start items-center pb-32">
        <div className="flex flex-row gap-3 items-center text-black dark:text-white">
          <h1 className="text-3xl font-bold">Start Conversation</h1>
        </div>
        {/* <div className="my-3">
          <a href="https://www.producthunt.com/posts/dappergpt?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-dappergpt" target="_blank">
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=389637&theme=light&period=daily"
              alt="DapperGPT - Better&#0032;UI&#0032;for&#0032;ChatGPT&#0032;with&#0032;customize&#0032;chat&#0044;&#0032;notes&#0032;&#0038;&#0032;extension | Product Hunt"
              style={{ width: '250px', height: '54px' }} width="250" height="54"
            />
          </a>
        </div> */}
        <div className="mt-5">
          <div className="relative">
          </div>
          <div className="relative flex flex-row justify-between mx-4 sm:mx-7 mb-5">
            <ChatActionSelectModel />
          </div>
          <div className="flex flex-col justify-center items-center max-w-[100vw] lg:max-w-full w-[36rem]">
            <div className="flex flex-row items-center justify-center gap-2 max-w-[calc(100vw-20px)] lg:max-w-full w-[33rem] mx-20">
              {/* <p className='font-semibold text-lg w-[120px] dark:text-white hidden lg:block'>Character</p> */}
              <TextInputLabelForm
                id="search"
                label=""
                className="mt-3 mb-5 flex-1 !w-auto"
                inputClassName="!bg-gray-100 dark:!bg-neutral-800 border-2 dark:!border-neutral-600 dark:text-white"
                placeholder="Search Character Instruction..."
                value={search}
                onChange={e => setSearch(e)}
                skipDarkClass
              />
            </div>
            <div className="mt-2 flex flex-row flex-wrap gap-4 justify-center">
              {/* <InstructionCard
                title="Character Instruction"
                icon={<BsRobot className="text-xl" />}
                description="Instruct your AI Character and how it will behave"
                onClick={() => setModelPopup({ open: true, type: 'instruction' })}
                className="bg-green-500 w-[100px]"
              /> */}
              <InstructionCardSmall
                title="Add New & Update"
                icon={<BsPlus className="text-2xl text-gray-700" />}
                description="Take notes and save it to your chat session"
                onClick={onNew}
                titleClassName="!text-gray-700"
                className="!bg-gray-300 w-[45vw] sm:w-[120px]"
              />
              {allInstructions.map((a, i) => (
                <InstructionCardSmall
                  key={a.id}
                  title={a.name}
                  icon={a.icon || <BsPeople className="text-xl" />}
                  description=""
                  onClick={() => onSelect(a.id)}
                  className="w-[45vw] sm:w-[120px]"
                  color={a.color || '#000000'}
                  {...!a.isDefault ? {
                    extraNode: (
                      <div className="absolute top-1 right-1 p-1 rounded text-[9px] bg-gray-400 text-black py-[2px]">
                        custom
                      </div>
                    )
                  } : {}}
                />
              ))}
              <Button
                size="sm"
                className="h-7 px-5 !py-3 bg-neutral-500"
                onClick={() => setModelPopup({ open: true, type: 'instruction' })}
              >
                Show all
              </Button>
              {/* <InstructionCardSmall
                title="Show All"
                icon={<BsPlus className="text-xl" />}
                description="Take notes and save it to your chat session"
                onClick={() => setModelPopup({ open: true, type: 'instruction' })}
                className="bg-yellow-500 w-[120px]"
              /> */}
              {/* <InstructionCard
                title="Prompt"
                icon={<IoMdConstruct className="text-xl" />}
                description="Start with Prompt just prompt or check some useful prompts"
                onClick={() => setModelPopup({ open: true, type: 'prompt' })}
                className="bg-slate-700 w-[300px]"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export const sessionDefaultModelAtom = selectAtom(chatSessionActiveAtom, (session) => ({
  defaultModel: session?.defaultModel,
  isSync: session?.isSync
}), deepEqual)

// eslint-disable-next-line react/display-name
const ChatActionSelectModel = memo(() => {
  const user = useAtomValue(userAtom)
  const { defaultModel, isSync } = useAtomValue(sessionDefaultModelAtom)

  const selectedModel = useMemo(() => {
    const model = openAiChatModels.find(a => a.value === defaultModel)
    return model
  }, [defaultModel])

  const onSetSession = useSetAtom(chatSessionActiveAtom)

  const onModelChange = (model: ChatModel) => {
    onSetSession({ defaultModel: model })
  }

  const onSyncChange = useCallback((sync: boolean) => {
    onSetSession({ isSync: sync })
  }, [onSetSession])

  useEffect(() => {
    if (isSync === undefined && user?.id) {
      onSyncChange(true)
    }
  }, [isSync, onSyncChange, user?.id])


  return (
    <div className="flex flex-row items-center justify-between w-full">
      <div className="">
        <Menu as="div" className="relative inline-block text-left z-30">
          <div>
            <Menu.Button className="p-1 px-1 w-full justify-center rounded-md bg-[#0B141A] text-sm font-medium text-white hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <div className="!bg-[#353541] inline-flex items-center gap-2 p-2 rounded">
                <ChatModel model={selectedModel?.type || ''} />
                <p className="text-gray-300">{selectedModel?.label || 'GPT-3.5-Turbo'}</p>
                <BiChevronDown
                  className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                  aria-hidden="true"
                />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div>
              <Menu.Items className="absolute left-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <p className="p-2 text-sm">Default model <span className="text-xs text-gray-500">(can be update)</span> :</p>
                <div className="px-1 py-1 max-h-[500px] overflow-auto">
                  {openAiChatModels.map(a => (
                    <Menu.Item key={a.value}>
                      {({ active }) => (
                        <button
                          onClick={() => onModelChange(a.value)}
                          className={`${defaultModel === a.value
                            ? 'bg-blue-100' : active ? 'bg-blue-800 text-white' : 'text-gray-900'
                            } group flex flex-row gap-2 w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <ChatModel model={a.type} />
                          <div>
                            <p className="text-left font-medium">{a.label}</p>
                            <p className="text-left text-[9px] leading-3 text-gray-500">{a.desc}</p>
                          </div>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </div>
          </Transition >
        </Menu>
      </div>
      {(user.id && user.isSynced) && (
        <ChatSyncToCloudButton
          value={!!isSync}
          onChange={onSyncChange}
        />
      )}
    </div>
  )
})

const ChatModel = memo(({ model }: { model: string }) => {
  return (
    <>
      {model.startsWith('gpt-4') ? (
        <div className="text-purple-500">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" className="icon-sm transition-colors group-hover/button:text-brand-purple" width="16" height="16"><path d="M12.784 1.442a.8.8 0 0 0-1.569 0l-.191.953a.8.8 0 0 1-.628.628l-.953.19a.8.8 0 0 0 0 1.57l.953.19a.8.8 0 0 1 .628.629l.19.953a.8.8 0 0 0 1.57 0l.19-.953a.8.8 0 0 1 .629-.628l.953-.19a.8.8 0 0 0 0-1.57l-.953-.19a.8.8 0 0 1-.628-.629l-.19-.953h-.002ZM5.559 4.546a.8.8 0 0 0-1.519 0l-.546 1.64a.8.8 0 0 1-.507.507l-1.64.546a.8.8 0 0 0 0 1.519l1.64.547a.8.8 0 0 1 .507.505l.546 1.641a.8.8 0 0 0 1.519 0l.546-1.64a.8.8 0 0 1 .506-.507l1.641-.546a.8.8 0 0 0 0-1.519l-1.64-.546a.8.8 0 0 1-.507-.506L5.56 4.546Zm5.6 6.4a.8.8 0 0 0-1.519 0l-.147.44a.8.8 0 0 1-.505.507l-.441.146a.8.8 0 0 0 0 1.519l.44.146a.8.8 0 0 1 .507.506l.146.441a.8.8 0 0 0 1.519 0l.147-.44a.8.8 0 0 1 .506-.507l.44-.146a.8.8 0 0 0 0-1.519l-.44-.147a.8.8 0 0 1-.507-.505l-.146-.441Z" fill="currentColor"></path></svg>
        </div>
      ) : model.startsWith('gpt-3.5') ? (
        <div className="text-green-500">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" className="icon-sm transition-colors text-brand-green" width="16" height="16"><path d="M9.586 1.526A.6.6 0 0 0 8.553 1l-6.8 7.6a.6.6 0 0 0 .447 1h5.258l-1.044 4.874A.6.6 0 0 0 7.447 15l6.8-7.6a.6.6 0 0 0-.447-1H8.542l1.044-4.874Z" fill="currentColor"></path></svg>
        </div>
      ) : (
        <div className="text-green-500">
          <img src="/images/anthropic.svg" alt="Anthropic" width="16px" height="16px" />
        </div>
      )}
    </>
  )
})
ChatModel.displayName = 'ChatModel'
