import { api } from "@acme/client"
import { FaStar, toast, ConditionalRender, ListBubble, AiFillMessage } from "@acme/ui"
import { defaultModelPrismaToString, type ChatSession, debounce, type ChatMessage, dayjs } from "@acme/util"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useCallback, useEffect, useMemo } from "react"
import { chatFavoritesAtom, chatSessionsAtom, chatSessionActiveIdAtom } from "../store/chat"
import { teamActiveAtom } from "../store/user"
import { useRouter } from "next/router"
import { selectAtom } from "jotai/utils"


const intersectById = (localStorageData: ChatSession[], apiData: ChatSession[]): ChatSession[] => {
  const localStorageDataMap = new Map(localStorageData.map(item => [item.id, item]))

  return apiData.map(apiItem => {
    const localStorageItem = localStorageDataMap.get(apiItem.id)

    if (localStorageItem) {
      localStorageDataMap.delete(apiItem.id)
      return {
        ...localStorageItem,
        ...apiItem,
        messages: [...localStorageItem.messages],
        isInit: false,
      }
    }

    return apiItem
  }).concat(Array.from(localStorageDataMap.values()))
}

// Function to sort chat sessions by order and orderUpdated
function sortChatSessions(chatSessions: ChatSession[]): ChatSession[] {
  return chatSessions.sort((a, b) => {
    const aOrder = a.order || 100;
    const bOrder = b.order || 100;

    if (aOrder && bOrder) {
      return aOrder - bOrder
    } else if (aOrder === bOrder) {
      // If order is the same, sort by orderUpdated
      // if a.orderUpdated exist and b.orderUpdated does not, a should be first
      // if b.orderUpdated exist and a.orderUpdated does not, b should be first
      // if neither exist, they are equal

      const aOrderUpdated = a.orderUpdated ? a.orderUpdated : a.created;
      const bOrderUpdated = b.orderUpdated ? b.orderUpdated : b.created;
      if (aOrderUpdated && b.orderUpdated) {
        return dayjs(aOrderUpdated).valueOf() - dayjs(bOrderUpdated).valueOf();
      } else if (aOrderUpdated && !bOrderUpdated) {
        return -1;
      } else if (bOrderUpdated && !aOrderUpdated) {
        return 1;
      } else {
        return 0;
      }
    } else if (aOrder && !bOrder) {
      return aOrder
    } else if (!aOrder && bOrder) {
      return bOrder
    }

    /*  const aLastMessage = a.messages[a.messages.length - 1];
     const bLastMessage = b.messages[b.messages.length - 1];
     const aDate = aLastMessage ? aLastMessage.created : a.created;
     const bDate = bLastMessage ? bLastMessage.created : b.created;
 
     // Sort in descending order (newest first)
     return bDate.getTime() - aDate.getTime(); */

    return 0
  });
}
// Function to sort chat sessions by last message / chat created
function sortChatSessionsByLastMessage(chatSessions: ChatSession[]): ChatSession[] {
  return chatSessions.sort((a, b) => {
    const aLastMessage = a.messages[a.messages.length - 1];
    const bLastMessage = b.messages[b.messages.length - 1];
    const aDate = aLastMessage ? dayjs(aLastMessage.created) : dayjs(a.created);
    const bDate = bLastMessage ? dayjs(bLastMessage.created) : dayjs(b.created);

    // Sort in descending order (newest first)
    return bDate.diff(aDate);
  });
}

// only left last message
export const chatSessionsNoMessage = selectAtom(chatSessionsAtom, (chatSessions) => chatSessions.map(n => ({
  ...n,
  messages: (n?.messages?.length && n.messages?.[n?.messages?.length - 1]) ? [n.messages[n.messages.length - 1] as ChatMessage] : []
}) satisfies ChatSession))

export const ChatList = () => {
  const router = useRouter()
  const chatId = router.query.chatId as string

  const teamActive = useAtomValue(teamActiveAtom)
  const chatSessions = useAtomValue(chatSessionsNoMessage)
  const [chatSessionActiveId, setChatSessionActiveId] = useAtom(chatSessionActiveIdAtom)
  const chatFavorites = useAtomValue(chatFavoritesAtom)
  const setChatSessions = useSetAtom(chatSessionsAtom)
  const setChatFavorites = useSetAtom(chatFavoritesAtom)

  const { isLoading: isLoadingFavorites } = api.chat.chatFavorites.useQuery(undefined,
    {
      enabled: !!teamActive?.id,
      onSuccess: (data) => {
        const cloudFavorites = data.map(f => ({ chatSessionId: f.chatSessionId, created: f.created as Date, updated: f.updated as Date }))
        setChatFavorites((favorites) => {
          // Find local favorites not in the cloud
          const localFavorites = favorites.filter(s => !cloudFavorites.some(a => a.chatSessionId === s.chatSessionId));
          // Find cloud favorites, and for each, update the local favorite with isSync: true
          const updatedCloudFavorites = cloudFavorites.map(cloudFavorite => {
            const localFavorite = favorites.find(fav => fav.chatSessionId === cloudFavorite.chatSessionId);
            return {
              ...localFavorite,
              ...cloudFavorite,
              isSync: true
            };
          });

          // Combine the updated cloud favorites and the local favorites not in the cloud
          return [...updatedCloudFavorites, ...localFavorites];
        });
      },
      // 20 seconds
      staleTime: 20000
    }
  )
  const { mutateAsync: onUpdateChatSession, isLoading: isLoadingUpdate } = api.chat.updateChatSession.useMutation()

  const { isLoading } = api.chat.chatSessions.useQuery(
    { teamId: teamActive?.id },
    {
      enabled: !!teamActive?.id,
      onSuccess: (data) => {
        const newChatSessions: ChatSession[] = (data || []).map(s => {
          return {
            ...s,
            localId: s.localId || '',
            name: s.name || '',
            isStreaming: s.isStreaming ? s.isStreaming : false,
            defaultInstruction: s.defaultInstruction || undefined,
            messages: [],
            defaultModel: s.defaultModel ? defaultModelPrismaToString(s.defaultModel) : undefined,
            created: s.created as Date,
            updated: s.updated as Date,
            isImageGen: s.isImageGen ? s.isImageGen : false,
            order: s.order || undefined,
            orderUpdated: s.orderUpdated || undefined,
            isInit: false,
            isSync: true
          }
        })
        setChatSessions(chatSessions => {
          // if isSync and not isInit then not exist in newChatSessions then delete
          const chatSessionsFiltered = chatSessions.filter(s => {
            if (s.isSync && !s.isInit) {
              return newChatSessions.some(a => a.id === s.id)
            }
            return true
          })
          const newChatSessionsIntersect = intersectById(chatSessionsFiltered, newChatSessions)
          const newChatSessionsSorted = sortChatSessions(newChatSessionsIntersect)
          return newChatSessionsSorted
        })
      },
      // 5 seconds
      staleTime: 5000
    },
  )
  const { mutateAsync: onDeleteChatSession } = api.chat.deleteChatSession.useMutation({
    onSuccess: (data) => {
      setChatSessions(prev => {
        return prev.filter(a => a.id !== data.id)
      })
    }
  })

  const chatSessionsSorted = useMemo(() => sortChatSessionsByLastMessage(chatSessions), [chatSessions])

  const chatSessionsFavorites = useMemo(() => {
    return chatSessionsSorted
      .filter(s => chatFavorites.some(f => f.chatSessionId === s.id))
      .map(s => {
        const lastMessage = s.messages[s.messages.length - 1]?.content?.content || s.messages[s.messages.length - 1]?.content?.content?.[0] || ''
        return {
          ...s,
          lastMessage
        }
      })
  }, [chatSessionsSorted, chatFavorites])

  const chatSesionsNormal = useMemo(() => {
    return chatSessionsSorted.filter(s => !chatFavorites.some(f => f.chatSessionId === s.id))
  }, [chatSessionsSorted, chatFavorites])

  const onDeleteSession = useCallback(async (id: string) => {
    if (chatSessions.length === 1) {
      toast.error('Cannot delete last session')
      return
    }
    const chatSession = chatSessions.find(a => a.id === id)
    if (!chatSession) return
    if (chatSession.isSync && !chatSession.isInit) {
      setChatSessions(prev => {
        return prev.map(a => {
          if (a.id === id) return { ...a, loading: true }
          return a
        })
      })
      try {
        await onDeleteChatSession({ id })
      } catch (error) {
        toast.error("Error deleting chat session")
        setChatSessions(prev => {
          return prev.map(a => {
            if (a.id === id) return { ...a, loading: undefined }
            return a
          })
        })
      }
    } else {
      setChatSessions(prev => {
        return prev.filter(a => a.id !== id)
      })
    }

    if (chatSessionActiveId === id) {
      const newId = chatSessions.find(a => a.id !== id)?.id || ''
      setChatSessionActiveId(newId)
      await router.push({ query: { ...router.query, chatId: newId } })
    }
  }, [chatSessions, chatSessionActiveId, setChatSessions, onDeleteChatSession, setChatSessionActiveId, router])

  const onEdit = useCallback(async (id: string, name: string) => {
    const findSession = chatSessions.find(a => a.id === id)
    if (!findSession) return
    if (findSession.isSync && !findSession.isInit) {
      await onUpdateChatSession({ id, name })
    }
    setChatSessions(prev => {
      return prev.map(a => {
        if (a.id === id) {
          return { ...a, name }
        }
        return a
      })
    })
  }, [chatSessions, onUpdateChatSession, setChatSessions])


  const onSelect = useCallback(async (id: string) => {
    await router.push({ query: { ...router.query, chatId: id } })
  }, [router])

  const onSetChatSessionActiveDebounce = useCallback(debounce((id: string) => setChatSessionActiveId(id), 70), [setChatSessionActiveId])

  useEffect(() => {
    if (chatId && router?.isReady) {
      if (chatId !== chatSessionActiveId) {
        onSetChatSessionActiveDebounce(chatId)
      }
    }
  }, [chatId, chatSessionActiveId, onSetChatSessionActiveDebounce, router?.isReady, setChatSessionActiveId])

  return (
    <>
      <div className="border-b-[1px] border-neutral-800 shadow-lg shadow-white ml-2" />
      <div className="flex flex-col gap-3">
        <ConditionalRender
          show={!!chatSessionsFavorites.length}
        >
          <div className="flex flex-col pl-2 gap-1">
            <div className="flex flex-row gap-2 items-center px-4 pt-2 pb-2 -ml-4">
              <FaStar className="text-yellow-400 text-sm" />
              <p className="text-yellow-400 text-xs uppercase font-light">Favorites</p>
            </div>
            {chatSessionsFavorites.map(a => (
              <ChatSessionBubble
                key={a.id}
                isActive={a.id === chatSessionActiveId}
                chatSession={a}
                onEdit={onEdit}
                onDelete={onDeleteSession}
                onSelect={onSelect}
                isFavorite
              />
            ))}
          </div>
        </ConditionalRender>

        <div className="flex flex-col pl-2 gap-1">
          <div className="flex flex-row gap-2 items-center px-4 pt-2 pb-2 -ml-4">
            <AiFillMessage className="text-gray-400 text-sm" />
            <p className="text-gray-400 text-xs uppercase font-light">All Chats</p>
          </div>
          {chatSesionsNormal.map(a => (
            <ChatSessionBubble
              key={a.id}
              isActive={a.id === chatSessionActiveId}
              chatSession={a}
              onEdit={onEdit}
              onDelete={onDeleteSession}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    </>
  )
}

type ChatSessionBubbleProps = {
  chatSession: ChatSession
  isActive: boolean
  isFavorite?: boolean
  onEdit: (id: string, name: string) => void
  onDelete: (id: string) => void
  onSelect: (id: string) => void
}

export const ChatSessionBubble = ({ chatSession, isFavorite, onDelete, onSelect, onEdit, isActive = false }: ChatSessionBubbleProps) => {
  const { name, messages, loading = false } = chatSession

  const lastMessage = useMemo(() => {
    if (messages.length === 0) return ''
    const veryLast = messages[messages.length - 1]?.content?.content || messages[messages.length - 1]?.content?.content?.[0] || ''
    if (!veryLast && messages.length > 1) {
      const last2 = messages[messages.length - 2]?.content?.content || messages[messages.length - 2]?.content?.content?.[0] || ''
      if (Array.isArray(last2)) return last2.join(' ')
      return last2
    }
    if (Array.isArray(veryLast)) return veryLast.join(' ')
    return veryLast
  }, [messages])

  return (
    <ListBubble
      data={{
        title: name,
        id: chatSession.id,
        desc: lastMessage || 'ㅤ'
      }}
      isFavorite={isFavorite}
      isActive={isActive}
      onEdit={onEdit}
      onDelete={onDelete}
      onSelect={onSelect}
      isSync={chatSession.isSync}
      isLoading={loading}
    />
  )
}


