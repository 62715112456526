import { type ReactNode } from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  size?: 'baseline' | 'small' | 'tiny' | 'micro';

  skipDarkClass?: boolean;
}

const defaultClass = {
  baseline: 'text-base leading-6',
  small: 'text-sm leading-5',
  tiny: 'text-xs leading-4',
  micro: 'text-[10px] leading-3',
}

export const TextBody = ({
  children, className, size = 'baseline'
}: Props) => {
  return (
    <p className={`${defaultClass[size]} ${className}`}>{children}</p>
  );
};

export const TextLabel = ({
  children, className, size = 'md', skipDarkClass = false
}: { children: ReactNode, className?: string, size?: 'sm' | 'md', skipDarkClass?: boolean }) => {
  return (
    <label className={`text-neutral-500 ${!skipDarkClass ? 'dark:text-neutral-100' : ''} flex items-start mb-1 ${size === 'sm' ? 'text-sm' : ''} ${className}`}>
      {children}
    </label>
  );
};
