import { ChatModel, type ChatSession } from "@acme/util";
import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";
import { chatSessionActiveIdAtom, chatSessionsAtom } from "./chat";
import { noteActiveIdAtom, notesAtom } from "./note";
import { teamActiveAtom, userAtom, userSubscriptionAtom } from "./user";
import { savedInstructionsAtom } from "./instruction";
import { savedPromptsAtom } from "./instruction";
import { SpeechLanguageCode } from "../hooks";

type ApiKey = {
    id: string;
    key: string;
    created: Date;
}

export type ChatQuickOption = { enable: boolean, label: string, action: 'regenerate' | 'promptPopup' | 'prompt', value: string, prompt?: string; isAutoSend?: boolean, desc?: string }

export const chatQuickOptionsDefaultAction: ChatQuickOption[] = [
    { enable: true, label: 'Regenerate', action: 'regenerate', value: 'action:::regenerate', desc: 'Regenerate the response' },
    { enable: true, label: 'Prompt', action: 'promptPopup', value: 'action:::prompt', desc: 'Show to Prompt List Popup' },
]

export const chatQuickOptionsDefault: ChatQuickOption[] = [
    ...chatQuickOptionsDefaultAction,
    { enable: false, label: 'Continue', action: 'prompt', value: 'prompt:::Please Continue', prompt: 'Please continue', isAutoSend: true },
    { enable: false, label: 'Make it Short', action: 'prompt', value: 'prompt:::Please Make it Short', prompt: 'Please make it short', isAutoSend: true },
]



export const apiKeyExistAtom = atomWithStorage('apiKeyExist', false)
export const apiKeyAtom = atomWithStorage<ApiKey | null>('apiKey', null)
export const apiKeyAnthropicAtom = atomWithStorage<ApiKey | null>('apiKeyAnthropic', null)
export const archivedAtom = atomWithStorage<{ userId: { chats: ChatSession[] } }[]>("chatSessionsArchived", []);

export const speechToTextSettingsAtom = atomWithStorage<{ language: SpeechLanguageCode | null, show: boolean }>('speechToTextSettings', { language: null, show: false })
export const textToSpeechSettingsAtom = atomWithStorage<{ language: SpeechLanguageCode, voiceURI: string, rate: number, pitch: number, showButton: boolean, show: boolean }>('textToSpeechSettings', {
    language: 'en-US', voiceURI: 'Daniel', rate: 1, pitch: 1, showButton: true, show: false
})


export const generalDefaultSettingsAtom = atomWithStorage<{ soundCompletion?: boolean }>('generalDefaultSettings', { soundCompletion: true })
export const chatDefaultSettingsAtom = atomWithStorage<{ defaultModel?: ChatModel, defaultMaxContextTokens?: number | 'auto' | '' }>(
    'chatDefaultSettings',
    {}
)
export const chatQuickOptionsAtom = atomWithStorage<{ list: ChatQuickOption[] }>('chatQuickOptions', { list: chatQuickOptionsDefault })

export const deleteNonSyncAtom = atom(null,
    (get, set, param?: { removeNonSync?: boolean }) => {
        const chats = get(chatSessionsAtom);
        const notes = get(notesAtom);

        set(chatSessionActiveIdAtom, null);
        set(noteActiveIdAtom, null);

        const chatsToKeep = chats.filter(chat => !chat.isSync);
        const notesToKeep = notes.filter(note => !note.isSync);

        set(userAtom, { id: "" })
        set(userSubscriptionAtom, null)
        set(teamActiveAtom, null)
        set(savedInstructionsAtom, [])
        set(savedPromptsAtom, [])

        if (param?.removeNonSync) {
            set(chatSessionsAtom, []);
            set(notesAtom, []);
        } else {
            set(chatSessionsAtom, chatsToKeep);
            set(notesAtom, notesToKeep);
        }
        set(apiKeyAtom, null)
        set(apiKeyExistAtom, false)
    }
)
