import { api } from "@acme/client"
import { FaStar, toast, ConditionalRender, ListBubble, AiFillMessage } from "@acme/ui"
import { defaultModelPrismaToString, type Note } from "@acme/util"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useCallback, useEffect, useMemo } from "react"
import { notesFavoritesAtom, notesAtom, noteActiveIdAtom } from "../store/note"
import { teamActiveAtom } from "../store/user"
import { useRouter } from "next/router"
import { selectAtom } from "jotai/utils"

const intersectById = (localStorageData: Note[], apiData: Note[]): Note[] => {
  const localStorageDataMap = new Map(localStorageData.map(item => [item.id, item]))

  return apiData.map(apiItem => {
    const localStorageItem = localStorageDataMap.get(apiItem.id)

    if (localStorageItem) {
      localStorageDataMap.delete(apiItem.id)
      return {
        ...localStorageItem,
        ...apiItem,
        isInit: false,
      }
    }

    return apiItem
  }).concat(Array.from(localStorageDataMap.values()))
}

// Function to sort note sessions by order and orderUpdated
function sortNotes(notes: Note[]): Note[] {
  return notes.sort((a, b) => {
    if (a.order === b.order) {
      // If order is the same, sort by orderUpdated
      // if a.orderUpdated exist and b.orderUpdated does not, a should be first
      // if b.orderUpdated exist and a.orderUpdated does not, b should be first
      // if neither exist, they are equal
      if (a.orderUpdated && b.orderUpdated) {
        return new Date(a.orderUpdated).getTime() - new Date(b.orderUpdated).getTime();
      } else if (a.orderUpdated && !b.orderUpdated) {
        return -1;
      } else if (b.orderUpdated && !a.orderUpdated) {
        return 1;
      } else {
        return 0;
      }
    }
    if (a.order) return a.order
    return 0
  });
}

export const notesNoContentAtom = selectAtom(notesAtom, (notes) => notes.map(n => ({ ...n, content: [], markdown: '' })))

export const NoteList = () => {
  const router = useRouter()
  const noteId = router.query.noteId as string

  const teamActive = useAtomValue(teamActiveAtom)
  const notes = useAtomValue(notesNoContentAtom)
  const setNotes = useSetAtom(notesAtom)
  const [noteActiveId, setNoteActiveId] = useAtom(noteActiveIdAtom)
  const noteFavorites = useAtomValue(notesFavoritesAtom)
  const setNoteFavorites = useSetAtom(notesFavoritesAtom)

  const { isLoading: isLoadingFavorites } = api.note.noteFavorites.useQuery(undefined,
    {
      enabled: !!teamActive?.id,
      onSuccess: (data) => {
        setNoteFavorites(data.map(f => ({ noteId: f.noteId, created: f.created as Date, updated: f.updated as Date })))
      }
    }
  )
  const { mutateAsync: onUpdateNote, isLoading: isLoadingUpdate } = api.note.updateNote.useMutation()

  const { isLoading } = api.note.notes.useQuery(
    { teamId: teamActive?.id },
    {
      enabled: !!teamActive?.id,
      onSuccess: (data) => {
        const newNotes: Note[] = (data || []).map(s => {
          return {
            ...s,
            markdown: s.markdown || '',
            localId: s?.id || '',
            name: s.name || '',
            defaultInstruction: s.defaultInstruction || '',
            defaultContinueInstruction: s.defaultContinueInstruction || '',
            defaultWriteInstruction: s.defaultWriteInstruction || '',
            defaultSelectInstruction: s.defaultSelectInstruction || '',
            defaultModel: s.defaultModel ? defaultModelPrismaToString(s.defaultModel) : undefined,
            created: s.created as Date,
            updated: s.updated as Date || undefined,
            order: s.order || undefined,
            orderUpdated: s.orderUpdated || undefined,
            isInit: false,
            isSync: true
          }
        })

        setNotes(prevNotes => {
          // if isSync and not isInit then not exist in newNotes then delete
          const notesFiltered = prevNotes.filter(s => {
            if (s.isSync && !s.isInit) {
              return newNotes.some(a => a.id === s.id)
            }
            return true
          })
          const newNotesIntersect = intersectById(notesFiltered, newNotes)
          const newNotesSorted = sortNotes(newNotesIntersect)
          return newNotesSorted
        })
      }
    }
  )
  const { mutateAsync: onDeleteNote } = api.note.deleteNote.useMutation({
    onSuccess: (data) => {
      setNotes(prev => {
        return prev.filter(a => a.id !== data.id)
      })
    }
  })

  const notesFavorites = useMemo(() => {
    return notes
      .filter(s => noteFavorites.some(f => f.noteId === s.id))
  }, [notes, noteFavorites])

  const noteSesionsNormal = useMemo(() => {
    return notes.filter(s => !noteFavorites.some(f => f.noteId === s.id))
  }, [notes, noteFavorites])

  const onDelete = useCallback(async (id: string) => {
    if (notes.length === 1) {
      toast.error('Cannot delete last session')
      return
    }
    const note = notes.find(a => a.id === id)
    if (!note) return
    if (note.isSync && !note.isInit) {
      setNotes(prev => {
        return prev.map(a => {
          if (a.id === id) return { ...a, loading: true }
          return a
        })
      })
      try {
        await onDeleteNote({ id })
      } catch (error) {
        toast.error("Error deleting note session")
        setNotes(prev => {
          return prev.map(a => {
            if (a.id === id) return { ...a, loading: undefined }
            return a
          })
        })
      }
    } else {
      setNotes(prev => {
        return prev.filter(a => a.id !== id)
      })
    }

    if (noteActiveId === id) {
      const newId = notes.find(a => a.id !== id)?.id || ''
      setNoteActiveId(newId)
      await router.push({ query: { ...router.query, noteId: newId } })
    }
  }, [notes, noteActiveId, setNotes, onDeleteNote, setNoteActiveId, router])

  const onEdit = useCallback(async (id: string, name: string) => {
    const findNote = notes.find(a => a.id === id)
    if (!findNote) return
    if (findNote.isSync && !findNote.isInit) {
      await onUpdateNote({ id, name })
    }
    setNotes(prev => {
      return prev.map(a => {
        if (a.id === id) {
          return { ...a, name }
        }
        return a
      })
    })
  }, [notes, onUpdateNote, setNotes])


  const onSelect = useCallback(async (id: string) => {
    await router.push({ query: { ...router.query, noteId: id } })
  }, [router])

  useEffect(() => {
    if (noteId) {
      if (noteId !== noteActiveId) {
        setNoteActiveId(noteId)
      }
    }
  }, [noteId, noteActiveId, setNoteActiveId])

  return (
    <>
      <div className="border-b-[1px] border-neutral-700 shadow-lg shadow-white" />
      <div className="flex flex-col gap-3">
        <ConditionalRender
          show={!!notesFavorites.length}
        >
          <div className="flex flex-col ">
            <div className="flex flex-row gap-2 items-center px-4 pt-2 pb-2">
              <FaStar className="text-gray-400 text-sm" />
              <p className="text-gray-400 text-xs uppercase font-light">Favorites</p>
            </div>
            {notesFavorites.map(a => (
              <NoteBubble
                key={a.id}
                isActive={a.id === noteActiveId}
                note={a}
                onEdit={onEdit}
                onDelete={onDelete}
                onSelect={onSelect}
                isFavorite
              />
            ))}
          </div>
        </ConditionalRender>
        <div>
          <div className="flex flex-row gap-2 items-center px-4 pt-2 pb-2">
            <AiFillMessage className="text-gray-400 text-sm" />
            <p className="text-gray-400 text-xs uppercase font-light">All Notes</p>
          </div>
          {noteSesionsNormal.map(a => (
            <NoteBubble
              key={a.id}
              isActive={a.id === noteActiveId}
              note={a}
              onEdit={onEdit}
              onDelete={onDelete}
              onSelect={onSelect}
            />
          ))}
        </div>
      </div>
    </>
  )
}

type NoteBubbleProps = {
  note: Note
  isActive: boolean
  isFavorite?: boolean
  onEdit: (id: string, name: string) => void
  onDelete: (id: string) => void
  onSelect: (id: string) => void
}

export const NoteBubble = ({ note, isFavorite, onDelete, onSelect, onEdit, isActive = false }: NoteBubbleProps) => {
  const { name, loading = false } = note

  return (
    <ListBubble
      data={{
        title: name,
        id: note.id,
      }}
      isFavorite={isFavorite}
      isActive={isActive}
      onEdit={onEdit}
      onDelete={onDelete}
      onSelect={onSelect}
      isSync={note.isSync}
      isLoading={loading}
    />
  )
}


