import { TextInputLabelForm, PopupWrapperAnimated, TextInputAreaLabelForm, Button, ButtonClose, toast } from "@acme/ui"
import { useAtom, useSetAtom } from "jotai"
import { useCallback, useState } from "react"
import { modelPopupAtom } from "../store/instruction"
import { useRouter } from "next/router"
import { composeContextImgsAtom } from "../store/util"

const isValidImageUrl = (url: string) => {
  return url.startsWith('http://') || url.startsWith('https://')
}


export const FileAttachPopup = () => {
  const [popup, setPopup] = useAtom(modelPopupAtom);
  if (popup?.open && popup?.type === 'attach-file') {
    return <FileAttachPopupContent />
  }
  return null
}

export const FileAttachPopupContent = () => {
  const [urlTemp, setUrlTemp] = useState<string>('')
  const [messageFiles, setMessageFiles] = useAtom(composeContextImgsAtom)
  const setModelPopup = useSetAtom(modelPopupAtom)
  const onClose = () => setModelPopup({ open: false, type: '' })

  const onRemoveFile = useCallback((index: number) => {
    setMessageFiles((prev) => {
      if (prev?.[index]) {
        [...prev].splice(index, 1)
        return prev
      }
      return []
    })
  }, [setMessageFiles])

  const onAddFile = useCallback(() => {
    if (!isValidImageUrl(urlTemp)) {
      toast.error('Invalid image URL')
      return
    }
    setMessageFiles((prev) => {
      if (prev) {
        return [...prev, { url: urlTemp, type: 'image' }]
      } else {
        return [{ url: urlTemp, type: 'image' }]
      }
    })
    setUrlTemp('')
  }, [setMessageFiles, urlTemp])

  return (
    <>
      <PopupWrapperAnimated
        show
        onClose={onClose}
        className="w-84 rounded shadow z-50 p-5 relative  bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-white"
        containerClassName="!items-start pt-[15vh]"
      >
        <ButtonClose onClick={onClose} />

        <p className='font-semibold text-lg'>Attach {/* File or  */}Image(s)</p>
        <div className="flex flex-col gap-1 items-center mb-5 mt-5">
          <TextInputAreaLabelForm
            label=""
            className="mb-1"
            placeholder="Image URL..."
            value={urlTemp}
            onChange={e => setUrlTemp(e)}
          />
          <Button
            size="sm"
            className="w-full"
            onClick={onAddFile}
          >
            + Add
          </Button>
        </div>

        <div className="max-h-[80vh] lg:max-h-[500px] overflow-y-auto relative  max-w-full w-full lg:w-[50vw]">
          {messageFiles?.length === 0 ? (
            <p className='text-center text-gray-800 dark:text-gray-400 mb-3'>No files attached</p>
          ) : (
            <p className='text-center text-gray-800 dark:text-gray-400 mb-3'>{messageFiles?.length} files attached</p>
          )}

          <div className='flex flex-col gap-2'>
            {messageFiles?.map((file, index) => (
              <div key={index} className='flex items-center justify-between bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-white p-2 pr-4 rounded-lg'>
                <div className='flex items-center gap-2'>
                  <img src={file.url} alt={file.url} className='w-20 h-20 rounded-lg' />
                  <p className='text-[10px]'>{file.url}</p>
                </div>
                <button
                  className='text-red-500 ml-2'
                  onClick={() => {
                    onRemoveFile(index)
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </PopupWrapperAnimated>
    </>
  )
}

