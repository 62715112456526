import jsonwebtoken from 'jsonwebtoken'
import axiosHere from 'axios'
import jsCookiesHere from 'js-cookie'
import produceImmer, { current as currentImmer } from 'immer'
import dayshere from 'dayjs'
import fastDeepEqual from 'fast-deep-equal'
import relativeTime from 'dayjs/plugin/relativeTime'
import debounceFn from 'lodash.debounce'
import {
    encode,
    decode,
    isWithinTokenLimit,
    encodeGenerator,
    decodeGenerator,
    decodeAsyncGenerator,
} from 'gpt-tokenizer'

dayshere.extend(relativeTime)

export const jwt = jsonwebtoken
export const axios = axiosHere
export const jsCookies = jsCookiesHere
export const produce = produceImmer
export const dayjs = dayshere
export const deepEqual = fastDeepEqual
export const current = currentImmer
export const debounce = debounceFn
export const tokenizer = {
    encode,
    decode,
    isWithinTokenLimit,
    encodeGenerator,
}
