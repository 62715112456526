import { useAtomValue, useSetAtom } from "jotai"
import { selectAtom } from "jotai/utils"
import { noteSettingsAtom } from "../store/note"
import { noteActiveAtom } from "../store/note"
import Head from 'next/head';
import { MdOutlineCloudSync, MdOutlineSyncDisabled } from "@acme/ui";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";

const noteActiveInfoAtom = selectAtom(noteActiveAtom, (data) => {
  if (data) {
    const { content, markdown, updated, ...allData } = data
    return allData
  }
  return null
})

export const NoteTitle = () => {
  const noteActive = useAtomValue(noteActiveInfoAtom)
  // trim and add ... if more than 20 characters
  const noteName = useMemo(() => ((noteActive?.name || '').length > 25 && isMobile) ? `${(noteActive?.name || '').slice(0, 25)}...` : noteActive?.name || '', [noteActive?.name])

  return (
    <div className="w-full flex flex-row items-center justify-between px-4 text-white">
      <h1 className="text-lg text-gray-700 dark:text-white whitespace-nowrap ">{noteName || ''}</h1>
    </div>
  )
}

export const NoteTitleMenu = () => {
  const noteActive = useAtomValue(noteActiveInfoAtom)
  const setNoteSettings = useSetAtom(noteSettingsAtom)

  return (
    <>
      <Head>
        <title>{noteActive?.name || 'DapperGPT'} - {noteActive?.name ? 'Note DapperGPT' : 'Note'}</title>
      </Head>
      <div className="w-full flex flex-row items-center justify-between px-4 gap-3 select-none">
        <p className="text-gray-400 dark:text-gray-400 hidden lg:flex flex-row items-center gap-1 text-xs">{noteActive?.isSync ? (<> <MdOutlineCloudSync className="text-lg" /><span>Synced</span></>) : (<><MdOutlineSyncDisabled className="text-lg" /><span>Not Sync</span></>)}</p>
        <p className="text-gray-400 dark:text-gray-400 text-sm flex lg:hidden">{noteActive?.isSync ? (<> <MdOutlineCloudSync className="text-lg" /></>) : (<><MdOutlineSyncDisabled className="text-lg text-gray-500" /></>)}</p>
        <div onClick={() => setNoteSettings(true)} className="bg-neutral-600 rounded-lg h-9 w-9 flex items-center justify-center cursor-pointer">
          ⚙️
        </div>
      </div>
    </>
  )
}