import React from 'react'
import { Switch } from '@headlessui/react'

const getSizeClass = (
  size: 'small' | 'medium' | 'large' | 'extrasmall'
): { switchClass: string; spanClass: string; translateClass: string } => {
  if (size === 'extrasmall') {
    return { switchClass: 'w-6 h-4', spanClass: 'w-3 h-3', translateClass: 'translate-x-2' }
  }
  if (size === 'small') {
    return { switchClass: 'w-8 h-5', spanClass: 'w-4 h-4', translateClass: 'translate-x-3' }
  }
  if (size === 'medium') {
    return { switchClass: 'w-12 h-6', spanClass: 'w-5 h-5', translateClass: 'translate-x-[21px]' }
  }
  if (size === 'large') {
    return { switchClass: 'w-12 h-7', spanClass: 'w-6 h-6', translateClass: 'translate-x-5' }
  }
  return { switchClass: '', spanClass: '', translateClass: '' }
}

export const SwitchWithLabel = ({
  value,
  onChange,
  label,
  size = 'medium',
  textClassName,
  switchClassName,
  spanClassName,
}: {
  value: boolean
  onChange: (a: boolean) => void
  label: string
  size?: 'small' | 'medium' | 'large' | 'extrasmall'
  textClassName?: string
  switchClassName?: string
  spanClassName?: string
}) => {
  const { switchClass = '', spanClass = '', translateClass = '' } = getSizeClass(size)

  return (
    <div>
      <Switch.Group as="div" className="flex items-center space-x-2">
        <Switch
          as="div"
          checked={value}
          onChange={(e: boolean) => {
            onChange(e)
            if (document) {
              if ('activeElement' in document) {
                if (document.activeElement) {
                  // @ts-ignore: Unreachable code error
                  document.activeElement.blur()
                }
              }
            }
          }}
          className={`${value ? 'bg-green-600' : 'bg-gray-600'
            } ${switchClass} ${switchClassName} relative inline-flex flex-shrink-0 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:shadow-outline`}
        >
          {({ checked }) => (
            <span
              className={`${checked ? translateClass : 'translate-x-0'
                } inline-block ${spanClass} ${spanClassName} transition duration-200 ease-in-out transform bg-white rounded-full`}
            />
          )}
        </Switch>
        <Switch.Label className={`cursor-pointer ${!textClassName ? 'text-white' : textClassName}`}>
          {label}
        </Switch.Label>
      </Switch.Group>
    </div>
  )
}
